.pdf {
    display: inline-block;
    text-align: center;
    width: 100%;

    &__image {
        @extend .img--responsive;
        border: 1px solid $blue;
        margin: 0 auto;
    }
}