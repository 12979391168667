.newsletters__toast {
    @include font-montserrat("SemiBold");
    animation: fadeout 0.5s 4.5s;
    animation-delay: 5s;
    animation-fill-mode: forwards;
    background-color: #1e6aaa;
    border: 3px solid black;
    border-radius: 2px;
    color: #ffffff;
    font-size: 20px;
    left: 50%;
    padding: 16px 40px;
    position: fixed;
    text-align: center;
    top: 150px;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;

    @include laptop {
        top: 200px;
    }

    @include desktop {
        top: 250px;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}