.deals {
    background-color: $beige;
    overflow: hidden;
    position: relative;

    $bg-width: 320px;
    $bg-height: 345px;

    &__background-container {
        background: no-repeat left top;
        background-size: cover;
        height: 0;
        padding-top: percentage($bg-height/$bg-width);
        position: relative;
        width: 100%;
        z-index: 5;

        &-md, &-lg, &-xl {
            display: none;
        }
    }

    &__view-coupons-arrow {
        background: url("/images/svg/dealsTriangle.svg") no-repeat left top;
        @include hide-text();
        background-size: cover;
        display: none;
        height: percentage(88px/$bg-height);
        left: percentage(10px/$bg-width);
        overflow: visible;
        position: absolute;
        text-indent: 100vw;
        top: percentage(20px/$bg-height);
        width: percentage(44px/$bg-width);
        z-index: 10;

        &.mobile {
            display: block;
        }
    }

    &__view-coupons-text {
        opacity: 0;
    }

    &__subtext {
        bottom: percentage(50px/$bg-height);
        left: percentage(10px/$bg-width);
        position: absolute;
        width: 340px;
        z-index: 5;
    }

    &__view-coupons {
        display: none;

        &:hover {
            color: $blue;
            text-decoration: underline;
        }

        &.mobile {
            display: inline-block;
            font-size: rem(14);
            margin: 0;
        }
    }

    &__description-text {
        display: none;
        padding-right: 20px;
    }

    &__deal-list {
        display: none;
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
    .deals {
        $bg-width: 800px;
        $bg-height: 759px;
        $bg-img-list: "/images/deals_backgrounds/deals-md-list.jpg";
        $slide-duration: .7s;

        max-height: $bg-height;

        &__background-container {
            opacity: 1;
            padding-top: percentage($bg-height/$bg-width);
            @include transform-style(preserve-3d); // Fix for webkit browsers not always rendering.
            right: 0;
            transition-property: right, opacity;
            transition-timing-function: ease-in-out;

            &-sm {
                display: none;
            }

            &-md {
                display: block;
            }

            &.show-list {
                opacity: 0;
                right: percentage(-585px/$bg-width);
            }
        }

        &__view-coupons-arrow {
            display: block;
            height: 0;
            left: percentage(43px/$bg-width);
            padding-top: percentage(194px/$bg-height);
            top: percentage(41px/$bg-height);
            transition-property: left;
            transition-timing-function: ease-in-out;
            width: percentage(97px/$bg-width);

            &.mobile {
                display: none;
            }

            &.show-list {
                left: percentage(630px/$bg-width);
            }
        }

        &__subtext {
            bottom: auto;
            left: auto;
            right: percentage(390px/$bg-width);
            top: percentage(380px/$bg-height);
            transition-property: right, opacity;
            transition-timing-function: ease-in-out;

            &.show-list {
                opacity: 0;
                right: percentage(-197px/$bg-width);
            }
        }

        &__view-coupons {
            color: $blue;
            @include font-montserrat("Bold");
            display: block;
            font-size: em(30);
            line-height: em(20);
            text-decoration: none;
            text-transform: uppercase;

            &.mobile {
                display: none;
            }
        }

        &__description-text {
            color: $blue;
            @include font-montserrat("SemiBold");
            display: block;
            font-size: em(22);
        }

        &__deal-list {
            background: url($bg-img-list) no-repeat left top;
            background-size: cover;
            display: block;
            height: 0;
            padding-top: percentage($bg-height/$bg-width);
            position: absolute;
            right: percentage(585px/$bg-width);
            top: 0;
            transition-property: right;
            transition-timing-function: ease-in-out;
            width: 100%;
            z-index: 1;

            &.show-list {
                right: 0;
            }
        }

        &__deal-list-content {
            display: block;
            padding: 22px;
            position: absolute;
            right: percentage(183px/$bg-width);
            top: percentage(69px/$bg-height);
            width: 525px;

            .featured-content {
                display: none;
                margin-bottom: 50px;
            }

            .featured-content:first-child {
                display: block;
            }
        }

        &__view-all {
            font-size: rem(18);
            margin: 0;
        }
    }
}

@media screen and (min-width: 1024px) {
    .deals {
        $bg-width: 1366px;
        $bg-height: 720px;

        height: auto;
        left: 50%;
        margin-left: -$bg-width/2;
        max-height: 1012px;
        width: $bg-width;

        &__background-container {
            padding-top: $bg-height;
            width: $bg-width;

            &-md {
                display: none;
            }

            &-lg {
                display: block;
            }

            &.show-list {
                opacity: 1;
                right: 0;
            }
        }

        &__view-coupons-arrow {
            height: percentage(185px/$bg-height);
            left: auto;
            padding-top: 0;
            right: percentage((578px/$bg-width));
            top: percentage(31px/$bg-height);
            width: percentage(93px/$bg-width);

            &.show-list {
                left: auto;
            }
        }

        &__subtext {
            display: none;
        }

        &__deal-list {
            background-image: none;
            padding-top: $bg-height;
            right: percentage(676px/$bg-width);
            width: 569px;
            z-index: 10;

            &.show-list {
                right: percentage(676px/$bg-width);
            }
        }

        &__deal-list-content {
            right: 0;
            top: percentage(30px/$bg-height);
        }
    }
}

@media screen and (min-width: 1366px) {
    .deals {
        $bg-width: 1366px;
        $bg-height: 720px;

        display: inline-block;
        left: auto;
        margin-left: -50vw;
        vertical-align: top;
        width: 100vw;

        &--no-contest {
            margin-left: 0;
        }

        &__background-container {
            margin-right: 0;
            padding-top: percentage($bg-height/$bg-width);
            width: 100%;

            &.show-list {
                right: 0;
            }

            &.show-form {
                opacity: 0;
            }
        }

        &__view-coupons-text {
            background-color: $off-white;
            color: $gray;
            font-size: #{12px / ($bg-width / 100)}vw;
            left: 0;
            line-height: #{24px / ($bg-width / 100)}vw;
            @include font-montserrat("Bold");
            // $bg-width / 100 gives 1 vw unit, the font be 12px at 1366px
            margin-left: percentage(450px/$bg-width);
            position: absolute;
            text-align: center;
            text-indent: 0;
            text-transform: uppercase;
            top: 50%;
            transition-property: opacity;
            @include transform(rotate(90deg));
            @include transform-origin(50% 0);
            transition-timing-function: ease-in-out;
            width: 200%;

            &.show-form {
                opacity: 1;
            }

            &.show-list {
                opacity: 0;
            }
        }

        &__subtext {
            bottom: percentage(30px/$bg-height);
            display: block;
            right: percentage(400px/$bg-width);
            text-align: left;
            width: percentage(250px/$bg-width);

            &.show-list {
                opacity: 1;
                right: percentage(400px/$bg-width);
            }

            &.show-form {
                opacity: 0;
            }
        }

        &__deal-list {
            margin-right: -220px;
            padding-top: percentage($bg-height/$bg-width);
            right: 65%;

            &.show-list {
                right: 65%;
            }
        }

        &__deal-list-content {
            padding: 22px 0;
        }
    }
}

@media screen and (min-width: 1920px) {
    .deals {
        $bg-width: 1569px;
        $bg-height: 902px;
        $bg-area-width: $bg-width/1920px;

        // Quit resizing when the screen is 2560px
        max-height: (($bg-height/$bg-width) * $bg-area-width) * 2560px;

        &__background-container {
            // The maximum width is less that of the contest's arrow when deals is expanded
            margin-left: percentage((1 - $bg-area-width));
            padding-top: percentage(($bg-height/$bg-width) * $bg-area-width);
            width: percentage($bg-area-width);

            &-lg {
                display: none;
            }

            &-xl {
                display: block;
            }
        }

        &__view-coupons-arrow {
            height: 0;
            padding-top: percentage(232px/1920px);
            right: percentage(811px/$bg-width * $bg-area-width);
            top: percentage(50px/$bg-height);
            width: percentage(116px/$bg-width * $bg-area-width);
        }

        &__view-coupons-text {
            // $bg-width / 100 gives 1 vw unit, the font be 12px at 1366px
            font-size: #{12px / ($bg-width / 100)}vw;
            line-height: #{24px / ($bg-width / 100)}vw;
        }

        &__subtext {
            right: percentage(550px/$bg-width * $bg-area-width);
            top: percentage(526px/$bg-height);
            width: 340px;

            &.show-list {
                right: percentage(550px/$bg-width * $bg-area-width);
            }
        }

        &__deal-list {
            margin-right: 0;
            right: percentage(960px/$bg-width * $bg-area-width);
            width: 569px;

            &.show-list {
                right: percentage(960px/$bg-width * $bg-area-width);
            }
        }

        &__deal-list-content {
            .featured-content:nth-child(2) {
                display: block;
            }
        }

        &__description-text {
            font-size: em(24);
        }
    }
}