.state-maps {

    * {
        box-sizing: border-box; //to make bootstrap grid work correctly
    }

    h3 {
        text-align: center;
    }

    .row {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__map-thumb {
        border: 5px solid $beige;
        box-shadow: 10px 13px 40px 0px rgba(75, 75, 75, 0.3);
    }

    &__icon {
        display: inline-block;
        height: 20px;
        margin-right: 10px;
        vertical-align: middle;
        width: auto;

        &--active {
            display: none;
        }
    }

    &__map-tab, &__map-tab:visited, &__map-tab:active {
        background-color: #e3e3d7;
        color: $gray;
        display: block;
        font-size: em(18px);
        line-height: 100%;
        margin-bottom: 10px;
        overflow: auto;
        padding: 10px 10px;
        @include font-montserrat("SemiBold");
        text-align: left;
        text-decoration: none;
        vertical-align: middle;

        svg {
            float: left;
            max-height: 35px;
            max-width: 35px;
        }
    }

    &__map-tab {
        &.active {
            background-color: $blue;
            color: $white;

            .state-maps__icon {
                display: none;
            }

            .state-maps__icon--active {
                display: inline-block;
            }
        }
    }

    &__maps {
        height: 450px;
        margin-top: 25px;
        width: 100%;
    }

    &__region-legend {
        display: none;

        .row {
            margin-bottom: 10px;
        }
    }

    &__region-legend-icon {
        display: inline-block;
        height: 15px;
        margin-right: 10px;
        width: 15px;

        &--northwest {
            background-color: #9e3737;
        }

        &--northeast {
            background-color: #527042;
        }

        &--southwest {
            background-color: #c29f16;
        }

        &--central {
            background-color: #5b4b6e;
        }

        &--southcentral {
            background-color: #1e7984;
        }

        &--southeast {
            background-color: #bd6014;
        }
    }

    &__tab-select {
        display: block;
        width: 100%;
    }

    &__marker-title {
        @include font-montserrat("SemiBold");
        font-size: em(28px, 13px);
        margin: em(18px, 28px) 0;
    }

    &__marker_description {
        font-size: em(16px, 13px);
    }

    &__marker-directions {
        font-size: em(18px, 13px);
        margin-top: 10px;
    }

    .nav-tabs {
        display: none;
    }

    @media screen and (min-width: 768px) {
        .state-maps__tab-select {
            display: none;
        }
        .nav-tabs {
            display: block;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 872px) {
        &__map-tab, &__map-tab:visited, &__map-tab:active {
            font-size: em(15px);
        }
        &__icon {
            margin-right: 5px;
        }
    }

}