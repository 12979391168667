.video-background {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @include laptop {
        justify-content: space-between;
    }

    &__item {
        flex-basis: 33%;
        margin-bottom: 35px;
        overflow: hidden;

        h4 {
            margin: 5px 0;
            max-width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
    }

    &__link {
        margin: 0 0 10px;
    }
}

/* Video Background Model dialog */
.video-background-modal {
    .modal-content {
        border-radius: 0;
    }

    &.fade.in {
        background-color: rgba(0, 46, 85, .8);
    }

    &__image {
        text-align: center;

        img {
            display: inline-block;
            height: auto;
            margin: 0 auto;
            max-width: 100%;
        }
    }

    &__links {
        a {
            display: inline-block;
            @extend .button;
            @extend .button-primary;
        }
    }

    &__close {
        display: block;
        float: right;
        margin-right: 10px;
        z-index: 5;
    }

    .button-close:after {
        margin-left: 0px;
    }
}
