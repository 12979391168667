.state-park-updates {
    .accordion__button-expand-all {
        font-size: em(16px);
        margin-bottom: em(20px, 16px);
        margin-left: 0;
    }

    .accordion__item-content {
        overflow: auto;

        p {
            font-size: 18px;
            line-height: 1.3;
            margin-top: 0;
        }
    }

    .state_parks_update_photo {
        float: none;
        margin: 0 20px 10px 0;
    }

    @media screen and (min-width: 430px) {
        .state_parks_update_photo {
            float: left;
        }
    }
}