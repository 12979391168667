// Fix for filter groups like "CUISINE/RESTAURANT" that are too long for the accordion on mobile, and cannot wrap.
@media screen and (max-width: 335px) {
    .refine-search .accordion__item-title {
        font-size: em(28px);
    }
}

.refine-search {

    &__intro {
        font-size: em(18px);
    }

    &__filters {
        margin-bottom: em(15px);
    }

    &__select-filter {
        margin: em(15) 0;

        &:last-child {
            margin-bottom: 0;
        }

        select {
            box-sizing: border-box;
            width: 100%;
        }
    }

    .text-field.searchFormText {
        padding: 0;
    }

    .text-field.searchFormText label {
        font-size: em(18px);
        //margin-bottom: em(20px, 18px);
        @include font-montserrat("Regular");
        margin-top: em(15px, 18px);
    }

    .text-field.searchFormText input[type="text"] {
        //margin: em(10px, 18px) 0;
        background-color: $off-white;
        font-size: em(18px);
        height: em(44px, 18px);
    }

}

@media screen and (min-width: 500px) {
    .refine-search {
        &__select-filter {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 1.5%;
            width: auto;
        }

        &__date-range {
            .date-field {
                box-sizing: border-box;
                display: inline-block;
                margin-right: 2%;
                width: 48%;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .refine-search {
        .text-field.searchFormText {
            display: inline-block;
            margin-right: em(10px, 18px);
            width: em(400px, 18px);
        }

        .text-field.searchFormText input[type="text"] {
            margin-bottom: em(10px, 18px);
        }

        &__select-filter {
            width: auto;

            select {
                width: auto;
            }
        }

        .button-primary {
            margin-top: 37px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .refine-search {
        &__select-filter {
            margin-right: em(15px);
        }
    }
}