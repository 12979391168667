.listing-map {
    padding: em(32px);

    &--state-park {
        padding: em(32px) 3%;
    }

    @include clearfix();

    #map {
        float: none;
        height: 50vw;
        max-height: 600px;
        min-height: 250px;
        width: 100%;
    }

    &__forms-container {
        background-color: $beige;
        float: none;
        height: auto;
        max-height: none;
        min-height: 250px;
        overflow: auto;
        width: 100%;
    }

    &__form {
        background-color: $beige;
        padding: em(25px, 18px) em(18px, 18px);
    }

    &__directions, &__directions label {
        @include font-montserrat("Bold");
        background-color: $beige;
        color: $gray;
        font-size: rem(18px);
    }

    &__form-title {
        @include font-montserrat("Bold");
        color: $blue;
        font-size: em(36px, 18px);
        text-align: left;
    }

    &__form button, &__form button {
        display: block;
        margin: 10px 18px 10px 0;
    }

    &__form--nearby {
        border-top: 2px solid $navy;
        padding-top: em(25px);
    }

    select {
        width: 100%;
    }
}

.content__wide {
    .listing__directions {
        padding-bottom: em(32px);
    }
}

@media screen and (min-width: 450px) {
    .listing-map__form .horizontal-field input[type="text"],
    .listing-map__form .horizontal-field select {
        width: 70%;
    }
}

@media screen and (min-width: 975px) {
    .listing-map {
        #map {
            float: right;
            width: 50%;
        }

        &__forms-container {
            float: left;
            height: 50vw;
            max-height: 600px;
            min-height: 250px;
            width: 50%;
        }
    }
}

@media screen and (min-width: 1024px) {
    /* Match .content__padded, except for top padding */
    .listing-map__forms-container {
        margin: 0 auto;
        padding: 0;
    }

    .listing-map__form--nearby {
        text-align: left;

        .horizontal-field {
            width: 100%;
        }

        ol {
            margin: auto;
            width: 40%;
        }

        li {
            text-align: left;
        }
    }

    .listing-map {
        #map {
            width: 60%;
        }

        &__forms-container {
            width: 40%;
        }
    }
}

@media screen and (min-width: 1400px) {
    .listing-map {
        #map {
            width: 70%;
        }

        &__forms-container {
            width: 30%;
        }
    }
}