.listing {
    &__sidebar {
        background-color: $beige;
        box-sizing: border-box;
        padding: em(15);
        width: 100%;

        @include tablet-and-below {
            display: none;
        }

        .button {
            margin-bottom: 0;
            margin-right: 0;
            padding-left: 0.7em;
            width: 100%;

            &:first-of-type {
                margin-top: 0;
            }

            @include tablet-and-below {
                display: none;
            }
        }

        &-section {
            margin-bottom: 45px;
            position: relative;

            @include tablet-and-below {
                display: none;
            }

            &:last-child {
                display: block;
                margin-bottom: 0;
            }
        }

        &-icon {
            position: absolute;
            right: 0;
            top: -15px;
            width: 100px;

            &--sun {
                width: 90px;
            }

            &--feather {
                width: 160px;
            }

            &--tree {
                top: -35px;
            }
        }

        &-modal {
            .modal-content {
                background-color: $beige;
                border-radius: 0;

                h3 {
                    margin-top: 0;
                }

                .listing__sidebar-icon {
                    top: 0;
                }
            }

            &.fade.in {
                background-color: rgba(0, 46, 85, .8);
            }

            &__close {
                bottom: 0;
                float: right;
                margin: 0 0 em(10px);
                position: relative;
            }
        }
    }
}