.pagination {
    @include font-montserrat("Regular");
    font-size: em(18px);
    padding: em(10px, 18px) 0;

    a {
        background-color: $blue;
        color: $white;
        cursor: pointer;
        display: inline-block;
        line-height: em(12px, 18px);
        margin: 0 em(6px, 18px) em(10px, 18px) 0;
        padding: em(15px, 18px);
        text-decoration: none;

        &.pagination__active {
            @include font-montserrat("Bold");
            background-color: $navy;
            line-height: em(13px, 18px);
        }
    }

    a:hover, a:focus, a:visited {
        color: $white;
        text-decoration: none;
    }

    // Reset styles if in a datepicker
    @at-root .datepicker .pagination {
        a {
            background-color: transparent;
            margin: 0;
            padding: 0;

            &.pagination__active {
                @include font-montserrat("Bold");
                background-color: unset;
                line-height: em(13px, 18px);
            }
        }
    }
}