.subevent-page {

    h2 {
        @include font-sucrose("Bold");
        font-size: 40px;
        margin-top: 0;
    }

    .search-result__image {
        margin-bottom: 30px;
    }

    &__event-time {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: em(20px, 18px);
    }

    &__event-date, &__event-hours, &__event-recurrence {
        @include font-montserrat("Bold");
        color: $blue;
        font-size: 22px;
        line-height: 1em;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include laptop {
            flex-direction: row;

            &-left, &-right {
                flex-basis: 50%;
            }
        }

        address {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            gap: 10px;
            line-height: 1.3em;
            margin-bottom: em(15px, 16px);
            margin-top: 0;
        }

        .search-result__phone {
            @include font-montserrat("SemiBold");
        }
    }

    &__admission {
        @include font-montserrat("SemiBold");
        color: $blue;
        font-size: 16px;
        margin-bottom: em(10px, 16px);
    }

    &__contact .button {
        margin-bottom: em(12px, 18px);
        text-align: center;
        width: 100%;
    }

    .search-result {
        border-bottom: none;
        padding-bottom: 0;
        padding-top: 0;
    }
}