.fishing-trail-overview {
    &__route-container {
        padding-top: em(25px);
        position: relative;
        @include clearfix;
    }

    &__map-container {
        margin: 0 auto em(20px);
        max-width: 100%;
        position: relative;
    }

    &__map {
        // TODO: Remove if not needed when we get final map image
        //-webkit-filter: drop-shadow(3px 3px 19px rgba(0, 0, 0, 0.7));
        //filter: drop-shadow(3px 3px 19px rgba(0, 0, 0, 0.7));
        position: relative;
    }

    &__map-icon {
        font-size: em(25px);
        margin-left: -.35em;
        margin-top: -.8em;
        position: absolute;
        text-decoration: none;

        .path1::before {
            text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
        }

        .fishing-trail-overview__map-icon__tooltip.show {
            display: block;
        }

        &:hover {
            .fishing-trail-overview__map-icon__tooltip {
                display: block;
            }
        }

        &__tooltip {
            @include font-montserrat("SemiBold");
            @extend .button-shadow;
            background-color: $off-white;
            border: 1px solid $beige;
            border-radius: 8px;
            bottom: 135%;
            color: $gray;
            display: none;
            font-size: em(16px, 25px);
            left: 50%;
            line-height: em(16px);
            padding: 5px;
            position: absolute;
            text-align: center;
            transform: translateX(-50%);
            width: 150px;
            z-index: 1;

            &::after {
                border: 10px solid transparent;
                border-top-color: $off-white;
                content: " ";
                left: 50%;
                margin-left: -10px;
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
            }
        }

        &-active-parent {
            .path1::before, .path2::before, .path3::before {
                font-size: em(27px);
            }
        }
    }

    &__routes {
        background-color: $fishing-trail-blue;
        color: $white;
        font-size: em(14px);
        margin-bottom: em(20px, 14px);
        padding: 10px 0 15px;

        &-header {
            color: $white;
            @include font-sucrose("Bold");
            font-size: em(22px);
            margin: em(20px) em(12px) em(20px) em(20px);
        }

        &-list {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        &-item {
            margin: em(18px) 0 0;
            opacity: 70%;
            padding: 0 0 0 8%;
            position: relative;

            i {
                .path1::before {
                    text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
                }
            }

            &:first-child {
                margin: em(14px) 0 0;
            }

            &--active {
                @include font-montserrat("SemiBold");
                opacity: 100%;
            }
        }

        &-link {
            font-weight: 700;
            line-height: em(29px);

            &:link, &:active, &:visited {
                color: $white;
                text-decoration: none;
            }

            &:hover {
                color: lighten($beige, .5);
            }

            i {
                font-size: em(25px);
            }
        }
    }

    &__route-info-heading {
        color: $fishing-trail-blue;
        font-size: em(24);
        margin: 0 0 em(8px, 24px);
    }

    &__route {
        display: none;
        margin-left: 4.5%;
        padding-top: 48%;
        vertical-align: top;
        width: 63.5%;

        &-map {
            display: none;
            outline: 2px solid $fishing-trail-blue;
            width: 100%;

            &-link {
                display: none;
            }
        }

        &-name {
            color: $fishing-trail-red;
            margin-bottom: em(8px, 34px);
        }

        &-info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            > div {
                display: flex;
                flex-direction: column;
                width: 47%;
            }

            a {
                text-decoration: none;
            }
        }

        &-update {
            margin-bottom: em(32px, 18px);
            margin-top: 0;
        }
    }

    &__fish-heading {
        color: $fishing-trail-blue;
    }

    &__partner {
        box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: -60px;
        margin-top: 100px;
        padding: 20px;

        @include desktop {
            flex-direction: row;
        }

        p {
            font-size: 1.1em;
            line-height: 1.25em;
            max-width: 450px;
        }

        @include tablet {
            align-items: center;
        }

        img {
            height: 65px;
            margin-right: 20px;

            @include mobile-only {
                height: 90px;
                margin-right: 15px;
                margin-top: 5px;
            }
        }

        &-info {
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            display: flex;
            flex: 1;
            margin-bottom: 15px;

            @include desktop {
                border-bottom: 0;
                border-right: 1px solid rgba(0, 0, 0, 0.25);
                flex-direction: row;
                margin-right: 15px;
            }
        }
    }
}

@media screen and (min-width: 500px) {
    .fishing-trail-overview {
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media screen and (min-width: 550px) {
    .fishing-trail-overview {
        &__routes {

            &-list {
                font-size: em(16px, 14px);
            }

            &-item {
                margin: em(7px) 0 0;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .fishing-trail-overview {

        &__map-container {
            margin: 0 auto;
            max-width: 100%;
            position: absolute;
        }

        &__routes-container {
            display: inline-block;
            left: 2%;
            padding-top: 11%;
            position: relative;
            width: 30%;
            z-index: 10;
        }

        &__routes {
            &-link {
                display: block;
            }
        }

        &__route {
            display: inline-block;

            &-map {
                display: block;

                &-link {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .fishing-trail-overview {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 1920px) {
    .fishing-trail-overview {
        padding-left: em(104px, 16px);
        width: em(1164px);
    }
}

/**
Fishing Trail Model dialog
 */
.fishing-trail-overview-modal {
    .modal-content {
        border-radius: 0;
    }

    &.fade.in {
        background-color: rgba(0, 46, 85, .8);
    }

    &__route {
        &-name {
            margin: 0 0 em(8px, 34px);
        }

        &-map {
            text-align: center;

            img {
                display: inline-block;
                height: auto;
                margin: 0 auto;
                max-width: 100%;
            }
        }

        &-update {
            margin: 0 0 em(25px);
        }

        &-info {
            font-size: em(16px);
            margin: 0 0 em(32px, 18px);
        }
    }

    &__close {
        display: block;
        float: right;
        margin-right: 10px;
        z-index: 5;
    }

    &__description-icon {
        display: inline-block;
        float: left;
        margin-right: 10px;
    }

    .button-close:after {
        margin-left: 0px;
    }
}

@media screen and (min-width: 768px) {
    .fishing-trail-overview-modal {
        .modal-dialog {
            width: 725px;
        }

        &__route-info-heading {
            display: none;
        }

        &__route {
            &-map {
                img {
                    max-width: 85%;
                }
            }

            &-update {
                display: none;
            }

            &-info {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 990px) {
    .fishing-trail-overview-modal {
        .modal-dialog {
            width: 900px;
        }
    }
}

