.follow-social {
    &__intro {
        @include font-montserrat("Bold");
        color: $blue;
        display: block;
        font-size: rem(24px);

        @include media-query(768px) {
            display: inline;
            margin-right: 20px;
        }
    }

    .social-icon {
        display: inline-block;
        margin-bottom: 3px;
        vertical-align: middle;

        @include laptop {
            display: inline-block;
        }
    }

    &__icons {
        white-space: nowrap;

        @include media-query(768px) {
            white-space: normal;
        }

        @include laptop {
            white-space: nowrap;
        }
    }

    &__share {
        @include clearfix();
        margin-top: 50px;
        overflow: hidden;

        &-btns {
            margin-top: 10px;

            > div {
                float: none;
                margin-bottom: 10px;
                margin-right: 20px;

                @include media-query(575px) {
                    float: left;
                }
            }
        }
    }

    &--listing {
        .social-icon {
            margin-bottom: 0;
            margin-right: rem(3px);

            @include laptop {
                margin-bottom: rem(3px);
                margin-right: 0;
            }
        }

        .follow-social__icons {
            display: flex;

            @include laptop {
                flex-direction: column;
            }
        }
    }
}
