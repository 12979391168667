.article-modal {
    // cover the Trip Planner logo
    z-index: 10000;

    &--itinerary {
        .modal-content {
            text-align: center;
        }
    }

    .modal-dialog {
        @media screen and (min-width: 768px) {
            width: 95vw;
        }
    }

    .modal-body {
        background-color: $beige;
    }

    .modal-content {
        border-radius: 0;
        color: $charcoalgray;
        font-size: rem(18);
        @include font-montserrat("Regular");
        line-height: rem(24);
        vertical-align: top;
    }

    &.fade.in {
        background-color: rgba(0, 46, 85, .8);
    }

    &__iframe {
        height: 85vh;
        width: 100%;
    }

    &__close {
        bottom: 0;
        float: right;
        margin: 0;
        position: relative;
    }
}