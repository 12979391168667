
.ug-gallery-wrapper .ug-theme-panel {
    position: absolute;
    xbackground-color: red;
    z-index: 11;
}

.ug-theme-panel .ug-strip-panel {
    z-index: 6;
}

/* --- fullscreen button -- */

.ug-theme-default .ug-default-button-fullscreen {
    background-image: url("/lib/unitegallery/images/button_fullscreen.png");
    background-position: 0px 0px;
    cursor: pointer;
    height: 50px;
    position: absolute;
    width: 53px;
    z-index: 3;
}

.ug-theme-default .ug-default-button-fullscreen.ug-button-hover {
    background-position: 0px -50px;
}

.ug-theme-default .ug-default-button-fullscreen.ug-fullscreenmode {
    background-position: 0px -100px;
}

.ug-theme-default .ug-default-button-fullscreen.ug-fullscreenmode.ug-button-hover {
    background-position: 0px -150px;
}

/* --- fullscreen button single mode-- */

.ug-theme-default .ug-default-button-fullscreen-single {
    background-image: url("/lib/unitegallery/images/button_fullscreen_single.png");
    background-position: 0px 0px;
    cursor: pointer;
    height: 50px;
    position: absolute;
    width: 52px;
    z-index: 3;
}

.ug-theme-default .ug-default-button-fullscreen-single.ug-button-hover {
    background-position: 0px -50px;
}

.ug-theme-default .ug-default-button-fullscreen-single.ug-fullscreenmode {
    background-position: 0px -100px;
}

.ug-theme-default .ug-default-button-fullscreen-single.ug-fullscreenmode.ug-button-hover {
    background-position: 0px -150px;
}

/* --- play button -- */

.ug-theme-default .ug-default-button-play {
    background-image: url("/lib/unitegallery/images/button_playpause.png");
    background-position: 0px 0px;
    cursor: pointer;
    height: 50px;
    position: absolute;
    width: 51px;
    z-index: 4;
}

.ug-theme-default .ug-default-button-play.ug-button-hover {
    background-position: 0px -50px;
}

.ug-theme-default .ug-default-button-play.ug-stop-mode {
    background-position: 0px -100px;
}

.ug-theme-default .ug-default-button-play.ug-stop-mode.ug-button-hover {
    background-position: 0px -150px;
}

/* --- play button single mode-- */

.ug-theme-default .ug-default-button-play-single {
    background-image: url("/lib/unitegallery/images/button_playpause_single.png");
    background-position: 0px 0px;
    cursor: pointer;
    height: 50px;
    position: absolute;
    width: 50px;
    z-index: 4;
}

.ug-theme-default .ug-default-button-play-single.ug-button-hover {
    background-position: 0px -50px;
}

.ug-theme-default .ug-default-button-play-single.ug-stop-mode {
    background-position: 0px -100px;
}

.ug-theme-default .ug-default-button-play-single.ug-stop-mode.ug-button-hover {
    background-position: 0px -150px;
}

/* --- hide panel button -- */

.ug-default-button-hidepanel {
    cursor: pointer;
    height: 23px;
    position: absolute;
    width: 23px;
    z-index: 5;
}

.ug-default-button-hidepanel .ug-default-button-hidepanel-bg {
    background-color: #000000;
    filter: alpha(opacity=40);
    height: 23px;
    left: 0px;
    opacity: 0.4;
    position: absolute;
    top: 0px;
    width: 23px;
    z-index: 1;
}

.ug-default-button-hidepanel .ug-default-button-hidepanel-tip {
    background-image: url("/lib/unitegallery/images/arrow_down_up.png");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    height: 7px;
    position: absolute;
    width: 7px;
    z-index: 2;
}

.ug-default-button-hidepanel.ug-button-hover .ug-default-button-hidepanel-tip {
    background-position: 0px -7px;
}

.ug-default-button-hidepanel.ug-button-hidden-mode .ug-default-button-hidepanel-tip {
    background-position: 0px -14px;
}

.ug-default-button-hidepanel.ug-button-hidden-mode.ug-button-hover .ug-default-button-hidepanel-tip {
    background-position: 0px -21px;
}

