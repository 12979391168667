.cc-window {
    background-color: #002e55;
    box-shadow: -4px 4px 5px 0px rgba(0, 0, 0, 0.28);
    color: #ffffff;
    opacity: 1;
    transition: opacity 1s ease;
}

.cc-window.cc-invisible {
    opacity: 0;
}

.cc-window {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    font-size: em(14);
    line-height: 1.5em;
    overflow: hidden;
    position: fixed;
    z-index: 9999
}

.cc-window.cc-floating {
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 24em;
    padding: 2em;
}

.cc-btn {
    background-color: #fac613;
    border-color: transparent;
    border-style: solid;
    border-width: 2px;
    color: #000000;
    display: block;
    font-size: .9em;
    font-weight: 700;
    padding: .4em .8em;
    text-align: center;
    white-space: normal;
}

.cc-btn:hover,
.cc-btn:focus {
    background-color: #fadc25;
}

.cc-btn,
.cc-link {
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.cc-link {
    color: #ffffff;
    display: inline-block;
    opacity: .8;
    padding: .2em;
    text-decoration: underline;
}

.cc-link:hover {
    color: #ffffff;
    opacity: 1;
    text-decoration: none;
}

.cc-link:active,
.cc-link:visited {
    color: #ffffff;
    opacity: 1;
}

.cc-highlight .cc-btn:first-child {
    background-color: transparent;
    border-color: transparent;
}

.cc-highlight .cc-btn:first-child:focus,
.cc-highlight .cc-btn:first-child:hover {
    background-color: transparent;
    text-decoration: underline;
}

.cc-top {
    top: 1em;
}

.cc-left {
    left: 1em;
}

.cc-floating > .cc-link {
    margin-bottom: 1em;
}

.cc-floating .cc-message {
    display: block;
    margin-bottom: 1em;
}

.cc-window.cc-floating .cc-compliance {
    -ms-flex: 1;
    flex: 1;
}

.cc-compliance {
    align-content: space-between;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-line-pack: justify;
    font-size: em(16, 14);
}

@media print {
    .cc-window {
        display: none;
    }
}

.cc-floating.cc-theme-classic {
    border-radius: 5px;
    padding: 1em;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
    display: inline;
    -ms-flex: none;
    flex: none;
    text-align: center;
}

.cc-theme-classic .cc-btn {
    border-radius: 5px;
}

.cc-theme-classic .cc-btn:last-child {
    min-width: 140px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
    display: inline-block;
}

.cc-compliance > .cc-btn {
    -ms-flex: 1;
    flex: 1;
}

@include tablet() {
    .cc-btn {
        white-space: nowrap;
    }
}

@include mobile-only() {

    .cc-top {
        top: auto;
    }

    .cc-window.cc-top {
        bottom: 0;
    }

    .cc-window.cc-left {
        left: 0;
        right: 0;
    }

    .cc-window.cc-floating {
        max-width: none;
    }

    .cc-floating.cc-theme-classic {
        border-radius: 0;
    }

    .cc-window .cc-message {
        margin-bottom: 1em;
        text-align: center;
    }
}