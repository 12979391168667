#map {
    z-index: 1;
}

#map .county-map-name-label {
    color: #ffffff;
}

#map .leaflet-popup-content {
    margin: 10px 15px;
}

#map .leaflet-popup-content p {
    margin: 0;
}