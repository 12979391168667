.deal {
    &__valid-date {
        color: $blue;

        @include font-sucrose("Bold");
        font-size: em(28px);
        line-height: 1em;
        margin-bottom: em(16px, 28px);
    }

    &__listing-name {
        @include font-sucrose("Bold");
        color: $blue;
        font-size: rem(24px);
        line-height: em(24px, 22px);
        margin-bottom: em(10px, 22px);
    }

    &__contact {
        .button {
            margin: em(12px, 18px) em(5px, 18px);
            padding-left: em(5px, 18px);
            padding-right: em(33px, 18px);
            text-align: center;
            width: 245px;
        }
    }

    .search-result {
        border-bottom: none;
        padding-bottom: 0;
        padding-top: 0;

        &__phone {
            margin-top: em(10px, 18px);
        }
    }

}

@media screen and (min-width: 1024px) {
    .deal {
        &__info-left {
            padding-right: em(10px, 18px);
        }

        &__info-left, &__info-right {
            display: inline-block;
            margin: 0;
            padding: 0;
            vertical-align: top;
            width: 50%;
        }
    }
}