.ok-go {
    border: 2px solid $off-white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;

    &__description {
        font-size: rem(16px);
        max-width: 100%;
        text-decoration: underline;
    }

    &__info {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;

        .image--responsive {
            // Because IE doesn't know what it's doing
            flex-shrink: 0;
        }
    }

    &__actions {
        margin-top: auto;
        width: 100%;
    }

    .button {
        display: inline-block;
        font-size: em(13px);
        margin: 0;
        padding: em(4px, $fh-psd-button-font-size) em(13px, $fh-psd-button-font-size);
        width: 100%;
    }

    select {
        margin-bottom: em(15px);
        min-width: auto;
        width: 100%;
    }

    &__modal-content {
        display: block;
        justify-items: stretch;
        padding: 20px;

        @include media-query(600px) {
            display: flex;
        }

        &-image {
            flex: 1 1 auto;
            text-align: center;

            > img {
                max-height: 200px;

                @include media-query(600px) {
                    display: block;
                    max-height: none;
                }
            }
        }

        &-text {
            flex: 2 1 auto;
            margin-left: 15px;
        }

        &-name {
            font-size: 20px;
            font-weight: bold;
            margin-top: 10px;

            @include laptop {
                margin-top: 0;
            }
        }

        &-description {
            @include font-montserrat("Regular");
            font-size: em(15px);
            line-height: 1.4;
            margin-bottom: 30px;
        }

    }

    &--added {
        img {
            opacity: 0.25;
        }

        .image--responsive {
            position: relative;
        }

        .image--responsive:after {
            color: $green;
            content: "\e95a";
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: "travicons" !important;
            font-size: 36px;
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            height: 36px;

            /* Better Font Rendering =========== */
            left: 50%;
            line-height: 1;

            margin-left: -18px;
            margin-top: -18px;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            speak: none;
            text-transform: none;
            top: 50%;
            width: 36px;
        }
    }
}