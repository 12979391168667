.resale-submission {
    background-color: #1e6aaa;
    color: white;
    height: em(32px, 16px);
    line-height: em(32px, 16px);
    pointer-events: none;
    text-align: center;
    width: em(60px, 16px);

    &_container {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        @include tablet {
            flex-direction: row;
        }
    }

    &_products {
        border: 1px solid #dbdbdb;
        border-radius: 0 10px 0 10px;
        flex-grow: 1;
        padding: 15px;
        position: relative;
    }

    &_remove {
        align-self: end;

        &_button {
            background-color: #c12b30;
            font-size: 16px;
            height: em(32px, 16px);
            margin: 0;
            padding: 0;
            text-align: center;
            width: em(60px, 16px);
        }
    }
}