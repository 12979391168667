.tripadvisor {
    padding: 25px 0;
    text-align: center;
    width: auto;

    @include laptop {
        padding: 25px 0 0;
    }

    &__ratings-image {
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
    }

    &__review-link {
        color: $charcoalgray;
        display: inline-block;
        font-size: em(18px);
        vertical-align: middle;
    }

    &__title {
        color: $charcoalgray;
        display: inline-block;
        font-size: em(12px);
        text-align: center;
        white-space: nowrap;
    }
}

