.article {

    &__header {
        &.article {
            padding-bottom: em(15px);
            padding-top: em(30px);
        }

        &-aarp {
            font-style: italic;

            a:link, a:visited, a:active, a:focus {
                color: $black;
            }

            a:hover {
                color: $navy;
            }

            img {
                margin-left: em(5px, 18px);
                vertical-align: middle;
            }
        }
    }

    &__column {
        &--wide {
            .ad-container {
                left: 50%;
                margin-top: 0;
                position: relative;
                transform: translateX(-50%);
                width: 100vw;
            }
        }
    }

    &__continuation-text {
        font-size: em(14px);
        font-style: italic;
        margin-bottom: em(5px);
        text-align: center;
    }

    &__body {
        code, pre {
            font-size: 16px;
            line-height: em(22px,16px);
            white-space: normal;
        }
    }

    &__pull-quote {
        border-left: 2px solid $beige;
        color: $charcoalgray;
        @include font-montserrat("SemiBold");
        font-size: em(18px);
        margin: 60px 10px 60px 30px;
        padding: 20px 30px 20px 30px;

        p {
            font-style: italic;
            margin: 0;

            &:before {
                content: "\"";
            }

            &:after {
                content: "\"";
            }
        }
    }

    &__primary-photo {
        padding-bottom: em(30px);
        width: 100%;
    }

    &__primary-photo-image {
        width: 100%;
    }

    &__primary-photo-caption {
        text-align: left;
    }

    &__related-header {
        margin: 0 0 em(10px, 22px);
    }

    &__related-list {
        list-style: none;
        margin: 0 0 em(20px, 18px);
        padding-left: em(15px, 18px);
    }

    &__related-list-item {
        line-height: em(20px, 18px);
        margin-bottom: 0;
        padding-bottom: em(10px, 18px);
    }

    &__map {
        height: em(300px);
    }

    &__map-list {
        list-style: none;
        margin: 0;
        padding: 0 0 em(25px) em(10px);
    }

    &__map-list-item {
        padding-top: em(10px);
    }

    &__map-list-marker {
        cursor: pointer;
        display: inline-block;
        height: 40px;
        margin-top: em(3px);
        position: relative;
        vertical-align: middle;
        width: 40px;

        .icon-map-marker-empty {
            color: #4980a7;
            font-size: em(40px, 18px);
            position: absolute;
        }
    }

    &__map-list-marker-number {
        color: $white;
        cursor: pointer;
        font-size: em(15px, 18px);
        left: 50%;
        letter-spacing: -1px;
        line-height: 1em;
        position: absolute;
        top: 17%;
        transform: translate(-55%, -17%);

        &--smaller {
            font-size: em(13px, 18px);
            top: 20%;
            transform: translate(-55%, -20%);
        }
    }

    &__map-list-listing {
        display: inline-block;
        font-size: em(18);
        line-height: em(22, 18);
        vertical-align: middle;
        width: 80%;
    }

    &__to-top {
        font-size: rem(16px);
        margin-left: 0;
    }

    &__footer.article {
        padding-bottom: em(10px);
    }
}

@media screen and (min-width: 768px) {
    .article {
        &__map-list {
            padding: 0 0 em(25px) em(10px);
        }
    }
}

@media screen and (min-width: 1024px) {
    .article {
        &__column {
            display: inline-block;
            vertical-align: top;

            &--wide {
                box-sizing: border-box;
                padding-right: em(48);
                width: 68%;

                .ad-container {
                    left: auto;
                    transform: translateX(0);
                    width: auto;
                }
            }

            &--narrow {
                width: 32%;
            }
        }

        &__primary-photo {
            box-sizing: border-box;
            padding: 0 em(20px) em(20px) 0;
        }

        &__primary-photo-caption {
            text-align: center;
        }
    }
}