.contest-and-deals {
    position: relative;
    width: 100vw;

    &__area-wrapper {
        overflow-x: hidden;
        width: 100%;
    }

    &__contest-container, &__deals-container {
        display: block;
        overflow: hidden;
        position: relative;
    }
}

@media screen and (min-width: 1366px) {
    $collapsed: 13%;
    $expanded: 87%;

    .contest-and-deals {
        overflow: hidden;

        &__contest-container, &__deals-container {
            box-sizing: border-box;
            display: inline-block;
            transition-property: width;
            // fix overflow:hidden causing forced typographical descenders
            transition-timing-function: ease-in-out;
            vertical-align: top;
            width: 50%;
        }

        &__contest-container {
            border-right: 10px solid $white;
            text-align: left;

            &.show-form {
                width: $expanded;
            }

            &.show-list {
                width: $collapsed;
            }

            &--no-deals {
                width: 100%;

                &.show-form {
                    width: 100%;
                }
            }
        }

        &__deals-container {
            text-align: right;

            &.show-list {
                width: $expanded;
            }

            &.show-form {
                width: $collapsed;
            }

            &--no-contest {
                width: 100%;

                &.show-list {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    $collapsed: 19%;
    $expanded: 81%;

    .contest-and-deals {
        &__contest-container {
            &.show-form {
                width: $expanded;
            }

            &.show-list {
                width: $collapsed;
            }
        }

        &__deals-container {
            &.show-list {
                width: $expanded;
            }

            &.show-form {
                width: $collapsed;
            }
        }
    }
}