.long-road-to-liberty__articles {
    padding-top: 15px;

    &-header {
        color: $black;
        font-size: 40px;
        margin: 15px 0 20px;
        text-align: center;
    }

    .search-result__name {
        a {
            color: $black;
            text-decoration: underline;

            &:hover {
                color: lighten($black, 35%);
            }
        }
    }
}