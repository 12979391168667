.contest-thanks {
    .contest {
        &--landing-page {
            .contest {
                &__title, &__prize-block {
                    display: none;
                }
            }
        }
    }
}

// TODO: Image backgrounds can add x-axis scrolling.
// TODO: Arrow and layout is messed up on `/contests`
.contest {
    // TODO: Make this color modifiable from the admin to match the contest images
    background-color: $navy;
    overflow: hidden;
    position: relative;

    &__title {
        color: $lightbeige;
        display: none;
    }

    $bg-width: 320px;
    $bg-height: 345px;

    &__background-container {
        height: 0;
        padding-top: percentage($bg-height/$bg-width);
        position: relative;
        width: 100%;
        z-index: 5;
    }

    &__background-image {
        background: none no-repeat left top;
        background-size: cover;
        bottom: 0;
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__background-image-sm {
        display: block;
    }

    &__enter-contest-arrow {
        background: url("/images/svg/contestTriangle.svg") no-repeat left top;
        @include hide-text();
        background-size: cover;
        display: none;
        height: percentage(88px/$bg-height);
        outline: none;
        overflow: visible;
        position: absolute;
        right: percentage(10px/$bg-width);
        text-indent: 100vw;
        top: percentage(20px/$bg-height);
        width: percentage(44px/$bg-width);
        z-index: 10;

        &.mobile {
            display: block;
        }
    }

    &__view-contest-text {
        opacity: 0;
    }

    &__prize-block {
        bottom: percentage(50px/$bg-height);
        position: absolute;
        text-align: right;
        width: 100%;
        z-index: 15;
    }

    &__click-to-enter-mobile {
        font-size: rem(14);
        margin: 0;
    }

    &__click-to-enter {
        color: $off-white;
        @include font-montserrat("Bold");
        display: none;
        font-size: rem(30);
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            color: $off-white;
            text-decoration: underline;
        }
    }

    &__prize-title {
        color: $yellow;
        display: none;
        @include font-montserrat("Bold");
        font-size: rem(24);
        line-height: em(18);
        padding: em(3) 0 em(5);
        text-transform: uppercase;
    }

    &__prize-list {
        color: $white;
        display: none;
        font-size: rem(16);
        @include font-montserrat("SemiBold");
        line-height: em(20);
        margin: 0;
        padding: 0;
    }

    &__prize {
        border-bottom: 1px solid $white;
        display: block;
        padding: em(5px) 0;

        p {
            font-size: rem(16px);
            line-height: em(28px, 16px);
            margin: 0;
        }

        a {
            color: $beige;

            &:hover {
                color: $yellow;
            }
        }
    }

    &__prize:last-child {
        border-bottom-width: 0;
    }

    &__contest-form-section {
        display: none;
        padding-top: em(20);
        transition-property: left;
        transition-timing-function: ease-in-out;
    }

    &__contest-form {
        background-color: $beige;
        color: $gray;
        font-size: em(16);
        padding: 0 10px 20px;

        .form-item__label {
            color: $gray;
        }

        .form-item__input {
            background-color: $beige;
        }

        .check-field {
            padding-left: 45px;
        }

        .submit {
            font-size: rem(14);
            text-align: center;
        }
    }

    &__contest-form-title {
        @include font-montserrat("Bold");
        color: $blue;
        font-size: em(30);
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
    }

    &__end-date {
        text-align: center;
    }

    &__previous-winner {
        display: none;
    }

    &__thank-you-block {
        color: $gray;
        padding: em(50) 0 em(5);
        text-align: center;
    }

    &__thank-you-text {
        @include hide-text();
        background: url("/images/contest_backgrounds/contest-thanks-sm.png") no-repeat center top;
        background-size: contain;
        height: 0;
        margin: 0 auto 55px;
        padding-top: percentage(137/260);
    }

    &__thank-you-brochure-title {
        @include font-montserrat("Bold");
        font-size: em(19);
        line-height: em(19);
        text-transform: uppercase;
    }

    &__thank-brochure-text {
        display: block;
        font-size: em(19);
        @include font-montserrat("SemiBold");
        line-height: em(19);
        padding: 0 17%;

        &:link, &:visited, &:active {
            color: $gray;
        }
    }

    &__thank-brochure-image {
        background: no-repeat center top;
        background-size: contain;
        height: 285px;
        margin: 0 auto;
        max-width: 350px;
    }

    &--landing-page {
        border-bottom: 5px solid $white;
        padding: 0 3% em(100) 3%;

        .contest {
            &__title {
                display: block;
                text-align: center;
            }

            &__background-container {
                display: none;
            }

            &__click-to-enter {
                display: none;

                &-mobile {
                    display: none;
                }
            }

            &__prize-block {
                position: relative;
                text-align: center;
            }

            &__prize-list, &__prize-title {
                display: inline-block;
                width: 90%;
            }

            &__prize-list {
                line-height: em(28);
            }

            &__contest-form-section {
                display: block;
            }

            &__contest-form {
                margin: 0 auto;
                max-width: 550px;
                // Fix collapsing h2 margin-top
                padding-top: 1px;

                .check-field {
                    padding-left: 0;
                }
            }

            &__previous-winner {
                display: block;
                text-align: center;
            }
        }
    }
}

@media screen and (min-width: 450px) {
    .contest {
        &--landing-page {
            .contest {
                &__contest-form {
                    .check-field {
                        padding-left: 45px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .contest {
        $bg-width: 800px;
        $bg-height: 759px;
        $slide-duration: .7s;

        max-height: $bg-height;

        &__background-container {
            left: 0;
            opacity: 1;
            @include transform-style(preserve-3d); // Fix for webkit browsers not always rendering.
            padding-top: percentage($bg-height/$bg-width);
            transition-property: left, opacity;
            transition-timing-function: ease-in-out;

            &.show-form {
                left: percentage(-585px/$bg-width);
                opacity: 0;
            }
        }

        &__background-image-sm {
            display: none;
        }

        &__background-image-md, &__background-image-md-form {
            display: block;
        }

        &__enter-contest-arrow {
            display: block;
            height: 0;
            padding-top: percentage(194px/$bg-width);
            right: percentage(44px/$bg-width);
            top: percentage(41px/$bg-height);
            transition-property: right;
            transition-timing-function: ease-in-out;
            width: percentage(97px/$bg-width);

            &.mobile {
                display: none;
            }

            &.show-form {
                right: percentage(630px/$bg-width);
            }
        }

        &__prize-block {
            bottom: percentage(80px/$bg-height);
            max-width: 375px;
            opacity: 1;
            right: percentage(70px/$bg-width);
            transition-property: right, opacity;
            transition-timing-function: ease-in-out;
            width: percentage(360px/$bg-width);

            &--background {
                background: rgba(30, 106, 170, 0.75);
                padding: 0 5px;
            }

            &--background-rounded {
                background: rgba(30, 106, 170, 0.75);
                border-radius: 10px;
                padding: 0 5px;
            }

            &--text-shadow {
                text-shadow: 0 0 15px $black, 0 0 2px $black;
            }

            &.show-form {
                opacity: 0;
                right: percentage(655px/$bg-width);
            }
        }

        &__click-to-enter-mobile {
            display: none;
        }

        &__click-to-enter {
            display: inline-block;
        }

        &__prize-title {
            display: block;
        }

        &__prize-list {
            display: block;
        }

        &__contest-form-section {
            display: block;
            height: 0;
            left: percentage(585px/$bg-width);
            padding-top: percentage($bg-height/$bg-width);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;

            &.show-form {
                left: 0;
            }
        }

        &__contest-form {
            left: percentage(200px/$bg-width);
            position: absolute;
            top: percentage(40px/$bg-height);
            width: 450px;
        }

        &__thank-you-text {
            background-image: url("/images/contest_backgrounds/contest-thanks-lg.png");
            padding-top: percentage(218/521);
        }

        &--landing-page {
            padding: 0;

            .contest {
                &__title {
                    display: none;
                }

                &__background-container {
                    display: block;
                }

                &__click-to-enter {
                    display: block;
                }

                &__prize-block {
                    position: absolute;
                    text-align: right;
                }

                &__prize-list, &__prize-title {
                    display: block;
                    width: auto;
                }
            }
        }

        &--thank-you-page {
            .contest {
                &__background-container {
                    left: percentage(-585px/$bg-width);
                    opacity: 0;
                }

                &__enter-contest-arrow {
                    display: none;
                }

                &__prize-block {
                    display: none;
                }

                &__contest-form-section {
                    left: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .contest-landing {
        overflow: hidden;
    }

    .contest-thanks {
        .contest {
            &--landing-page {
                .contest {
                    &__prize-block {
                        display: block;
                    }
                }
            }
        }
    }

    .contest {
        $bg-width: 1366px;
        // Need a taller form? Increase this value!
        $bg-height: 720px;

        height: auto;
        left: 50%;
        margin-left: -$bg-width/2;
        max-height: 1012px;
        width: $bg-width;

        &__background-container {
            left: 50%;
            margin-left: -#{$bg-width/2};
            padding-top: $bg-height;
            width: $bg-width;

            &.show-form {
                left: 50%;
                opacity: 1;
            }
        }

        &__background-image-md, &__background-image-md-form {
            display: none;
        }

        &__background-image-lg {
            display: block;
        }

        &__enter-contest-arrow {
            height: percentage(186px/$bg-height);
            left: percentage(613px/$bg-width);
            padding-top: 0;
            top: percentage(31px/$bg-height);
            width: percentage(93px/$bg-width);

            &.show-form {
                left: percentage(613px/$bg-width);
            }
        }

        &__prize-block {
            bottom: 13%;
            left: percentage(351px/$bg-width);
            max-width: 345px;
            width: percentage(342px/$bg-width);

            &.show-form {
                opacity: 1;
                right: auto;
            }
        }

        &__click-to-enter {
            display: none;
        }

        &__contest-form-section {
            background-image: none;
            left: percentage(735px/$bg-width);
            padding-top: 0;
            top: percentage(30px/$bg-height);
            width: auto;
            z-index: 10;

            &.show-form {
                left: percentage(735px/$bg-width);
            }
        }

        &__contest-form {
            left: percentage(200px/$bg-width);
            top: percentage(40px/$bg-height);
            width: 430px;
        }

        &__contest-form-title {
            margin: em(4);
        }

        &__thank-you-block {
            color: $gray;
            padding: em(20) 0 0;
            text-align: center;
        }

        &__thank-you-text {
            color: $gray;
            margin: 0 auto em(20);
            text-align: center;
        }

        &--landing-page {
            .contest {
                &__click-to-enter {
                    display: none;
                }
            }
        }

        &--thank-you-page {
            .contest {
                &__background-container {
                    left: 50%;
                    opacity: 1;
                }

                &__enter-contest-arrow {
                    display: block;

                    &.mobile {
                        display: none;
                    }
                }

                &__prize-block {
                    display: block;
                }

                &__contest-form-section {
                    left: percentage(735px/$bg-width);
                }
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    $bg-width: 1366px;
    // Need a taller form? Increase this value!
    $bg-height: 720px;

    .contest-and-deals__contest-container {
        .contest {
            display: inline-block;
            left: 0;
            margin-left: 0;
            width: 100vw;

            &.show-list {
                margin-left: -37vw;
            }

            &__enter-contest-arrow {
                left: percentage(568px/$bg-width);

                &.show-form {
                    left: percentage(568px/$bg-width);
                }
            }

            &__background-container {
                margin-left: percentage(-45px/$bg-width);
            }

            &__prize-block {
                margin-left: percentage(-45px/$bg-width);
            }

            &__contest-form-section {
                left: percentage(705px/$bg-width);

                &.show-form {
                    left: percentage(705px/$bg-width);
                }
            }
        }
    }

    .contest {
        left: 0;
        margin-left: 0;
        transition-property: margin-left;
        transition-timing-function: ease-in-out;
        vertical-align: top;
        width: 100%;

        &__enter-contest-arrow {
            height: percentage(186px/$bg-height);
            left: percentage(613px/$bg-width);
            padding-top: 0;
            top: percentage(31px/$bg-height);
            width: percentage(93px/$bg-width);

            &.show-form {
                left: percentage(613px/$bg-width);
            }
        }

        &__view-contest-text {
            background-color: $blue;
            color: $white;
            // $bg-width / 100 gives 1 vw unit, the font should be 12px at 1366px
            font-size: #{12px / ($bg-width / 100)}vw;
            line-height: #{24px / ($bg-width / 100)}vw;
            margin-right: percentage(450px/$bg-width);
            @include font-montserrat("Bold");
            position: absolute;
            right: 0;
            text-align: center;
            text-indent: 0;
            text-transform: uppercase;
            top: 50%;
            transition-property: opacity;
            @include transform(rotate(-90deg));
            @include transform-origin(50% 0);
            transition-timing-function: ease-in-out;
            width: 200%;

            &.show-list {
                opacity: 1;
            }

            &.show-form {
                opacity: 0;
            }
        }

        &__background-container {
            left: 0;
            margin-left: 0;
            padding-top: percentage($bg-height/$bg-width);
            width: 100%;

            &.show-form {
                left: 0;
            }

            &.show-list {
                opacity: 0;
            }
        }

        &__prize-block {
            left: percentage(345px/$bg-width);
            max-width: none;
            width: percentage(348px/$bg-width);

            &.show-list {
                opacity: 0;
            }
        }

        &__click-to-enter {
            display: block;
        }

        &__prize-list {
            font-size: rem(18);
        }

        &__contest-form-section {
            left: percentage(735px/$bg-width);

            &.show-form {
                left: percentage(735px/$bg-width);
            }
        }

        &--thank-you-page {
            .contest {
                &__background-container {
                    left: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    $bg-width: 1577px;
    $bg-height: 902px;
    $bg-area-width: $bg-width/1920px;

    .contest-and-deals__contest-container {
        .contest {
            &.show-list {
                margin-left: -31vw;
            }

            &__background-container {
                margin-left: percentage(-10px/$bg-width * $bg-area-width);
            }

            &__enter-contest-arrow {
                left: percentage(801px/$bg-width * $bg-area-width);

                &.show-form {
                    left: percentage(801px/$bg-width * $bg-area-width);
                }
            }

            &__prize-block {
                margin-left: percentage(-10px/$bg-width * $bg-area-width);
            }
        }
    }

    .contest {
        // Quit resizing when the screen is 2560px
        max-height: (($bg-height/$bg-width) * $bg-area-width) * 2560px;

        &.show-list {
            margin-left: -31vw;
        }

        &__background-container {
            margin-left: 0;
            padding-top: percentage(($bg-height/$bg-width) * $bg-area-width);
            // The maximum width takes into account the deals arrow when the contest is expanded
            width: percentage($bg-area-width);
        }

        &__background-image-lg {
            display: none;
        }

        &__background-image-xl {
            display: block;
        }

        &__enter-contest-arrow {
            height: 0;
            left: percentage(811px/$bg-width * $bg-area-width);
            padding-top: percentage(232px/1920px);
            top: percentage(50px/$bg-height);
            width: percentage(116px/$bg-width * $bg-area-width);

            &.show-form {
                left: percentage(811px/$bg-width * $bg-area-width);
            }
        }

        &__prize-block {
            bottom: 18%;
            left: percentage(490px/$bg-width * $bg-area-width);
            margin-left: 0;
            width: percentage(400px/$bg-width * $bg-area-width);
        }

        &__click-to-enter {
            display: inline-block;
        }

        &--landing-page {
            .contest {
                &__prize-block {
                    left: percentage(530px/$bg-width * $bg-area-width);
                }
            }
        }
    }
}