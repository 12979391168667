@include subnav(long-road-to-liberty, $long-road-to-liberty-red, $long-road-to-liberty-red, #ffffff, $white, $long-road-to-liberty-red, $long-road-to-liberty-red, #000000, $long-road-to-liberty-red);

.long-road-to-liberty {
    &__container {
        a {
            text-decoration: none;
        }

        button, .button {
            background-color: $long-road-to-liberty-red;

            &:hover {
                background-color: darken($long-road-to-liberty-red, 7.5%);
            }
        }

        .button {
            &:link, &:visited, &:active {
                background-color: $long-road-to-liberty-red;
            }
        }

        .summary-button {
            &:link, &:visited, &:active {
                background-color: $long-road-to-liberty-dark-gray;
            }

            &:hover {
                background-color: darken($long-road-to-liberty-dark-gray, 7.5%);
            }
        }

        .pagination {
            a:link, a:visited, a:active {
                background-color: $long-road-to-liberty-red;
            }

            .pagination__active {
                background-color: darken($long-road-to-liberty-red, 7.5%);
            }
        }

        .search-summary__per-page {
            margin-right: 13.5em;
        }
    }

    &__tagline {
        @include font-montserrat("Regular");
        font-size: 17px;
        line-height: 26px;
        padding: em(5px, 17px) 0 em(25px, 17px);

        @include tablet {
            @include font-montserrat("Medium");
            font-size: 19px;
            letter-spacing: 1px;
            padding: em(5px, 19px) 0 em(30px, 19px);
        }

        @include laptop {
            padding: em(15px, 19px) 0 em(50px, 19px);
        }

        @include desktop {
            font-size: 20px;
            padding: em(15px, 20px) 0 em(50px, 20px);
        }
    }

    &__background-wrap {
        @extend .music-trail__background-wrap;
    }

    &__background {
        @extend .music-trail__background;

        .webp &--00 {
            background: url("/images/long-road-to-liberty/backgrounds/background-00.png") no-repeat left 0;
        }

        &--00 {
            @extend .music-trail__background--00;
        }

        .webp &--01 {
            background: url("/images/long-road-to-liberty/backgrounds/background-01.png") no-repeat right 0;
        }

        &--01 {
            @extend .music-trail__background--01;
        }

        .webp &--02 {
            background: url("/images/long-road-to-liberty/backgrounds/background-02.png") no-repeat left;
        }

        &--02 {
            @extend .music-trail__background--02;
        }

        .webp &--03 {
            background: url("/images/long-road-to-liberty/backgrounds/background-03.png") no-repeat right;
        }

        &--03 {
            @extend .music-trail__background--03;
        }
    }
}
