.---------Protection-------- {
}

.ug-gallery-wrapper,
.ug-gallery-wrapper * {
    max-width: none;
    -moz-transition: all !important;
    -ms-transition: all !important;
    -o-transition: all !important;
    -webkit-transition: all;
    transition: all !important;
}

.ug-gallery-wrapper {
    z-index: 0;
}

.ug-item-wrapper img {
    margin: 0px;
    max-width: none;
    padding: 0px;
    width: auto;
}

.ug-videoplayer-wrapper video {
    height: 100%;
    visibility: visible !important;
    width: 100%;
}

.---------General_Styles-------- {
}

.ug-rtl {
    direction: rtl;
}

.ug-gallery-wrapper {
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    position: relative;
}

.ug-error-message {
    color: #f30d0d;
    font-size: 20px;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
}

.ug-error-message-wrapper {
    border: 1px solid black;
    height: 100%;
    width: 100%;
}

.ug-gallery-wrapper .ug-canvas-pie {
    position: absolute;
    z-index: 4;
}

.ug-gallery-wrapper .ug-progress-bar {
    overflow: hidden;
    position: absolute;
    z-index: 4;
}

.ug-gallery-wrapper .ug-progress-bar-inner {
    xposition: absolute;
}

.ug-gallery-wrapper.ug-fullscreen {
    height: 100% !important;
    max-height: none !important;
    max-width: none !important;
    width: 100% !important;
}

.ug-gallery-wrapper.ug-fake-fullscreen {
    bottom: 0px !important;
    height: auto !important;
    left: 0px !important;
    margin: 0px !important;
    max-height: none !important;
    max-width: none !important;
    padding: 0px !important;
    position: fixed !important;
    right: 0px !important;
    top: 0px !important;
    width: auto !important;
    z-index: 999999 !important;
}

.ug-body-fullscreen {
    height: 100% !important;
    margin: 0px !important;
    overflow: hidden !important;
    padding: 0px !important;
    width: 100% !important;
}

.ug-gallery-wrapper .ug-preloader-trans {
    background-image: url("/lib/unitegallery/images/loader_skype_trans.gif");
    background-repeat: no-repeat;
    display: block;
    height: 35px;
    height: 32px;
    width: 32px;
}

.--------------SLIDER------------------ {
}

.ug-gallery-wrapper .ug-slider-wrapper {
    background-color: #000000;
    overflow: hidden;
    position: absolute;
    z-index: 1;
}

.ug-slider-wrapper .ug-slide-wrapper {
    position: absolute;
}

.ug-slide-wrapper.ug-slide-clickable {
    cursor: pointer;
}

.ug-slider-wrapper .ug-slider-inner {
    position: absolute;
    z-index: 0;
}

.ug-slider-wrapper .ug-item-wrapper {
    overflow: hidden;
    position: absolute;
}

.ug-slider-wrapper,
.ug-item-wrapper img {

    box-sizing: border-box;
    max-width: none !important;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none; /* make unselectable */
}

.ug-slider-wrapper .ug-slider-preloader {
    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    border: 2px solid #ffffff;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

.ug-slider-preloader.ug-loader1 {
    background-image: url("/lib/unitegallery/images/loader-white1.gif");
    height: 30px;
    width: 30px;
}

.ug-slider-preloader.ug-loader1.ug-loader-black {
    background-image: url("/lib/unitegallery/images/loader-black1.gif");
}

.ug-slider-preloader.ug-loader2 {
    background-image: url("/lib/unitegallery/images/loader-white2.gif");
    height: 32px;
    width: 32px;
}

.ug-slider-preloader.ug-loader2.ug-loader-black {
    background-image: url("/lib/unitegallery/images/loader-black2.gif");
}

.ug-slider-preloader.ug-loader3 {
    background-image: url("/lib/unitegallery/images/loader-white3.gif");
    height: 38px;
    width: 38px;
}

.ug-slider-preloader.ug-loader3.ug-loader-black {
    background-image: url("/lib/unitegallery/images/loader-black3.gif");
}

.ug-slider-preloader.ug-loader4 {
    background-color: white;
    background-image: url("/lib/unitegallery/images/loader-white4.gif");
    height: 32px;
    width: 32px;
}

.ug-slider-preloader.ug-loader4.ug-loader-black {
    background-image: url("/lib/unitegallery/images/loader-black4.gif");
}

.ug-slider-preloader.ug-loader5 {
    background-color: white;
    background-image: url("/lib/unitegallery/images/loader-white5.gif");
    border: none;
    border-radius: 0px;
    height: 8px;
    width: 60px;
}

.ug-slider-preloader.ug-loader5.ug-loader-black {
    background-image: url("/lib/unitegallery/images/loader-black5.gif");
    border: 2px solid #000000;
}

.ug-slider-preloader.ug-loader6 {
    background-image: url("/lib/unitegallery/images/loader-white6.gif");
    height: 32px;
    width: 32px;
}

.ug-slider-preloader.ug-loader6.ug-loader-black {
    background-image: url("/lib/unitegallery/images/loader-black6.gif");
}

.ug-slider-preloader.ug-loader7 {
    background-image: url("/lib/unitegallery/images/loader-white7.gif");
    border-radius: 3px;
    border-width: 3px;
    height: 10px;
    width: 32px;
}

.ug-slider-preloader.ug-loader7.ug-loader-black {
    background-image: url("/lib/unitegallery/images/loader-black7.gif");
}

.ug-slider-preloader.ug-loader-black {
    background-color: #000000;
    border-color: #000000;
}

.ug-slider-preloader.ug-loader-nobg {
    background-color: transparent;
}

.ug-slider-wrapper .ug-button-videoplay {
    cursor: pointer;
    position: absolute;
    z-index: 2;
}

.ug-slider-wrapper .ug-button-videoplay.ug-type-square {
    background-image: url("/lib/unitegallery/images/play-button-square.png");
    background-position: 0px -66px;
    height: 66px;
    width: 86px;
}

.ug-slider-wrapper .ug-button-videoplay.ug-type-square.ug-button-hover {
    background-position: 0px 0px;
}

.ug-slider-wrapper .ug-button-videoplay.ug-type-round {
    background-image: url("/lib/unitegallery/images/play-button-round.png");
    filter: alpha(opacity=90);
    height: 76px;
    opacity: 0.9;
    transition: all 0.3s ease 0s !important;
    width: 76px;
}

.ug-slider-wrapper .ug-button-videoplay.ug-type-round.ug-button-hover {
    filter: alpha(opacity=100);
    opacity: 1;
    transition: all 0.3s ease 0s !important;
}

.--------------VIDEO_PLAYER------------------ {
}

.ug-gallery-wrapper .ug-videoplayer {
    background-color: #000000;
    background-image: url("/lib/unitegallery/images/loader-black1.gif");
    background-position: center center;
    background-repeat: no-repeat;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
}

.ug-videoplayer .ug-videoplayer-wrapper {
    background-color: #000000;
    background-image: url("/lib/unitegallery/images/loader-black1.gif");
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.ug-videoplayer .ug-videoplayer-button-close {
    background-image: url("/lib/unitegallery/images/button-close.png");
    cursor: pointer;
    height: 64px;
    position: absolute;
    width: 64px;
    z-index: 1000;
}

.ug-videoplayer .ug-videoplayer-button-close:hover {
    background-position: 0px -64px;
}

.--------------THUMBS_STRIP------------------ {
}

.ug-gallery-wrapper .ug-thumbs-strip,
.ug-gallery-wrapper .ug-thumbs-grid {
    overflow: hidden;
    position: absolute;

    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none; /* make unselectable */

    xbackground-color: green;
    z-index: 10;
}

.ug-thumbs-strip.ug-dragging {
    xcursor: -webkit-grab;
    xcursor: -moz-grab;
}

.ug-gallery-wrapper .ug-thumbs-strip-inner,
.ug-gallery-wrapper .ug-thumbs-grid-inner {
    position: absolute;
}

.ug-thumb-wrapper.ug-thumb-generated {
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    overflow: hidden;
    position: absolute;
    -moz-user-select: none;

    -ms-user-select: none;
    -webkit-user-select: none; /* make unselectable */
}

.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-loader,
.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-error {
    background-position: center center;
    background-repeat: no-repeat;
    left: 0px;
    position: absolute;
    top: 0px;
}

.ug-thumb-wrapper .ug-thumb-border-overlay {
    border-style: solid;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    left: 0px;
    position: absolute;
    top: 0px;
    z-index: 4;
}

.ug-thumb-wrapper .ug-thumb-overlay {
    left: 0px;
    position: absolute;
    top: 0px;
    z-index: 3;
}

.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-loader-dark {
    background-image: url("/lib/unitegallery/images/loader.gif");
}

.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-loader-bright {
    background-image: url("/lib/unitegallery/images/loader_bright.gif");
}

.ug-thumb-wrapper.ug-thumb-generated .ug-thumb-error {
    background-image: url("/lib/unitegallery/images/not_loaded.png");
}

.ug-thumb-wrapper.ug-thumb-generated img {
    max-width: none !important;
    position: absolute;
    z-index: 1;
}

.ug-thumb-wrapper.ug-thumb-generated img.ug-thumb-image-overlay {
    max-width: none !important;
    position: absolute;
    z-index: 2;
}

/* bw desaturate effect lightweight */
img.ug-bw-effect {
    -moz-filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
}

/* blur effect desaturate effect lightweight */
img.ug-blur-effect {

    -moz-filter: blur(0.8px);
    -ms-filter: blur(0.8px);
    -o-filter: blur(0.8px);
    -webkit-filter: blur(0.8px);
    filter: blur(0.8px);
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id='blur'><feGaussianBlur stdDeviation=\'0.8\' /></filter></svg>#blur"); /* Firefox 10+, Firefox on Android */
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius="0.8");
}

img.ug-sepia-effect {
    background-color: #5e2612;
    -moz-filter: sepia(100%);
    -ms-filter: sepia(100%);
    -o-filter: sepia(100%);
    -webkit-filter: sepia(1);
    -webkit-filter: sepia(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'sepia\'><feColorMatrix values=\'0.14 0.45 0.05 0 0 0.12 0.39 0.04 0 0 0.08 0.28 0.03 0 0 0 0 0 1 0\' /></filter></svg>#sepia"); /* Firefox 10+, Firefox on Android */
    filter: sepia(100%);
    filter: alpha(opacity=50);
    zoom: 1;
}

.--------------BULLETS------------------ {
}

.ug-gallery-wrapper .ug-bullets {
    left: 0px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 0px;
}

.ug-gallery-wrapper .ug-bullets .ug-bullet {
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
    float: left;
    z-index: 2;
}

.ug-gallery-wrapper .ug-bullets .ug-bullet:first-child {
    margin-left: 0px;
}

.--------------TEXT_PANEL------------------ {
}

.ug-gallery-wrapper .ug-textpanel {
    overflow: hidden;
    position: absolute;
}

.ug-slider-wrapper .ug-textpanel {
    z-index: 2;
}

.ug-gallery-wrapper .ug-textpanel-bg,
.ug-gallery-wrapper .ug-textpanel-title,
.ug-gallery-wrapper .ug-textpanel-description {
    display: block;
    margin: 0px;
    padding: 0px;
    position: absolute;
}

.ug-gallery-wrapper .ug-textpanel-bg {
    background-color: #000000;
    filter: alpha(opacity=40);
    opacity: 0.4;
    z-index: 1;
}

.ug-textpanel-textwrapper {
    overflow: hidden;
}

.ug-gallery-wrapper .ug-textpanel-title {
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    z-index: 2;
}

.ug-gallery-wrapper .ug-textpanel-description {
    color: white;
    font-size: 14px;
    text-align: left;
    z-index: 3;
}

.--------------ZOOM_PANEL------------------ {
}

.ug-slider-wrapper .ug-zoompanel {
    left: 100px;
    position: absolute;
    top: 200px;
}

.ug-zoompanel .ug-zoompanel-button {
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
}

.--------------ZOOM_PANEL_BUTTONS------------------ {
}

.ug-gallery-wrapper .ug-arrow-left {
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
    position: absolute;
}

.ug-gallery-wrapper .ug-arrow-right {
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
}

.--------------GRID_PANEL_AND_ARROWS------------------ {
}

.ug-gallery-wrapper .ug-grid-panel {
    background-color: #000000;
    position: absolute;
    xbackground-color: blue;

    z-index: 2;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow {
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    z-index: 11;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow.ug-button-disabled {
    cursor: default;
}

/* handle tip */
.ug-gallery-wrapper .ug-panel-handle-tip {
    cursor: pointer;
    position: absolute;
    z-index: 13;
}

.ug-gallery-wrapper .ug-overlay-disabled {
    background-color: #000000;
    background-image: url("/lib/unitegallery/images/cover-grid.png");
    filter: alpha(opacity=30);
    height: 100%;
    opacity: 0.3;
    position: absolute;
    width: 100%;
    z-index: 100;
}

.--------------STRIP_PANEL------------------ {
}

.ug-gallery-wrapper .ug-strip-panel {
    background-color: #000000;
    position: absolute;
    z-index: 2;
}

.ug-strip-panel .ug-strip-arrow {
    cursor: pointer;
    position: absolute;
}

.ug-strip-panel .ug-strip-arrow.ug-button-disabled {
    cursor: default;
}

.ug-strip-panel .ug-strip-arrow-tip {
    position: absolute;
}

.--------------TILES------------------ {
}

.ug-thumb-wrapper.ug-tile {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    margin: 0px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
}

.ug-tile .ug-tile-cloneswrapper {
    filter: alpha(opacity=90);
    height: 0px;
    left: 0px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 0px;
}

/* transit something */
/* transit fixed size items */
/* transit image overlay */

.ug-tiles-transit.ug-tiles-transition-active .ug-thumb-wrapper.ug-tile,
.ug-tiles-transit.ug-tiles-transition-active .ug-thumb-wrapper.ug-tile .ug-trans-enabled {
    transition: left 0.7s ease 0s, top 0.7s ease 0s, width 0.7s ease 0s, height 0.7s ease 0s !important;
}

.ug-thumb-wrapper.ug-tile.ug-tile-clickable {
    cursor: pointer;
}

.ug-thumb-wrapper.ug-tile .ug-image-container {
    left: 0px;
    overflow: hidden;
    position: absolute;
    top: 0px;
    z-index: 1;
}

.ug-thumb-wrapper.ug-tile img.ug-thumb-image {

    border: none;
    display: block;
    height: auto;
    margin: 0px !important;
    max-height: none !important;

    max-width: none !important;
    padding: 0px !important;
    position: absolute;
    -moz-user-select: none;

    -ms-user-select: none;
    -webkit-user-select: none; /* make unselectable */
    width: auto;
}

.ug-thumb-wrapper.ug-tile .ug-tile-image-overlay {
    left: 0px;
    overflow: hidden;
    position: absolute;
    top: 0px;
    z-index: 2;
}

.ug-thumb-wrapper.ug-tile .ug-tile-image-overlay img {
    max-width: none !important;
    width: 100%;
}

.ug-tiletype-justified .ug-thumb-wrapper.ug-tile .ug-tile-image-overlay img {
    height: 100%;
    max-width: none !important;
}

.ug-thumb-wrapper.ug-tile .ug-tile-icon {
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 38px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 38px;
    xbackground-color: green;
    z-index: 6;
}

.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-link {
    background-image: url("/lib/unitegallery/images/icon-link32.png");
}

.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-zoom {
    background-image: url("/lib/unitegallery/images/icon-zoom32.png");
}

.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-play {
    background-image: url("/lib/unitegallery/images/icon-play32.png");
}

.ug-thumb-wrapper.ug-tile .ug-tile-icon:hover {
    background-position: 0 -38px;
}

.ug-thumbs-grid .ug-tile-image-overlay img {
    height: auto;
    max-width: none !important;
    position: absolute;
    width: auto;
}

/* text panel on tiles*/

.ug-tile .ug-textpanel {
    cursor: default;
    z-index: 5;
}

.ug-tile.ug-tile-clickable .ug-textpanel {
    cursor: pointer;
}

.ug-tile .ug-textpanel-title {
    font-size: 14px;
    font-weight: bold;
}

.--------------CAROUSEL------------------ {
}

.ug-gallery-wrapper .ug-carousel-wrapper {
    overflow: hidden;
    position: absolute;
}

.ug-gallery-wrapper .ug-carousel-inner {
    position: absolute;
}

.------------------LIGHTBOX--------------------- {
}

.ug-gallery-wrapper.ug-lightbox {
    border: none;
    display: none;
    height: 100%;
    left: 0;
    margin: 0px;
    max-height: none !important;
    max-width: none !important;
    outline: invert none medium;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
}

.ug-lightbox .ug-lightbox-top-panel {
    position: absolute;
    z-index: 2;
}

.ug-lightbox .ug-lightbox-top-panel-overlay {
    background-color: #000000;
    filter: alpha(opacity=40);
    height: 100%;
    opacity: 0.4;
    position: absolute;
    width: 100%;
}

.ug-lightbox .ug-lightbox-overlay {
    background-color: #000000;
    height: 100%;
    opacity: 1;
    width: 100%;
    z-index: 1;
}

.ug-lightbox .ug-slider-wrapper {
    background-color: transparent !important;
    xbackground-color: green;
    z-index: 1;
}

.ug-lightbox .ug-textpanel {
    z-index: 2;
}

.ug-lightbox .ug-lightbox-arrow-left,
.ug-lightbox .ug-lightbox-arrow-right {
    background-image: url("/lib/unitegallery/images/lightbox-arrow-left.png");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 55px;
    position: absolute;
    width: 50px;
    z-index: 3;
}

.ug-lightbox .ug-lightbox-arrow-right {
    background-image: url("/lib/unitegallery/images/lightbox-arrow-right.png");
}

.ug-lightbox .ug-lightbox-button-close {
    background-image: url("/lib/unitegallery/images/lightbox-icon-close.png");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 36px;
    position: absolute;
    width: 36px;
    z-index: 4;
}

.ug-lightbox-compact .ug-lightbox-button-close {
    background-image: url("/lib/unitegallery/images/lightbox-icon-close-compact2.png");
    height: 41px;
    width: 45px;
}

.ug-lightbox .ug-lightbox-arrow-left.ug-arrow-hover,
.ug-lightbox .ug-lightbox-arrow-right.ug-arrow-hover {
    background-position: 0px -55px;
}

.ug-lightbox .ug-lightbox-button-close.ug-button-hover {
    background-position: 0px -35px;
}

.ug-lightbox-compact .ug-lightbox-button-close.ug-button-hover {
    background-position: 0px -40px;
}

.ug-lightbox .ug-lightbox-numbers {
    color: #e5e5e5;
    font-size: 12px;
    left: 8px;
    position: absolute;
    top: 14px;
    z-index: 3;
}

.ug-lightbox-compact .ug-lightbox-numbers {
    font-size: 14px;
    padding-right: 5px;
    padding-top: 7px;
}

.ug-lightbox .ug-textpanel-title,
.ug-lightbox .ug-textpanel-description {
    color: #e5e5e5;
    font-size: 14px;
    font-weight: normal;
}

.ug-lightbox-compact .ug-textpanel-title,
.ug-lightbox-compact .ug-textpanel-description {
    color: #f3f3f3;
    font-size: 16px;
}

.------------------CATEGORY_TABS--------------------- {
}

.ug-tabs-wrapper {
    margin-bottom: 20px;
    margin-top: 0px;
    text-align: center;
}

.ug-tabs-wrapper a.ug-tab {
    background-color: #333333;
    border: 1px solid #969696;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    margin-left: 5px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;

    text-decoration: none;
}

.ug-tabs-wrapper a.ug-tab:hover {
    background-color: #707070 !important;
}

.ug-tabs-wrapper a.ug-tab.ug-tab-selected,
.ug-tabs-wrapper a.ug-tab.ug-tab-selected:hover {
    background-color: #2077a2 !important;
    cursor: default !important;
}

.ug-tabs-wrapper a.ug-tab:first-child {
    margin-left: 0px !important;
}

.------------------DEFAULT_SKIN--------------------- {
}

/* --- arrows -- */

.ug-arrow-left.ug-skin-default,
.ug-arrow-right.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/slider_arrow_left.png");
    background-position: 0px 0px;
    height: 30px;
    width: 15px;
}

.ug-arrow-right.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/slider_arrow_right.png");
}

.ug-arrow-left.ug-skin-default.ug-arrow-hover,
.ug-arrow-right.ug-skin-default.ug-arrow-hover {
    background-position: 0px -30px;
}

.ug-arrow-left.ug-skin-default.ug-arrow-disabled,
.ug-arrow-right.ug-skin-default.ug-arrow-disabled {
    background-position: 0px -60px;
}

/* --- bullets -- */

.ug-bullets.ug-skin-default .ug-bullet {
    background-image: url("/lib/unitegallery/skins/default/slider_bullets.png");
    background-position: top left;
    height: 15px;
    margin-left: 5px;
    width: 15px;
}

.ug-bullets.ug-skin-default .ug-bullet.ug-bullet-active {
    background-position: bottom left;
}

.ug-bullets.ug-skin-default.ug-bullets-gray .ug-bullet {
    background-image: url("/lib/unitegallery/skins/default/tile_bullets_gray.png");
}

.ug-bullets.ug-skin-default.ug-bullets-blue .ug-bullet {
    background-image: url("/lib/unitegallery/skins/default/tile_bullets_blue.png");
}

.ug-bullets.ug-skin-default.ug-bullets-brown .ug-bullet {
    background-image: url("/lib/unitegallery/skins/default/tile_bullets_brown.png");
}

.ug-bullets.ug-skin-default.ug-bullets-green .ug-bullet {
    background-image: url("/lib/unitegallery/skins/default/tile_bullets_green.png");
}

.ug-bullets.ug-skin-default.ug-bullets-red .ug-bullet {
    background-image: url("/lib/unitegallery/skins/default/tile_bullets_red.png");
}

.ug-button-tile-navigation {
    background-position: top left;
    cursor: pointer;
    float: left;
    height: 36px;
    width: 36px;
}

.ug-button-tile-navigation.ug-button-tile-left {
    background-image: url("/lib/unitegallery/skins/default/tile_button_left.png");
}

.ug-button-tile-navigation.ug-button-tile-right {
    background-image: url("/lib/unitegallery/skins/default/tile_button_right.png");
}

.ug-button-tile-navigation:hover {
    background-position: bottom left;
}

.ug-button-tile-navigation.ug-button-disabled {
    opacity: 0.6;
}

.ug-button-tile-navigation.ug-button-disabled:hover {
    background-position: top left;
}

.ug-button-tile-navigation.ug-button-tile-play {
    background-image: url("/lib/unitegallery/skins/default/tile_button_play_pause.png");
    width: 35px;
}

.ug-button-tile-navigation.ug-button-tile-play.ug-pause-icon {
    background-position: bottom left;
}

.ug-button-tile-navigation.ug-button-tile-play:hover {
    background-position: top left;
}

.ug-button-tile-navigation.ug-button-tile-play.ug-pause-icon:hover {
    background-position: bottom left;
}

/* --- play button -- */

.ug-button-play.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/button_playpause.png");
    background-position: 0px 0px;
    cursor: pointer;
    height: 17px;
    position: absolute;
    width: 14px;
}

.ug-button-play.ug-skin-default.ug-button-hover {
    background-position: 0px -17px;
}

.ug-button-play.ug-skin-default.ug-stop-mode {
    background-position: 0px -34px;
}

.ug-button-play.ug-skin-default.ug-stop-mode.ug-button-hover {
    background-position: 0px -51px;
}

/* --- fullscreen button -- */

.ug-button-fullscreen.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/button_fullscreen.png");
    background-position: 0px 0px;
    cursor: pointer;
    height: 16px;
    position: absolute;
    width: 16px;
}

.ug-button-fullscreen.ug-skin-default.ug-button-hover {
    background-position: 0px -16px;
}

.ug-button-fullscreen.ug-skin-default.ug-fullscreenmode {
    background-position: 0px -32px;
}

.ug-button-fullscreen.ug-skin-default.ug-fullscreenmode.ug-button-hover {
    background-position: 0px -48px;
}

/* --- zoom panel --- */

.ug-zoompanel.ug-skin-default .ug-zoompanel-button {
    background-position: 0px 0px;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 23px;
    margin-top: 5px;
    width: 23px;
}

.ug-zoompanel.ug-skin-default .ug-zoompanel-plus {
    margin-top: 0px;
}

.ug-zoompanel.ug-skin-default .ug-zoompanel-plus {
    background-image: url("/lib/unitegallery/skins/default/icon_zoom_plus.png");
}

.ug-zoompanel.ug-skin-default .ug-zoompanel-minus {
    background-image: url("/lib/unitegallery/skins/default/icon_zoom_minus.png");
}

.ug-zoompanel.ug-skin-default .ug-zoompanel-return {
    background-image: url("/lib/unitegallery/skins/default/icon_zoom_back.png");
}

.ug-zoompanel.ug-skin-default .ug-zoompanel-button.ug-button-hover {
    background-position: 0px -23px;
}

.ug-zoompanel.ug-skin-default .ug-zoompanel-button-disabled,
.ug-zoompanel.ug-skin-default .ug-zoompanel-button.ug-button-hover.ug-zoompanel-button-disabled {
    background-position: 0px -46px;
    cursor: default;
}

/* --- Grid Panel Arrows -- */

/*--- down arrow ---*/

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/arrow_grid_down.png");
    background-position: 0px 0px;
    height: 15px;
    width: 30px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default.ug-button-hover {
    background-position: 0px -15px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-down.ug-skin-default.ug-button-disabled {
    background-position: 0px -30px;
}

/*--- up arrow ---*/

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/arrow_grid_up.png");
    background-position: 0px 0px;
    height: 15px;
    width: 30px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default.ug-button-hover {
    background-position: 0px -15px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-up.ug-skin-default.ug-button-disabled {
    background-position: 0px -30px;
}

/*--- left arrow ---*/

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/grid_arrow_left.png");
    background-position: 0px -40px;
    height: 40px;
    width: 40px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default.ug-button-hover {
    background-position: 0px -0px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left.ug-skin-default.ug-button-disabled {
    background-position: 0px -80px;
}

/*--- right arrow ---*/

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/grid_arrow_right.png");
    background-position: 0px -40px;
    height: 40px;
    width: 40px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default.ug-button-hover {
    background-position: 0px -0px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right.ug-skin-default.ug-button-disabled {
    background-position: 0px -80px;
}

/*--- left arrow - horizontal panel type ---*/

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/slider_arrow_left.png");
    background-position: 0px 0px;
    height: 30px;
    width: 15px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default.ug-button-hover {
    background-position: 0px -30px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-left-hortype.ug-skin-default.ug-button-disabled {
    background-position: 0px -60px;
}

/*--- right arrow - horizontal panel type ---*/

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/slider_arrow_right.png");
    background-position: 0px 0px;
    height: 30px;
    width: 15px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default.ug-button-hover {
    background-position: 0px -30px;
}

.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default.ug-button-hover.ug-button-disabled,
.ug-gallery-wrapper .ug-grid-panel .grid-arrow-right-hortype.ug-skin-default.ug-button-disabled {
    background-position: 0px -60px;
}

/* --- Strip Panel Arrows -- */

/* left and right arrows */

.ug-strip-panel .ug-strip-arrow-left.ug-skin-default,
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default {
    height: 50px;
    width: 25px;
}

.ug-strip-panel .ug-strip-arrow-left.ug-skin-default .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default .ug-strip-arrow-tip {
    background-position: 0px 0px;
    background-repeat: no-repeat;
    height: 7px;
    width: 4px;
}

.ug-strip-panel .ug-strip-arrow-left.ug-skin-default .ug-strip-arrow-tip {
    background-image: url("/lib/unitegallery/skins/default/arrows_strip_left.png");
}

.ug-strip-panel .ug-strip-arrow-right.ug-skin-default .ug-strip-arrow-tip {
    background-image: url("/lib/unitegallery/skins/default/arrows_strip_right.png");
}

.ug-strip-panel .ug-strip-arrow-left.ug-skin-default.ug-button-hover .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-right.ug-skin-default.ug-button-hover .ug-strip-arrow-tip {
    background-position: 0px -7px;
}

/* up and down arrows */

.ug-strip-panel .ug-strip-arrow-up.ug-skin-default,
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default {
    height: 25px;
    width: 50px;
}

.ug-strip-panel .ug-strip-arrow-up.ug-skin-default .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default .ug-strip-arrow-tip {
    background-position: 0px 0px;
    background-repeat: no-repeat;
    height: 4px;
    width: 7px;
}

.ug-strip-panel .ug-strip-arrow-up.ug-skin-default .ug-strip-arrow-tip {
    background-image: url("/lib/unitegallery/skins/default/arrows_strip_up.png");
}

.ug-strip-panel .ug-strip-arrow-down.ug-skin-default .ug-strip-arrow-tip {
    background-image: url("/lib/unitegallery/skins/default/arrows_strip_down.png");
}

.ug-strip-panel .ug-strip-arrow-up.ug-skin-default.ug-button-hover .ug-strip-arrow-tip,
.ug-strip-panel .ug-strip-arrow-down.ug-skin-default.ug-button-hover .ug-strip-arrow-tip {
    background-position: -7px 0px;
}

.ug-strip-panel .ug-strip-arrow.ug-skin-default.ug-button-disabled .ug-strip-arrow-tip {
    background-position: 0px 0px;
    filter: alpha(opacity=50);
    opacity: 0.5;
}

/* --- Panel Handle Tip -- */

/* left and right handle */
.ug-gallery-wrapper .ug-handle-tip-left.ug-skin-default,
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/grid_handle_black_left.png");
    background-position: 0px -36px;
    background-repeat: no-repeat;
    height: 36px;
    width: 22px;
}

.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/grid_handle_black_right.png");
    background-position: 0px -36px;
}

.ug-gallery-wrapper .ug-handle-tip-left.ug-button-hover.ug-skin-default,
.ug-gallery-wrapper .ug-handle-tip-right.ug-button-hover.ug-skin-default {
    background-position: 0px -0px;
}

.ug-gallery-wrapper .ug-handle-tip-left.ug-skin-default.ug-button-closed,
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default.ug-button-closed {
    background-position: 0px -108px;
}

.ug-gallery-wrapper .ug-handle-tip-left.ug-skin-default.ug-button-closed.ug-button-hover,
.ug-gallery-wrapper .ug-handle-tip-right.ug-skin-default.ug-button-closed.ug-button-hover {
    background-position: 0px -72px;
}

/* top and bottom handle */

.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/grid_handle_black_top.png");
    background-position: -36px 0px;
    background-repeat: no-repeat;
    height: 22px;
    width: 36px;
}

.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default {
    background-image: url("/lib/unitegallery/skins/default/grid_handle_black_bottom.png");
    background-position: -36px 0px;
}

.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default.ug-button-hover,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default.ug-button-hover {
    background-position: 0px 0px;
}

.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default.ug-button-closed,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default.ug-button-closed {
    background-position: -108px 0px;
}

.ug-gallery-wrapper .ug-handle-tip-top.ug-skin-default.ug-button-closed.ug-button-hover,
.ug-gallery-wrapper .ug-handle-tip-bottom.ug-skin-default.ug-button-closed.ug-button-hover {
    background-position: -72px 0px;
}

