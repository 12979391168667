.site-partners {
    background-color: $beige;
    padding: 45px 0;
    text-align: center;
}

.partner-block {
    display: inline-block;
    padding: 7px;
    vertical-align: top;

    &__logo-circle {
        background-color: $off-white;
        border-radius: 75px;
        height: 150px;
        margin: 0 10px;
        overflow: hidden;
        vertical-align: top;
        width: 150px;
    }

    &__partner-logo {
        @include hide-text();
        background-position: center;
        background-repeat: no-repeat;
        background-size: 83%;
        display: block;
        height: 150px;
        width: 150px;

        &.discover-logo {
            background-image: url("/images/svg/discoverOKLogo.svg");
        }

        &.ok-today-logo {
            background-image: url("/images/svg/okTodayLogo.svg");
        }

        &.state-parks-logo {
            background-image: url("/images/svg/stateParksLogo.svg");
        }
    }

    &__description {
        color: $charcoalgray;
        display: none;
        font-size: rem(18);
        @include font-montserrat("Regular");
        line-height: rem(24);
        text-align: center;
        vertical-align: top;
    }

    &__icon {
        display: none;
        height: 75px * 1.33;
        width: 70px * 1.33;

        .wifi .one,
        .wifi .two,
        .wifi .three {
            opacity: 0;
        }

        &.discover-icon:hover {
            .tv {
                text-align: center;
            }

            /* Left burst */
            /* @-webkit-keyframes  */
            @keyframes flash {
                0%, 2% {
                    opacity: 0;
                }
                3%, 6% {
                    opacity: 1;
                }

                4.5%, 10%, 100% {
                    opacity: 0;
                }
            }
            @keyframes flash2 {
                0%, 3% {
                    opacity: 0;
                }
                4%, 7% {
                    opacity: 1;
                }

                5%, 10%, 100% {
                    opacity: 0;
                }
            }
            @keyframes flash3 {
                0%, 5% {
                    opacity: 0;
                }
                6%, 8% {
                    opacity: 1;
                }

                7%, 10%, 100% {
                    opacity: 0;
                }
            }

            .wifi .one {
                animation: flash 5s ease 0s infinite;
                opacity: 0;
            }

            .wifi .two {
                animation: flash2 5s ease 0s infinite;
                opacity: 0;
            }

            .wifi .three {
                animation: flash3 5s ease 0s infinite;
                opacity: 0;
            }

            /* Dials */
            @keyframes dial1 {
                0% {
                    transform: scale3d(1, 1, 1);
                }
                45% {
                    transform: scale3d(1, 1, 1);
                }
                50% {
                    transform: scale3d(0.75, 0.75, 0.75);
                }
                55% {
                    transform: scale3d(1, 1, 1);
                }
                100% {
                    transform: scale3d(1, 1, 1);
                }
            }

            .dials .top {
                animation: dial1 5s ease 0s infinite;
                transform-origin: 76% 61%;
            }

            @keyframes dial2 {
                0% {
                    transform: scale3d(1, 1, 1);
                }
                25% {
                    transform: scale3d(1, 1, 1);
                }
                30% {
                    transform: scale3d(0.75, 0.75, 0.75);
                }
                35% {
                    transform: scale3d(1, 1, 1);
                }
                100% {
                    transform: scale3d(1, 1, 1);
                }
            }

            .dials .bottom {
                animation: dial2 5s ease 0s infinite;
                transform-origin: 76% 72%;
            }
        }

        &.ok-today-icon:hover {
            @keyframes page {
                0% {
                    transform: perspective(1000px) rotate3d(0, 1, 0, 0deg) translate3d(0%, 0, 0);
                    opacity: 1;
                    transform-origin: 50% 0%;
                }
                75% {
                    transform: perspective(1000px) rotate3d(0, 1, 0, -180deg) translate3d(0, 0, 0);
                    opacity: 1;
                    transform-origin: 50% 0%;
                }
                100% {
                    transform-origin: 50% 0%;
                    opacity: 0;
                    transform: perspective(1000px) rotate3d(0, 1, 0, -180deg) translate3d(0%, 0, 0);
                }
            }

            .page {
                animation: page 5s ease 0s infinite;
            }
        }
    }

    &__partner-action {
        font-size: rem(30);
        @include font-montserrat("Black");
        letter-spacing: 2px;
        line-height: rem(30);
        margin-bottom: 25px;
        text-transform: uppercase;
    }
}

@media screen and (min-width: 768px) {
    .partner-block {
        display: inline-block;
        margin: 0 3.35%;
        padding: 0;

        &__logo-circle {
            border-radius: 100px;
            display: inline-block;
            height: 200px;
            margin: 0;
            width: 200px;
        }

        &__partner-logo {
            height: 200px;
            width: 200px;
        }

        &__description {
            min-height: 250px;
            width: 375px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .partner-block {

        &__logo-circle {
            border-radius: 115px;
            height: 230px;
            width: 230px;
        }

        &__partner-logo {
            background-size: 80%;
            height: 230px;
            width: 230px;
        }

        &__description {
            width: 345px;
        }
    }
}

@media screen and (min-width: 1920px) {
    .site-partners {
        padding: 50px 0;
        text-align: center;
    }

    .partner-block {
        display: inline-block;
        margin: 0 70px 0 0;
        text-align: left;
        width: 530px;

        &__logo-circle {
            margin: 0;
        }

        &__icon {
            display: inline-block;
        }

        &__description {
            display: inline-block;
            width: 300px;
        }
    }

    .partner-block:last-child {
        margin: 0;
    }
}

.partner-modal {
    .modal-dialog {
        @media screen and (min-width: 768px) {
            width: 725px;
        }
        @media screen and (min-width: 990px) {
            width: 900px;
        }
    }

    .modal-body {
        background-color: $beige;
    }

    .modal-content {
        border-radius: 0;
        color: $charcoalgray;
        font-size: rem(18);
        @include font-montserrat("Regular");
        line-height: rem(24);
        text-align: center;
        vertical-align: top;
    }

    &.fade.in {
        background-color: rgba(0, 46, 85, .8);
    }

    &__logo-circle {
        @extend .partner-block__logo-circle;
        border-radius: 100px;
        display: block;
        height: 200px;
        margin: 0 auto;
        width: 200px;
    }

    &__partner-logo {
        @extend .partner-block__partner-logo;
        display: inline-block;
        height: 200px;
        width: 200px;
    }

    &__partner-description {
        display: inline-block;
        margin-bottom: 20px;
        max-width: 560px;
        text-align: left;

        .partner-block__partner-action {
            display: none;
        }

        .partner-block__icon {
            display: block;
            margin: 0 auto;

            &.ok-today-icon .page {
                animation: page 5s ease 0s infinite;
            }

            &.discover-icon {
                .wifi .one {
                    animation: flash 5s ease 0s infinite;
                }

                .wifi .two {
                    animation: flash2 5s ease 0s infinite;
                }

                .wifi .three {
                    animation: flash3 5s ease 0s infinite;
                }

                .dials .top {
                    animation: dial1 5s ease 0s infinite;
                    transform-origin: 76% 61%;
                }

                .dials .bottom {
                    animation: dial2 5s ease 0s infinite;
                    transform-origin: 76% 72%;
                }
            }
        }
    }

    p {
        @include font-montserrat("Regular");
        color: $gray;
        font-size: em(15px);
        line-height: 1.4;
        margin-bottom: 30px;

        a {
            color: $gray;
        }
    }

    &__close {
        bottom: 0;
        float: right;
        margin: 0;
        position: relative;
    }
}