.nav {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.nav > li {
    display: block;
    position: relative;
}

.nav > li > a {
    display: block;
    padding: 10px 15px;
    position: relative;
}

.nav > li > a:hover,
.nav > li > a:focus {
    background-color: #eeeeee;
    text-decoration: none;
}

.nav > li.disabled > a {
    color: #777777;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
    background-color: transparent;
    color: #777777;
    cursor: not-allowed;
    text-decoration: none;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: #eeeeee;
    border-color: #337ab7;
}

.nav .nav-divider {
    background-color: #e5e5e5;
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
}

.nav > li > a > img {
    max-width: none;
}

.nav-tabs {
    border-bottom: 1px solid #dddddd;
}

.nav-tabs > li {
    float: left;
    margin-bottom: -1px;
}

.nav-tabs > li > a {
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    line-height: 1.42857143;
    margin-right: 2px;
}

.nav-tabs > li > a:hover {
    border-color: #eeeeee #eeeeee #dddddd;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-bottom-color: transparent;
    color: #555555;
    cursor: default;
}

.nav-tabs.nav-justified {
    border-bottom: 0;
    width: 100%;
}

.nav-tabs.nav-justified > li {
    float: none;
}

.nav-tabs.nav-justified > li > a {
    margin-bottom: 5px;
    text-align: center;
}

.nav-tabs.nav-justified > .dropdown .dropdown-menu {
    left: auto;
    top: auto;
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified > li {
        display: table-cell;
        width: 1%;
    }
    .nav-tabs.nav-justified > li > a {
        margin-bottom: 0;
    }
}

.nav-tabs.nav-justified > li > a {
    border-radius: 4px;
    margin-right: 0;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #dddddd;
}

@media (min-width: 768px) {
    .nav-tabs.nav-justified > li > a {
        border-bottom: 1px solid #dddddd;
        border-radius: 4px 4px 0 0;
    }
    .nav-tabs.nav-justified > .active > a,
    .nav-tabs.nav-justified > .active > a:hover,
    .nav-tabs.nav-justified > .active > a:focus {
        border-bottom-color: #ffffff;
    }
}

.nav-pills > li {
    float: left;
}

.nav-pills > li > a {
    border-radius: 4px;
}

.nav-pills > li + li {
    margin-left: 2px;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    background-color: #337ab7;
    color: #ffffff;
}

.nav-stacked > li {
    float: none;
}

.nav-stacked > li + li {
    margin-left: 0;
    margin-top: 2px;
}

.nav-justified {
    width: 100%;
}

.nav-justified > li {
    float: none;
}

.nav-justified > li > a {
    margin-bottom: 5px;
    text-align: center;
}

.nav-justified > .dropdown .dropdown-menu {
    left: auto;
    top: auto;
}

@media (min-width: 768px) {
    .nav-justified > li {
        display: table-cell;
        width: 1%;
    }
    .nav-justified > li > a {
        margin-bottom: 0;
    }
}

.nav-tabs-justified {
    border-bottom: 0;
}

.nav-tabs-justified > li > a {
    border-radius: 4px;
    margin-right: 0;
}

.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
    border: 1px solid #dddddd;
}

@media (min-width: 768px) {
    .nav-tabs-justified > li > a {
        border-bottom: 1px solid #dddddd;
        border-radius: 4px 4px 0 0;
    }
    .nav-tabs-justified > .active > a,
    .nav-tabs-justified > .active > a:hover,
    .nav-tabs-justified > .active > a:focus {
        border-bottom-color: #ffffff;
    }
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}

.nav-tabs .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
}

.nav:before,
.nav:after {
    content: " ";
    display: table;
}

.nav:after {
    clear: both;
}