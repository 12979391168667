/*
Example Use:

h1 {
    @include font-montserrat('Regular');
}
*/

@mixin font-montserrat($variation) {
    font: {
        family: "Montserrat-#{$variation}", Helvetica, Arial, sans-serif;
        style: normal;
        weight: normal;
    }
}

/*
Available Styles

Black,
Bold,
ExtraBold
ExtraLight
Italic,
Light,
Medium,
Regular,
SemiBold,
Thin
 */

$styles: "Black", "Bold", "Italic", "Regular", "Medium", "SemiBold";
$directory: "#{$webFontDirectory}/montserrat";

@each $style in $styles {
    @font-face {
        font-family: "Montserrat-#{$style}";
        font-display: swap; /* Read next point */
        unicode-range: U+000-5FF; /* Download only latin glyphs */
        src: local("Montserrat-#{$style}"), url("#{$directory}/Montserrat-#{$style}.woff2") format("woff2"), url("#{$directory}/Montserrat-#{$style}.woff") format("woff");
    }
}