/**
 * Global Form layout - Horizontal Label Placement
 */
.form-item {
    line-height: 1rem;
    padding-bottom: em(8px, $fh-input-font-size);
    position: relative;
    text-align: left;

    &--indent-field {
        margin-left: em(25px);

        @include tablet {
            margin-left: em(50px);
        }
    }

    &.check-field {
        padding-bottom: 0;
    }

    &.error {
        .form-item__input {
            box-shadow: 0 0 4px 1px $error;
        }

        .form-item__select {
            box-shadow: 0 0 4px 1px $error !important;
        }
    }

    /* For display help text inline */
    .note {
        font-size: em($fh-input-font-size * .85, $fh-input-font-size);
        padding-left: em(5px, $fh-input-font-size);
    }

    &__label {
        color: $black;
        display: inline-block;
        text-align: left;

        &.required::after {
            color: $error;
            content: "*";
            margin-left: 0.2rem;
        }

        &--checkbox, &--radio {
            margin: em(10px, $fh-psd-input-font-size) 0;
            text-transform: none;
        }
    }

    &__input {
        background-color: $off-white !important;
        display: block;
        margin: em(2px, $fh-psd-input-font-size) 0 em(5px, $fh-psd-input-font-size);
        width: 100%;

        &--bg-white {
            background-color: $white !important;
        }
    }

    &__select {
        box-shadow: none !important;
        display: block;
        margin: em(2px, $fh-psd-input-font-size) 0 em(5px, $fh-psd-input-font-size);
        width: 100%;
    }

    &__choice {
        display: flex;
        flex-wrap: wrap;
    }

    &__checkbox, &__radio {
        display: inline-block;
    }

    &__checkbox-grid {
        > .form-item__checkbox {
            transform: scale3d(1, 1, 1);
        }

        &--odd {
            background-color: $lightbeige;
        }

        &--even {
            background-color: $white;
        }
    }
}

.help-text {
    font-size: em(12px, $fh-psd-input-font-size)
}

.invalidJumpTo {
    border: 1px solid $error !important;
}

input:valid, select:valid, textarea:valid {
    border: none !important;
}

/**
 * Text field
 */
.text-field, .date-field {
    &.error {
        input {
            box-shadow: 0 0 4px 1px $error;
        }
    }

    label {
        display: block;
        text-align: left;
        width: 100%;
    }

    input {
        background-color: $off-white;
        display: block;
        margin: em(2px, $fh-psd-input-font-size) 0 em(5px, $fh-psd-input-font-size);
        width: 100%;
    }

    &--bg-white {
        input {
            background-color: $white;
        }
    }
}

/**
 * Date field
 */
.date-field {
    &.no-date-support {
        padding-right: 41px;
        position: relative;
    }

    &.error {
        input {
            box-shadow: 0 0 4px 1px $error;
        }
    }

    input {
        display: inline-block;
    }

    img.ui-datepicker-trigger {
        position: absolute;
        right: 0;
    }
}

/**
 * Checkbox Field
 */
.check-field, .radio-field {
    label {
        box-sizing: border-box;
        display: inline-block;
        line-height: em(20);
        padding: em(8, $fh-input-font-size) 0 em(8, $fh-input-font-size) 48px;
        position: relative;
        text-align: left;
        text-transform: none;
        width: 100%;
    }

    input[type="checkbox"],
    input[type="radio"] {
        left: 0;
        margin-top: em(-6px, $fh-psd-input-font-size);
        padding: 0;
        position: absolute;
        top: 50%;
    }

    &.error {
        label[for] {
            box-shadow: 0 0 4px 1px $error;
        }
    }
}

/**
 * Select Field
 */
.select-field {
    &.error {
        select {
            box-shadow: 0 0 4px 1px $error;
        }
    }

    label {
        display: block;
        text-align: left;
        width: 100%;
    }

    select {
        display: block;
        margin: em(2px, $fh-psd-input-font-size) 0 em(5px, $fh-psd-input-font-size);
        width: 100%;
    }
}

/**
 * Text Area
 */
.text-area {
    &.error {
        textarea {
            box-shadow: 0 0 4px 1px $error;
        }
    }

    textarea {
        background-color: $off-white;
        border: 0;
        box-shadow: inset 2px 3px 3px 0px rgba(164, 164, 158, 0.5);
        box-sizing: border-box;
        font-size: rem(14px);
        height: 100px;
        line-height: em(18px, 14px);
        margin: 2px 0 5px;
        padding: 5px 10px;
        width: 100%;
    }

    &--bg-white {
        textarea {
            background-color: $white;
        }
    }
}

.submit-button,
input[type="submit"].submit-button {
    font-size: em(16px);
}

.error-box {
    background-color: #f2dede;
    border: 1px solid #e498a4;
    border-radius: 5px;
    color: red;
    font-size: em(16px);
    font-weight: bold;
    margin: 5px;
    padding: 10px;

    > strong {
        display: block;
        font-size: em(18px);
        margin-bottom: em(5px, 18px);
    }

    > p {
        font-size: em(16px);
        margin-bottom: 5px;
    }

    > p:last-child {
        margin-bottom: 0;
    }

    ul {
        font-size: em(14px);

    }
}

.line-divider {
    border-top: 1px solid $beige;
    margin-top: 40px;
    padding-top: 40px;
}

@media screen and (min-width: 450px) {
    .form-item {
        &.horizontal-field {
            padding-bottom: 0;
        }

        &--horizontal {
            align-items: center;
            display: flex;
            flex-direction: row;
            padding-bottom: 0;

            &.error {
                input {
                    box-shadow: 0 0 4px 1px $error;
                }
            }

            label, > .form-item__label {
                padding: 0;
                text-align: right;
                width: 26%;
            }

            input[type="text"], > .form-item__input {
                margin: em(5px, $fh-psd-input-font-size) 2%;
                vertical-align: middle;
                width: 70%;
            }

            select {
                margin: em(5px, $fh-psd-input-font-size) 2%;
                vertical-align: middle;
                width: 70%;
            }

            &.state {
                input[type="text"] {
                    width: 15%;
                }
            }
        }

        &--zipcode {
            input[type="text"], > .form-item__input {
                margin: em(5px, $fh-psd-input-font-size) 2%;
                width: 23%;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .check-field {
        label {
            // Move the content away from the checkbox
            padding: em(4, $fh-input-font-size) 0 em(4, $fh-input-font-size) 28px;
        }

        input[type="checkbox"] {
            margin-left: 4px;
        }
    }
}