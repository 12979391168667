.international {

    &__background-wrap {
        overflow: hidden;
        position: relative;
    }

    &__background {
        $tablet-percent: 0.6;
        $laptop-percent: 0.4;
        $laptop-desktop-percent: 0.2;

        $top: 104px;
        $max-height-00: 536px;
        $tablet-max-height-00: round(abs(($max-height-00 * $tablet-percent) - $max-height-00));
        $laptop-max-height-00: round(abs(($max-height-00 * $laptop-percent) - $max-height-00));
        $laptop-desktop-max-height-00: round(abs(($max-height-00 * $laptop-desktop-percent) - $max-height-00));

        $max-height-01: 730px;
        $tablet-max-height-01: round(abs(($max-height-01 * $tablet-percent) - $max-height-01));
        $laptop-max-height-01: round(abs(($max-height-01 * $laptop-percent) - $max-height-01));
        $laptop-desktop-max-height-01: round(abs(($max-height-01 * $laptop-desktop-percent) - $max-height-01));

        $max-height-02: 510px;
        $tablet-max-height-02: round(abs(($max-height-02 * $tablet-percent) - $max-height-02));
        $laptop-max-height-02: round(abs(($max-height-02 * $laptop-percent) - $max-height-02));
        $laptop-desktop-max-height-02: round(abs(($max-height-02 * $laptop-desktop-percent) - $max-height-02));

        &--00 {
            background: url("/images/shell/state-parks/backgrounds/00_trees.png") no-repeat left;
            background-size: contain;
            display: none;
            position: absolute;
            z-index: -1;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-00;
                top: $top;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-00;
                top: 0;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
            }

            @include desktop {
                max-height: $max-height-00;
            }
        }

        &--01 {
            background: url("/images/shell/state-parks/backgrounds/01_trees.png") no-repeat right;
            background-size: contain;
            display: none;
            position: absolute;
            z-index: -1;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-01;
                top: $top + $tablet-max-height-00;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-01;
                top: $laptop-max-height-00;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-01;
                top: $laptop-desktop-max-height-00;
            }

            @include desktop {
                max-height: $max-height-01;
                top: $max-height-00;
            }
        }

        &--02 {
            background: url("/images/shell/state-parks/backgrounds/02_trees.png") no-repeat left;
            background-size: contain;
            display: none;
            position: absolute;
            z-index: -1;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-02;
                top: $top + $tablet-max-height-00 + $tablet-max-height-01;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-02;
                top: $laptop-max-height-00 + $laptop-max-height-01;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-02;
                top: $laptop-desktop-max-height-00 + $laptop-desktop-max-height-01;
            }

            @include desktop {
                max-height: $max-height-02;
                top: $max-height-00 + $max-height-01;
            }
        }
    }

    &__bordered-text {
        @include bordered-text($blue);

        @media screen and (min-width: 800px) {
            @include bordered-text($white);
        }
    }

    // Remove top margin from intro paragraph.
    > div.content__padded {
        p:first-child {
            margin-top: 0;
        }
    }

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 300px;
    }

    &__country-selector {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: flex-start;
        margin-bottom: 15px;

        &-flag {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            border: solid 2px $blue;
            border-radius: 100%;
            height: 40px;
            width: 40px;

            &--us {
                background-image: url("/images/flags/us.svg");
            }

            &--de {
                background-image: url("/images/flags/de.svg");
            }

            &--gb {
                background-image: url("/images/flags/gb.svg");
            }

            &--ie {
                background-image: url("/images/flags/ie.svg");
            }

            &--at {
                background-image: url("/images/flags/at.svg");
            }

            &--ch {
                background-image: url("/images/flags/ch.svg");
            }

            &--nl {
                background-image: url("/images/flags/nl.svg");
            }

            &--au {
                background-image: url("/images/flags/au.svg");
            }

            &--nz {
                background-image: url("/images/flags/nz.svg");
            }
        }

        &-locale {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
        }
    }

    &__item {
        display: inline-block;
        margin-bottom: 60px;
        vertical-align: top;
        width: 100%;

        @media screen and (min-width: 768px) {
            margin: 0 1.5% 40px;
            width: 48.5%;

            &:nth-child(odd) {
                margin-left: 0;
            }
            &:nth-child(even) {
                margin-right: 0;
            }
        }

        @media screen and (min-width: 1024px) {
            margin: 0 1.5%;
            width: 31.3%;

            // Reset nth-child
            &:nth-child(odd), &:nth-child(even) {
                margin: 0 1.5%;
            }

            &:nth-child(3n+1) {
                margin-left: 0;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    &__item-image {
        border: 5px solid $beige;
        box-shadow: 10px 17px 50px 0 rgba(75, 75, 75, 0.5);
        margin-bottom: 10px;
    }

    &__title {
        font-size: rem(28px);
        margin-bottom: 0;
        margin-top: 5px;
        text-align: center;

        a {
            @include font-sucrose("Bold");
            color: $blue;
            text-align: center;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__partner {
        @include image-border;
        background-color: $white;
        box-sizing: border-box;
        display: flex;
        flex: 1;
        flex-direction: row;
        height: 100%;
        padding: 15px;

        h4 {
            margin-top: 5px;
        }

        &-image {
            height: 110px;
            margin-right: 15px;
            width: 110px;
        }

        &-links {
            display: flex;
            flex-direction: column;

            a {
                font-size: em(18, 16);
                margin-bottom: em(12, 18);
            }
        }
    }
}