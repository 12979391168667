.sticky-sub-nav {
    background-color: $blue;
    position: static;

    &__container {
        width: 100%;

        @include laptop {
            display: flex;
            justify-content: center;
        }
    }

    &__toggle {
        background-color: $blue;
        display: block;
        font-size: 1em;
        margin: 0;
        padding: 15px 0;
        width: 100%;

        @include laptop {
            display: none;
        }
    }

    &__nav_list {
        display: none;
        font-size: em(16px);
        @include font-montserrat("Regular");
        list-style: none;
        margin: 0;
        padding: 0;

        @include laptop {
            display: flex;
        }

        li {
            color: $white;
            line-height: 1.3;
            margin: 0;
            text-transform: uppercase;

            @include laptop {
                align-items: center;
                border-right: 1px solid $white;
                display: flex;
                justify-content: center;

                &:first-child {
                    border-left: 1px solid $white;
                }

                &:hover {
                    background: $white;
                    border-top: 10px solid $blue;

                    a {
                        color: $gray;
                        padding-top: 5px;
                    }
                }
            }

            a {
                color: $white;
                display: block;
                padding: 15px 20px;
                text-align: center;
                text-decoration: none;

                @include laptop {
                    display: block;
                    max-width: 210px;
                    padding: 15px 20px;
                }
            }
        }
    }

    &__toggled {
        display: block;

        @include laptop {
            display: flex;
        }
    }
}