.lake-conditions {

    #current {
        height: 0;
        position: relative;
        top: -200px;
        width: 0;
    }

    h2 {
        @include font-sucrose("Bold");
        font-size: em(45px);
        margin-bottom: 0;

        &.search-result__name {
            font-size: em(30px);
        }
    }

    h3 {
        color: $gray;
        font-size: em(20px);
        line-height: 1.3;
        margin: 20px 0 30px 0;

        .algae-color {
            color: #6cb945;
        }

        .stop-color {
            color: #fe0000;
        }
    }

    .search-result {
        border-bottom: none;

        p {
            margin-top: 10px;
        }

        h3 {
            color: $blue;
            font-size: em(18px);
            @include font-montserrat("Regular");
            margin: 0;
            padding: 0;
            text-transform: uppercase;
        }
    }

    &__caption {
        border-bottom: 1px solid #e3e3d7;
        @include font-montserrat("Bold");
        color: $blue;
        font-size: em(18px);
        letter-spacing: .025em;
        line-height: 1.3;
        padding: 0 10px 20px 10px;
        text-transform: uppercase;
    }

    &__embed-container {
        height: 0;
        max-width: 100%;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;

        iframe,
        .embed-container object,
        .embed-container embed {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &__embed-border-shadow {
        border: 5px solid $beige;
        -moz-box-shadow: 10px 10px 50px 0px rgba(75, 75, 75, 0.5);
        -webkit-box-shadow: 10px 10px 50px 0px rgba(75, 75, 75, 0.5);
        box-shadow: 10px 10px 50px 0px rgba(75, 75, 75, 0.5);
    }

    &__recognize-photos {
        background-color: $beige;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 60px 0;

        p {
            color: $gray;
            display: block;
            font-size: em(20px);
            margin: 10px 20px;
        }
    }

    &__recognize-photos-row {
        overflow: auto;

        img {
            display: block;
            float: none;
            height: auto;
            margin: 30px auto;
            max-width: 100%;
        }
    }

    &__lakes-search {
        border-bottom: 1px solid $beige;
        border-top: 1px solid $beige;
        padding: 40px 0;

        label {
            color: $blue;
            @include font-sucrose("Bold");
            display: block;
            font-size: em(30px);
            margin-bottom: 10px;
            margin-right: 30px;
            position: relative;
            text-transform: uppercase;
            top: 5px;
        }

        select {
            display: block;
            height: 2.8em;
            margin-bottom: 10px;
            max-width: 100%;
        }

        input.submit-button[type="submit"] {
            display: block;
            margin-bottom: 10px;
            margin-top: 0px;
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    &__tips {
        h2 {
            margin-top: 0;
        }
    }

    &__pdf {
        h2 {
            margin-top: 0;
        }

        ul {
            list-style-type: none;

            li {
                color: $gray;
                font-size: 24px;

                a {
                    color: $gray;
                    text-decoration: none;

                    &:hover {
                        color: $blue;
                        text-decoration: underline;
                    }
                }

                ul {
                    margin-top: 10px;

                    li {
                        font-size: 18px;
                        line-height: 1.2;
                        margin: 20px 0;
                    }
                }
            }
        }

        .pdf-container {
            overflow: auto;

            &__col1 {
                float: left;
                margin-right: 2%;
                width: 48%;
            }

            &__col2 {
                float: left;
                margin-left: 2%;
                width: 48%;
            }
        }
    }

    &__links {
        h2 {
            margin-top: 0;
        }

        ul {
            color: $gray;
            list-style-type: none;
            margin-left: 0;
            padding-left: 0;

            li {
                font-size: 24px;

                a {
                    color: $gray;
                    text-decoration: none;

                    &:hover {
                        color: $blue;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 768px) {

        h3 {
            font-size: em(30px);
            margin: 40px 0 50px 0;
        }

        &__lakes-faq {
            .accordion__item-content {
                p {
                    padding-left: 35px;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        &__recognize-photos {
            p,
            h3 {
                margin: 20px 170px 50px 170px;
            }
        }

        &__recognize-photos-row {
            margin: 0 auto;

            img {
                float: left;
                margin: 1%;
                max-width: 31%;

            }
        }

        &__recognize-photos-row-2col {
            margin: 0 auto;
            width: 66%;

            img {
                max-width: 48%;
            }

        }

        &__lakes-search {
            label,
            select {
                display: inline-block;
            }
        }
        input.submit-button[type="submit"] {
            display: inline-block;
            height: 2.8em;
            margin-left: 10px;
        }

    }
}