@import "modules_separators-photo-ops";
@import "modules_separators-hidden-treasures";
@import "modules_separators-beauty-and-stories";
@import "modules_separators-unwritten-stories";
@import "modules_separators-quote";

.separator {
    box-shadow: inset 0 40px 50px -50px, inset 0 -40px 50px -60px;
    overflow: hidden;
    padding: 50px 20px;
    position: relative;
    text-align: center;

    &__background-block {
        background-color: $white;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -2;
    }

    &__background {
        display: none;
        left: 50%;
        position: absolute;
        top: 50%;
        z-index: -1;
    }

    &__icon-block {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        z-index: 5;
    }

    &__quote-block {
        color: $blue;
        display: inline-block;
        font-size: rem(50px);
        line-height: rem(45px);
        padding-top: 15px;
        @include font-sucrose("Bold");
        position: relative;
        vertical-align: middle;
        z-index: 5;
    }

    .bordered-text {
        @include bordered-text($blue);
    }
}

@media screen and (min-width: 768px) {
    .separator {
        &__background-block {
            background-color: $off-white;
        }

        &__background {
            display: block;
        }

        &__icon-block {
            margin: 0 12px 0 0;
        }

        &__quote-block {
            padding: 0;
            text-align: left;
        }
    }
}

@media screen and (min-width: 1920px) {
    .separator {
        &__background-block {
            .separator__background {
                background-size: 100% 600px;
                margin-left: -50%;
                width: 100%;
            }
        }
    }
}