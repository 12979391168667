.music-trail__header {
    @include aspect-ratio(800px, 258px);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    @media screen and (min-width: 1024px) {
        @include aspect-ratio(1811px, 357px);
    }

    .no-webp &--default, .no-js &--default {
        background-image: url("/images/page_headers/music-trail-header-tablet.jpg");

        @media screen and (min-width: 1024px) {
            background-image: url("/images/page_headers/music-trail-header.jpg");
        }
    }

    .webp &--default {
        background-image: url("/images/page_headers/music-trail-header-tablet.webp");

        @media screen and (min-width: 1024px) {
            background-image: url("/images/page_headers/music-trail-header.webp");
        }
    }

    &-title {
        color: $music-trail-red;
        display: block;
        font-size: em(50px);

        @media screen and (min-width: 500px) {
            font-size: em(65px);
        }
    }
}