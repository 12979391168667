.listing {
    position: relative;

    hr {
        border: 0;
        border-top: 1px solid #e3e3d7
    }

    &__background-wrap {
        position: relative;
    }

    &--state-park {
        .listing__background--00 {
            background-image: url("/images/shell/state-parks/backgrounds/00_trees.png") !important;
            background-position: left !important;
        }

        .listing__background--01 {
            background-image: url("/images/shell/state-parks/backgrounds/01_trees.png") !important;
            background-position: right !important;
        }

        .listing__background--02 {
            background-image: url("/images/shell/state-parks/backgrounds/02_trees.png") !important;
            background-position: left !important;
        }
    }

    &__background {
        $tablet-percent: 0.6;
        $laptop-percent: 0.4;
        $laptop-desktop-percent: 0.2;

        $top: 104px;
        $max-height-00: 536px;
        $tablet-max-height-00: round(abs(($max-height-00 * $tablet-percent) - $max-height-00));
        $laptop-max-height-00: round(abs(($max-height-00 * $laptop-percent) - $max-height-00));
        $laptop-desktop-max-height-00: round(abs(($max-height-00 * $laptop-desktop-percent) - $max-height-00));

        $max-height-01: 730px;
        $tablet-max-height-01: round(abs(($max-height-01 * $tablet-percent) - $max-height-01));
        $laptop-max-height-01: round(abs(($max-height-01 * $laptop-percent) - $max-height-01));
        $laptop-desktop-max-height-01: round(abs(($max-height-01 * $laptop-desktop-percent) - $max-height-01));

        $max-height-02: 510px;
        $tablet-max-height-02: round(abs(($max-height-02 * $tablet-percent) - $max-height-02));
        $laptop-max-height-02: round(abs(($max-height-02 * $laptop-percent) - $max-height-02));
        $laptop-desktop-max-height-02: round(abs(($max-height-02 * $laptop-desktop-percent) - $max-height-02));

        &--00 {
            background-image: url("/images/topic_backgrounds/urban.png");
            background-position: right;
            background-repeat: no-repeat;
            background-size: contain;
            display: none;
            position: absolute;
            z-index: -1;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-00;
                top: $top;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-00;
                top: 0;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
            }

            @include desktop {
                max-height: $max-height-00;
            }
        }

        &--01 {
            background-image: url("/images/topic_backgrounds/route-66.png");
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            display: none;
            position: absolute;
            z-index: -1;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-01;
                top: $top + $tablet-max-height-00;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-01;
                top: $laptop-max-height-00;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-01;
                top: $laptop-desktop-max-height-00;
            }

            @include desktop {
                max-height: $max-height-01;
                top: $max-height-00;
            }
        }

        &--02 {
            background-image: url("/images/topic_backgrounds/museum.png");
            background-position: right;
            background-repeat: no-repeat;
            background-size: contain;
            display: none;
            position: absolute;
            z-index: -1;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-02;
                top: $top + $tablet-max-height-00 + $tablet-max-height-01;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-02;
                top: $laptop-max-height-00 + $laptop-max-height-01;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-02;
                top: $laptop-desktop-max-height-00 + $laptop-desktop-max-height-01;
            }

            @include desktop {
                max-height: $max-height-02;
                top: $max-height-00 + $max-height-01;
            }
        }
    }

    &__bordered-text {
        @include bordered-text($blue);

        @media screen and (min-width: 800px) {
            @include bordered-text($white);
        }
    }

    // Remove top margin from intro paragraph.
    > div.content__padded {
        p:first-child {
            margin-top: 0;
        }
    }

    &__heading {
        font-size: em(40px);
    }

    .content__wide {
        @include clearfix();
    }

    &__details-note {
        display: block;
        font-size: em(14px);
        margin-top: 10px;
    }

    &__details-group {
        display: block;
        margin-bottom: em(5px);
    }

    &__details-group-name {
        @include font-montserrat("Bold");
    }

    &__readmore-button {
        @include mobile-only {
            width: 100%;
        }
    }

    &__alert {
        background-color: #fac613;
        display: block;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include laptop {
            border-bottom-left-radius: 85px 100%;
            margin-left: 65%;
            padding-left: 75px;
            padding-right: 10px;
            text-align: left;
        }

        &-icon {
            @include font-montserrat("Black");
            background-color: #ffffff;
            border-radius: 26px;
            color: #1e6aaa;
            display: inline-block;
            font-size: 20px;
            height: 26px;
            line-height: 26px;
            position: relative;
            text-align: center;
            top: 2%;
            width: 26px;
        }

        &-text {
            @include font-montserrat("SemiBold");
            color: #1e6aaa;
            display: block;
            line-height: 50px;
            margin: 0 0 5px 8px;
            overflow: hidden;
            padding: 0;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include laptop {
                line-height: 40px;
            }
        }
    }

    &__photos {
        @include clearfix;
        display: block;
        margin-top: 15px;
    }

    &__photo {
        @include grid(2, 0);

        overflow: hidden;

        @include aspect-ratio(1, 1);
        @include tablet {
            @include grid(4, 0);
        }

        &-img {
            height: auto;
            max-width: 100%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    &__activity {
        background-color: $blue;
        display: inline-block;
        height: 45px;
        margin-bottom: 10px;
        margin-right: 10px;
        padding: 10px;
        position: relative;
        text-align: center;
        width: 45px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            .listing__activity-tooltip {
                visibility: visible;
            }
        }

        &-tooltip {
            @extend .button-shadow;
            background-color: $off-white;
            border: 1px solid $beige;
            border-radius: 8px;
            bottom: 105%;
            color: $gray;
            left: 50%;
            min-width: 100px;
            padding: 5px;
            position: absolute;
            text-align: center;
            transform: translateX(-50%);
            visibility: hidden;
            width: auto;
            z-index: 1;

            &::after {
                border: 10px solid transparent;
                border-top-color: $off-white;
                content: " ";
                left: 50%;
                margin-left: -10px;
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
            }
        }

        i {
            color: $white;
            font-size: em(45);
            line-height: 45px;
        }
    }

    .tag-icons-grid--things-to-do {
        width: 100%;
        @include clearfix;
    }

    .tag-icon {
        box-sizing: border-box;
        margin: 0 0 7px 0;
        @include grid(2, 7px);

        @include tablet {
            margin: 0 0 14px 0;
            @include grid(2, 14px);
        }

        @include laptop {
            @include grid(3, 14px);
        }
    }

    &__360-image {
        width: 100%;
    }

    &__booking {
        .button {
            display: block;
            margin-right: 0;
        }

        @include laptop {
            display: none;
        }
    }
}