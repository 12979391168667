.long-road-to-liberty__header {
    @include aspect-ratio(768px, 246px);
    @include webp-image("/images/page_headers/long-road-to-liberty-header-tablet");
    background: no-repeat center top / cover;

    @include laptop {
        @include aspect-ratio(1024px, 286px);
        @include webp-image("/images/page_headers/long-road-to-liberty-header-laptop");
    }

    @include desktop {
        @include aspect-ratio(2183px, 438px);
        @include webp-image("/images/page_headers/long-road-to-liberty-header-desktop");
    }

    &-title {
        color: $long-road-to-liberty-red;
        display: block;
        font-size: em(50px);

        @include media-query(500px) {
            font-size: em(65px);
        }
    }
}