.things-to-do-heading {
    padding-bottom: 0;

    &__title {
        span {
            display: inline-block;
            @include font-sucrose("Bold");

            .js-shuffle-results {
                font-size: 1.1em;
            }
        }
    }
}

.things-to-do-landing {
    margin: auto;
    text-align: center;

    .featured-content {
        margin-bottom: 35px;
    }

    &__search {
        .button-primary {
            height: em(44px, 18px);
            margin: em(10px, 18px) em(10px, 18px) em(10px, 18px) 0;
            min-width: em(175px, 18px);
            text-align: center;
            vertical-align: bottom;
        }
    }

    &__search-item {
        padding: 0;

        label {
            @include font-sucrose("Bold");
            color: $gray;
            font-size: em(24);

            span {
                @include font-montserrat("Regular");
                font-size: em(12);
            }
        }

        input[type="text"] {
            background-color: $off-white;
            font-size: em(18px);
            height: em(44px, 18px);
        }
    }
}

@media screen and (min-width: 768px) {
    .things-to-do-landing {
        .featured-content {
            display: inline-block;
            margin: 35px 30px;
        }

        .featured-content__topic-block {
            top: 24px;
        }

        &__search-item {
            display: inline-block;
            margin-right: em(10px, 18px);
            max-width: em(450px, 18px);

            input[type="text"] {
                margin-bottom: em(10px, 18px);
            }
        }
    }
}

@media screen and (min-width: 930px) {
    .things-to-do-landing {
        text-align: left;
        width: 934px;
    }
}

@media screen and (min-width: 1465px) {
    .things-to-do-landing {
        width: 1410px;
    }
}
