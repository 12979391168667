.music-trail__genre {
    &-icon {
        @include font-montserrat("Regular");
        background-color: $music-trail-red;
        box-sizing: border-box;
        display: block;
        height: 96px;
        position: relative;
        text-decoration: none;
        width: 100%;

        &-content {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            height: 100%;
            justify-content: center;
            padding: 15px;

            i {
                color: $white;
                font-size: em(58);
            }

            span {
                color: $white;
                display: inline-block;
                font-size: em(18);
                margin-left: 5px;
            }
        }
    }
}