.industry-materials {
    $gutter: 25px;
    display: flex;
    flex-wrap: wrap;

    &__document {
        $ratio: 246 / 312;
        $width: 100%;

        background: white;
        border: 3px solid $blue;
        box-sizing: border-box;
        flex-basis: $width;
        height: 0;
        margin-bottom: $gutter;
        overflow: hidden;
        padding-top: calc((#{$ratio} * #{$width}) - 6px);
        position: relative;
        width: $width;

        @include media-query(650px) {
            $width: 48%;
            flex-basis: $width;
            margin: 0 2% 2% 0;
            padding-top: calc((#{$ratio} * #{$width}) - 6px);
            width: $width;
        }

        @include laptop {
            $width: 31%;
            flex-basis: $width;
            padding-top: calc((#{$ratio} * #{$width}) - 6px);
            width: $width;
        }
    }

    &__document-aspect-ratio {
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__document-image {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    &__document-info {
        background-color: rgba(251, 199, 0, 0.8);
        bottom: 0;
        position: absolute;
        text-align: center;
        width: 100%;

        a {
            @include font-montserrat("SemiBold");
            text-decoration: none;
        }
    }
}