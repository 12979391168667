/* Brochure International Landing Page */
.brochures-international-landing {
    &__country-select {
        padding: 36px 0;

        @include laptop {
            padding: 0;
        }

        select {
            width: 100%;

            @include media-query(400px) {
                width: auto;
            }
        }
    }

    &__teacher-guide {
        color: #666666;
        font-size: em(18px);
        line-height: em(22px, 18px);
        margin-bottom: 15px;
        padding-right: 40px;

        @include laptop {
            font-size: em(16, 18);
            margin-bottom: 0;
        }

        strong {
            float: right;
            text-align: left;
            width: 520px;
        }
    }

    &__loading-msg {
        display: none;
        padding-top: 10px;
    }

    .button-call-to-action {
        font-size: em(14);
        margin-left: 0;
    }
}

/* Brochure International Page */
.international-brochure-page {
    &__heading {
        color: #666666;
        margin-bottom: 20px;

        .warning {
            color: #ff0000;
            font-weight: bold;
        }

        .notice {
            color: $orange;
        }
    }
}

.international-brochure-form {
    margin-bottom: 15px;
    padding-top: 30px;

    &__section {
        display: inline-block;
        vertical-align: top;
        width: 100%;

        @include media-query(850px) {
            padding: 0 2%;
            width: 46%;
        }
    }

    &__country {
        margin-bottom: em(5px);

        label {
            margin-right: 7px;
        }
    }

    &__help-text {
        color: #666666;
        font-size: 14px;
        height: 40px;
        padding-left: 30px;
    }
}

.electronic-email-header {
    display: inline-block;
    vertical-align: top;
    width: 50%;

    h3 {
        margin-top: 0;
    }
}

.student-guide {
    @include clearfix;

    .button-call-to-action {
        font-size: em(15px);
        margin-left: 0;
    }

    &__image {
        float: left;
        margin-right: 20px;
        width: 300px;

        img {
            box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.3);
        }
    }

    &__details {
        margin-top: 222px;
    }

    &__link {
        padding-top: 15px;
    }
}

/* End Brochure International Page */
