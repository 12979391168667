.music-trail__itinerary {

    @include mobile-only {
        .button {
            width: 100%;
        }
    }

    &-map {
        height: 358px;
        width: 100%;
        z-index: 1;

        &-wrapper {
            border: 5px solid $beige;
            box-shadow: 7px 10px 25px 0px rgba(75, 75, 75, 0.5);
            box-sizing: border-box;
            margin-bottom: 20px;
            position: relative;
            vertical-align: bottom;
        }

        &-marker {
            height: 40px;
            position: relative;
            width: 22px;

            .icon-map-marker-empty {
                color: $music-trail-red;
                font-size: 3.2em;
                left: -8px;
                position: absolute;
                text-shadow: 0 1px 2px $music-trail-charcoal-gray;
            }

            &-number {
                color: $white;
                font-size: 1em;
                left: 50%;
                letter-spacing: -1px;
                line-height: 1em;
                position: absolute;
                top: 20%;
                transform: translate(-55%, -12%);
            }
        }
    }

    &-thumbnail-map {
        @include aspect-ratio(264px, 145px);
        background-position: center right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        width: 100%;

        .no-webp &, .no-js & {
            background-image: url("/images/music-trail/itinerary-map.png");
        }

        .webp & {
            background-image: url("/images/music-trail/itinerary-map.webp");
        }

        &-point {
            background-image: url(/images/music-trail/pin.png);
            background-repeat: no-repeat;
            background-size: contain;
            height: 6px;
            position: absolute;
            width: 6px;
            z-index: 10;

            @include media-query-between(600px, 1023px) {
                height: 11px;
                width: 11px;
            }
        }
    }

    &-items {
        margin-top: 25px;

        div:nth-last-child(2) {
            border: none;
        }
    }

    &-item {
        &-location {
            border-bottom: 1px solid $music-trail-charcoal-gray;
            margin-bottom: 25px;

            &:last-child {
                border: none;
            }

            &-address {
                @include font-montserrat("Bold");
                color: $music-trail-red;
                font-size: rem(22px);
                line-height: rem(24px);
                margin-top: 15px;
                text-transform: uppercase;
            }

            &-info {
                h3 {
                    @include font-montserrat("Bold");
                    margin-top: 0;
                    text-transform: uppercase;
                }

            }

            &-related {
                background-color: $beige;
                margin-bottom: 15px;
                padding: 10px;

                p {
                    margin-bottom: 0;
                }
            }

            &-directions {
                display: none;

                li {
                    border-bottom: 1px solid $music-trail-gray;
                    padding: 12px 20% 12px 0;
                    position: relative;
                    width: 80%;

                    @include tablet {
                        padding: 12px 10% 12px 0;
                        width: 90%;
                    }

                    > span {
                        display: inline-block;
                        font-weight: bold;
                        position: absolute;
                        right: 0;
                        top: 13px;
                    }
                }

                @include mobile-only {
                    ol {
                        padding-left: 20px;
                    }

                    strong {
                        display: block;
                    }
                }
            }
        }

        &-city {
            background-color: $beige;
            display: flex;
            margin-top: em(25px);

            h3 {
                margin-top: 0;
            }

            &-info {
                flex: 3;
                padding: 25px;

                ul {
                    list-style-type: square;
                }
            }

            &-image {
                flex: 2;

                img {
                    height: 100%;
                }
            }
        }
    }
}