.newsletters {
    .form-item button {
        font-size: rem(16px);
    }

    .button {
        font-size: em(12px);
    }

    input[type="submit"] {
        margin-left: 0;
    }

    &__options {
        margin-left: 50px;
    }
}

.update-email-subscription select {
    margin-bottom: 10px;
    margin-top: 10px;
    max-width: 100%;
}

@media screen and (min-width: 1024px) {
    .update-email-subscription select {
        margin-bottom: 0;
        margin-top: 0;
    }
}