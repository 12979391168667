.state-parks__resources {

    &-item {
        display: inline-block;
        margin-bottom: 60px;
        vertical-align: top;
        width: 100%;

        @media screen and (min-width: 768px) {
            margin: 0 1.5% 40px;
            width: 48.5%;

            &:nth-child(odd) {
                margin-left: 0;
            }
            &:nth-child(even) {
                margin-right: 0;
            }
        }
    }

    &-item-image {
        border: 5px solid $beige;
        box-shadow: 10px 17px 50px 0 rgba(75, 75, 75, 0.5);
        margin: 0 auto 10px;
    }

    &-title {
        font-size: rem(28px);
        margin-bottom: 0;
        margin-top: 5px;
        text-align: center;

        a {
            @include font-sucrose("Bold");
            color: $blue;
            text-align: center;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}