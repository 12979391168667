.state-parks {

    &__background-wrap {
        overflow: hidden;
        position: relative;
    }

    &-faq-background-wrap {
        position: relative;
    }

    &__background {
        $tablet-percent: 0.6;
        $laptop-percent: 0.4;
        $laptop-desktop-percent: 0.2;

        $top: 104px;
        $max-height-00: 536px;
        $tablet-max-height-00: round(abs(($max-height-00 * $tablet-percent) - $max-height-00));
        $laptop-max-height-00: round(abs(($max-height-00 * $laptop-percent) - $max-height-00));
        $laptop-desktop-max-height-00: round(abs(($max-height-00 * $laptop-desktop-percent) - $max-height-00));

        $max-height-01: 730px;
        $tablet-max-height-01: round(abs(($max-height-01 * $tablet-percent) - $max-height-01));
        $laptop-max-height-01: round(abs(($max-height-01 * $laptop-percent) - $max-height-01));
        $laptop-desktop-max-height-01: round(abs(($max-height-01 * $laptop-desktop-percent) - $max-height-01));

        $max-height-02: 510px;
        $tablet-max-height-02: round(abs(($max-height-02 * $tablet-percent) - $max-height-02));
        $laptop-max-height-02: round(abs(($max-height-02 * $laptop-percent) - $max-height-02));
        $laptop-desktop-max-height-02: round(abs(($max-height-02 * $laptop-desktop-percent) - $max-height-02));

        &--00 {
            background: url("/images/shell/state-parks/backgrounds/00_trees.png") no-repeat left;
            background-size: contain;
            display: none;
            position: absolute;
            z-index: -1;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-00;
                top: $top;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-00;
                top: 0;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
            }

            @include desktop {
                max-height: $max-height-00;
            }
        }

        &--01 {
            background: url("/images/shell/state-parks/backgrounds/01_trees.png") no-repeat right;
            background-size: contain;
            display: none;
            position: absolute;
            z-index: -1;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-01;
                top: $top + $tablet-max-height-00;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-01;
                top: $laptop-max-height-00;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-01;
                top: $laptop-desktop-max-height-00;
            }

            @include desktop {
                max-height: $max-height-01;
                top: $max-height-00;
            }
        }

        &--02 {
            background: url("/images/shell/state-parks/backgrounds/02_trees.png") no-repeat left;
            background-size: contain;
            display: none;
            position: absolute;
            z-index: -1;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-02;
                top: $top + $tablet-max-height-00 + $tablet-max-height-01;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-02;
                top: $laptop-max-height-00 + $laptop-max-height-01;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-02;
                top: $laptop-desktop-max-height-00 + $laptop-desktop-max-height-01;
            }

            @include desktop {
                max-height: $max-height-02;
                top: $max-height-00 + $max-height-01;
            }
        }
    }

    &__bordered-text {
        @include bordered-text($blue);

        @media screen and (min-width: 800px) {
            @include bordered-text($white);
        }
    }

    &__banner {
        background-color: darken($orange, 4%);
        border-bottom: 4px solid $yellow;
        border-top: 4px solid $yellow;
        color: $white;
        display: flex;
        justify-content: center;
        padding: 15px 10px;
        text-align: center;

        p {
            font-size: em(19px, 16px);
            margin-bottom: 0;
        }

        a {
            color: $white;
        }
    }

    // Remove top margin from intro paragraph.
    > div.content__padded {
        p:first-child {
            margin-top: 0;
        }
    }
}