.nav {
    &__dropdown {
        display: flex;
        width: 100%;

        &-section {
            display: flex;
            flex: 1;
            flex-direction: column;
            min-height: 225px;

            h4 {
                border-bottom: 1px solid $white;
                margin: 0;
                padding-bottom: 8px;
                text-align: center;
            }

            &:first-child {
                border-right: 1px solid $white;
            }
        }

        &-grid {
            display: flex;
            flex: 1;
            outline: 1px solid $white;

            &-item {
                border-right: 1px solid $white;
                flex: 1;

                &:last-child {
                    border-right: none;
                }
            }
        }

        &-article {
            margin: 15px;
            text-transform: none;

            &-title {
                margin-bottom: 15px;

                a {
                    color: $white !important;
                    font-size: em(18);
                    text-decoration: none;
                    @include font-montserrat("Bold")
                }
            }

            &-body {
                display: flex;
            }

            &-image {
                flex: 2;

                img {
                    border: 5px solid $beige;
                    box-shadow: 0 10px 25px 0 rgba(75, 75, 75, 0.5);
                    box-sizing: border-box;
                }
            }

            &-details {
                flex: 3;
                font-size: em(16, 18);
                margin-left: 15px;

                @include desktop {
                    font-size: em(18, 16);
                }

                .road_trip-btn {
                    font-size: 0.95rem;
                    margin-bottom: 0px;
                    margin-top: 0px;
                }
            }

        }
    }

    &__icon {
        align-items: center;
        border-bottom: 5px solid $navy;
        box-sizing: border-box;
        display: flex;
        height: 100%;
        justify-content: center;
        @include font-montserrat("SemiBold");
        padding-top: 5px;
        position: relative;
        text-align: center;
        text-decoration: none;

        i {
            color: $white;
            font-size: em(35);

            @include desktop {
                font-size: em(40);
            }
        }

        svg {
            fill: $white;
            max-height: 28px;
            max-width: 28px;
            min-height: 28px;
            min-width: 28px;
        }

        span {
            color: $white;
            font-size: em(16);
            line-height: em(16, 14);
            margin-left: 5px;
            text-align: left;
        }

        &:hover {
            border-bottom: 5px solid $blue;
        }

        &--regions {
            display: flex;
            font-size: em(14);

            i {
                font-size: em(55);
            }

            svg {
                max-height: 55px;
                max-width: 55px;
                min-height: 55px;
                min-width: 55px;
            }

            span {
                line-height: em(18, 14);
            }

            @include desktop {
                font-size: em(16);

                svg {
                    max-height: 65px;
                    max-width: 65px;
                    min-height: 65px;
                    min-width: 65px;
                }
            }
        }

    }
}