.state-parks__newsletter {
    background: url("/images/shell/state-parks/newsletter-bg.jpg") no-repeat top center;
    background-size: cover;
    box-sizing: border-box;
    min-height: 700px;
    padding: 100px 20px;

    @media (min-width: 992px) {
        padding: 170px 0;
    }

    @media (min-width: 1200px) {
        min-height: 900px;
        padding: 280px 0;
    }

    h2 {
        color: #ffffff;
        font-size: em(21px);
        @include font-montserrat("Regular");
        font-weight: bold;
        line-height: rem(32px);
        margin-top: 20px;
        text-shadow: 1px 1px 6px #000000;

        @media (min-width: 500px) {
            font-size: em(25px);
        }

        @media (min-width: 992px) {
            font-size: em(30px);
        }

        @media (min-width: 1200px) {
            font-size: em(30px);
            margin-top: 60px;
        }
    }

    &-details {
        text-align: center;
    }

    &-icon {
        img {
            margin: 0 auto;
        }
    }
}