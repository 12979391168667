// Color palette from Aaron's style guide
$navy: #002e55;
$blue: #1e6aaa;
$beige: #e3e3d7;
$white: #ffffff;
$yellow: #fac613;
$yellow-orange: #de9027;
$orange: #f08c00;
$gray: #4b4b4b;
$green: #537142;

// Other colors
$off-white: #f3f2ec;
$charcoalgray: #555555;
$black: #000000;
$lightbeige: #edede8;
$red: #a94442;
$error: $red;
$dark-orange: #d39b2e;

// Imagine That
$imagine-that-dark-orange: #c0642a;
$imagine-that-light-orange: #cf992f;
$imagine-that-green: #7a9b4b;
$imagine-that-blue: #5ea8dc;

// Fishing Trail
$fishing-trail-red: #ef4824;
$fishing-trail-blue: #0a3e54;
$fishing-trail-tan: #fff7e1;

// Music Trail
$music-trail-red: #a71e22;
$music-trail-tan: #fff7e1;
$music-trail-blue: #0a3e54;
$music-trail-gray: #d0cbc1;
$music-trail-charcoal-gray: #383430;
$music-trail-white: #dbdbcf;
$music-trail-gold: #8b7c41;

// Long Road To Liberty
$long-road-to-liberty-red: #8b2237;
$long-road-to-liberty-tan: #fff7e1;
$long-road-to-liberty-blue: #0a3e54;
$long-road-to-liberty-gray: #77787b;
$long-road-to-liberty-charcoal-gray: #383430;
$long-road-to-liberty-dark-gray: #333333;
$long-road-to-liberty-white: #dbdbcf;
$long-road-to-liberty-gold: #8b7c41;
$long-road-to-liberty-blue-green: #4f9297;
$long-road-to-liberty-yellow: #d69844;
$long-road-to-liberty-green: #678172;

// Weekender
$weekend-blue: #004e9a;

// Route 66 Centennial
$route-66-centennial-blue: #024c76;

$webFontDirectory: "/fonts";

// Form resets
// Font sizes in the PSD, and base for property size calculations using em(). "px" suffix added by em().
// Increasing this will increase the font size but other properties, such as margin and padding, will have the same values as the PSD (no scaling).
$fh-psd-button-font-size: 10 !default;
$fh-psd-input-font-size: 14 !default;
$fh-psd-label-font-size: 15.15 !default;

// Font

// Font size used for font-size property. "px" suffix added by rem().
// Increasing this will scale all element sizes.
$fh-button-font-size: $fh-psd-button-font-size !default;
$fh-input-font-size: $fh-psd-input-font-size !default;
$fh-font-size: $fh-psd-input-font-size !default; // default for all other elements.
