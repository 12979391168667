/*
|--------------------------------------------------------------------------
| Sizes
|--------------------------------------------------------------------------
|
| Each size represents the max width for the device
| Mobile: 320 - 768, Tablet: 768 - 1024, Laptop: 1024 - 1920, Desktop: 1920 - Up
*/

$phone: 768px;
$tablet: 1024px;
$laptop: 1920px;

@mixin mobile-only {
    @media (max-width: #{$phone - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$phone}) {
        @content;
    }
}

@mixin tablet-and-below {
    @media (max-width: #{$tablet - 1px}) {
        @content;
    }
}

@mixin tablet-only {
    @media (min-width: #{$phone}) and (max-width: #{$tablet - 1px}) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: #{$tablet}) {
        @content;
    }
}

@mixin laptop-and-below {
    @media (max-width: #{$laptop - 1px}) {
        @content;
    }
}

@mixin laptop-only {
    @media (min-width: #{$tablet}) and (max-width: #{$laptop - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$laptop}) {
        @content;
    }
}

.mobile-only {
    @include tablet {
        display: none !important;
    }
}

.tablet {
    @include mobile-only {
        display: none !important;
    }
}

.tablet-and-below {
    @include laptop {
        display: none !important;
    }
}

.tablet-only {
    @include mobile-only {
        display: none !important;
    }
    @include laptop {
        display: none !important;
    }
}

.laptop {
    @include tablet-and-below {
        display: none !important;
    }
}

.laptop-and-below {
    @include desktop {
        display: none !important;
    }
}

.laptop-only {
    @include tablet-and-below {
        display: none !important;
    }
    @include desktop {
        display: none !important;
    }
}

.desktop {
    @include laptop-and-below {
        display: none !important;
    }
}

@mixin media-query($width) {
    @media (min-width: #{$width}) {
        @content
    }
}

@mixin media-query-max($width) {
    @media (max-width: #{$width}) {
        @content
    }
}

@mixin media-query-between($minWidth, $maxWidth) {
    @media (min-width: #{$minWidth}) and (max-width: #{$maxWidth}) {
        @content
    }
}

@mixin media-query-debug-text {
    position: absolute;
    top: 5px;
    right: 5px;
}

@mixin media-query-debug {
    @include mobile-only {
        background-color: rgba(255, 0, 0, 0.3);
        &::after {
            content: "Phone";
            @include media-query-debug-text;
        }
    }

    @include tablet {
        background-color: rgba(0, 255, 0, 0.3);
        &::after {
            content: "Tablet";
            @include media-query-debug-text;
        }
    }

    @include laptop {
        background-color: rgba(0, 0, 255, 0.3);
        &::after {
            content: "Laptop";
            @include media-query-debug-text;
        }
    }

    @include desktop {
        background-color: rgba(255, 255, 0, 0.3);
        &::after {
            content: "Desktop";
            @include media-query-debug-text;
        }
    }
}
