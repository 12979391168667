// Styling for Map Filter Buttons
@mixin filter-button($color) {
    background-color: $color;
    color: white;
    outline: none;

    @media(hover: hover) {
        &:hover {
            background-color: darken($color, 7.5%);
        }
    }
}

// Styling for Map Points
@mixin map-point($color) {
    .map-star-circle {
        fill: $color;
    }

    &-active {
        transform: scale(1.15);

        .map-star-circle {
            fill: white;
        }

        .map-star-star {
            fill: $color;
        }
    }
}

// Styling for header images
@mixin webp-image($imageUrl) {
    @at-root .no-webp &, .no-js & {
        background-image: url("#{$imageUrl}.jpg");
    }

    @at-root .webp & {
        background-image: url("#{$imageUrl}.webp");
    }
}