.music-trail__grid {
    margin-top: 30px;

    &-card {
        background-color: $music-trail-charcoal-gray;
        box-sizing: border-box;
        color: $music-trail-white;
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding: 15px;
        position: relative;
        text-align: center;
        text-decoration: none;

        @include tablet {
            flex-direction: column;
        }

        &:hover, &:visited {
            color: $music-trail-white;
        }

        &-image {
            width: 50%;

            @include tablet {
                width: 100%;
            }

            img {
                border: 5px solid $music-trail-white;
                box-sizing: border-box;
                display: block;
            }
        }

        &-content {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-around;
            padding-left: 5px;
            width: 50%;

            @include tablet {
                padding-left: 0;
                width: 100%;
            }

            &-title {
                @include font-sucrose("Bold");
                color: $music-trail-white;
                font-size: 1.4em;
                line-height: 1em;
                overflow-wrap: break-word;
                width: 100%;
                word-wrap: break-word;

                @include tablet {
                    font-size: 1.8em;
                    margin: 25px 0;
                }
            }

            i {
                font-size: 3em;
            }
        }
    }
}