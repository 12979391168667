@import "modules_whats-new-backgrounds";

$even: "4n";
$odd: "4n+2";

.whats-new {
    background-color: white;
    overflow: hidden;
    position: relative;

    &__header {
        color: $blue;
        font-size: rem(32);
        margin: 20px auto 0;
        position: relative;
        @include font-sucrose("Bold");
        text-align: center;
        text-transform: uppercase;
        width: 300px;
        z-index: 20;

        @include media-query(400px) {
            font-size: rem(42);
            width: 380px;
        }

        @include media-query(550px) {
            font-size: rem(52);
            width: 480px;
        }

        @include media-query(700px) {
            font-size: rem(62);
            width: 620px;
        }

        @include laptop {
            font-size: rem(70);
            margin: 50px auto;
            padding: 0 380px 0 0;
        }

        @include desktop {
            padding-right: 580px;
        }
    }

    &__bordered-text {
        @include bordered-text($blue);
    }

    &__view-all {
        bottom: 35px;
        left: 50%;
        margin: 0 0 0 -132px;
        position: absolute;
        z-index: 1;

        @include tablet {
            bottom: auto;
            left: auto;
            margin-bottom: 0;
            margin-left: 28px;
            top: 64%;
        }

        @include laptop {
            margin-left: 135px;
        }
    }
}

.whats-new-blocks {
    margin-top: 20px;

    &__background {
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: auto;
    }
}

.whats-new-block {
    position: relative;
    text-align: center;

    .featured-content {
        display: inline-block;
        margin-bottom: 10px;
        width: 300px;
    }

    @include mobile-only {
        &:last-child {
            padding-bottom: 100px;
        }
    }
}

@media screen and (min-width: 400px) {
    .whats-new-block {
        .featured-content {
            width: auto;
        }
    }
    .whats-new-block:nth-child(#{$even}) {
        .featured-content {
            margin-left: 20%;
        }
    }
    .whats-new-block:nth-child(#{$odd}) {
        .featured-content {
            margin-right: 20%;
        }
    }
}

@media screen and (min-width: 700px) {
    .whats-new {
        padding-bottom: 75px;
    }

    .whats-new-blocks {
        margin-top: 100px;
    }

    .whats-new-block {
        .featured-content {
            margin-bottom: 20px;
            padding-top: 15px;
            width: 310px;

            &__topic-block {
                padding: 6px 8px 8px;
                width: 270px;
            }

            &__topic {
                font-size: em(18);
                margin-bottom: 9px;
                padding: 0 0 9px;
                width: 220px;
            }

            &__name {
                font-size: em(14);
                line-height: em(16);
                padding: 0 0 0 15px;
                width: 180px;
            }

            &__image-block {
                padding: 40px 0 0 15px;
            }

            &__image {
                max-height: 275px;
                max-width: 275px;
            }

            &__topic-icon {
                height: 50px;
            }
        }
    }

    .whats-new-block:nth-child(#{$even}) {
        margin-top: -170px;

        .featured-content {
            margin-left: 10px;
            padding-left: 310px;
        }
    }

    .whats-new-block:nth-child(#{$odd}) {
        margin-top: -100px;

        .featured-content {
            margin-right: 10px;
            padding-right: 310px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .whats-new-blocks {
        margin-top: 130px;
    }

    .whats-new-block {
        .featured-content {
            margin: 0;
            width: 512px;

            &__topic-block {
                padding: 6px 9px 10px 13px;
                width: 340px;
            }

            &__topic {
                font-size: em(26);
                margin-bottom: 6px;
                padding: 0 0 8px;
                width: auto;
            }

            &__image-block {
                padding: 50px 0 0 22px;
            }

            &__name {
                background-position: 1px 3px;
                font-size: em(18);
                line-height: em(18);
                padding: 0 0 0 17px;
                width: 240px;
            }

            &__image {
                max-height: 450px;
                max-width: 450px;
            }

            &__topic-icon {
                height: 65px;
            }
        }
    }

    .whats-new-block:nth-child(#{$even}) {
        margin-top: -200px;

        .featured-content {
            margin-left: 0;
            padding-left: 512px;
        }
    }

    .whats-new-block:nth-child(#{$odd}) {
        margin-top: -130px;

        .featured-content {
            margin-right: 0;
            padding-right: 512px;
        }
    }
}

@media screen and (min-width: 1920px) {
    .whats-new-block:nth-child(#{$even}) {
        .featured-content {
            margin-left: 45px;
        }
    }

    .whats-new-block:nth-child(#{$odd}) {
        .featured-content {
            margin-right: 45px;
        }
    }
}