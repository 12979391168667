.international__header {
    &-title {
        @include font-sucrose("Bold");
        color: $blue;
        font-size: em(35px);
        line-height: em(35px, 35px);
        margin: 0;
        padding: em(15px, 35px) 0 em(35px, 35px);
        text-align: center;
        text-transform: uppercase;

        @media screen and (min-width: 500px) {
            font-size: em(42px);
        }

        @include tablet {
            text-align: center;
        }

        @include laptop {
            display: none;
        }
    }
}