.listing {

    $gutter: 25px;

    &__document {
        $ratio: 282 / 364;

        background: white;
        border: 3px solid $blue;
        box-sizing: border-box;
        height: 0;
        margin-bottom: #{$gutter};
        overflow: hidden;
        padding-top: calc(#{$ratio} * (99.9% * 1 - (#{$gutter} - #{$gutter} * 1)));
        position: relative;
        @include grid(1, $gutter);

        @include tablet {
            padding-top: calc(#{$ratio} * (99.9% * 1 / 2 - (#{$gutter} - #{$gutter} * 1 / 2)));
            @include grid(2, $gutter);
        }
    }

    &__document-aspect-ratio {
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__document-image {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    &__document-info {
        align-items: center;
        background-color: rgba(251, 199, 0, 0.8);
        bottom: 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        min-height: 66px;
        padding: rem(5) rem(10);
        position: absolute;
        text-align: center;
        width: 100%;

        a {
            @include font-montserrat("SemiBold");
            text-decoration: none;
        }

        p {
            margin: 0;
        }
    }
}