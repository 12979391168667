.featured-content {
    min-height: 250px;
    position: relative;

    @include laptop {
        min-height: 350px;
    }

    &__topic-block {
        background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YzZjJlYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2Y5ZjlmNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
        background: -webkit-linear-gradient(left, rgba(243, 242, 236, 1) 0%, rgba(249, 249, 246, 1) 50%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(243, 242, 236, 1) 0%, rgba(249, 249, 246, 1) 50%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        display: block;
        left: 0;
        padding: 6px 8px 8px;
        position: absolute;
        text-align: left;
        top: 0;
        width: 270px;
        z-index: 5;
    }

    &__topic-link {
        text-decoration: none;
    }

    &__topic {
        border-bottom: 1px solid $yellow;
        color: $blue;
        font-size: em(18);
        line-height: em(18);
        @include font-montserrat("Bold");
        margin-bottom: 9px;
        padding: 0 0 9px;
        width: 220px;
    }

    &__name {
        color: $charcoalgray;
        display: block;
        font-size: em(14);
        @include font-montserrat("Regular");
        line-height: em(16);
        padding: 0 0 0 15px;
        text-decoration: none;
        width: 180px;
    }

    &__location-marker {
        background: url("/images/svg/locationMarker.svg") no-repeat 1px 1px;
        background-size: 4%;
    }

    &__city {
        @include font-montserrat("Italic");
    }

    &__image-block {
        display: inline-block;
        padding: 40px 0 0 15px;
        position: relative;
        z-index: 3;
    }

    &__image {
        border: 5px solid $beige;
        box-shadow: 5px 8px 40px 0 rgba(75, 75, 75, .5);
        display: block;
        max-height: 275px;
        max-width: 275px;
    }

    &__topic-icon {
        bottom: 0;
        color: $white;
        font-size: 3em;
        position: absolute;
        right: 10px;

        @include media-query(700px) {
            bottom: 15px;
            right: 15px;
        }

        @include tablet {
            bottom: 25px;
            font-size: 3.5em;
        }

        @include laptop {
            bottom: 15px;
        }
    }
}

@media screen and (min-width: 768px) {
    .featured-content {
        &__topic-block {
            padding: 6px 9px 10px 13px;
            width: 340px;
        }

        &__topic {
            font-size: em(24);
            margin-bottom: 6px;
            padding: 0 0 8px;
            width: 275px;
        }

        &__name {
            background-position: 1px 3px;
            font-size: em(18);
            line-height: em(18);
            padding: 0 0 0 17px;
            width: 230px;
        }

        &__image-block {
            padding: 50px 0 0 22px;
        }

        &__image {
            max-height: 450px;
            max-width: 450px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .featured-content {
        &__image-block {
            &:hover {
                .featured-content {
                    &__topic {
                        color: $navy;
                        font-size: em(30);
                        width: auto;
                    }

                    &__name {
                        font-size: em(22);
                        text-decoration: underline;
                        width: 275px;
                    }
                }
            }
        }
    }
}
