.long-road-to-liberty-map {
    padding: 0;

    &__wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__image {
        @include aspect-ratio(1130px, 541px);
        background: url("/images/long-road-to-liberty/map.png") no-repeat center top / contain;
        filter: drop-shadow(2px 2px 5px #969696);
        margin-bottom: 15px;
        width: 100%;
    }

    &__filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px 10px;
        justify-content: space-between;
        width: 100%;
        z-index: 1;

        @include laptop {
            left: 30px;
            top: 95px;
            width: auto;
        }

        &-button {
            @include font-montserrat("Bold");
            background-color: $white;
            box-sizing: border-box;
            color: black;
            cursor: pointer;
            flex-basis: 100%;
            font-size: 16px;
            outline: 2px solid black;
            outline-offset: -2px;
            padding: 15px 0;
            text-align: center;

            @include tablet {
                flex-basis: 49%;
            }

            @include laptop {
                align-items: center;
                display: flex;
                flex-basis: 19%;
                justify-content: center;
                padding: 10px 0;
                width: 285px;
            }

            @media(hover: hover) {
                &:hover {
                    background-color: #e9e9e9;
                }
            }

            &.long-road-to-liberty-blue-green {
                @include filter-button($long-road-to-liberty-blue-green);
            }

            &.long-road-to-liberty-red {
                @include filter-button($long-road-to-liberty-red);
            }

            &.long-road-to-liberty-yellow {
                @include filter-button($long-road-to-liberty-yellow);
            }

            &.long-road-to-liberty-green {
                @include filter-button($long-road-to-liberty-green);
            }

            &.long-road-to-liberty-gray {
                @include filter-button($long-road-to-liberty-gray);
            }
        }
    }

    &__point {
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        filter: drop-shadow(2px 2px 7px #a9a9a9);
        height: 30px;
        position: absolute;
        transition: all .1s ease-in-out;
        width: 30px;
        z-index: 1;

        svg {
            height: 20px;
            width: 20px;

            @include tablet {
                height: 25px;
                width: 25px;
            }
        }

        @include tablet {
            height: 35px;
            width: 35px;
        }

        @include laptop {
            height: 40px;
            width: 40px;
        }

        @media(hover: hover) {
            &:hover {
                transform: scale(1.15);
                z-index: 2;

                .long-road-to-liberty-map__point-tooltip {
                    background-color: #f3f2ec;
                    border: 1px solid #e3e3d7;
                    border-radius: 5px;
                    bottom: 25px;
                    display: block;
                    font-size: 15px;
                    left: 50%;
                    min-width: 150px;
                    padding: 5px 15px;
                    position: absolute;
                    text-align: center;
                    transform: translateX(-50%);
                    z-index: 3;
                }
            }
        }

        .map-star-star {
            fill: white;
        }

        &-tooltip {
            display: none;
        }

        &.type-town {
            @include map-point($long-road-to-liberty-blue-green);
        }

        &.type-business {
            @include map-point($long-road-to-liberty-yellow);
        }

        &.type-attraction {
            @include map-point($long-road-to-liberty-red);
        }

        &.type-site {
            @include map-point($long-road-to-liberty-green);
        }

        &.type-figure {
            @include map-point($long-road-to-liberty-gray);
        }
    }

    &__summary-box {
        box-sizing: border-box;
        color: white;
        display: flex;
        filter: drop-shadow(2px 2px 5px #969696);
        flex-direction: column;
        width: 100%;

        @include tablet {
            margin: 25px 0;
        }

        &.background-town {
            background-color: $long-road-to-liberty-blue-green
        }

        &.background-business {
            background-color: $long-road-to-liberty-yellow
        }

        &.background-attraction {
            background-color: $long-road-to-liberty-red
        }

        &.background-site {
            background-color: $long-road-to-liberty-green
        }

        &.background-figure {
            background-color: $long-road-to-liberty-gray
        }

        &-content-wrapper {
            display: flex;
            flex-direction: column;
            margin: auto;
            max-width: 395px;
            padding: 30px;

            @include tablet {
                flex-direction: row;
                margin: 0;
                max-width: 100%;
                padding: 30px;
            }

            &-link, a {
                color: white;
                text-decoration: underline;
            }

            &-img {
                flex-basis: 300px;
                margin: 0 25px 0 0;

                img {
                    filter: drop-shadow(2px 2px 4px #3c3c3c);
                    width: 300px;
                }
            }

            &-mobile {
                &-name {
                    @include font-sucrose("Bold");
                    display: block;
                    font-size: 30px;

                    @include tablet {
                        display: none;
                    }
                }

                img {
                    filter: drop-shadow(2px 2px 4px #3c3c3c);
                    margin: 10px 0 5px 0;
                    width: 100%;

                    @include tablet {
                        margin: 0 25px 0 0;
                        width: 300px;

                    }
                }
            }

            &-desktop {
                flex-basis: calc(100% - 300px);

                &-name {
                    @include font-sucrose("Bold");
                    display: none;
                    font-size: 48px;

                    @include tablet {
                        display: block;
                    }
                }

                p {
                    font-size: 24px;
                    margin: 15px 0 0;
                }
            }
        }
    }

    .hide {
        display: none;
    }
}

button.button-close {
    background-color: $long-road-to-liberty-dark-gray;
}