.state-parks__header {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 1 auto;
    height: 200px;
    width: 100%;

    @media screen and (min-width: 430px) {
        height: 300px;
    }

    @media screen and (min-width: 800px) {
        height: 400px;
    }

    @include laptop {
        background-position: top center;
        height: 550px;
    }

    @media screen and (min-width: 1920px) {
        // All backgrounds have this, it was extracted out
        background-position: bottom center;
    }

    @at-root {
        h1.state-parks__header-title {
            display: none;
            @media screen and (min-width: 800px) {
                display: block;
            }
        }

        h2.state-parks__header-title {
            display: block;
            font-size: em(50px);
            @media screen and (min-width: 500px) {
                font-size: em(65px);
            }

            @media screen and (min-width: 800px) {
                display: none;
            }
        }
    }

    &-title {
        color: $blue;
        font-size: rem(65px); // Slightly smaller than PSD because the web font doesn't look as good when the letter spacing is reduced.
        line-height: em(60px, 70px);
        margin: 0;
        @include font-sucrose("Bold");
        padding: 25px 0;
        text-align: left;
        text-transform: uppercase;

        @media screen and (min-width: 800px) {
            color: $white;
            font-size: rem(80px);
            line-height: em(70px, 80px);
            padding: 125px 0 50px 0; // Centered at 1024px, left and right padding no longer needs to match .content__padded
            text-align: center;
            text-shadow: 0 0 15px $black, 0 0 2px $black;

            span {
                font-size: 3rem;
            }
        }

        @include laptop {
            padding: 200px 0 50px 0;
        }
    }

    &--campsites {
        // Images scale down from larger version.
        background-image: url("/images/page_headers/header_book_rv_sites_mobile.jpg");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/header_book_rv_sites_tablet.jpg");
        }

        @media screen and (min-width: 800px) {
            background-image: url("/images/page_headers/header_book_rv_sites_laptop.jpg");
        }

        @media screen and (min-width: 1366px) {
            background-image: url("/images/page_headers/header_book_rv_sites_desktop.jpg");
        }

        @media screen and (min-width: 2250px) {
            // Fix issue with line art going through header text.
            h1 {
                padding-top: 125px;
            }
        }
    }

    &--golf {
        background-image: url("/images/page_headers/header_book_golf_mobile.jpg");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/header_book_golf_tablet.jpg");
        }

        @media screen and (min-width: 800px) {
            background-image: url("/images/page_headers/header_book_golf_laptop.jpg");
        }

        @media screen and (min-width: 1366px) {
            background-image: url("/images/page_headers/header_book_golf_desktop.jpg");
            background-position: top center;
        }
    }

    &--lodging {
        background-image: url("/images/page_headers/header_book_cabin_lodge_mobile.jpg");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/header_book_cabin_lodge_tablet.jpg");
        }

        @media screen and (min-width: 800px) {
            background-image: url("/images/page_headers/header_book_cabin_lodge_laptop.jpg");
        }

        @media screen and (min-width: 1366px) {
            background-image: url("/images/page_headers/header_book_cabin_lodge_desktop.jpg");
        }
    }

    &--meeting {
        background-image: url("/images/page_headers/state-parks-book-a-meeting-mobile.webp");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/state-parks-book-a-meeting-tablet.webp");
        }

        @media screen and (min-width: 800px) {
            background-image: url("/images/page_headers/state-parks-book-a-meeting-laptop.webp");
        }

        @media screen and (min-width: 1366px) {
            background-image: url("/images/page_headers/state-parks-book-a-meeting-desktop.webp");
        }
    }

    &--search {
        background-image: url("/images/page_headers/header_find_a_state_park_mobile.jpg");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/header_find_a_state_park_tablet.jpg");
        }

        @media screen and (min-width: 800px) {
            background-image: url("/images/page_headers/header_find_a_state_park_laptop.jpg");
        }

        @media screen and (min-width: 1366px) {
            background-image: url("/images/page_headers/header_find_a_state_park_desktop.jpg");
        }
    }

    &--bookings {
        background-image: url("/images/page_headers/bookings-header.png");
    }

    &--resources {
        background-image: url("/images/page_headers/resources-header.png");
    }

    &--get-involved {
        background-image: url("/images/page_headers/get-involved-header.png");
    }

    &--soundscapes {
        // 60:23
        height: 0;
        overflow: hidden;
        padding-top: 38.3333333333%;
        position: relative;

        @media screen and (min-width: 420px) {
            // 801:257
            padding-top: 32.0848938826%;
        }
        @media screen and (min-width: 801px) {
            // 192:55
            padding-top: 28.6458333333%;
        }

        &-image {
            background-image: url("/images/page_headers/state-parks-soundscapes-mobile.png");
            background-size: contain;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            @media screen and (min-width: 420px) {
                background-image: url("/images/page_headers/state-parks-soundscapes-tablet.png");
            }
            @media screen and (min-width: 801px) {
                background-image: url("/images/page_headers/state-parks-soundscapes.png");
            }
        }
    }

    &--updates {
        background-image: url("/images/page_headers/header_updates_mobile.jpg");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/header_updates_tablet.jpg");
        }

        @media screen and (min-width: 800px) {
            background-image: url("/images/page_headers/header_updates_laptop.jpg");
        }

        @media screen and (min-width: 1366px) {
            background-image: url("/images/page_headers/header_updates_desktop.jpg");
        }
    }

    &--faqs {
        background-image: url("/images/page_headers/header_sp_faqs_mobile.jpg");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/header_sp_faqs_tablet.jpg");
        }

        @media screen and (min-width: 800px) {
            background-image: url("/images/page_headers/header_sp_faqs_laptop.jpg");
        }

        @media screen and (min-width: 1366px) {
            background-image: url("/images/page_headers/header_sp_faqs_desktop.jpg");
        }
    }

    &--lake-conditions {
        background-image: url("/images/page_headers/header_lake_conditions_mobile.jpg");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/header_lake_conditions_tablet.jpg");
        }

        @media screen and (min-width: 800px) {
            background-image: url("/images/page_headers/header_lake_conditions_laptop.jpg");
        }

        @media screen and (min-width: 1366px) {
            background-image: url("/images/page_headers/header_lake_conditions_desktop.jpg");
            background-position: top center;
        }
    }
}