.box {
    &__link {
        color: $blue;
        @include font-sucrose("Bold");
        display: block;
        text-align: center;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__image {
        border: 5px solid $beige;
        box-shadow: 10px 17px 50px 0 rgba(75, 75, 75, 0.5);
        box-sizing: border-box;
        margin: 0 auto 10px;
    }

    &__image-title {
        font-size: rem(28px);
        margin-bottom: 0;
        margin-top: 5px;
        text-align: center;
    }

    &__description {
        margin-top: 15px;
    }
}