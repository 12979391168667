.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}

.text-hide {
    background-color: transparent;
    border: 0;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
}

.hidden {
    display: none !important;
}

.affix {
    position: fixed;
}
