.listing__related {

    &-listings {
        list-style: square;
        padding-left: 1em;

        @include tablet {
            column-gap: 1em;
            columns: 2;
        }
    }

    &-listing {
        margin-left: 1em;
    }

    &-other {
        @include clearfix;
        background-color: rgba(227, 227, 215, .5);
        box-sizing: border-box;
        padding: 20px 15px;
        width: 100%;

        @include tablet {
            padding: 25px 50px;
        }

        &-icon {
            color: $black;
            font-size: em(100);
            margin: 0 auto;
            text-align: center;
            vertical-align: middle;
        }

        &-column {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            @include tablet {
                @include grid(2, 50px);
            }

            .button {
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;
                text-align: center;
                width: 100%;
            }

            ul {
                color: $black;
                list-style: none;
                padding-left: 25px;

                & li {
                    position: relative;
                }

                & li:before {
                    color: $blue;
                    content: "\203A";
                    font-size: 2em;
                    left: -18px;
                    position: absolute;
                    top: -3px;
                }
            }
        }
    }

    &--state-park .listing__related {

        &-other {
            background: url("/images/shell/state-parks/learn-more.jpg") no-repeat center;
            background-size: cover;

            &-icon {
                color: $white;
            }

            &-column {
                .button {
                    @extend .button-yellow;
                }

                ul {
                    color: $white;

                    & li:before {
                        color: $yellow;
                    }
                }
            }
        }
    }
}