@include subnav(music-trail, #000000, $music-trail-blue, #ffffff, $white, $music-trail-red, $music-trail-blue, $music-trail-red, $music-trail-red);

.music-trail {
    background-color: $black;

    &__container {
        background-color: $white;

        a {
            text-decoration: none;
        }

        select {
            background: $music-trail-charcoal-gray url("/images/music-trail/select-field-arrow.png") 100% / 1.75em no-repeat;
            color: $white;
        }

        button, .button {
            background-color: $music-trail-red !important;

            &:hover {
                background-color: $music-trail-red !important;
            }
        }

        .button--black {
            background-color: $music-trail-charcoal-gray !important;

            &:hover {
                background-color: $music-trail-charcoal-gray !important;
            }
        }

        .pagination {
            a {
                background-color: $music-trail-red;
            }

            .pagination__active {
                background-color: #750f13;
            }
        }

        .search-summary__per-page {
            margin-right: 13.5em;
        }
    }

    &__background-wrap {
        overflow: hidden;
        position: relative;

        // Remove top margin from intro paragraph.
        > .content__wide, > .content__padded, > .content__padded--inline {
            position: relative;

            h1, h2, h3, h4, h5, h6 {
                color: $music-trail-red;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            p:first-child {
                margin-top: 0;
            }
        }

        button {
            background-color: $music-trail-blue;
        }
    }

    &__background {
        $laptop-percent: 0.4;
        $laptop-desktop-percent: 0.2;

        $top: 104px;
        $max-height-00: 604px;
        $laptop-max-height-00: round(abs(($max-height-00 * $laptop-percent) - $max-height-00));
        $laptop-desktop-max-height-00: round(abs(($max-height-00 * $laptop-desktop-percent) - $max-height-00));

        .no-webp &--00, .no-js &--00 {
            background: url("/images/music-trail/backgrounds/background-00.png") no-repeat left;
        }

        .webp &--00 {
            background: url("/images/music-trail/backgrounds/background-00.webp") no-repeat left;
        }

        &--00 {
            background-size: contain;
            display: none;
            position: absolute;
            z-index: 0;

            @include laptop {
                display: block;
                height: 100%;
                max-height: $laptop-max-height-00;
                top: 0;
                width: 100%;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
            }

            @include desktop {
                max-height: $max-height-00;
            }
        }

        .no-webp &--01, .no-js &--01 {
            background: url("/images/music-trail/backgrounds/background-01.png") no-repeat right;
        }

        .webp &--01 {
            background: url("/images/music-trail/backgrounds/background-01.webp") no-repeat right;
        }

        &--01 {
            background-size: contain;
            display: none;
            position: absolute;
            z-index: 0;

            @include laptop {
                display: block;
                height: 100%;
                max-height: $laptop-max-height-00;
                top: $laptop-max-height-00 * 0.75;
                width: 100%;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
                top: $laptop-desktop-max-height-00 * 0.75;
            }

            @include desktop {
                max-height: $max-height-00;
                top: $max-height-00 * 0.75;
            }
        }

        .no-webp &--02, .no-js &--02 {
            background: url("/images/music-trail/backgrounds/background-02.png") no-repeat left;
        }

        .webp &--02 {
            background: url("/images/music-trail/backgrounds/background-02.webp") no-repeat left;
        }

        &--02 {
            background-size: contain;
            display: none;
            position: absolute;
            z-index: 0;

            @include laptop {
                display: block;
                height: 100%;
                max-height: $laptop-max-height-00;
                top: $laptop-max-height-00 * 1.75;
                width: 100%;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
                top: $laptop-desktop-max-height-00 * 1.75;
            }

            @include desktop {
                max-height: $max-height-00;
                top: $max-height-00 * 1.75;
            }
        }

        .no-webp &--03, .no-js &--03 {
            background: url("/images/music-trail/backgrounds/background-03.png") no-repeat right;
        }

        .webp &--03 {
            background: url("/images/music-trail/backgrounds/background-03.webp") no-repeat right;
        }

        &--03 {
            background-size: contain;
            display: none;
            position: absolute;
            z-index: 0;

            @include laptop {
                display: block;
                height: 100%;
                max-height: $laptop-max-height-00;
                top: $laptop-max-height-00 * 2.75;
                width: 100%;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
                top: $laptop-desktop-max-height-00 * 2.75;
            }

            @include desktop {
                max-height: $max-height-00;
                top: $max-height-00 * 2.75;
            }
        }
    }

    &-stars {
        background: url("../../images/music-trail/stars.png") repeat-x;
        height: 9px;
        margin-bottom: 15px;
    }
}
