/*
Not modular and doesn't apply to _base or _layout
*/
$browser-context: 16;

@function em($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1em;
}

@function rem($pixels) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @return $pixels / ($browser-context * 1px) * 1rem;
}

@mixin input-placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}

@mixin transform($name) {
    -webkit-transform: $name;
    -moz-transform: $name;
    -o-transform: $name;
    transform: $name;
}

@mixin transform-origin($coords) {
    -webkit-transform-origin: $coords;
    -moz-transform-origin: $coords;
    -o-transform-origin: $coords;
    transform-origin: $coords;
}

@mixin transform-style($name) {
    -webkit-transform-style: $name;
    -moz-transform-style: $name;
    -o-transform-style: $name;
    transform-style: $name;
}

@mixin hide-text {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin bordered-text($color) {
    display: inline-block;
    padding-top: 4px;
    border: 1px solid $color;
    border-width: em(1) 0;
    font-size: em(11.2);
    line-height: em(14);
    vertical-align: middle;
}

@mixin grid($columns, $gutter) {
    $n: if($columns == 1, NaN, $columns);
    $fraction: if($columns == 1, 1, 1/#{$columns});

    @if $gutter > 0 {
        width: calc(99.999% * #{$fraction} - (#{$gutter} - #{$gutter} * #{$fraction}));
    } @else {
        width: calc(99.999% * #{$fraction});
    }

    &:nth-child(1n) {
        float: left;
        margin-right: #{$gutter};
        clear: none;
    }

    &:last-child {
        margin-right: 0;
    }

    @if $columns > 1 {
        &:nth-child(#{$n}n) {
            margin-right: 0;
            float: right;
        }

        &:nth-child(#{$n}n + 1) {
            clear: both;
        }
    }
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin subnav($property, $menuUnderline: $blue, $menuSeperator: $yellow, $menuBg: $white, $menuActiveColor: $blue, $menuBgActive: $off-white, $menuMobileBg: $blue, $menuColor: $blue, $menuBorder: $menuSeperator) {
    .#{$property}-menu {
        border-bottom: 2px solid $menuUnderline;

        @include laptop {
            width: em(980px);
            margin: 0 auto;
            padding: em(30px) 0 0 0;
        }

        @include desktop {
            width: em(1185px);
        }

        &__container {
            margin: 0 auto;
            width: 100%;
        }

        &__toggle {
            background-color: $menuMobileBg;
            display: block;
            font-size: 1em;
            margin: 0;
            padding: 15px 0;
            width: 100%;

            &:hover {
                background-color: $menuMobileBg;
            }

            &-icon {
                font-size: em(22px);
            }

            @include laptop {
                display: none;
            }
        }

        &__list {
            @include clearfix();
            @include font-montserrat("SemiBold");
            display: none;
            font-size: em(16px);
            list-style: none;
            margin: 0;
            padding: 0;

            @include laptop {
                display: flex;
                justify-content: space-around;
            }
        }

        &__list-item {
            color: $menuColor;
            line-height: 1.3;
            margin: 0;
            text-transform: uppercase;
            background-color: $menuBg;

            @include laptop {
                align-items: center;
                border-left: 2px solid $menuSeperator;
                display: flex;
                flex-grow: 1;
                justify-content: center;
            }

            &:first-child {
                @include laptop {
                    border-left: none;
                }
            }

            a {
                color: $menuColor;
                display: block;
                padding: 15px 0;
                text-align: center;
                text-decoration: none;

                @include laptop {
                    display: block;
                    padding: 15px 20px;
                    width: 100%
                }
            }

            &.active, &:hover {
                background: $off-white;
                border-top: 5px solid $menuBorder;

                a {
                    padding-top: 10px;
                }
            }

            &.active {
                background: $menuBgActive;

                a {
                    color: $menuActiveColor;
                }
            }

            &--no-padding {
                a {
                    padding: 0;
                }

                &:hover {
                    a {
                        padding: 0;
                    }
                }
            }

            &--no-hover {
                &:hover {
                    background: $menuBg;
                    border-top: none;

                    a {
                        padding-top: 0;
                    }
                }
            }

            &--desktop-hidden {
                @include laptop {
                    display: none;
                }
            }
        }

        .sticky-sub-nav__toggled {
            display: block;

            @include laptop {
                display: flex;
            }
        }

        &__trip-advisor-logo {
            width: auto;
            height: 46px;
        }
    }
}

@mixin image-border {
    border: 5px solid $beige;
    box-shadow: 5px 5px 15px 0 rgba(75, 75, 75, 0.5);
    // remove extra space due to being inline
    vertical-align: bottom;
}
