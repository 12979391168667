$vw_percentage: 0.6;

body.vw-animate {
    #visit_widget_button {
        animation: inserted 0.001s, play 850ms 0.5s steps(1) 3, shrink 1.5s 5s forwards;
        transform: scale(1);
    }
}

#visit_widget_button {
    animation: inserted 0.001s;
    background: url("/images/layout/visit-widget-sprite.png") no-repeat;
    background-size: 388px 185px;
    display: block;
    height: 185px;
    transform: scale($vw_percentage);
    transform-origin: bottom left;
    width: 191px;

    .animation-container {
        height: 100%;
        width: 100%;
    }

    a {
        display: block;
        height: 100%;
        width: 100%;
    }

    &:hover {
        #visit_widget_close_button {
            display: block;
        }
    }
}

#visit_widget_button_image {
    display: none !important;
}

#visit_widget_close_button {
    background-color: #c12b30;
    border-radius: 50%;
    color: white;
    display: none;
    height: 30px;
    line-height: 25px;
    margin: 10px;
    position: absolute;
    right: -20px;
    text-align: center;
    top: -5px;
    width: 30px;
    z-index: 100000;

    &::after {
        content: "\00d7";
        display: inline-block;
        font-size: 1.5rem;
    }
}

@keyframes play {
    50% {
        background-position: -189px;
    }
}

@keyframes shrink {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale($vw_percentage);
    }
}

@keyframes inserted {
    from {
        opacity: 0.99;
    }
    to {
        opacity: 1;
    }
}

.vw-add-to-trip {
    margin-top: em(10px, $fh-psd-input-font-size);
    position: relative;
    z-index: 1;
}