.contest-winners {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @include tablet {
        align-items: stretch;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-around;
    }

    &__header {
        margin-bottom: 30px;
    }

    &__info {
        border: thin solid black;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        justify-content: space-between;
        max-width: 400px;
        padding: 15px;
        text-align: center;
        width: 100%;

        @include tablet {
            width: 48%;
        }

        @include laptop {
            width: 31%;
        }

        strong {
            color: $blue;
        }

        &-contest {
            font-size: 20px;
            margin: 0 0 5px 0;
        }
    }
}
