.accordion {

    &__item {
        margin-bottom: em(5px, 16px);
    }

    &__item-title {
        background-color: $off-white;
        color: $gray;
        @include font-sucrose("Bold");
        display: block;
        font-size: em(28px);
        font-weight: normal;
        line-height: 1em;
        padding: em(15px, 30px) em(5px, 30px) em(15px, 30px) em(44px, 30px);
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
        word-wrap: break-word;

        &:hover {
            background-color: darken($off-white, 3%);
        }

        img {
            display: block;
            vertical-align: middle;
        }
    }

    &__item-title span {
        display: block;
        @include font-montserrat("SemiBold");
        font-size: em(24px, 30px);
        text-transform: none;
    }

    &__item-expand {
        display: inline-block;
        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.4));
        height: 25px;
        margin-bottom: em(-2px);
        margin-left: em(-26px, 30px);
        margin-right: em(3px);
        position: relative;
        width: 25px;

        &:before,
        &:after {
            background-color: $yellow;
            content: "";
            position: absolute;
            transition: transform 0.25s ease-out;

        }

        /* Vertical line */
        &:before {
            height: 100%;
            left: 50%;
            margin-left: -2px;
            top: 0;
            transform: rotate(90deg);
            width: 4px;
        }

        /* horizontal line */
        &:after {
            height: 4px;
            left: 0;
            margin-top: -2px;
            top: 50%;
            transform: rotate(180deg);
            width: 100%;
        }
    }

    img.accordion__item-arrow {
        display: inline-block;
        -webkit-filter: drop-shadow(3px -3px 2px rgba(0, 0, 0, 0.4));
        filter: drop-shadow(3px -3px 2px rgba(0, 0, 0, 0.4));
        margin-left: em(-26px, 30px);
        margin-right: 3px;
        transform: rotate(90deg);
    }

    &__item-content {
        background-color: $white;
        border-bottom: 2px solid $off-white;
        border-left: 2px solid $off-white;
        border-right: 2px solid $off-white;
        padding: em(15px);

        &-title:first-child {
            margin-top: 0;
        }

        &-title:only-of-type {
            display: none;
        }
    }

    &__item--collapse, .js-accordion__item--collapse {

        .accordion__item-expand {
            cursor: pointer;

            &:before {
                transform: rotate(0deg);
            }

            &:after {
                transform: rotate(0deg);
            }
        }

        img.accordion__item-arrow {
            -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.4));
            transform: rotate(0deg);
        }

        .accordion__item-content {
            display: none;
        }
    }
}

.accordion--no-content-padding {
    .accordion__item-content {
        padding: 0;
    }
}

@media screen and (min-width: 768px) {
    .accordion {
        &__item-title span {
            display: inline;
        }

        &__item-title img {
            display: inline-block;
        }
    }
}