.deal-result {
    border-bottom: 1px solid $beige;
    font-size: rem(18px);
    margin: 0;
    padding: em(30px, 18px) 0;
    position: relative;

    &__image img {
        border: 5px solid $beige;
        box-shadow: 10px 17px 50px 0px rgba(75, 75, 75, 0.5);
        max-width: em(290px, 18px);
    }

    &__details {
        address {
            margin-bottom: em(10px, 18px);
        }
    }

    h2 {
        font-size: em(32px, 18px);
        font-weight: bold;
        @include font-montserrat("Bold");
        letter-spacing: .025em;
        line-height: em(30px, 32px);
        margin-bottom: em(10px, 32px);
        margin-top: em(20px, 32px);

        a {
            text-decoration: none;
        }
    }

    h3 {
        font-size: em(18px, 18px);
        margin-bottom: em(10px, 18px);
        @include font-montserrat("Bold");
        margin-top: 0;

        a {
            text-decoration: none;
        }
    }

    &__offer-date {
        @include font-montserrat("Regular");
        color: $blue;
        font-size: em(18px, 18px);
        text-transform: uppercase;
    }

    &__listing-name, &__address {
        @include font-montserrat("Regular");
        color: $blue;
        font-size: em(18px, 18px);
        text-transform: none;
    }

    p {
        color: $gray;
        font-size: em(18);
        @include font-montserrat("Regular");
        margin-bottom: em(25px, 18px);
        margin-top: em(15px, 18px);
    }

    .button {
        margin: 0;
        padding-left: em(15px, 18px);
        padding-right: em(45px, 18px);
    }
}

@media screen and (min-width: 768px) {
    .deal-result {
        @include clearfix();

        &__image {
            float: left;
            text-align: center;
            width: em(340px, 18px);

            img {
                max-width: em(310px, 18px);
            }
        }

        &__details {
            margin-left: em(340px, 18px);
        }

        h2 {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 1024px) {
    .deal-result {
        padding: em(50px, 18px) 0;

        &__image {
            width: em(430px, 18px);

            img {
                max-width: em(390px, 18px);
            }
        }

        &__details {
            margin-left: em(430px, 18px);
            padding-left: em(20px, 18px);
        }
    }
}