.cities-and-regions {
    &__filter {
        display: inline-block;
        width: 100%;

        @include media-query(700px) {
            width: 47%;
            &:nth-child(1) {
                margin-right: 3%;
            }
            &:nth-child(2) {
                margin-left: 3%;
            }
        }
    }

    &__sort {
        width: 100%;

        @include laptop {
            width: 400px;
        }
    }

    &__search {
        display: inline-block;
        margin-bottom: em(15);
        width: 80%;
    }

    &__search-submit {
        display: inline-block;
        font-size: em(14, 16);
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        text-indent: em(-21, 14);
        width: 20%;

        &:after {
            text-indent: 0;
        }
    }
}