.result-block {
    border-bottom: 1px solid $beige;
    font-size: rem(18px);
    padding: em(30px, 18px) 0;
    position: relative;

    address {
        @include font-montserrat("SemiBold");
        color: $blue;
        font-size: em(14px);
        line-height: 1.3;
        margin-top: em(10px);

        b {
            @include font-montserrat("Bold");
            font-weight: normal;
        }
    }

    p {
        color: $gray;
        font-size: 1em;
        @include font-montserrat("Regular");
        margin-bottom: em(25px);
        margin-top: em(10px);

        &:last-child {
            margin-bottom: em(15px, 18px);
        }
    }

    .button {
        font-size: em(14px);
        margin: 0;
        padding-left: em(15px, 18px);
        padding-right: em(45px, 18px);
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        border: none;
    }

    &__image-section {
        .button {
            margin-top: 12px;
        }
    }

    &__image {
        display: block;

        img {
            border: 5px solid $beige;
            box-shadow: 0 10px 25px 0 rgba(75, 75, 75, 0.5);
            box-sizing: border-box;
            display: block;
            max-width: 100%;
            width: 390px;
        }
    }

    &__event-date {
        color: $blue;
        @include font-montserrat("Bold");
        font-size: em(20px);
        margin-bottom: em(10px);
    }

    &__phone {
        @include font-montserrat("Bold");
    }

    &__subhead {
        font-size: em(24px, 18px);
        line-height: em(28px, 24px);
        margin-top: em(10px, 24px);
        @include font-montserrat("Bold");
    }

    &__title, &__sub-title {
        font-size: em(22px);
        font-weight: bold;
        @include font-montserrat("Bold");
        letter-spacing: .025em;
        line-height: 1.15em;
        margin-bottom: em(0);
        margin-top: em(10px);

        a {
            text-decoration: none;
        }
    }

    &__sub-title {
        font-size: em(16px);
        margin-top: em(5px);
    }
}

@media screen and (min-width: 768px) {
    .result-block {
        @include clearfix();
        padding: em(30px, 18px) 0 0 0;

        &__image-section {
            float: left;
            text-align: left;
            width: em(350px, 18px);
        }

        &__image {
            img {
                box-shadow: 10px 17px 50px 0px rgba(75, 75, 75, 0.5);
                display: inline;
                margin: 0;
                max-width: em(350px, 18px);
            }
        }

        &__details {
            margin-left: em(370px, 18px);
        }

        &__title {
            font-size: em(24px, 18px);
            margin-top: 0;
        }

        &__sub-title {
            font-size: em(18px);
        }

        .button {
            display: inline-block;

            &:last-child {
                margin-bottom: em(30px, 18px);
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .result-block {
        padding: em(50px, 18px) 0 0 0;

        &__title {
            font-size: em(26px);
        }

        &__sub-title {
            font-size: em(20px);
        }

        .button {
            &:last-child {
                margin-bottom: em(50px, 18px);
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    .result-block {
        &__image-section {
            float: left;
            text-align: left;
            width: em(390px, 18px);
        }

        &__image {
            img {
                box-shadow: 10px 17px 50px 0px rgba(75, 75, 75, 0.5);
                display: inline;
                margin: 0;
                max-width: em(390px, 18px);
            }
        }

        &__details {
            margin-left: em(430px, 18px);
        }
    }
}
