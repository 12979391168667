.event-form-page {
    form {
        p {
            font-size: em(15px);
        }
    }
}

// Reused breakpoint values that were moved to a map and a function call to ease making changes.
$event-card-breakpoints: (
    (
        min-width: 0px,
        card-container-height: 406px,
        card-width: 225px,
        card-height: 310px,
        card-padding: 10px,
    ),
    (
        min-width: 1024px,
        card-container-height: 516px,
        card-width: 300px,
        card-height: 420px,
        card-padding: 10px,
    ),
    (
        min-width: 1920px,
        card-container-height: 540px,
    )
);

@function get-event-card-breakpoint-value($breakpoint, $key) {
    $result: map-get(nth($event-card-breakpoints, 1), $key);
    @each $map in $event-card-breakpoints {
        $value: map-get($map, $key);
        @if $value != null {
            $min-width: map-get($map, min-width);

            @if $breakpoint >= $min-width {
                $result: $value;
            }
        }
    }
    @return $result;
}

.owl-carousel {
    box-sizing: border-box;
    height: 100%;
    padding-left: 48px;
    padding-right: 48px;

    @include desktop {
        padding-left: 60px;
        padding-right: 60px;
    }
}

.upcoming-events {
    background-color: $yellow;
    margin: 0;
    overflow: auto;
    padding: 0;

    @include laptop {
        text-align: right;
    }

    &__header {
        text-align: center;

        @include tablet {
            @include clearfix();
            text-align: left;
        }

        @include laptop {
            background-position: -145px -5px;
            background-repeat: no-repeat;
            display: block;
            float: left;
            height: get-event-card-breakpoint-value(1024px, card-container-height);
            text-align: right;
            vertical-align: top;
            width: 298px;
        }

        @include media-query(1370px) {
            background-position: -95px -5px;
            width: 348px;
        }

        @include desktop {
            background-position: 20px -5px;
            height: get-event-card-breakpoint-value(1920px, card-container-height);
            width: 463px;
        }

        h1 {
            @include font-sucrose("Bold");
            color: $blue;
            font-size: rem(62px);
            line-height: em(56px, 62px);
            margin: em(30px, 62px) 0 em(15px, 62px) 0;

            @include tablet {
                display: block;
                float: left;
                margin-left: em(60px, 62px);
                margin-right: 15px;
                width: em(280px, 62px);
            }

            @include laptop {
                display: inline-block;
                float: none;
                margin-bottom: 0;
                margin-left: 0;
                margin-right: em(13px, 62px);
                vertical-align: top;
            }
        }
    }

    &__buttons {

        @include tablet {
            display: block;
            margin-right: 0;
            margin-top: 30px;
            overflow: hidden;
            padding-left: 18px;
        }

        @include laptop {
            display: inline-block;
            margin-top: 15px;
            padding-right: 20px;
        }

        .button {
            display: block;
            margin: 10px auto;
            padding-left: 0px;
            padding-right: 0px;
            text-align: center;
            width: em(200px, 18px);

            @include media-query(400px) {
                display: inline-block;
                margin-left: 15px;
            }

            @include tablet {
                display: block;
                margin-left: 0;
            }
        }

        .button:nth-child(2) {
            margin-bottom: 30px;
        }

        @include laptop {
            .button:nth-child(1) {
                margin-top: 0;
            }

            .button:nth-child(2) {
                margin-top: 25px;
            }
        }

        .button-primary {
            padding: em(10px, 18px) em(30px, 18px) em(10px, 18px) 0;

            &::after {
                width: auto;
            }
        }
    }

    &__body {
        background-color: $blue;
        height: get-event-card-breakpoint-value(320px, card-container-height);
        overflow: hidden;
        padding: 0;
        position: relative;
        text-align: center;

        @include tablet {
            height: get-event-card-breakpoint-value(768px, card-container-height);
            white-space: nowrap;

            .event-card {
                white-space: normal;
            }
        }

        @include laptop {
            display: block;
            height: get-event-card-breakpoint-value(1024px, card-container-height);
            margin: 0;
        }

        @include desktop {
            height: get-event-card-breakpoint-value(1920px, card-container-height);
        }

        &_outer {
            overflow: hidden;
            padding-bottom: 48px;
            padding-top: 48px;

            @include desktop {
                padding-bottom: 60px;
                padding-top: 60px;
            }
        }

        &_nav .disabled {
            display: none;
        }
    }

    &__scroll-button--next, &__scroll-button--prev {
        @include hide-text();
        background-color: transparent;
        background-image: url("/images/modules/events/arrow.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        height: 30px;
        margin: -15px 0 0 0;
        padding: 0 0;
        position: absolute;
        top: 50%;
        width: 50px;
        z-index: 3;
    }

    &__scroll-button--next:hover, &__scroll-button--prev:hover,
    &__scroll-button--next:focus, &__scroll-button--prev:focus {
        background-color: transparent;
    }

    &__scroll-button--prev {
        left: -5px;
        transform: rotate(90deg);
        @include media-query(400px) {
            left: 0;
        }
        @include laptop {
            left: 5px;
        }
        @include desktop {
            left: 10px;
        }
    }

    &__scroll-button--next {
        right: -5px;
        transform: rotate(270deg);
        @include media-query(400px) {
            right: 0;
        }
        @include laptop {
            right: 5px;
        }
        @include desktop {
            right: 10px;
        }
    }

    &__cards {
        font-size: rem(19px);
        position: relative;

        @include laptop {
            font-size: rem(24px);
        }
    }
}

.event-card {
    background-color: $white;
    color: $gray;
    display: block;
    font-size: rem(18px);
    height: get-event-card-breakpoint-value(320px, card-height) - get-event-card-breakpoint-value(320px, card-padding) * 2;
    @include font-montserrat("SemiBold");
    line-height: rem(18px);
    margin: 0 auto;
    padding: get-event-card-breakpoint-value(320px, card-padding);
    text-align: left;
    text-decoration: none;
    width: get-event-card-breakpoint-value(320px, card-width) - get-event-card-breakpoint-value(320px, card-padding) * 2;
    z-index: 2;

    &:visited {
        color: $gray;
    }

    @include media-query(400px) {
        box-shadow: 0 33px 37px -25px rgba(0, 45, 85, 0.85);
    }

    @include laptop {
        font-size: rem(24px);
        height: get-event-card-breakpoint-value(1024px, card-height) - get-event-card-breakpoint-value(1024px, card-padding) * 2;
        line-height: rem(24px);
        padding: get-event-card-breakpoint-value(1024px, card-padding);
        width: get-event-card-breakpoint-value(1024px, card-width) - get-event-card-breakpoint-value(1024px, card-padding) * 2;
    }

    @include desktop {
        //box-shadow: 8px 15px 30px 0px rgba(0, 46, 85, 0.75);
        height: get-event-card-breakpoint-value(1920px, card-height) - get-event-card-breakpoint-value(1920px, card-padding) * 2;
        padding: get-event-card-breakpoint-value(1920px, card-padding);
        width: get-event-card-breakpoint-value(1920px, card-width) - get-event-card-breakpoint-value(1920px, card-padding) * 2;
    }

    &__info {
        background: $white;
        bottom: 0;
        height: auto;
        padding-top: em(9px, 19px);
        position: absolute;
        width: inherit;
    }

    &__listing-photo {
        width: 100%;
    }

    &__date-range {
        @include font-montserrat("Black");
        color: $blue;
        font-size: 1rem;
        letter-spacing: em(1.5px, 16px);
        margin-bottom: 2px;
        text-transform: uppercase;
    }

    &__city {
        font-size: rem(16px);
        padding-bottom: 0;

        @include laptop {
            font-size: rem(22px);
        }
    }
}
