.sp-booking-results {
    &__row:last-child .search-result:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: em(30px, 18px);
    }

    &__row--two-column {
        display: block;
    }
}

@media screen and (max-width: 1023px) {
    .sp-booking-results {
        .search-result__image {
            img {
                box-sizing: border-box;
                display: block;
                max-width: none; // Override value from .search-result__image
                width: 100%;
            }
        }

    }
}

// Override search result layout on tablet to match booking PSD with a large image and content underneath.
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .sp-booking-results {
        .search-result__image {
            float: none;
            width: 100%;

            img {
                max-width: none;
            }
        }

        .search-result__details {
            margin-left: 0;

            h2 {
                margin-top: em(20px, 32px);
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .sp-booking-results__row {
        display: flex;

        .search-result__image {
            text-align: left;
        }
    }

    .sp-booking-results__row--two-column {
        border-bottom: 1px solid $off-white;
        @include clearfix;
        display: flex;

        .search-result {
            border-bottom: none;
            box-sizing: border-box;
            width: 50%;

            &:nth-of-type(odd) {
                border-right: 1px solid $off-white;
                float: left;
                padding: em(30px, 18px) em(30px, 18px) em(30px, 18px) 0;
            }

            &:nth-of-type(even) {
                float: right;
                padding: em(30px, 18px) 0 em(30px, 18px) em(30px, 18px);
            }
        }

        .search-result__image {
            display: block;
            float: none;
        }

        .search-result__details {
            margin-left: 0;

            h2 {
                margin-top: em(20px, 32px);
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.heading-link {
    display: block;
    text-decoration: none;
}