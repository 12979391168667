.music-trail__map {
    @include clearfix;
    @include aspect-ratio(980px, 509px);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin-bottom: 25px;
    position: relative;
    top: 10px;

    .no-webp &, .no-js & {
        background-image: url("/images/music-trail/map.png");
    }

    .webp & {
        background-image: url("/images/music-trail/map.webp");
    }

    &-wrapper {
        position: relative;
    }

    &-filters {
        display: flex;
        flex-direction: column;
        margin: 25px auto 15px;
        width: 100%;

        @include tablet {
            width: 375px;
        }

        @include laptop {
            left: 60px;
            position: absolute;
            top: 150px;
            width: 215px;
            z-index: 1;
        }

        span {
            font-size: rem(18px);
        }

        select {
            margin-top: 10px;
        }
    }

    &-point {
        background-image: url(/images/music-trail/map-star.png);
        background-repeat: no-repeat;
        background-size: contain;
        height: 22px;
        position: absolute;
        width: 22px;
        z-index: 10;

        &--selected {
            filter: none !important;
            height: 32px;
            width: 32px;
            z-index: 11;
        }

        &--unselected {
            filter: grayscale(1);
        }

        &-pop-up {
            background-color: #a71e22;
            color: $white;
            padding: 15px;
            top: 40px;
            z-index: 15;

            a {
                color: $white;
            }

            .music-trail-stars {
                margin: 10px 0;
            }

            &-title {
                @include font-sucrose("Bold");
                font-size: rem(38px);

                @include tablet {
                    font-size: rem(40px);
                }
            }

            &-content {
                display: flex;
                flex-direction: column;

                @include tablet {
                    flex-direction: row;
                }

                &-text {
                    flex: 1;
                }

                &-image {
                    @include tablet {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
