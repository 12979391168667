@include subnav(listing);
.listing-menu {
    &--header-hidden {
        padding-top: em(50);
    }

    @include laptop {
        padding-top: em(50);

        &--state-park {
            padding-top: em(30);
        }

        @include desktop {
            padding-top: em(70);

            &--state-park {
                padding-top: em(30);
            }
        }
    }
}