.music-trail-card {
    background-color: $music-trail-charcoal-gray;
    color: #dbdbcf;
    display: block;
    flex: 1;
    margin-bottom: 15px;
    padding: 15px;
    position: relative;

    &__title {
        @include font-sucrose("Bold");
        color: $music-trail-white;
        font-size: rem(24px);
        line-height: 1em;
        margin-bottom: 15px;
        overflow-wrap: break-word;
        text-decoration: none;
        word-wrap: break-word;
    }

    &__album-list {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;

        a {
            color: $music-trail-white;
            text-decoration: underline;

            &:hover {
                color: darken($music-trail-white, 10%);
            }
        }
    }

    &--discography {
        $gutter: 0.4rem;

        .columns {
            margin-left: -$gutter;
            margin-right: -$gutter;
            margin-top: -$gutter;

            &:last-child {
                margin-bottom: -$gutter;
            }
        }

        .column {
            padding: 0 $gutter $gutter;
        }
    }

    &--red {
        background-color: $music-trail-red;
    }
}