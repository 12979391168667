.weekender_overview {
    &__video {
        background-color: $black;

        &-content {
            padding: 0;
            @extend .content__padded--inline;
        }
    }

    .upcoming-events {
        &__title {
            @include font-montserrat("Regular");
            color: $yellow;

            > strong {
                @include font-montserrat("Bold");
            }
        }

        &__body {
            background: rgb(0, 46, 85);
            background: linear-gradient(180deg, rgba(0, 46, 85, 1) 0%, rgba(0, 78, 154, 1) 100%);
            border-bottom: 20px solid $weekend-blue;
            border-top: 20px solid $yellow;
            height: 520px;
        }

        &__cards {
            margin: 0 auto;
            max-width: 987px;
            width: 100%;

            @include media-query(1330px) {
                max-width: 1325px;
            }
        }

        &__scroll-button--next, &__scroll-button--prev {
            top: 38%;
        }

        &__scroll-button--next {
            right: 0;
        }

        &__scroll-button--prev {
            left: 0;
        }
    }

    .event-card {
        background-color: transparent;
        box-shadow: none;
        color: $white;
        height: auto;
        padding: 0;
        width: auto;

        @include media-query(475px) {
            width: 375px;
        }

        &__header {
            box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.55);
            position: relative;
        }

        &__body {
            margin-top: 15px;
            padding: 5px 15px;
        }

        &__duration {
            background-color: $black;
            border-radius: 5px;
            bottom: 10px;
            color: $white;
            font-size: rem(22px);
            padding: 2px 5px;
            position: absolute;
            right: 10px;
            @include font-montserrat("SemiBold");
        }

        &__info {
            background: rgb(250, 198, 19);
            background: linear-gradient(90deg, rgba(250, 198, 19, 0.50) 50%, rgba(255, 255, 255, 0) 85%);
            bottom: auto;
            color: $white;
            left: 0;
            padding: 10px;
            position: absolute;
            right: 0;
            text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
            top: 0;
        }
    }
}

.weekender {
    padding-bottom: 40px;

    h1, h2, h3, h4, h5, h6 {
        color: $weekend-blue;
        @include font-montserrat("Bold");
    }

    &__video {
        .hero-video {
            display: block;

            &__call-to-action {
                bottom: 10%;

                a {
                    background-color: $yellow;
                }
            }
        }
    }

    a, a:visited {
        color: $weekend-blue;
    }

    button, .button {
        background-color: $weekend-blue;
        color: $white;

        &:hover, &:visited {
            background-color: $weekend-blue;
            color: $white;
        }
    }

    a.button-yellow:visited, a.button-yellow:hover, a.button-yellow:active {
        background-color: $yellow;
        color: $white;
    }

    .search-result {
        border: none;
    }

    .search-result + .search-result {
        border-top: 2px $beige solid;
    }

    .readmore {
        &__description {
            &::after {
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fdedc5 100%);
            }

            &-container {
                margin-bottom: em(40px);
            }
        }
    }

    &__background {
        &-wrap {
            overflow: hidden;
            position: relative;
        }

        $tablet-percent: 0.6;
        $laptop-percent: 0.4;
        $laptop-desktop-percent: 0.2;

        $top: 0;
        $max-height: 585px;
        $tablet-max-height: round(abs(($max-height * $tablet-percent) - $max-height));
        $laptop-max-height: round(abs(($max-height * $laptop-percent) - $max-height));
        $laptop-desktop-max-height: round(abs(($max-height * $laptop-desktop-percent) - $max-height));

        &--00, &--01, &--02, &--03 {
            background-repeat: no-repeat;
            background-size: contain;
            display: none;
            filter: alpha(opacity=30);
            opacity: 0.3;
            position: absolute;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height;
            }

            @include desktop {
                max-height: $max-height;
            }
        }

        &--00 {
            background-image: url("/images/weekender/weekender-episode-background-00.png");
            top: $top
        }

        &--01 {
            background-image: url("/images/weekender/weekender-episode-background-01.png");

            @include tablet {
                max-height: $tablet-max-height;
                top: $tablet-max-height;
            }

            @include laptop {
                max-height: $laptop-max-height;
                top: $laptop-max-height;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height;
                top: $laptop-desktop-max-height;
            }

            @include desktop {
                max-height: $max-height;
                top: $max-height;
            }
        }

        &--02 {
            background-image: url("/images/weekender/weekender-episode-background-02.png");
            @include tablet {
                max-height: $tablet-max-height;
                top: $tablet-max-height * 2;
            }

            @include laptop {
                max-height: $laptop-max-height;
                top: $laptop-max-height * 2;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height;
                top: $laptop-desktop-max-height * 2;
            }

            @include desktop {
                max-height: $max-height;
                top: $max-height * 2;
            }
        }

        &--03 {
            background-image: url("/images/weekender/weekender-episode-background-01.png");
            @include tablet {
                max-height: $tablet-max-height;
                top: $tablet-max-height * 3;
            }

            @include laptop {
                max-height: $laptop-max-height;
                top: $laptop-max-height * 3;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height;
                top: $laptop-desktop-max-height * 3;
            }

            @include desktop {
                max-height: $max-height;
                top: $max-height * 3;
            }
        }
    }

    &__pagination {
        display: flex;

        &-link {
            align-items: center;
            display: flex;
            flex: 1 1 auto;
            font-size: em(18px);
            @include font-montserrat("SemiBold");

            &--prev {
                justify-content: flex-start;
                text-decoration: none;
            }

            &--next {
                justify-content: flex-end;
                text-decoration: none;
            }

            > span {
                padding: 0 em(5px);
            }
        }
    }

    &__related-listings {
        background-color: $weekend-blue;
        color: $white;
        display: flex;
        margin-bottom: em(15px);

        @include tablet {
            margin-bottom: em(25px);
            min-height: em(320px);
        }

        h4 {
            color: $white;
            @include font-montserrat("SemiBold");
            margin-top: 0;
        }

        ul {
            font-size: em(16px);
            list-style-type: square;
        }

        a {
            color: $white;
            text-decoration: none;

            &:visited {
                color: $white;
            }
        }

        &-info {
            flex: 1;
            padding: em(40px) em(30px);
            @include tablet {
                padding: em(30px) em(20px);
            }
            @include laptop {
                padding: em(40px) em(30px);
            }
        }

        &-image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: none;

            @include tablet {
                display: block;
                flex: 2;
            }
        }
    }

    &__related-more {
        color: $white;
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
        }

        h4 {
            color: $white;
            @include font-montserrat("SemiBold");
            margin-top: 0;

            @include tablet {
                text-align: center;
            }
        }

        ul {
            color: $white;
            font-size: em(16px);
            list-style-type: square;
        }

        a {
            color: $white;
            text-decoration: none;

            &:visited {
                color: $white;
            }
        }

        &-box {
            display: flex;
            flex: 1;

            &:first-child {
                margin-bottom: 15px;
                @include tablet {
                    margin-bottom: 0;
                    margin-right: 15px;
                }
            }

            &-icon {
                align-items: center;
                background: $weekend-blue;
                display: none;
                flex: 1;
                font-size: 125px;
                justify-content: center;
                margin-right: rem(5px);

                @include tablet {
                    display: flex;
                }
            }

            &-info {
                background: $weekend-blue;
                flex: 2;
                padding: em(30px);
                @include tablet {
                    padding: em(20px);
                }
                @include laptop {
                    padding: em(30px);
                }
            }
        }
    }

    &__video {
        background: $weekend-blue url("/images/weekender/weekender-header.png") no-repeat center bottom;
        background-size: cover;

        &-content {
            padding: 0;
            @extend .content__padded--inline;
        }
    }
}

