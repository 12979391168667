@media screen and (min-width: 550px) {
    .whats-new-block__state-parks {
        &__background {
            &.western-heritage {
                background: url("/images/topic_backgrounds/western-heritage.png") no-repeat center top;
                background-size: 100% 576px;
                bottom: 100px;
                left: 40%;
                margin-left: -770px;
                min-height: 576px;
                min-width: 1920px;
                @media (min-width: 1024px) {
                    bottom: 150px;
                }
            }

            &.fishing {
                background: url("/images/topic_backgrounds/fishing.png") no-repeat right bottom;
                bottom: 100px;
                margin-right: -290px;
                min-height: 575px;
                min-width: 1133px;
                right: 15%;
                @media (min-width: 1024px) {
                    bottom: -200px;
                }
            }

            &.route-66 {
                background: url("/images/topic_backgrounds/route-66.png") no-repeat left top;
                left: 25%;
                margin-left: -480px;
                min-height: 573px;
                min-width: 1058px;
                top: -170px;
            }

            &.urban {
                background: url("/images/topic_backgrounds/urban.png") no-repeat right top;
                bottom: 130px;
                margin-right: -290px;
                min-height: 688px;
                min-width: 1406px;
                right: 15%;
                @media (min-width: 1024px) {
                    bottom: -150px;
                }
            }

            &.american-indian-culture {
                background: url("/images/topic_backgrounds/american-indian-culture.png") no-repeat left top;
                bottom: 0;
                left: 25%;
                margin-left: -480px;
                min-height: 609px;
                min-width: 1788px;
                @media (min-width: 768px) {
                    top: 0;
                }
            }

            &.museum {
                background: url("/images/topic_backgrounds/museum.png") no-repeat right bottom;
                bottom: -125px;
                margin-right: -295px;
                min-height: 429px;
                min-width: 1592px;
                right: 15%;
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    .whats-new-block__state-parks:nth-child(odd) {
        .whats-new-block {
            &__background {
                left: 0;
                margin-left: 0;
            }
        }
    }
    .whats-new-block__state-parks:nth-child(even) {
        .whats-new-block {
            &__background {
                margin-right: 0;
                right: 0;
            }
        }
    }
}