.fall-foliage {

    &__route-container {
        position: relative;
        @include clearfix;
    }

    &__map-container {
        margin: 0 auto em(20px);
        max-width: 100%;
        position: relative;
    }

    &__map {
        -webkit-filter: drop-shadow(3px 3px 19px rgba(0, 0, 0, 0.7));
        filter: drop-shadow(3px 3px 19px rgba(0, 0, 0, 0.7));
    }

    &__map-icon {
        background: url("/images/fall_foliage/fall_foliage_map_icon.png") no-repeat -6px -5px;
        color: white;
        font-size: em(18px);
        height: 45px;
        @include font-sucrose("Bold");
        line-height: 57px;
        position: absolute;
        text-align: center;
        // Adding the same shadow repeatedly so that it's actually visible.
        text-decoration: none;
        text-shadow: 0 0 5px #78271c, 0 0 5px #78271c, 0 0 5px #78271c, -1px -1px #000000, 1px 1px #000000;
        width: 40px;
        @include transform(translate(-50%, -50%));
        @include transform-style(preserve-3d); // Fix for webkit browsers not always rendering.

        &:hover {
            color: #ffdfb5;
        }

        &--active {
            filter: drop-shadow(3px 8px 4px #383430);
        }
    }

    &__map-icon--link {
        background: url("/images/fall_foliage/fall_foliage_map_icon.png") no-repeat -6px -5px;
        display: inline-block;
        height: 45px;
        line-height: 18px;
        position: relative;
        vertical-align: text-bottom;
        width: 40px;
    }

    &__routes {
        background-color: #d87a00;
        color: $white;
        font-size: em(14px);
        margin-bottom: em(20px, 14px);
        padding: 10px 0 15px;

        &-header {
            color: $white;
            @include font-sucrose("Bold");
            font-size: em(22px);
            margin: 0 em(12px);
        }

        &-list {
            counter-reset: fall-foliage-list-counter;
            list-style-type: none;
            margin: 0;
            @include font-montserrat("Light");
            padding: 0;
        }

        &-item {
            margin: em(18px) 0 0;
            padding: 0 em(12px) 0 em(40px);
            position: relative;

            &:first-child {
                margin: em(14px) 0 0;
            }

            &:before {
                content: counter(fall-foliage-list-counter) ".";
                counter-increment: fall-foliage-list-counter;
                font-weight: bold;
                left: 0;
                position: absolute;
                text-align: right;
                top: 0;
                @include font-montserrat("Regular");
                width: em(28px);
            }

            &--active {
                font-weight: bold;
            }
        }

        &-link {
            &:link, &:active, &:visited {
                color: $white;
                text-decoration: none;
            }

            &:hover {
                color: lighten($beige, .5);
                text-decoration: underline;
            }
        }
    }

    &__color-report {
        font-size: em(24);
        margin: 0 0 em(8px, 24px);
    }

    &__route {
        display: none;
        margin-left: 3.5%;
        padding-top: 48%;
        vertical-align: top;
        width: 63.5%;

        &-map {
            display: none;
            outline: 2px solid $black;
            width: 100%;
        }

        &-name {
            margin-bottom: em(8px, 34px);
        }

        &-info {
            margin: 0 0 em(32px, 18px);
        }

        &-update {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 500px) {
    .fall-foliage {
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media screen and (min-width: 550px) {
    .fall-foliage {
        &__routes {

            &-list {
                font-size: em(16px, 14px);
            }

            &-item {
                margin: em(7px) 0 0;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .fall-foliage {

        &__map-container {
            margin: 0 auto;
            max-width: 100%;
            position: absolute;
        }

        &__routes-container {
            display: inline-block;
            padding-top: 10%;
            width: 32.5%;
        }

        &__routes {
            &-link {
                display: block;
            }
        }

        &__route {
            display: inline-block;

            &-map {
                display: block;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .fall-foliage {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 1920px) {
    .fall-foliage {
        padding-left: em(104px, 16px);
        width: em(1164px);

        &__routes-container {
            padding-top: 9.3%;
            width: 32%;
        }
    }
}

/**
Fall Foliage Model dialog
 */
.fall-foliage-modal {
    .modal-content {
        border-radius: 0;
    }

    &.fade.in {
        background-color: rgba(0, 46, 85, .8);
    }

    &__route {
        &-name {
            margin: 0 0 em(8px, 34px);
        }

        &-map {
            text-align: center;

            img {
                display: inline-block;
                height: auto;
                margin: 0 auto;
                max-width: 100%;
            }
        }

        &-update {
            margin: 0 0 em(25px);
        }

        &-info {
            font-size: em(16px);
            margin: 0 0 em(32px, 18px);
        }
    }

    &__close {
        bottom: 0;
        float: right;
        margin: 0 0 em(10px);
        position: relative;
    }

    &__description-icon {
        display: inline-block;
        float: left;
        margin-right: 10px;
    }
}

@media screen and (min-width: 768px) {
    .fall-foliage-modal {
        .modal-dialog {
            width: 725px;
        }

        &__color-report {
            display: none;
        }

        &__route {
            &-map {
                img {
                    max-width: 85%;
                }
            }

            &-update {
                display: none;
            }

            &-info {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 990px) {
    .fall-foliage-modal {
        .modal-dialog {
            width: 900px;
        }
    }
}

