.state-parks-landing {

    @media (min-width: 1024px) {
        .upcoming-events__parks {
            .upcoming-events {
                &__header {
                    background-image: url("/images/modules/events/state-park-event-bg.png");

                    h1 {
                        font-size: rem(55px);
                        width: 5em;
                    }
                }

                &__buttons {
                    margin-top: 20px;
                }
            }
        }
    }

}