.brochure-kit {
    &__header {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        &-image {
            margin: 0 0 em(24px) 0;

            @include tablet {
                margin: 0 em(24px) 0 0;
            }
        }

        &-description {
            align-items: center;
            display: flex;
            flex-direction: column;

            @include tablet {
                align-items: flex-start;
                flex-direction: row;
            }

            @include laptop {
                align-items: center;
            }
        }
    }
}
