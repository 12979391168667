$psd-site-footer-font-size: 18;

.site-footer {
    background-color: $navy;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: rem($psd-site-footer-font-size);
    padding: 40px 0;

    a, a:hover, a:visited, a:active {
        color: $white;
    }

    &__footer-link, &__footer-link:visited, &__footer-link:active {
        text-decoration: none;
    }

    &__footer-link:hover {
        text-decoration: underline;
    }

    &__logo-image {
        max-width: 175px;
        padding: 10px 0;
    }

    &__section {
        line-height: em(24px, $psd-site-footer-font-size);
        margin-bottom: 25px;
        text-align: center;

        a {
            line-height: em(36px, $psd-site-footer-font-size);
        }

        &--resource-links {
            margin-bottom: -15px;
            white-space: nowrap;

            a {
                display: block;
            }
        }

        &--free-brochures {
            margin-bottom: 10px;

            a {
                img {
                    margin-bottom: 12px;
                    margin-top: 8px;
                }
            }
        }

        &--newsletter {
            margin-bottom: 0;
        }

        &--copyright {
            font-size: 9px;
        }
    }

    &__section-header, &__section-header--emphasize {
        color: $yellow;
        text-transform: uppercase;
    }

    &__section-header {
        @include font-montserrat("Bold");
        letter-spacing: em(2px, $psd-site-footer-font-size);
        line-height: em(30px, $psd-site-footer-font-size);

        &--trip-planner {
            margin: 25px 0 10px 0;
        }

        &--resource-links {
            margin-bottom: 10px;
        }
    }

    &__section-header--emphasize {
        @include font-sucrose("Bold");
        font-size: em(29px, $psd-site-footer-font-size);
        letter-spacing: em(1px, $psd-site-footer-font-size);
        line-height: em(14px, $psd-site-footer-font-size);
    }

    &__separator {
        background: url("/images/layout/footer-buffalo.png") no-repeat center center;
        height: 25px;
        margin: 30px 0 20px 0;
    }

    &__brochures {
        display: flex;
        justify-content: center;
        position: relative;
    }

    &__brochures img {
        padding-top: 10px;
        width: 250px;
    }

    &__section form {
        display: flex;
        margin: em(20px, $psd-site-footer-font-size) auto 0;
        max-width: 20rem;
    }

    &__section form input[type="email"] {
        background-color: #f8f7f2;
        flex: 3;
        margin: 0;

        @include tablet-only {
            max-width: 188px;
        }
    }

    &__section form button {
        font-size: rem(14px);
        letter-spacing: normal;
        margin: 0;
        padding: 0 em(10px, 14px);
    }

    &__section-social {
        margin: auto auto em(15px, $psd-site-footer-font-size);
        width: 210px;

        a.social-icon {
            -moz-border-radius: 25px;
            -webkit-border-radius: 25px;
            border-radius: 25px;
            display: inline-block;
            margin: 5px 10px;
            text-decoration: none;
        }
    }

    &__section-badges {
        margin-bottom: em(15px, $psd-site-footer-font-size);

        &-container {
            .badge {
                display: inline-block;
                height: 35px;
                position: relative;
                width: 125px;

                img {
                    height: 100%;
                    position: absolute;
                    top: 3px;
                }

                &--ios {
                    img {
                        left: 10px;
                    }
                }

                &--android {
                    img {
                        right: 10px;
                    }
                }
            }
        }
    }

    &__privacy-policy {
        display: flex;
        flex-direction: column;

        &-link {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &--desktop {
            display: none;
        }
    }
}

@media screen and (min-width: 768px) {
    .site-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        text-align: center;

        &__logo-image {
            width: auto;
        }

        &__section {
            display: inline-block;
            margin-bottom: 20px;
            margin-top: 20px;
            padding: 0;
            vertical-align: top;
        }

        &__section-header {
            margin-top: 10px;

            &--trip-planner {
                margin: 0;
            }
        }

        &__section:nth-child(1) {
            margin-right: 15px;
            width: 160px;
        }

        &__section:nth-child(2) {
            border-left: 1px solid $white;
            border-right: 1px solid $white;
            display: flex;
            flex-direction: column;
            height: 430px;
            padding: 0 10px;
            width: 285px;
        }

        &__section:nth-child(3) {
            margin-left: 15px;
            width: 250px;
        }

        &__separator {
            margin: 20px 0 12px 0;
        }

        &__brochures img {
            margin-top: 0;
            width: 141px;
        }

        &__privacy-policy {
            display: flex;
            flex-direction: column;
            font-size: em(15px, 18px);
            height: 100%;
            justify-content: center;

            &--desktop {
                display: flex;
            }

            &--mobile {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .site-footer {
        &__section:nth-child(1) {
            margin-right: 35px;
        }

        &__section:nth-child(3) {
            margin-left: 35px;
        }
    }
}