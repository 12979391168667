.deals-page {
    position: relative;
    $imageWidth: 320px;
    $imageHeight: 152px;
    $imgRatio: $imageHeight / $imageWidth;

    &__results-header {
        h2 {
            font-size: em(40);
            margin-top: 0;
        }
    }

    &__results-count {
        border: 1px solid $beige;
        border-width: 1px 0;
        color: $gray;
        font-size: em(14);
        line-height: em(14);
        padding: 10px 0;
        text-transform: uppercase;
    }

    &__footer {
        position: relative;
    }

    &__footer-image {
        background: url("/images/deals_backgrounds/deals-page-sm.jpg") no-repeat left top;
        background-size: cover;
        padding-top: percentage($imgRatio);
        width: 100%;
    }
}

@media screen and (min-width: 501px) {
    .deals-page {
        $imageWidth: 800px;
        $imageHeight: 238px;
        $imgRatio: $imageHeight / $imageWidth;

        &__footer {
            padding-top: $imageHeight;
        }

        &__footer-image {
            background-image: url("/images/deals_backgrounds/deals-page-md.jpg");
            padding-top: $imageHeight;
            position: absolute;
            right: 0;
            top: 0;
            width: $imageWidth;

        }
    }
}

@media screen and (min-width: 800px) {
    .deals-page {
        $imageWidth: 800px;
        $imageHeight: 238px;
        $imgRatio: $imageHeight / $imageWidth;

        &__footer {
            padding-top: 0;
        }

        &__footer-image {
            background-image: url("/images/deals_backgrounds/deals-page-md.jpg");
            margin-left: 0;
            padding-top: percentage($imgRatio);
            position: relative;
            right: auto;
            width: 100%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .deals-page {
        &__results-grid {
            margin-bottom: 10px;
        }
    }
}

@media screen and (min-width: 1247px) {
    .deals-page {
        $imageWidth: 1366px;
        $imageHeight: 371px;
        $imgRatio: $imageHeight / $imageWidth;

        &__footer {
            padding-top: $imageHeight;
        }

        &__footer-image {
            background-image: url("/images/deals_backgrounds/deals-page-lg.jpg");
            padding-top: $imageHeight;
            position: absolute;
            right: 0;
            width: $imageWidth;
        }
    }
}

@media screen and (min-width: 1366px) {
    .deals-page {
        $imageWidth: 1920px;
        $imageHeight: 371px;
        $imgRatio: $imageHeight / $imageWidth;

        &__footer {
            padding-top: $imageHeight;
        }

        &__footer-image {
            background-image: url("/images/deals_backgrounds/deals-page-xl.jpg");
            padding-top: $imageHeight;
            position: absolute;
            right: 0;
            width: $imageWidth;
        }
    }
}

@media screen and (min-width: 1920px) {
    .deals-page {
        $imageWidth: 1920px;
        $imageHeight: 371px;
        $imgRatio: $imageHeight / $imageWidth;

        &__footer {
            height: $imageHeight;
            overflow: hidden;
            padding-top: 0;
        }

        &__footer-image {
            background-image: url("/images/deals_backgrounds/deals-page-xl.jpg");
            bottom: 0;
            padding-top: percentage($imgRatio);
            position: absolute;
            top: auto;
            width: 100%;
        }
    }
}