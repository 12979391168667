.hero-video {
    background-color: $black;
    position: relative;

    &__video-container {
        display: block;
        padding-top: 56.25%;
        position: relative;

        &--home-page {
            padding-top: var(--video-size);
        }
    }

    &__video-player {
        bottom: 0px;
        height: 100% !important;
        left: 0px;
        position: absolute !important;
        right: 0px;
        top: 0px;
        width: 100% !important;

        .vjs-overlay.vjs-overlay-background {
            background: none;
            bottom: 0;
            color: white;
            font-weight: bold;
            height: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            width: 100%;

            a {
                display: block;
                height: 100%;
                pointer-events: all;
                width: 100%;
            }
        }
    }

    &__call-to-action {
        bottom: 10%;
        display: block;
        position: absolute;
        text-align: center;
        width: 100%;

        @include media-query(575px) {
            bottom: 15%;
        }
    }

    &__explore-button, &__view-episode {
        font-size: rem(12);
        margin: 0;

        @include media-query(575px) {
            font-size: rem(14);
        }
    }

    &__logo {
        left: 0;
        margin-top: 3%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        z-index: 1;

        img {
            width: 37%;
        }

        @include tablet {
            margin-top: 6%;

            img {
                width: 33%;
            }
        }
    }

    // Modify brightcove player to not show native play button
    .hero-video__video-player .vjs-big-play-button {
        display: none;
    }

    // Modify brightcove player to show poster when video completes
    .vjs-ended .vjs-poster {
        display: inline-block;
    }

    // Hide controls when player has ended and not looping
    .vjs-ended .vjs-control-bar {
        display: none;
    }

    //Fix for certain buttons not being transparent
    .vjs-control-bar {
        button {
            background-color: transparent;

            &:hover, &:visited {
                background-color: $weekend-blue;
            }
        }

        .vjs-subs-caps-button:hover {
            background-color: $weekend-blue;
        }

        .vjs-menu-item-text {
            color: white;
        }

        .vjs-volume-panel.vjs-hover .vjs-mute-control {
            background-color: $weekend-blue;
        }

        .vjs-volume-horizontal {
            background-color: $weekend-blue;
        }

        .vjs-volume-level {
            background-color: white;
        }
    }
}

@media screen and (min-width: 768px) {
    .hero-video {
        &__call-to-action {
            bottom: 16%;
        }
    }
}

@media screen and (min-width: 1920px) {
    .hero-video {
        &__call-to-action {
            bottom: 22%;
        }
    }
}