/*! jQuery UI - v1.9.2 - 2017-04-11
* http://jqueryui.com
* Includes: jquery.ui.core.css, jquery.ui.resizable.css, jquery.ui.selectable.css, jquery.ui.accordion.css, jquery.ui.autocomplete.css, jquery.ui.button.css, jquery.ui.datepicker.css, jquery.ui.dialog.css, jquery.ui.menu.css, jquery.ui.progressbar.css, jquery.ui.slider.css, jquery.ui.spinner.css, jquery.ui.tabs.css, jquery.ui.tooltip.css, jquery.ui.theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Trebuchet%20MS%2CTahoma%2CVerdana%2CArial%2Csans-serif&fsDefault=1.1em&fwDefault=bold&cornerRadius=0&bgColorHeader=%231e6aaa&bgTextureHeader=flat&borderColorHeader=%231e6aaa&fcHeader=%23ffffff&iconColorHeader=%23ffffff&bgColorContent=%23f3f2ec&bgTextureContent=highlight_soft&borderColorContent=%23dddddd&fcContent=%23333333&iconColorContent=%23222222&bgColorDefault=%23f6f6f6&bgTextureDefault=glass&borderColorDefault=%23cccccc&fcDefault=%23000000&iconColorDefault=%23000000&bgColorHover=%23fac613&bgTextureHover=flat&borderColorHover=%23fbcb09&fcHover=%23ffffff&iconColorHover=%23ffffff&bgColorActive=%231e6aaa&bgTextureActive=flat&borderColorActive=%231e6aaa&fcActive=%23ffffff&iconColorActive=%23ffffff&bgColorHighlight=%231e6aaa&bgTextureHighlight=flat&borderColorHighlight=%231e6aaa&fcHighlight=%23ffffff&iconColorHighlight=%23ffffff&bgColorError=%23b81900&bgTextureError=diagonals_thick&borderColorError=%23cd0a0a&fcError=%23ffffff&iconColorError=%23ffd27a&bgColorOverlay=%23666666&bgTextureOverlay=diagonals_thick&bgImgOpacityOverlay=20&opacityOverlay=50&bgColorShadow=%23000000&bgTextureShadow=flat&bgImgOpacityShadow=10&opacityShadow=20&thicknessShadow=5px&offsetTopShadow=-5px&offsetLeftShadow=-5px&cornerRadiusShadow=5px&bgImgOpacityHeader=0&bgImgOpacityContent=100&bgImgOpacityDefault=100&bgImgOpacityHover=0&bgImgOpacityActive=65&bgImgOpacityHighlight=0&bgImgOpacityError=18
* Copyright jQuery Foundation and other contributors; Licensed MIT */

/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
    display: none;
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.ui-helper-reset {
    border: 0;
    font-size: 100%;
    line-height: 1.3;
    list-style: none;
    margin: 0;
    outline: 0;
    padding: 0;
    text-decoration: none;
}

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
    content: "";
    display: table;
}

.ui-helper-clearfix:after {
    clear: both;
}

.ui-helper-clearfix {
    zoom: 1;
}

.ui-helper-zfix {
    filter: Alpha(Opacity=0);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
    cursor: default !important;
}

/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
    background-repeat: no-repeat;
    display: block;
    overflow: hidden;
    text-indent: -99999px;
}

/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.ui-resizable {
    position: relative;
}

.ui-resizable-handle {
    display: block;
    font-size: 0.1px;
    position: absolute;
}

.ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle {
    display: none;
}

.ui-resizable-n {
    cursor: n-resize;
    height: 7px;
    left: 0;
    top: -5px;
    width: 100%;
}

.ui-resizable-s {
    bottom: -5px;
    cursor: s-resize;
    height: 7px;
    left: 0;
    width: 100%;
}

.ui-resizable-e {
    cursor: e-resize;
    height: 100%;
    right: -5px;
    top: 0;
    width: 7px;
}

.ui-resizable-w {
    cursor: w-resize;
    height: 100%;
    left: -5px;
    top: 0;
    width: 7px;
}

.ui-resizable-se {
    bottom: 1px;
    cursor: se-resize;
    height: 12px;
    right: 1px;
    width: 12px;
}

.ui-resizable-sw {
    bottom: -5px;
    cursor: sw-resize;
    height: 9px;
    left: -5px;
    width: 9px;
}

.ui-resizable-nw {
    cursor: nw-resize;
    height: 9px;
    left: -5px;
    top: -5px;
    width: 9px;
}

.ui-resizable-ne {
    cursor: ne-resize;
    height: 9px;
    right: -5px;
    top: -5px;
    width: 9px;
}

.ui-selectable-helper {
    border: 1px dotted black;
    position: absolute;
    z-index: 100;
}

.ui-accordion .ui-accordion-header {
    cursor: pointer;
    display: block;
    margin-top: 2px;
    padding: .5em .5em .5em .7em;
    position: relative;
    zoom: 1;
}

.ui-accordion .ui-accordion-icons {
    padding-left: 2.2em;
}

.ui-accordion .ui-accordion-noicons {
    padding-left: .7em;
}

.ui-accordion .ui-accordion-icons .ui-accordion-icons {
    padding-left: 2.2em;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    left: .5em;
    margin-top: -8px;
    position: absolute;
    top: 50%;
}

.ui-accordion .ui-accordion-content {
    border-top: 0;
    overflow: auto;
    padding: 1em 2.2em;
    zoom: 1;
}

.ui-autocomplete {
    cursor: default;
    left: 0;
    position: absolute;
    top: 0;
}

/* workarounds */
* html .ui-autocomplete {
    width: 1px;
}

/* without this, the menu expands to 100% in IE6 */
.ui-button {
    cursor: pointer;
    display: inline-block;
    margin-right: .1em;
    overflow: visible;
    padding: 0;
    position: relative;
    text-align: center;
    zoom: 1;
}

/* the overflow property removes extra width in IE */
.ui-button, .ui-button:link, .ui-button:visited, .ui-button:hover, .ui-button:active {
    text-decoration: none;
}

.ui-button-icon-only {
    width: 2.2em;
}

/* to make room for the icon, a width needs to be set here */
button.ui-button-icon-only {
    width: 2.4em;
}

/* button elements seem to need a little more width */
.ui-button-icons-only {
    width: 3.4em;
}

button.ui-button-icons-only {
    width: 3.7em;
}

/*button text element */
.ui-button .ui-button-text {
    display: block;
    line-height: 1.4;
}

.ui-button-text-only .ui-button-text {
    padding: .4em 1em;
}

.ui-button-icon-only .ui-button-text, .ui-button-icons-only .ui-button-text {
    padding: .4em;
    text-indent: -9999999px;
}

.ui-button-text-icon-primary .ui-button-text, .ui-button-text-icons .ui-button-text {
    padding: .4em 1em .4em 2.1em;
}

.ui-button-text-icon-secondary .ui-button-text, .ui-button-text-icons .ui-button-text {
    padding: .4em 2.1em .4em 1em;
}

.ui-button-text-icons .ui-button-text {
    padding-left: 2.1em;
    padding-right: 2.1em;
}

/* no icon support for input elements, provide padding by default */
input.ui-button {
    padding: .4em 1em;
}

/*button icon element(s) */
.ui-button-icon-only .ui-icon, .ui-button-text-icon-primary .ui-icon, .ui-button-text-icon-secondary .ui-icon, .ui-button-text-icons .ui-icon, .ui-button-icons-only .ui-icon {
    margin-top: -8px;
    position: absolute;
    top: 50%;
}

.ui-button-icon-only .ui-icon {
    left: 50%;
    margin-left: -8px;
}

.ui-button-text-icon-primary .ui-button-icon-primary, .ui-button-text-icons .ui-button-icon-primary, .ui-button-icons-only .ui-button-icon-primary {
    left: .5em;
}

.ui-button-text-icon-secondary .ui-button-icon-secondary, .ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary {
    right: .5em;
}

.ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary {
    right: .5em;
}

/*button sets*/
.ui-buttonset {
    margin-right: 7px;
}

.ui-buttonset .ui-button {
    margin-left: 0;
    margin-right: -.3em;
}

/* workarounds */
button.ui-button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* reset extra padding in Firefox */
.ui-datepicker {
    display: none;
    padding: .2em .2em 0;
    width: 17em;
}

.ui-datepicker .ui-datepicker-header {
    padding: .2em 0;
    position: relative;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    height: 1.8em;
    position: absolute;
    top: 2px;
    width: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
    top: 1px;
}

.ui-datepicker .ui-datepicker-prev {
    left: 2px;
}

.ui-datepicker .ui-datepicker-next {
    right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
    left: 1px;
}

.ui-datepicker .ui-datepicker-next-hover {
    right: 1px;
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
    display: block;
    left: 50%;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
}

.ui-datepicker .ui-datepicker-title {
    line-height: 1.8em;
    margin: 0 2.3em;
    text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month-year {
    width: 100%;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width: 49%;
}

.ui-datepicker table {
    border-collapse: collapse;
    font-size: .9em;
    margin: 0 0 .4em;
    width: 100%;
}

.ui-datepicker th {
    border: 0;
    font-weight: bold;
    padding: .7em .3em;
    text-align: center;
}

.ui-datepicker td {
    border: 0;
    padding: 1px;
}

.ui-datepicker td span, .ui-datepicker td a {
    display: block;
    padding: .2em;
    text-align: right;
    text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin: .7em 0 0 0;
    padding: 0 .2em;
}

.ui-datepicker .ui-datepicker-buttonpane button {
    cursor: pointer;
    float: right;
    margin: .5em .2em .4em;
    overflow: visible;
    padding: .2em .6em .3em .6em;
    width: auto;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
    width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
    float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
    margin: 0 auto .4em;
    width: 95%;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
    border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
    clear: left;
}

.ui-datepicker-row-break {
    clear: both;
    font-size: 0em;
    width: 100%;
}

/* RTL support */
.ui-datepicker-rtl {
    direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
    left: auto;
    right: 2px;
}

.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
    left: auto;
    right: 1px;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: right;
}

.ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
    border-left-width: 1px;
    border-right-width: 0;
}

.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 1px;
    border-right-width: 0;
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
    filter: mask(); /*must have*/
    height: 200px; /*must have*/
    left: -4px; /*must have*/
    position: absolute; /*must have*/
    top: -4px; /*must have*/
    width: 200px; /*must have*/
    z-index: -1; /*must have*/
}

.ui-dialog {
    left: 0;
    overflow: hidden;
    padding: .2em;
    position: absolute;
    top: 0;
    width: 300px;
}

.ui-dialog .ui-dialog-titlebar {
    padding: .4em 1em;
    position: relative;
}

.ui-dialog .ui-dialog-title {
    float: left;
    margin: .1em 16px .1em 0;
}

.ui-dialog .ui-dialog-titlebar-close {
    height: 18px;
    margin: -10px 0 0 0;
    padding: 1px;
    position: absolute;
    right: .3em;
    top: 50%;
    width: 19px;
}

.ui-dialog .ui-dialog-titlebar-close span {
    display: block;
    margin: 1px;
}

.ui-dialog .ui-dialog-titlebar-close:hover, .ui-dialog .ui-dialog-titlebar-close:focus {
    padding: 0;
}

.ui-dialog .ui-dialog-content {
    background: none;
    border: 0;
    overflow: auto;
    padding: .5em 1em;
    position: relative;
    zoom: 1;
}

.ui-dialog .ui-dialog-buttonpane {
    background-image: none;
    border-width: 1px 0 0 0;
    margin: .5em 0 0 0;
    padding: .3em 1em .5em .4em;
    text-align: left;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: right;
}

.ui-dialog .ui-dialog-buttonpane button {
    cursor: pointer;
    margin: .5em .4em .5em 0;
}

.ui-dialog .ui-resizable-se {
    bottom: 3px;
    height: 14px;
    right: 3px;
    width: 14px;
}

.ui-draggable .ui-dialog-titlebar {
    cursor: move;
}

.ui-menu {
    display: block;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 2px;
}

.ui-menu .ui-menu {
    margin-top: -3px;
    position: absolute;
}

.ui-menu .ui-menu-item {
    margin: 0;
    padding: 0;
    width: 100%;
    zoom: 1;
}

.ui-menu .ui-menu-divider {
    border-width: 1px 0 0 0;
    font-size: 0;
    height: 0;
    line-height: 0;
    margin: 5px -2px 5px -2px;
}

.ui-menu .ui-menu-item a {
    display: block;
    font-weight: normal;
    line-height: 1.5;
    padding: 2px .4em;
    text-decoration: none;
    zoom: 1;
}

.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
    font-weight: normal;
    margin: -1px;
}

.ui-menu .ui-state-disabled {
    font-weight: normal;
    line-height: 1.5;
    margin: .4em 0 .2em;
}

.ui-menu .ui-state-disabled a {
    cursor: default;
}

/* icon support */
.ui-menu-icons {
    position: relative;
}

.ui-menu-icons .ui-menu-item a {
    padding-left: 2em;
    position: relative;
}

/* left-aligned */
.ui-menu .ui-icon {
    left: .2em;
    position: absolute;
    top: .2em;
}

/* right-aligned */
.ui-menu .ui-menu-icon {
    float: right;
    position: static;
}

.ui-progressbar {
    height: 2em;
    overflow: hidden;
    text-align: left;
}

.ui-progressbar .ui-progressbar-value {
    height: 100%;
    margin: -1px;
}

.ui-slider {
    position: relative;
    text-align: left;
}

.ui-slider .ui-slider-handle {
    cursor: default;
    height: 1.2em;
    position: absolute;
    width: 1.2em;
    z-index: 2;
}

.ui-slider .ui-slider-range {
    background-position: 0 0;
    border: 0;
    display: block;
    font-size: .7em;
    position: absolute;
    z-index: 1;
}

.ui-slider-horizontal {
    height: .8em;
}

.ui-slider-horizontal .ui-slider-handle {
    margin-left: -.6em;
    top: -.3em;
}

.ui-slider-horizontal .ui-slider-range {
    height: 100%;
    top: 0;
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.ui-slider-vertical {
    height: 100px;
    width: .8em;
}

.ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-bottom: -.6em;
    margin-left: 0;
}

.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
    bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
    top: 0;
}

.ui-spinner {
    display: inline-block;
    overflow: hidden;
    padding: 0;
    position: relative;
    vertical-align: middle;
}

.ui-spinner-input {
    background: none;
    border: none;
    margin: .2em 0;
    margin-left: .4em;
    margin-right: 22px;
    padding: 0;
    vertical-align: middle;
}

.ui-spinner-button {
    cursor: default;
    display: block;
    font-size: .5em;
    height: 50%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 16px;
}

.ui-spinner a.ui-spinner-button {
    border-bottom: none;
    border-right: none;
    border-top: none;
}

/* more specificity required here to overide default borders */
.ui-spinner .ui-icon {
    left: 0;
    margin-top: -8px;
    position: absolute;
    top: 50%;
}

/* vertical centre icon */
.ui-spinner-up {
    top: 0;
}

.ui-spinner-down {
    bottom: 0;
}

/* TR overrides */
.ui-spinner .ui-icon-triangle-1-s {
    /* need to fix icons sprite */
    background-position: -65px -16px;
}

.ui-tabs {
    padding: .2em;
    position: relative;
    zoom: 1;
}

/* position: relative prevents IE scroll bug (element with position: relative inside container with overflow: auto appear as "fixed") */
.ui-tabs .ui-tabs-nav {
    margin: 0;
    padding: .2em .2em 0;
}

.ui-tabs .ui-tabs-nav li {
    border-bottom: 0;
    float: left;
    list-style: none;
    margin: 1px .2em 0 0;
    padding: 0;
    position: relative;
    top: 0;
    white-space: nowrap;
}

.ui-tabs .ui-tabs-nav li a {
    float: left;
    padding: .5em 1em;
    text-decoration: none;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
    margin-bottom: -1px;
    padding-bottom: 1px;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active a, .ui-tabs .ui-tabs-nav li.ui-state-disabled a, .ui-tabs .ui-tabs-nav li.ui-tabs-loading a {
    cursor: text;
}

.ui-tabs .ui-tabs-nav li a, .ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active a {
    cursor: pointer;
}

/* first selector in group seems obsolete, but required to overcome bug in Opera applying cursor: text overall if defined elsewhere... */
.ui-tabs .ui-tabs-panel {
    background: none;
    border-width: 0;
    display: block;
    padding: 1em 1.4em;
}

.ui-tooltip {
    -webkit-box-shadow: 0 0 5px #aaaaaa;
    box-shadow: 0 0 5px #aaaaaa;
    max-width: 300px;
    padding: 8px;
    position: absolute;
    z-index: 9999;
}

/* Fades and background-images don't work well together in IE6, drop the image */
* html .ui-tooltip {
    background-image: none;
}

body .ui-tooltip {
    border-width: 2px;
}

/* Component containers
----------------------------------*/
.ui-widget {
    font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
    font-size: 1.1em;
}

.ui-widget .ui-widget {
    font-size: 1em;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
    font-size: 1em;
}

.ui-widget-content {
    background: #f3f2ec url("/images/jquery_ui/ui-bg_highlight-soft_100_eeeeee_1x100.png") 50% top repeat-x;
    border: 1px solid #dddddd;
    color: #333333;
}

.ui-widget-content a {
    color: #333333;
}

.ui-widget-header {
    background: #1e6aaa;
    border: 1px solid #1e6aaa;
    color: #ffffff;
    font-weight: bold;
}

.ui-widget-header a {
    color: #ffffff;
}

/* Interaction states
----------------------------------*/
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    background: #f6f6f6 url("/images/jquery_ui/ui-bg_glass_100_f6f6f6_1x400.png") 50% 50% repeat-x;
    border: 1px solid #cccccc;
    color: #000000;
    font-weight: bold;
}

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
    color: #000000;
    text-decoration: none;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
    background: #fac613;
    border: 1px solid #fbcb09;
    color: #ffffff;
    font-weight: bold;
}

.ui-state-hover a, .ui-state-hover a:hover, .ui-state-hover a:link, .ui-state-hover a:visited {
    color: #ffffff;
    text-decoration: none;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    background: #1e6aaa;
    border: 1px solid #1e6aaa;
    color: #ffffff;
    font-weight: bold;
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
    color: #ffffff;
    text-decoration: none;
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    background: #1e6aaa;
    border: 1px solid #1e6aaa;
    color: #ffffff;
}

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
    color: #ffffff;
}

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
    background: #b81900 url("/images/jquery_ui/ui-bg_diagonals-thick_18_b81900_40x40.png") 50% 50% repeat;
    border: 1px solid #cd0a0a;
    color: #ffffff;
}

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
    color: #ffffff;
}

.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
    color: #ffffff;
}

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
    font-weight: bold;
}

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
    filter: Alpha(Opacity=70);
    font-weight: normal;
    opacity: .7;
}

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
    background-image: none;
    filter: Alpha(Opacity=35);
    opacity: .35;
}

.ui-state-disabled .ui-icon {
    filter: Alpha(Opacity=35);
}

/* For IE8 - See #6059 */

/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
    background-image: url("/images/jquery_ui/ui-icons_222222_256x240.png");
    height: 16px;
    width: 16px;
}

.ui-widget-content .ui-icon {
    background-image: url("/images/jquery_ui/ui-icons_222222_256x240.png");
}

.ui-widget-header .ui-icon {
    background-image: url("/images/jquery_ui/ui-icons_ffffff_256x240.png");
}

.ui-state-default .ui-icon {
    background-image: url("/images/jquery_ui/ui-icons_222222_256x240.png");
}

.ui-state-hover .ui-icon, .ui-state-focus .ui-icon {
    background-image: url("/images/jquery_ui/ui-icons_ffffff_256x240.png");
}

.ui-state-active .ui-icon {
    background-image: url("/images/jquery_ui/ui-icons_ffffff_256x240.png");
}

.ui-state-highlight .ui-icon {
    background-image: url("/images/jquery_ui/ui-icons_ffffff_256x240.png");
}

.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {
    background-image: url("/images/jquery_ui/ui-icons_ffd27a_256x240.png");
}

/* positioning */
.ui-icon-carat-1-n {
    background-position: 0 0;
}

.ui-icon-carat-1-ne {
    background-position: -16px 0;
}

.ui-icon-carat-1-e {
    background-position: -32px 0;
}

.ui-icon-carat-1-se {
    background-position: -48px 0;
}

.ui-icon-carat-1-s {
    background-position: -64px 0;
}

.ui-icon-carat-1-sw {
    background-position: -80px 0;
}

.ui-icon-carat-1-w {
    background-position: -96px 0;
}

.ui-icon-carat-1-nw {
    background-position: -112px 0;
}

.ui-icon-carat-2-n-s {
    background-position: -128px 0;
}

.ui-icon-carat-2-e-w {
    background-position: -144px 0;
}

.ui-icon-triangle-1-n {
    background-position: 0 -16px;
}

.ui-icon-triangle-1-ne {
    background-position: -16px -16px;
}

.ui-icon-triangle-1-e {
    background-position: -32px -16px;
}

.ui-icon-triangle-1-se {
    background-position: -48px -16px;
}

.ui-icon-triangle-1-s {
    background-position: -64px -16px;
}

.ui-icon-triangle-1-sw {
    background-position: -80px -16px;
}

.ui-icon-triangle-1-w {
    background-position: -96px -16px;
}

.ui-icon-triangle-1-nw {
    background-position: -112px -16px;
}

.ui-icon-triangle-2-n-s {
    background-position: -128px -16px;
}

.ui-icon-triangle-2-e-w {
    background-position: -144px -16px;
}

.ui-icon-arrow-1-n {
    background-position: 0 -32px;
}

.ui-icon-arrow-1-ne {
    background-position: -16px -32px;
}

.ui-icon-arrow-1-e {
    background-position: -32px -32px;
}

.ui-icon-arrow-1-se {
    background-position: -48px -32px;
}

.ui-icon-arrow-1-s {
    background-position: -64px -32px;
}

.ui-icon-arrow-1-sw {
    background-position: -80px -32px;
}

.ui-icon-arrow-1-w {
    background-position: -96px -32px;
}

.ui-icon-arrow-1-nw {
    background-position: -112px -32px;
}

.ui-icon-arrow-2-n-s {
    background-position: -128px -32px;
}

.ui-icon-arrow-2-ne-sw {
    background-position: -144px -32px;
}

.ui-icon-arrow-2-e-w {
    background-position: -160px -32px;
}

.ui-icon-arrow-2-se-nw {
    background-position: -176px -32px;
}

.ui-icon-arrowstop-1-n {
    background-position: -192px -32px;
}

.ui-icon-arrowstop-1-e {
    background-position: -208px -32px;
}

.ui-icon-arrowstop-1-s {
    background-position: -224px -32px;
}

.ui-icon-arrowstop-1-w {
    background-position: -240px -32px;
}

.ui-icon-arrowthick-1-n {
    background-position: 0 -48px;
}

.ui-icon-arrowthick-1-ne {
    background-position: -16px -48px;
}

.ui-icon-arrowthick-1-e {
    background-position: -32px -48px;
}

.ui-icon-arrowthick-1-se {
    background-position: -48px -48px;
}

.ui-icon-arrowthick-1-s {
    background-position: -64px -48px;
}

.ui-icon-arrowthick-1-sw {
    background-position: -80px -48px;
}

.ui-icon-arrowthick-1-w {
    background-position: -96px -48px;
}

.ui-icon-arrowthick-1-nw {
    background-position: -112px -48px;
}

.ui-icon-arrowthick-2-n-s {
    background-position: -128px -48px;
}

.ui-icon-arrowthick-2-ne-sw {
    background-position: -144px -48px;
}

.ui-icon-arrowthick-2-e-w {
    background-position: -160px -48px;
}

.ui-icon-arrowthick-2-se-nw {
    background-position: -176px -48px;
}

.ui-icon-arrowthickstop-1-n {
    background-position: -192px -48px;
}

.ui-icon-arrowthickstop-1-e {
    background-position: -208px -48px;
}

.ui-icon-arrowthickstop-1-s {
    background-position: -224px -48px;
}

.ui-icon-arrowthickstop-1-w {
    background-position: -240px -48px;
}

.ui-icon-arrowreturnthick-1-w {
    background-position: 0 -64px;
}

.ui-icon-arrowreturnthick-1-n {
    background-position: -16px -64px;
}

.ui-icon-arrowreturnthick-1-e {
    background-position: -32px -64px;
}

.ui-icon-arrowreturnthick-1-s {
    background-position: -48px -64px;
}

.ui-icon-arrowreturn-1-w {
    background-position: -64px -64px;
}

.ui-icon-arrowreturn-1-n {
    background-position: -80px -64px;
}

.ui-icon-arrowreturn-1-e {
    background-position: -96px -64px;
}

.ui-icon-arrowreturn-1-s {
    background-position: -112px -64px;
}

.ui-icon-arrowrefresh-1-w {
    background-position: -128px -64px;
}

.ui-icon-arrowrefresh-1-n {
    background-position: -144px -64px;
}

.ui-icon-arrowrefresh-1-e {
    background-position: -160px -64px;
}

.ui-icon-arrowrefresh-1-s {
    background-position: -176px -64px;
}

.ui-icon-arrow-4 {
    background-position: 0 -80px;
}

.ui-icon-arrow-4-diag {
    background-position: -16px -80px;
}

.ui-icon-extlink {
    background-position: -32px -80px;
}

.ui-icon-newwin {
    background-position: -48px -80px;
}

.ui-icon-refresh {
    background-position: -64px -80px;
}

.ui-icon-shuffle {
    background-position: -80px -80px;
}

.ui-icon-transfer-e-w {
    background-position: -96px -80px;
}

.ui-icon-transferthick-e-w {
    background-position: -112px -80px;
}

.ui-icon-folder-collapsed {
    background-position: 0 -96px;
}

.ui-icon-folder-open {
    background-position: -16px -96px;
}

.ui-icon-document {
    background-position: -32px -96px;
}

.ui-icon-document-b {
    background-position: -48px -96px;
}

.ui-icon-note {
    background-position: -64px -96px;
}

.ui-icon-mail-closed {
    background-position: -80px -96px;
}

.ui-icon-mail-open {
    background-position: -96px -96px;
}

.ui-icon-suitcase {
    background-position: -112px -96px;
}

.ui-icon-comment {
    background-position: -128px -96px;
}

.ui-icon-person {
    background-position: -144px -96px;
}

.ui-icon-print {
    background-position: -160px -96px;
}

.ui-icon-trash {
    background-position: -176px -96px;
}

.ui-icon-locked {
    background-position: -192px -96px;
}

.ui-icon-unlocked {
    background-position: -208px -96px;
}

.ui-icon-bookmark {
    background-position: -224px -96px;
}

.ui-icon-tag {
    background-position: -240px -96px;
}

.ui-icon-home {
    background-position: 0 -112px;
}

.ui-icon-flag {
    background-position: -16px -112px;
}

.ui-icon-calendar {
    background-position: -32px -112px;
}

.ui-icon-cart {
    background-position: -48px -112px;
}

.ui-icon-pencil {
    background-position: -64px -112px;
}

.ui-icon-clock {
    background-position: -80px -112px;
}

.ui-icon-disk {
    background-position: -96px -112px;
}

.ui-icon-calculator {
    background-position: -112px -112px;
}

.ui-icon-zoomin {
    background-position: -128px -112px;
}

.ui-icon-zoomout {
    background-position: -144px -112px;
}

.ui-icon-search {
    background-position: -160px -112px;
}

.ui-icon-wrench {
    background-position: -176px -112px;
}

.ui-icon-gear {
    background-position: -192px -112px;
}

.ui-icon-heart {
    background-position: -208px -112px;
}

.ui-icon-star {
    background-position: -224px -112px;
}

.ui-icon-link {
    background-position: -240px -112px;
}

.ui-icon-cancel {
    background-position: 0 -128px;
}

.ui-icon-plus {
    background-position: -16px -128px;
}

.ui-icon-plusthick {
    background-position: -32px -128px;
}

.ui-icon-minus {
    background-position: -48px -128px;
}

.ui-icon-minusthick {
    background-position: -64px -128px;
}

.ui-icon-close {
    background-position: -80px -128px;
}

.ui-icon-closethick {
    background-position: -96px -128px;
}

.ui-icon-key {
    background-position: -112px -128px;
}

.ui-icon-lightbulb {
    background-position: -128px -128px;
}

.ui-icon-scissors {
    background-position: -144px -128px;
}

.ui-icon-clipboard {
    background-position: -160px -128px;
}

.ui-icon-copy {
    background-position: -176px -128px;
}

.ui-icon-contact {
    background-position: -192px -128px;
}

.ui-icon-image {
    background-position: -208px -128px;
}

.ui-icon-video {
    background-position: -224px -128px;
}

.ui-icon-script {
    background-position: -240px -128px;
}

.ui-icon-alert {
    background-position: 0 -144px;
}

.ui-icon-info {
    background-position: -16px -144px;
}

.ui-icon-notice {
    background-position: -32px -144px;
}

.ui-icon-help {
    background-position: -48px -144px;
}

.ui-icon-check {
    background-position: -64px -144px;
}

.ui-icon-bullet {
    background-position: -80px -144px;
}

.ui-icon-radio-on {
    background-position: -96px -144px;
}

.ui-icon-radio-off {
    background-position: -112px -144px;
}

.ui-icon-pin-w {
    background-position: -128px -144px;
}

.ui-icon-pin-s {
    background-position: -144px -144px;
}

.ui-icon-play {
    background-position: 0 -160px;
}

.ui-icon-pause {
    background-position: -16px -160px;
}

.ui-icon-seek-next {
    background-position: -32px -160px;
}

.ui-icon-seek-prev {
    background-position: -48px -160px;
}

.ui-icon-seek-end {
    background-position: -64px -160px;
}

.ui-icon-seek-start {
    background-position: -80px -160px;
}

/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
    background-position: -80px -160px;
}

.ui-icon-stop {
    background-position: -96px -160px;
}

.ui-icon-eject {
    background-position: -112px -160px;
}

.ui-icon-volume-off {
    background-position: -128px -160px;
}

.ui-icon-volume-on {
    background-position: -144px -160px;
}

.ui-icon-power {
    background-position: 0 -176px;
}

.ui-icon-signal-diag {
    background-position: -16px -176px;
}

.ui-icon-signal {
    background-position: -32px -176px;
}

.ui-icon-battery-0 {
    background-position: -48px -176px;
}

.ui-icon-battery-1 {
    background-position: -64px -176px;
}

.ui-icon-battery-2 {
    background-position: -80px -176px;
}

.ui-icon-battery-3 {
    background-position: -96px -176px;
}

.ui-icon-circle-plus {
    background-position: 0 -192px;
}

.ui-icon-circle-minus {
    background-position: -16px -192px;
}

.ui-icon-circle-close {
    background-position: -32px -192px;
}

.ui-icon-circle-triangle-e {
    background-position: -48px -192px;
}

.ui-icon-circle-triangle-s {
    background-position: -64px -192px;
}

.ui-icon-circle-triangle-w {
    background-position: -80px -192px;
}

.ui-icon-circle-triangle-n {
    background-position: -96px -192px;
}

.ui-icon-circle-arrow-e {
    background-position: -112px -192px;
}

.ui-icon-circle-arrow-s {
    background-position: -128px -192px;
}

.ui-icon-circle-arrow-w {
    background-position: -144px -192px;
}

.ui-icon-circle-arrow-n {
    background-position: -160px -192px;
}

.ui-icon-circle-zoomin {
    background-position: -176px -192px;
}

.ui-icon-circle-zoomout {
    background-position: -192px -192px;
}

.ui-icon-circle-check {
    background-position: -208px -192px;
}

.ui-icon-circlesmall-plus {
    background-position: 0 -208px;
}

.ui-icon-circlesmall-minus {
    background-position: -16px -208px;
}

.ui-icon-circlesmall-close {
    background-position: -32px -208px;
}

.ui-icon-squaresmall-plus {
    background-position: -48px -208px;
}

.ui-icon-squaresmall-minus {
    background-position: -64px -208px;
}

.ui-icon-squaresmall-close {
    background-position: -80px -208px;
}

.ui-icon-grip-dotted-vertical {
    background-position: 0 -224px;
}

.ui-icon-grip-dotted-horizontal {
    background-position: -16px -224px;
}

.ui-icon-grip-solid-vertical {
    background-position: -32px -224px;
}

.ui-icon-grip-solid-horizontal {
    background-position: -48px -224px;
}

.ui-icon-gripsmall-diagonal-se {
    background-position: -64px -224px;
}

.ui-icon-grip-diagonal-se {
    background-position: -80px -224px;
}

/* Misc visuals
----------------------------------*/

/* Corner radius */
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    -moz-border-radius-topleft: 0;
    -khtml-border-top-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    -moz-border-radius-topright: 0;
    -khtml-border-top-right-radius: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    -khtml-border-bottom-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    -khtml-border-bottom-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
}

/* Overlays */
.ui-widget-overlay {
    background: #666666 url("/images/jquery_ui/ui-bg_diagonals-thick_20_666666_40x40.png") 50% 50% repeat;
    filter: Alpha(Opacity=50);
    opacity: .5;
}

.ui-widget-shadow {
    background: #000000;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    filter: Alpha(Opacity=20);
    margin: -5px 0 0 -5px;
    opacity: .2;
    padding: 5px;
}