.curated-search-filter {
    background-color: $navy;
    color: $white;
    display: block;
    padding: em(10);

    @include media-query(550px) {
        display: flex;
        justify-content: space-between;
    }

    &__sort {
        margin-bottom: 20px;
    }

    &__sort, &__search {
        display: block;

        @include media-query(550px) {
            margin: 0;
            width: 48%;
        }

        @include tablet {
            padding: 0 15px;
            width: 45%;
        }
    }

    &__sort-label, &__search-label {
        display: block;
        font-size: em(18, 16);
        margin-bottom: 5px;

        @include laptop {
            font-size: em(20, 16);
        }
    }

    &__sort-list {
        max-width: 100%;
        vertical-align: middle;
        width: 100%;
    }

    &__search-fields {
        align-items: stretch;
        display: flex;
        justify-content: flex-start;
    }

    &__search-field[type="search"], &__submit {
        margin: 0;
        vertical-align: middle;
    }

    &__submit {
        align-items: center;
        display: flex;
        font-size: em(13);
        min-width: 90px;
        padding: em(6, 13) em(5, 13);

        .icon {
            flex-basis: 18px;
        }
    }
}