// Layout related SASS.

.content {
    &__padded, &__wide, &__padded--inline, &__wide--inline {
        padding: em(26px) 3% em(100px) 3%;
        position: relative;

        @include tablet {
            padding: em(30px) 9% em(100px) 9%;
        }

        @include laptop {
            margin: 0 auto;
            padding: em(35px) 0 em(85px);
            width: em(980px);
        }

        @include desktop {
            margin: 0 auto;
            padding: em(50px) 0 em(85px);
            width: em(980px);
        }
    }

    &__wide {
        @include desktop {
            width: em(1185px);
        }
    }

    &__padded--inline, &__wide--inline {
        padding-bottom: 0;
    }

    &--padded {
        padding: 0 0 em(100px);
        width: auto;

        @include laptop {
            padding: 0 0 em(85px);
            width: auto;
        }
    }
}

.container-fluid, .container-fluid * {
    box-sizing: border-box;
}
