@font-face {
    font-family: "travicons";
    src: url("#{$webFontDirectory}/icons/travicons.ttf?dsfkck") format("truetype"),
    url("#{$webFontDirectory}/icons/travicons.woff?dsfkck") format("woff"),
    url("#{$webFontDirectory}/icons/travicons.svg?dsfkck#travicons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "travicons" !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: never;
    text-transform: none;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-weekender:before {
    content: "\e996";
}

.icon-road-trip-ok:before {
    content: "\e997";
}

.icon-next:before {
    content: "\e988";
}

.icon-pause:before {
    content: "\e989";
}

.icon-play:before {
    content: "\e98a";
}

.icon-previous:before {
    content: "\e98b";
}

.icon-repeat:before {
    content: "\e98c";
}

.icon-shuffle:before {
    content: "\e98d";
}

.icon-envelope-bold:before {
    content: "\e987";
}

.icon-compass:before {
    content: "\e986";
}

.icon-music-trail:before {
    content: "\e985";
}

.icon-star:before {
    content: "\e984";
}

.icon-binoculars-alt:before {
    content: "\e978";
}

.icon-church:before {
    content: "\e979";
}

.icon-cowboy-boot:before {
    content: "\e97a";
}

.icon-cowboy-hat:before {
    content: "\e97b";
}

.icon-guitar:before {
    content: "\e97c";
}

.icon-mandolin:before {
    content: "\e97d";
}

.icon-microphone:before {
    content: "\e97e";
}

.icon-record:before {
    content: "\e97f";
}

.icon-saxophone:before {
    content: "\e980";
}

.icon-sunglasses:before {
    content: "\e981";
}

.icon-theater-alt:before {
    content: "\e982";
}

.icon-trumpet-alt:before {
    content: "\e983";
}

.icon-radio-empty:before {
    content: "\e976";
}

.icon-radio-selected:before {
    content: "\e977";
}

.icon-feathers:before {
    content: "\e975";
}

.icon-clock:before {
    content: "\e974";
}

.icon-envelope-alt:before {
    content: "\e973";
}

.icon-way-finder-outline .path1:before {
    color: rgb(255, 255, 255) !important;
    content: "\e970";
}

.icon-way-finder-outline .path2:before {
    content: "\e971";
    margin-left: -1em;
}

.icon-way-finder-outline .path3:before {
    color: rgb(255, 255, 255) !important;
    content: "\e972";
    margin-left: -1em;
}

.icon-fishing-trail:before {
    content: "\e96f";
}

.icon-way-finder .path1:before {
    content: "\e96d";
}

.icon-way-finder .path2:before {
    color: rgb(170, 172, 172) !important;
    content: "\e96e";
    margin-left: -1em;
}

.icon-antlers:before {
    content: "\e969";
}

.icon-mug:before {
    content: "\e96a";
}

.icon-pizza:before {
    content: "\e96b";
}

.icon-chevron-down:before {
    content: "\e965";
}

.icon-chevron-left:before {
    content: "\e966";
}

.icon-chevron-right:before {
    content: "\e967";
}

.icon-chevron-up:before {
    content: "\e968";
}

.icon-menu:before {
    content: "\e964";
}

.icon-book-alt:before {
    content: "\e959";
}

.icon-checkmark:before {
    content: "\e95a";
}

.icon-close:before {
    content: "\e95b";
}

.icon-facebook:before {
    content: "\e95c";
}

.icon-instagram:before {
    content: "\e95e";
}

.icon-pinterest:before {
    content: "\e95f";
}

.icon-shopping-cart:before {
    content: "\e960";
}

.icon-trip-advisor:before {
    content: "\e961";
}

.icon-twitter:before {
    content: "\e962";
}

.icon-youtube:before {
    content: "\e963";
}

.icon-agritourism:before {
    content: "\e900";
}

.icon-archery:before {
    content: "\e901";
}

.icon-atv:before, .icon-atving:before {
    content: "\e902";
}

.icon-bags:before {
    content: "\e903";
}

.icon-basketball:before {
    content: "\e904";
}

.icon-bed:before {
    content: "\e905";
}

.icon-beer-mug:before {
    content: "\e906";
}

.icon-bell:before {
    content: "\e907";
}

.icon-biking:before {
    content: "\e908";
}

.icon-binoculars:before {
    content: "\e909";
}

.icon-boat:before, .icon-boating:before {
    content: "\e90a";
}

.icon-book:before {
    content: "\e90b";
}

.icon-boot:before, .icon-hiking:before {
    content: "\e90c";
}

.icon-buffalo:before, .icon-watchable_wildlife:before {
    content: "\e90d";
}

.icon-building:before {
    content: "\e90e";
}

.icon-bus:before {
    content: "\e90f";
}

.icon-cabin:before {
    content: "\e910";
}

.icon-calendar:before {
    content: "\e911";
}

.icon-camera:before {
    content: "\e912";
}

.icon-canoe:before, .icon-canoeing_rafting_kayaking:before {
    content: "\e913";
}

.icon-car:before {
    content: "\e914";
}

.icon-cupcake:before {
    content: "\e915";
}

.icon-cutlery:before {
    content: "\e916";
}

.icon-darts:before, .icon-game_room:before {
    content: "\e917";
}

.icon-disc-golf:before, .icon-disc_golf:before {
    content: "\e918";
}

.icon-dog:before {
    content: "\e919";
}

.icon-dollar:before {
    content: "\e91a";
}

.icon-elephant:before {
    content: "\e91b";
}

.icon-envelope:before {
    content: "\e91c";
}

.icon-family:before {
    content: "\e91d";
}

.icon-ferris-wheel:before {
    content: "\e91e";
}

.icon-fish:before, .icon-fishing:before {
    content: "\e91f";
}

.icon-flag:before {
    content: "\e920";
}

.icon-food-truck:before {
    content: "\e921";
}

.icon-garden:before {
    content: "\e922";
}

.icon-genealogy:before {
    content: "\e923";
}

.icon-ghost:before {
    content: "\e924";
}

.icon-golf:before, .icon-golf_course_on_site:before {
    content: "\e925";
}

.icon-grill:before {
    content: "\e926";
}

.icon-hamburger:before {
    content: "\e927";
}

.icon-horseback-riding:before, .icon-horseback_riding:before {
    content: "\e928";
}

.icon-id-card:before {
    content: "\e929";
}

.icon-building-alt:before {
    content: "\e92a";
}

.icon-key:before {
    content: "\e92b";
}

.icon-lamp:before {
    content: "\e92c";
}

.icon-leaf:before {
    content: "\e92d";
}

.icon-lodge-key:before {
    content: "\e92e";
}

.icon-lotus:before {
    content: "\e92f";
}

.icon-map-marker:before {
    content: "\e930";
}

.icon-map-marker-empty:before {
    content: "\e96c";
}

.icon-map:before {
    content: "\e931";
}

.icon-market-stand:before {
    content: "\e932";
}

.icon-martini-glass:before {
    content: "\e933";
}

.icon-motorcycle:before {
    content: "\e934";
}

.icon-museum:before {
    content: "\e935";
}

.icon-music:before {
    content: "\e936";
}

.icon-nature-center:before, .icon-nature_center:before {
    content: "\e937";
}

.icon-paint:before {
    content: "\e938";
}

.icon-painting:before {
    content: "\e939";
}

.icon-palette:before {
    content: "\e93a";
}

.icon-picnic-area:before {
    content: "\e93b";
}

.icon-poker-chip:before {
    content: "\e93c";
}

.icon-ranch:before {
    content: "\e93d";
}

.icon-rappelling:before, .icon-rock_climbing_rappelling:before {
    content: "\e93e";
}

.icon-reticle:before, .icon-hunting:before {
    content: "\e93f";
}

.icon-roadside-oddities:before {
    content: "\e940";
}

.icon-roller-coaster:before {
    content: "\e941";
}

.icon-romance:before {
    content: "\e942";
}

.icon-route-66:before {
    content: "\e943";
}

.icon-running:before {
    content: "\e944";
}

.icon-rv:before {
    content: "\e945";
}

.icon-search:before {
    content: "\e946";
}

.icon-snowflake:before {
    content: "\e947";
}

.icon-stage-coach:before {
    content: "\e948";
}

.icon-steak:before {
    content: "\e949";
}

.icon-sun:before {
    content: "\e94a";
}

.icon-swimming:before {
    content: "\e94b";
}

.icon-tennis:before, .icon-tennis_court:before {
    content: "\e94c";
}

.icon-tent:before {
    content: "\e94d";
}

.icon-theater:before {
    content: "\e94e";
}

.icon-tipi:before {
    content: "\e94f";
}

.icon-tree:before {
    content: "\e950";
}

.icon-trumpet:before {
    content: "\e951";
}

.icon-urban:before {
    content: "\e952";
}

.icon-volleyball:before {
    content: "\e953";
}

.icon-waterskiing:before, .icon-water_skiing:before {
    content: "\e954";
}

.icon-wedding:before {
    content: "\e955";
}

.icon-western:before {
    content: "\e956";
}

.icon-wine-glass:before {
    content: "\e957";
}

.icon-winery:before {
    content: "\e958";
}
