.music-trail__listing {
    .js-description-read-more {
        display: block;
    }

    @include mobile-only {
        display: flex;
        flex-direction: column-reverse;
    }

    @include tablet {
        .js-description {
            max-height: none !important;

            &:after {
                display: none;
            }
        }

        .js-description-read-more {
            display: none;
        }
    }

    &-image {
        margin-bottom: 25px;
    }

    &-gallery {
        position: absolute;
        top: -9999%;

        &-photo {
            cursor: pointer;
        }
    }

    &-album-gallery {
        position: absolute;
        top: -9999%;

        .ug-slider-control {
            z-index: 5;
        }

        &-photo {
            cursor: pointer;
        }
    }

    &-biography {
        blockquote {
            color: $music-trail-red;
            font-size: rem(28px);
            font-style: italic;
        }
    }

    .music-trail__genre-icon:not(:last-of-type) {
        margin-bottom: 10px;
    }
}