@import "modules_unite-gallery";
@import "modules_unite-gallery-default-theme";

.ug-lightbox {

    .ug-textpanel-title {
        @include font-montserrat("Bold");
        color: $white;
        font-size: em(18);
    }

    .ug-textpanel-description {
        @include font-montserrat("Regular");
        color: $off-white;
        font-size: em(16);

        @include tablet {
            font-size: em(18);
        }
    }

    .ug-textpanel-photo-credit {
        color: $gray;
        @include font-montserrat("Regular");
        font-size: em(16);
        font-style: italic;
        padding-top: em(20);
    }

    .ug-textpanel-album-tracks {
        text-align: left;

        ol {
            font-size: em(14);
            margin: 5px 0;
            padding-left: em(24, 14);

            @include tablet {
                font-size: em(16);
                padding-left: em(30);
            }
        }

        li {
            margin-bottom: 1px;
        }
    }
}

.article-media-player {
    margin: -75px auto auto;
    max-width: 1400px;
    padding: 5px;

    @include tablet {
        margin-top: 0;
        padding: 0 75px;
    }
}

.media-gallery-container {
    background: $beige url("/images/bxslider/bx_loader.gif") no-repeat center center;
    height: 450px;

    img {
        display: none;
    }
}

.media-gallery-container.media-gallery-bottom-spacing {
    margin-bottom: em(48px);
}

.media-gallery-container.ug-gallery-wrapper {
    background: none;
}