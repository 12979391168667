/** Confirmation Page Layout **/
.brochure-order-review {
    padding: 35px 0;

    @include tablet {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
    }

    &__item-image {
        flex: 1 1 auto;
        padding: 0 20px 15px;
        text-align: center;

        img {
            max-width: 150px;
            min-width: 75px;

            @include tablet {
                min-width: 100px;
            }
        }
    }

    &__item-content {
        flex: 5 1 auto;
        font-size: em(16px);
    }

    &__item-title {
        border-bottom: 1px solid $gray;
        color: $charcoalgray;
        font-weight: bold;
        margin: 0 0 15px;
    }

    &__item-description {
        margin: 0;
    }
}

.brochure-order-details {
    font-size: em(16px, 18px);
    margin-bottom: 10px;
    padding: 5px 0 0;

    ul {
        color: #666666;
        list-style-type: disc;
        padding-left: 25px;
    }

    .notice {
        color: $orange;
    }

    @include tablet {
        p {
            color: $charcoalgray;
            margin-bottom: 15px;
        }
    }
}

.brochure-order-address {
    color: $charcoalgray;
}

/** End Confirmation Page Layout **/