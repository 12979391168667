.whats-new__state-parks {
    &__header {
        color: $blue;
        font-size: rem(80);
        @include font-sucrose("Bold");
        line-height: rem(70);
        margin: 25px auto 0;
        text-transform: uppercase;
        width: 300px;
    }

    &__bordered-text {
        @include bordered-text($blue);
    }
}

.whats-new-block__state-parks {
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;

    &__background {
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: auto;
    }

    .featured-content {
        display: inline-block;
        margin-bottom: 25px;
        width: 300px;
    }
}

@media screen and (min-width: 400px) {
    .whats-new-block__state-parks {
        .featured-content {
            width: auto;
        }
    }
    .whats-new-block__state-parks:nth-child(even) {
        .featured-content {
            margin-left: 20%;
        }
    }
    .whats-new-block__state-parks:nth-child(odd) {
        .featured-content {
            margin-right: 20%;
        }
    }
}

@media screen and (min-width: 768px) {
    .whats-new__state-parks {
        &__header {
            margin: 50px auto 12px;
            padding: 0 350px 0 15px;
            width: 450px;
        }
    }

    .whats-new-block__state-parks {
        .featured-content {
            margin-bottom: 45px;
        }
    }
    .whats-new-block__state-parks:nth-child(even) {
        .featured-content {
            margin-left: 25%;
        }
    }
    .whats-new-block__state-parks:nth-child(odd) {
        .featured-content {
            margin-right: 25%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .whats-new__state-parks {
        &__header {
            margin: 50px auto -15px;
            padding: 0 550px 0 0;
            width: 450px;
        }
    }

    .whats-new-block__state-parks {
        position: absolute;

        .featured-content {
            margin: 0;
        }
    }

    .whats-new-block__state-parks:nth-child(odd) {
        top: 0px;

        .featured-content {
            margin-right: 0;
            padding-right: 512px;
            padding-top: 25px;
            width: 512px;
        }
    }
    .whats-new-block__state-parks:nth-child(even) {
        .featured-content {
            bottom: 0;
            left: 50%;
            margin-left: 0;
            position: absolute;
            width: 512px;
        }
    }
}

@media screen and (min-width: 1920px) {
    .whats-new__state-parks {
        &__header {
            margin: 50px auto -15px;
            padding-right: 950px;
            width: 450px;
        }
    }
    .whats-new-block__state-parks:nth-child(odd) {
        .featured-content {
            padding-right: 570px;
        }
    }
    .whats-new-block__state-parks:nth-child(even) {
        .featured-content {
            margin-left: 60px;
        }
    }
}