.instagram-gallery {
    background-color: #000000;

    img {
        vertical-align: middle;
        width: 100%;
    }

    .overlay {
        //position: relative;
        //width: 50%;
    }

    .square {
        @include aspect-ratio(1, 1)
    }

    .image {
        backface-visibility: hidden;
        background-position: center center;
        background-size: cover;
        display: block;
        height: auto;
        opacity: 1;
        transition: .3s ease;
        width: 100%;
    }

    .middle {
        background: url("/lib/unitegallery/images/icon-zoom32.png") no-repeat 0 0;
        height: 38px;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        transition: .3s ease;
        width: 38px;
    }

    .overlay:hover .image {
        cursor: pointer;
        opacity: 0.8;
    }

    .overlay:hover .middle {
        opacity: 1;
    }

    //.text {
    //  background-color: #4CAF50;
    //  color: white;
    //  font-size: 16px;
    //  padding: 16px 32px;
    //}
}

.instagram-modal {
    .modal-dialog {
        @media screen and (min-width: 768px) {
            width: 725px;
        }
        @media screen and (min-width: 990px) {
            width: 900px;
        }
    }

    .modal-body {
        padding-left: 0;
        padding-right: 0;
    }

    .modal-content {
        border-radius: 0;
    }

    &.fade.in {
        background-color: rgba(0, 46, 85, .8);
    }

    .image-image {
        img {
            display: block;
            height: auto;
            margin: 0 auto;
            max-width: 100%;
        }
    }

    h4 {
        @include font-montserrat("Bold");
        color: $gray;
        font-size: em(18px);
        margin-top: 5px;
        text-transform: uppercase;

        a {
            color: $gray;
        }
    }

    p {
        @include font-montserrat("Regular");
        color: $gray;
        font-size: em(15px);
        line-height: 1.4;
        margin-bottom: 30px;

        a {
            color: $gray;
        }
    }

    &__close {
        bottom: 0;
        float: right;
        margin: 0;
        //padding: 20px 50px;
        //font-size: em(22px);
        //text-transform: uppercase;
        position: relative;
    }

    &__description-icon {
        display: inline-block;
        float: left;
        margin-right: 10px;
    }

}




