.music-trail__trivia-overview {
    hr {
        border: 0;
        border-top: 1px solid $black;
        display: block;
        height: 1px;
        margin: 25px 0;
        padding: 0;
    }

    &-content {
        color: $music-trail-red;
        font-size: rem(18px);
        @include font-montserrat("SemiBold");
        text-align: center;
    }

    &-options {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-bottom: 25px;

        select {
            margin-bottom: 5px;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include tablet {
            flex-direction: row;

            select {
                margin-bottom: 0;
                margin-right: 5px;
                max-width: 290px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.music-trail__trivia {
    background-color: $white;
    color: $black;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 750px;
    padding: 15px;

    hr {
        border-bottom: none;
        border-left: none;
        border-right: none;
        border-top: 6px dotted $music-trail-gray;
        max-width: 500px;
        width: 100%;
    }

    &-header {
        align-items: center;
        display: flex;
        flex-direction: column;

        @include media-query(650px) {
            margin: 0 auto;
            max-width: 75%;
        }

        &-title {
            color: $music-trail-red;
            font-size: rem(21px);
            @include font-montserrat("Regular");
            text-align: center;
            text-transform: uppercase;
        }
    }

    &-body-content {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    &-body-header {
        @include font-sucrose("Bold");
        color: $music-trail-red;
        font-size: rem(24px);
        text-align: center;

        @include media-query(650px) {
            font-size: rem(28px);
        }

        &-alt {
            @include font-montserrat("Bold");
            color: $black;
            font-size: rem(24px);
            margin: 5px 0;
            text-align: center;
        }
    }

    &-body-text {
        @include font-montserrat("Regular");
        color: $music-trail-red;
        font-size: rem(19px);
        margin-bottom: 15px;
        text-align: center;

        @include media-query(650px) {
            font-size: rem(22px);
        }
    }

    &-radio-answer {
        @include font-montserrat("Regular");
        color: $black;
        font-size: rem(19px);
    }

    &-answer-options {
        margin-bottom: 15px;

        @include tablet {
            display: flex;
            flex-wrap: wrap;
            width: 90%;
        }

        @at-root .music-trail__listing.columns & {
            width: auto;
        }

        label {
            font-size: rem(22px);
            text-transform: none;
            vertical-align: center;
        }

        input[type="radio"] {
            display: none;
        }

        input[type="radio"] + label span.music-trail__trivia-radio {
            cursor: pointer;
            margin-right: 5px;

            &:before {
                color: $music-trail-red;
                content: "\e976";
                font-family: "travicons" !important;
                -webkit-font-smoothing: antialiased;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                line-height: 1;

                /* Better Font Rendering =========== */
                -moz-osx-font-smoothing: grayscale;
                speak: none;
                text-transform: none;
            }
        }

        input[type="radio"]:checked + label span.music-trail__trivia-radio {
            &:before {
                content: "\e977";
            }
        }
    }

    &-answer-option {
        margin-bottom: rem(16px);

        @include tablet {
            box-sizing: border-box;
            padding: 0 30px;
            width: 50%;
        }

        @at-root .music-trail__listing.columns & {
            padding: 0 10px;
            width: auto;
        }
    }

    &-share {
        .button {
            margin-top: 0;
        }

        .button-group {
            display: flex;
            justify-content: center;
        }
    }
}