.state-parks__volunteer {
    &-info {
        display: flex;
        flex-direction: column;

        > div:first-child {
            margin-bottom: rem(25px);
        }

        @include laptop {
            flex-direction: row;
            justify-content: flex-start;

            > div:first-child {
                margin-right: rem(25px);
            }
        }

        &-title {
            font-weight: 700;
            margin-bottom: 0;
        }

        &-details {
            @include mobile-only {
                flex-direction: column;
            }

            @include tablet-and-below {
                display: flex;
                flex-wrap: wrap;

                > div {
                    width: 50%;
                }
            }
        }
    }

    &-page {
        position: relative;
        $imageWidth: 320px;
        $imageHeight: 152px;
        $imgRatio: $imageHeight / $imageWidth;

        @at-root {
            h1.state-parks__volunteer-page-header-title {
                display: none;

                @media screen and (min-width: 800px) {
                    display: block;
                }
            }

            h2.state-parks__volunteer-page-header-title {
                display: block;

                @media screen and (min-width: 800px) {
                    display: none;
                }
            }
        }

        &-header-title {
            color: $blue;
            font-size: rem(44px); // Slightly smaller than PSD because the web font doesn't look as good when the letter spacing is reduced.
            line-height: em(60px, 70px);
            margin: 0;
            @include font-sucrose("Bold");
            padding: 25px 0;
            text-align: left;
            text-transform: uppercase;

            @media screen and (min-width: 500px) {
                font-size: em(48px);
            }

            @media screen and (min-width: 800px) {
                color: $white;
                font-size: rem(55px);
                line-height: em(70px, 80px);
                padding: 125px 0 50px 0; // Centered at 1024px, left and right padding no longer needs to match .content__padded
                text-align: center;
                text-shadow: 0 0 15px $black, 0 0 2px $black;

                span {
                    font-size: 3rem;
                }
            }

            @include laptop {
                padding: 200px 0 50px 0;
            }
        }

        &-results-header {
            h2 {
                font-size: em(40);
                margin-top: 0;
            }
        }

        &-results-count {
            border: 1px solid $beige;
            border-width: 1px 0;
            color: $gray;
            font-size: em(14);
            line-height: em(14);
            padding: 10px 0;
            text-transform: uppercase;
        }

        // 1024
        &-results-grid {
            margin-bottom: 10px;
        }
    }
}

.state-parks__volunteer-result {
    border-bottom: 1px solid $beige;
    font-size: rem(18px);
    margin: 0;
    padding: em(30px, 18px) 0;
    position: relative;

    &-image img {
        border: 5px solid $beige;
        box-shadow: 10px 17px 50px 0px rgba(75, 75, 75, 0.5);
        max-width: em(290px, 18px);
    }

    &-details {
        address {
            margin-bottom: em(10px, 18px);
        }
    }

    h2 {
        font-size: em(32px, 18px);
        font-weight: bold;
        @include font-montserrat("Bold");
        letter-spacing: .025em;
        line-height: em(30px, 32px);
        margin-bottom: em(10px, 32px);
        margin-top: em(20px, 32px);

        a {
            text-decoration: none;
        }
    }

    h3 {
        font-size: em(18px, 18px);
        margin-bottom: em(10px, 18px);
        @include font-montserrat("Bold");
        margin-top: 0;

        a {
            text-decoration: none;
        }
    }

    &-offer-date {
        @include font-montserrat("Regular");
        color: $blue;
        font-size: em(18px, 18px);
        text-transform: uppercase;
    }

    &-event-name {
        @include font-montserrat("Regular");
        color: $blue;
        font-size: em(18px, 18px);
        text-transform: none;
    }

    p {
        color: $gray;
        font-size: em(18);
        @include font-montserrat("Regular");
        margin-bottom: em(25px, 18px);
        margin-top: em(15px, 18px);
    }

    .button {
        margin: 0;
        padding-left: em(15px, 18px);
        padding-right: em(45px, 18px);
    }
}

.volunteer-search-filter {
    padding: em(30px, 18px) 0;

    @media screen and (min-width: 768px) {
        padding: em(50px, 18px) 0;
    }

    &__filter-heading {
        color: $charcoalgray;
        @include font-sucrose("Bold");
        font-size: em(20px);
        margin: 0 0 em(15px, 20px);
    }
}

@media screen and (min-width: 768px) {
    .state-parks__volunteer-result {
        @include clearfix();

        &-image {
            float: left;
            text-align: center;
            width: em(340px, 18px);

            img {
                max-width: em(310px, 18px);
            }
        }

        &-details {
            margin-left: em(340px, 18px);
        }

        h2 {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 1024px) {
    .state-parks__volunteer-result {
        padding: em(50px, 18px) 0;

        &-image {
            width: em(430px, 18px);

            img {
                max-width: em(390px, 18px);
            }
        }

        &-details {
            margin-left: em(430px, 18px);
            padding-left: em(20px, 18px);
        }
    }
}
