.image-upload {
    &__before {
        position: relative;
        text-align: center;

        input {
            background: red;
            display: block;
            height: 100%;
            margin: auto;
            opacity: 0;
            position: absolute;
            width: 100%;
            z-index: -1;

            &:hover {
                cursor: pointer;
            }

            // Put buffer file field on top of submitted one
            &:not([name]) {
                z-index: 10;
            }
        }

        .image-upload__icon {
            display: block;
            margin: auto;
            width: 150px;
        }
    }

    &__clear {
        background: none;
        border: none;
        color: #2d3748;
        cursor: pointer;
        font-size: 12px;
        position: absolute;
        right: 7px;
        top: 7px;

        &:hover {
            background: transparent;
            color: #2d3748;
        }
    }

    &__container {
        background: #f7fafc;
        border: 0.5px solid #a3a8b1;
        border-radius: 10px;
        height: 100%;
        padding: 30px;
        position: relative;
    }

    &__drop {
        background-color: #eff2ff;
        border: 3px dashed #a3a8b1;
        border-radius: 10px;
        box-sizing: border-box;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__error {
        color: red;
        font-size: 15px;
        text-align: center;
    }

    &__icon {
        circle, g, line, path, polyline {
            fill: none;
            stroke: #303c42;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
    }

    &__max {
        color: #959694;
        float: right;
        font-size: 12px;
        position: relative;
        z-index: 1;
    }

    &__preview {
        .image-upload__image-holder {
            background: #ffffff;
            border-radius: 10px;
            display: inline-block;
            height: 150px;
            margin: 5px 5px;
            position: relative;
            width: 150px;

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }

            .image-upload__image-delete {
                background: red;
                border-radius: 50%;
                color: #ffffff;
                height: 29px;
                position: absolute;
                right: 4px;
                top: 4px;
                width: 29px;

                .image-upload__icon {
                    height: 66%;
                    margin: 6px 8px;
                    width: 66%;
                }
            }

            .image-upload__image-delete:hover {
                cursor: pointer;
            }

            .image-upload__plus {
                background: #f7fafc;
                border: 1px dashed;
                border-radius: 50%;
                bottom: 43%;
                color: #2d3748;
                font-size: 21pt;
                height: 30px;
                line-height: 30px;
                position: absolute;
                right: -42px;
                text-align: center;
                width: 30px;
            }

            .image-upload__plus:hover {
                cursor: pointer;
            }
        }
    }
}