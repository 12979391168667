@media screen and (min-width: 550px) {
    .tourism-industry {
        &__background {

            position: relative;
            z-index: -1;

            &.forms {
                background: url("/images/topic_backgrounds/route-66.png") no-repeat left bottom;
                background-size: contain;
                bottom: 100px;
                margin-bottom: -225px;
                min-height: 500px;

                @include mobile-only() {
                    bottom: 225px;
                    margin-bottom: -275px;
                }
            }

            &.contacts {
                background: url("/images/topic_backgrounds/fishing.png") no-repeat right bottom;
                background-size: contain;
                bottom: 275px;

                margin-bottom: -275px;
                min-height: 533px;
            }

            &.research-and-reports {
                background: url("/images/topic_backgrounds/american-indian-culture.png") no-repeat left bottom;
                background-size: contain;
                bottom: 200px;
                margin-bottom: -200px;

                min-height: 400px;
                transform: scaleX(-1);

            }

            &.consumer-show-programs {
                background: url("/images/topic_backgrounds/american-indian-culture.png") no-repeat left bottom;
                background-size: contain;
                bottom: 150px;

                margin-bottom: -150px;
                min-height: 500px;

                @include mobile-only() {
                    bottom: 300px;
                    margin-bottom: -300px;
                }
            }
        }
    }
}