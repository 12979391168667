@include subnav(fishing-trail, $fishing-trail-red, $fishing-trail-blue, $fishing-trail-tan, $white, $fishing-trail-blue, $fishing-trail-blue);

.fishing-trail {

    background-color: $fishing-trail-blue;
    border-bottom: 5px solid $fishing-trail-red;

    &__container {
        background-color: $fishing-trail-tan;
    }

    &__background-wrap {
        overflow: hidden;
        position: relative;

        // Remove top margin from intro paragraph.
        > div.content__padded {

            h2 {
                color: $fishing-trail-blue;
            }

            p:first-child {
                margin-top: 0;
            }
        }

        .readmore__description::after {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $fishing-trail-tan 100%);
        }

        button {
            background-color: $fishing-trail-blue;
        }
    }

    &__background {
        $tablet-percent: 0.6;
        $laptop-percent: 0.4;
        $laptop-desktop-percent: 0.2;

        $top: 104px;
        $max-height-00: 514px;
        $tablet-max-height-00: round(abs(($max-height-00 * $tablet-percent) - $max-height-00));
        $laptop-max-height-00: round(abs(($max-height-00 * $laptop-percent) - $max-height-00));
        $laptop-desktop-max-height-00: round(abs(($max-height-00 * $laptop-desktop-percent) - $max-height-00));

        .no-webp &--00, .no-js &--00 {
            background: url("/images/fishing-trail/backgrounds/background-00.jpg") no-repeat left;
        }

        .webp &--00 {
            background: url("/images/fishing-trail/backgrounds/background-00.webp") no-repeat left;
        }

        &--00 {
            background-size: contain;
            display: none;
            position: absolute;
            z-index: 0;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-00;
                top: $top;
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-00;
                top: 0;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
            }

            @include desktop {
                max-height: $max-height-00;
            }
        }

        .no-webp &--01, .no-js &--01 {
            background: url("/images/fishing-trail/backgrounds/background-01.jpg") no-repeat right;
        }

        .webp &--01 {
            background: url("/images/fishing-trail/backgrounds/background-01.webp") no-repeat right;
        }

        &--01 {
            background-size: contain;
            display: none;
            position: absolute;
            z-index: 0;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-00;
                top: $top + ($tablet-max-height-00 * 0.75);
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-00;
                top: $laptop-max-height-00 * 0.75;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
                top: $laptop-desktop-max-height-00 * 0.75;
            }

            @include desktop {
                max-height: $max-height-00;
                top: $max-height-00 * 0.75;
            }
        }

        .no-webp &--02, .no-js &--02 {
            background: url("/images/fishing-trail/backgrounds/background-02.jpg") no-repeat left;
        }

        .webp &--02 {
            background: url("/images/fishing-trail/backgrounds/background-02.webp") no-repeat left;
        }

        &--02 {
            background-size: contain;
            display: none;
            position: absolute;
            z-index: 0;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-00;
                top: $top + ($tablet-max-height-00 * 1.75);
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-00;
                top: $laptop-max-height-00 * 1.75;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
                top: $laptop-desktop-max-height-00 * 1.75;
            }

            @include desktop {
                max-height: $max-height-00;
                top: $max-height-00 * 1.75;
            }
        }

        .no-webp &--03, .no-js &--03 {
            background: url("/images/fishing-trail/backgrounds/background-03.jpg") no-repeat right;
        }

        .webp &--03 {
            background: url("/images/fishing-trail/backgrounds/background-03.webp") no-repeat right;
        }

        &--03 {
            background-size: contain;
            display: none;
            position: absolute;
            z-index: 0;

            @include tablet {
                display: block;
                height: 100%;
                max-height: $tablet-max-height-00;
                top: $top + ($tablet-max-height-00 * 2.75);
                width: 100%;
            }

            @include laptop {
                max-height: $laptop-max-height-00;
                top: $laptop-max-height-00 * 2.75;
            }

            @include media-query(1366px) {
                max-height: $laptop-desktop-max-height-00;
                top: $laptop-desktop-max-height-00 * 2.75;
            }

            @include desktop {
                max-height: $max-height-00;
                top: $max-height-00 * 2.75;
            }
        }

        &__bordered-text {
            @include bordered-text($blue);

            @media screen and (min-width: 800px) {
                @include bordered-text($white);
            }
        }
    }

    &__line-divider {
        border-top-color: $fishing-trail-red;
    }

    &__grand-slam-logo {
        display: block;
        height: 250px;
        margin: 0 auto;

        @include tablet {
            float: left;
            height: 275px;
            margin: 0 0 25px;
            padding-top: 10px;
        }
    }

    &__fish-upload {
        margin-bottom: 35px;
    }

    &__fish-label {
        font-size: 1.8rem;
        vertical-align: center;

        > img {
            margin-bottom: 10px;
            margin-right: 10px;
            max-width: 115px;
            vertical-align: middle;
        }
    }

    &__fish-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 25px;

        @include mobile-only {
            flex-direction: column;
        }

        @include tablet {
            div {
                width: 32%;
            }
        }

    }
}

.button__fishing-trail {
    background-color: $fishing-trail-red !important;

    &:hover {
        background-color: $fishing-trail-red !important;
    }
}
