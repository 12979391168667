
.fade {
    opacity: 0;
    -o-transition: opacity 0.15s linear;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

.fade.in {
    opacity: 1;
}

.collapse {
    display: none;
}

.collapse.in {
    display: block;
}

tr.collapse.in {
    display: table-row;
}

tbody.collapse.in {
    display: table-row-group;
}

.collapsing {
    height: 0;
    overflow: hidden;
    position: relative;
    -o-transition-duration: 0.35s;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -o-transition-property: height, visibility;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -o-transition-timing-function: ease;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.modal-open {
    overflow: hidden;
}

.modal {
    bottom: 0;
    display: none;
    left: 0;
    outline: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1050;
}

.modal.fade .modal-dialog {
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -o-transition: -o-transform 0.3s ease-out;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    margin: 10px;
    position: relative;
    width: auto;
}

.modal-content {
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    outline: 0;
    position: relative;
}

.modal-backdrop {
    background-color: #000000;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1040;
}

.modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0;
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.modal-header {
    border-bottom: 1px solid #e5e5e5;
    padding: 15px;
}

.modal-header .close {
    margin-top: -2px;
}

.modal-title {
    line-height: 1.42857143;
    margin: 0;
}

.modal-body {
    padding: 15px;
    position: relative;
}

.modal-footer {
    border-top: 1px solid #e5e5e5;
    padding: 15px;
    text-align: right;
}

.modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
    margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
    margin-left: 0;
}

.modal-scrollbar-measure {
    height: 50px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
    width: 50px;
}

@media (min-width: 768px) {
    .modal-dialog {
        margin: 30px auto;
        width: 600px;
    }
    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
    .modal-sm {
        width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}

.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
    content: " ";
    display: table;
}

.modal-header:after,
.modal-footer:after {
    clear: both;
}