@include subnav(state-park);

.state-park-listing {
    .state-park-menu__list-item:nth-last-child(2) {
        border-right: none;
    }
}

.state-park-menu__list-item--icon {

    &:hover {
        a {
            padding-top: 4px;
        }
    }

    a {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 9px 0;
    }

    .icon {
        margin-right: 10px;
    }
}