.detailed-graph {
    h5 {
        margin: auto auto;
        padding: 10px;
    }

    table {
        border: 1px solid black;
        border-collapse: collapse;
        margin: 0 auto;
    }

    tr, td {
        border: 1px solid black;
        line-height: 15px;
        padding: 5px 10px;
    }

    .even {
        background-color: #edede8;
    }

    .odd {
        background-color: #ffffff;
    }

    .center {
        text-align: center;
    }

}
