/*
Example Use:

h1 {
    @include font-sucrose('BoldTwo');
}
*/

@mixin font-sucrose($variation) {
    font: {
        family: "Sucrose-#{$variation}", Impact, sans-serif;
        style: normal;
        weight: normal;
    }
}

/*
Available Styles

Regular,
Bold, BoldOne, BoldTwo, BoldThree, BoldFour,
One, Two, Three, Four,
SlantRegular,
SlantBold, SlantBoldOne, SlantBoldTwo, SlantBoldThree, SlantBoldFour,
SlantOne, SlantTwo, SlantThree, SlantFour
*/

$styles: "Bold";
$directory: "#{$webFontDirectory}/sucrose";

@each $style in $styles {
    @font-face {
        font-family: "Sucrose-#{$style}";
        font-display: swap; /* Read next point */
        unicode-range: U+000-5FF; /* Download only latin glyphs */
        src: local("Sucrose-#{$style}"), url("#{$directory}/Sucrose-#{$style}.woff2") format("woff2"), url("#{$directory}/Sucrose-#{$style}.woff") format("woff");
    }
}
