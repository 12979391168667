.state-parks-list {
    padding-bottom: em(15px);

    &__grid {
        @include font-montserrat("Regular");
        color: $gray;
        display: none;
        flex-wrap: wrap;
        font-size: rem(14px);
        gap: 1%;
        list-style: none;
        margin: 0;
        padding: 0;

        @include media-query(600px) {
            display: flex;
        }

        li {
            background-color: $lightbeige;
            box-sizing: border-box;
            flex-basis: 100%;
            margin: 0;
            padding: 0;

            @include media-query(600px) {
                flex-basis: 49%;

                &:nth-child(4n), &:nth-child(4n-1) {
                    background-color: $white;
                }

                &:nth-child(4n-2), &:nth-child(4n-3) {
                    background-color: $lightbeige;
                }
            }

            @include media-query(800px) {
                flex-basis: 32%;

                &:nth-child(6n), &:nth-child(6n-1), &:nth-child(6n-2) {
                    background-color: $white;
                }

                &:nth-child(6n-3), &:nth-child(6n-4), &:nth-child(6n-5) {
                    background-color: $lightbeige;
                }
            }

            @include laptop {
                flex-basis: 24%;

                &:nth-child(8n), &:nth-child(8n-1), &:nth-child(8n-2), &:nth-child(8n-3) {
                    background-color: $white;
                }

                &:nth-child(8n-4), &:nth-child(8n-5), &:nth-child(8n-6), &:nth-child(8n-7) {
                    background-color: $lightbeige;
                }
            }

            a {
                display: block;
                padding: em(10px, 14px);
                white-space: nowrap;
            }
        }
    }

    &__dropdown {
        margin-bottom: em(15px);

        @include media-query(600px) {
            display: none;
        }
    }

    &__submit {
        font-size: 14px;
        margin: 0;
        padding-left: em(10px, 14px);
        padding-right: em(31px, 14px);

        @include media-query(600px) {
            display: none;
        }
    }
}

.state-parks-map {
    height: 350px;
    margin: 25px 0;
    width: 100%;

    @include tablet {
        height: 400px;
    }

    &__marker-title {
        @include font-montserrat("SemiBold");
        display: block;
        font-size: 18px;
    }
}