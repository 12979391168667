.state-parks__bookings {

    &-types {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &-item {
        margin-bottom: 60px;
        width: 100%;

        @media screen and (min-width: 768px) {
            width: 50%;
        }
    }

    &-item-image {
        border: 5px solid $beige;
        box-shadow: 10px 17px 50px 0 rgba(75, 75, 75, 0.5);
        margin-bottom: 10px;
    }

    &-title {
        font-size: rem(28px);
        margin-bottom: 0;
        margin-top: 5px;
        text-align: center;

        a {
            @include font-sucrose("Bold");
            color: $blue;
            text-align: center;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}