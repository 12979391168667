.whats-new-blocks {
    &__background {
        display: none;
    }
}

@media screen and (min-width: 550px) {
    .whats-new-blocks {
        &__background {
            display: block;

            &.western-heritage {
                background: no-repeat center top;
                background-size: 100% 576px;
                left: 40%;
                margin-left: -770px;
                min-height: 576px;
                min-width: 1920px;
                top: -250px;
                @media (min-width: 1024px) {
                    top: 0;
                }
            }

            &.fishing {
                background: no-repeat right top;
                margin-right: -200px;
                min-height: 575px;
                min-width: 1133px;
                right: 15%;
                top: 0;
                @media (min-width: 1024px) {
                    margin-right: -290px;
                    top: 331px;
                }
            }

            &.route-66 {
                background: no-repeat left top;
                left: 25%;
                margin-left: -290px;
                min-height: 573px;
                min-width: 1058px;
                top: 470px;
                @media (min-width: 1024px) {
                    margin-left: -480px;
                    top: 536px;
                }
            }

            &.urban {
                background: no-repeat right top;
                margin-right: -260px;
                min-height: 688px;
                min-width: 1406px;
                right: 10%;
                top: 780px;
                @media (min-width: 1024px) {
                    margin-right: 0;
                    right: 0;
                    top: 838px;

                }
            }

            &.american-indian-culture {
                background: no-repeat left top;
                left: 15%;
                margin-left: -340px;
                min-height: 609px;
                min-width: 1788px;
                top: 1343px;
                @media (min-width: 1024px) {
                    top: 1065px;
                }
            }

            &.museum {
                background: no-repeat right bottom;
                bottom: 0;
                display: none;
                margin-right: -600px;
                min-height: 429px;
                min-width: 1592px;
                right: 15%;
                @media (min-width: 1024px) {
                    display: block;
                    margin-right: -295px;
                }
            }

            &.black-mesa {
                background: url("/images/topic_backgrounds/black-mesa.png") no-repeat left;
                max-height: 433px;
                min-width: 1310px;
            }

            &.native-american {
                background: url("/images/topic_backgrounds/native-american.png") no-repeat left;
                max-height: 538px;
                min-width: 1318px;
            }

            &.robbers-cave {
                background: url("/images/topic_backgrounds/robbers-cave.png") no-repeat right;
                max-height: 348px;
                min-width: 1382px;
            }

            &.sun {
                background: url("/images/topic_backgrounds/sun.png") no-repeat left;
                max-height: 567px;
                min-width: 1545px;
            }

            &.light-house {
                background: url("/images/topic_backgrounds/light-house.png") no-repeat right;
                max-height: 369px;
                min-width: 1440px;
            }
        }
    }
}

@media screen and (min-width: 1920px) {

    .whats-new-blocks {
        &__background:nth-child(4n+3) {
            left: 0;
            margin-left: 0;
        }

        &__background:nth-child(4n+1) {
            margin-right: 0;
            right: 0;
        }
    }
}