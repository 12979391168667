.verify-address-modal {
    padding: 20px;

    &__title {
        margin-top: 0;
    }

    &__description {
        margin-bottom: 20px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @include laptop {
            flex-direction: row;
            margin-bottom: 40px;
        }
    }

    &__address {
        &-content {
            margin-bottom: 40px;
        }

        &-block {
            font-weight: bold;
            margin: 20px 0;
        }
    }
}