.listing {
    &__amenities {
        &-group {

            margin-bottom: 15px;

            @include tablet {
                @include grid(2, 15px);
            }

            &--open {
                .listing__amenities-content {
                    display: block;
                }

                .listing__amenities-button {
                    &:after {
                        content: "-"
                    }
                }
            }
        }

        &-content {
            @include clearfix;
            border: 1px solid $beige;
            clear: both;
            margin-bottom: 15px;
            padding: 10px;

            &-title {
                border-bottom: 1px solid $beige;
                @include font-montserrat("SemiBold");
                margin-top: 0;
            }

            &-list {
                list-style: square;
                padding-left: 1em;
                @include tablet {
                    column-gap: 1em;
                    columns: 2;
                }
            }

            &-list-item {
                margin-left: 1em;
            }
        }

        &-button {
            @extend .button-call-to-action;
            margin: 0;
            width: 100%;

            &:after {
                content: "+";
                padding: 0 1em;
                transform: translateY(-50%);
                width: 48px;
            }
        }
    }
}