.separator {
    $sep-class: ".beauty-and-stories";

    &__icon {
        &#{$sep-class} {
            display: none;
        }
    }

    &__background {
        &#{$sep-class} {
            $width: 1920px;
            $height: 600px;

            background: no-repeat top left;
            display: block;
            height: $height;
            margin-left: -#{$width/2};
            margin-top: -#{$height/2};
            overflow: hidden;
            width: $width;
        }
    }

    &__quote-block {
        &#{$sep-class} {
            max-width: 335px;
        }
    }
}

@media screen and (min-width: 768px) {
    .separator {
        $sep-class: ".beauty-and-stories";

        &__icon {
            &#{$sep-class} {
                display: inline-block;
                width: 355px;
            }
        }
    }
}
