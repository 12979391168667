$widthLaptop: 1077px;
$widthDesktop: 1368px;

.header {
    box-shadow: 0 0 16px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    @include laptop {
        box-shadow: 0 0 46px;
    }

    &__warning {
        align-items: center;
        background-color: darken($orange, 4%);
        box-sizing: border-box;
        color: $white;
        display: flex;
        flex-direction: column;
        padding: 10px 5px;
        position: relative;
        z-index: 10;

        @include tablet {
            padding: 15px 0 15px 10px;
        }

        @include laptop {
            border-bottom: 4px solid $yellow-orange;
            justify-content: center;
            margin-top: 0;
        }

        p {
            box-sizing: border-box;
            font-size: em(19px, 16px);
            line-height: 24px;
            margin-bottom: 0;
            margin-top: 5px;
            padding-right: 24px;
            position: relative;
            width: 100%;

            &:first-child {
                margin-top: 0;
            }

            @include tablet {
                margin-top: 15px;
            }

            @include laptop {
                width: 1024px;
            }
        }

        a {
            color: $white;
        }

        &-close {
            font-size: 22px;
            line-height: 22px;
            position: absolute;
            right: 6px;
            text-decoration: none;
            top: 6px;

            &:after {
                content: "\e95b";
                font-family: "travicons";
            }
        }
    }

    &__top {
        background-color: $blue;
        margin-top: 0;

        &-wrap {
            display: flex;
            margin: 0 auto;

            @include laptop {
                max-width: $widthLaptop;
            }

            @include desktop {
                max-width: $widthDesktop;
            }
        }

        &-left, &-right, &-center {
            display: flex;
            flex: 1;
            flex-direction: row;

            @include laptop {
                padding: 19px 0 15px;
            }

            @include desktop {
                padding: 37px 0 16px;
            }

            a {
                align-items: center;
                color: $white;
                display: flex;
                text-decoration: none;
                text-transform: uppercase;
                white-space: nowrap;
            }
        }

        &-left, &-right {
            display: none;
            @include laptop {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }
        }

        &-center {
            justify-content: center;
            position: relative;
        }
    }

    &__main-text {
        @include font-sucrose("Bold");
        @include laptop {
            font-size: 26px;
            line-height: 26px;
        }
    }

    &__sub-text {
        @include laptop {
            display: flex;
            font-size: 50px;
            margin-right: 10px;
        }
    }

    $flagPadding: 5px;
    $flagBorder: 4px;

    $flagMobileWidth: 86px;
    $flagTabletWidth: 144px;
    $flagLaptopWidth: 187px;
    $flagDesktopWidth: 238px;

    $flagMobileHeight: 52px;
    $flagLaptopHeight: 144px;
    $flagDesktopHeight: 170px;

    &__flag {
        background-color: $white;
        border: $flagBorder solid $yellow-orange;
        border-top: 0;
        display: flex;
        height: $flagMobileHeight;
        justify-content: center;
        padding: $flagPadding;
        position: absolute;
        top: 0;
        width: $flagMobileWidth;
        z-index: 11;

        @include tablet {
            height: $flagMobileHeight;
            width: $flagTabletWidth;
        }

        @include laptop {
            height: $flagLaptopHeight;
            padding: 0;
            top: -88px;
            width: $flagLaptopWidth;
        }

        @include desktop {
            height: $flagDesktopHeight;
            top: -107px;
            width: $flagDesktopWidth;
        }

        img {
            height: 50px;
            z-index: 1001;

            @include tablet {
                height: 62px;
            }

            @include laptop {
                height: $flagLaptopHeight;
            }

            @include desktop {
                height: $flagDesktopHeight;
            }
        }

        &::before {
            border-bottom: 0 solid transparent;
            border-left: ($flagMobileWidth / 2) + ($flagPadding + $flagBorder) solid transparent;
            border-right: ($flagMobileWidth / 2) + ($flagPadding + $flagBorder) solid transparent;
            border-top: 16px solid $yellow-orange;
            content: "";
            display: block;
            height: 0;
            left: -$flagBorder;
            position: absolute;
            right: 0;
            top: 66px;
            width: 0;

            @include tablet {
                border-left: ($flagTabletWidth / 2) + ($flagPadding + $flagBorder) solid transparent;
                border-right: ($flagTabletWidth / 2) + ($flagPadding + $flagBorder) solid transparent;
                border-top: 21px solid $yellow-orange;
            }

            @include laptop {
                border-left: ($flagLaptopWidth / 2) + $flagBorder solid transparent;
                border-right: ($flagLaptopWidth / 2) + $flagBorder solid transparent;
                border-top: 32px solid $yellow-orange;
                top: $flagLaptopHeight + $flagBorder;
            }

            @include desktop {
                border-left: ($flagDesktopWidth / 2) + $flagBorder solid transparent;
                border-right: ($flagDesktopWidth / 2) + $flagBorder solid transparent;
                border-top: 46px solid $yellow-orange;
                top: $flagDesktopHeight + $flagBorder;
            }
        }

        &::after {
            border-bottom: 0 solid transparent;
            border-left: ($flagMobileWidth / 2) + $flagPadding solid transparent;
            border-right: ($flagMobileWidth / 2) + $flagPadding solid transparent;
            border-top: 15px solid $white;
            content: "";
            display: block;
            height: 0;
            left: 0;
            position: absolute;
            top: 100%;
            width: 0;

            @include tablet {
                border-left: ($flagTabletWidth / 2) + $flagPadding solid transparent;
                border-right: ($flagTabletWidth / 2) + $flagPadding solid transparent;
                border-top: 20px solid $white;
            }

            @include laptop {
                border-left: ($flagLaptopWidth / 2) solid transparent;
                border-right: ($flagLaptopWidth / 2) solid transparent;
                border-top: 30px solid $white;
            }

            @include desktop {
                border-left: ($flagDesktopWidth / 2) solid transparent;
                border-right: ($flagDesktopWidth / 2) solid transparent;
                border-top: 44px solid $white;
            }
        }
    }

    &__menu-button {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
        text-decoration: none;

        @include laptop {
            display: none !important;
        }

        &-label {
            @include font-montserrat("Bold");
            color: $white;
            cursor: pointer;
            display: inline-block;
            font-size: em(12);
            text-align: center;

            @include tablet {
                font-size: em(17);
            }
        }

        &-icon {
            display: block;
            font-size: em(40);

            @include tablet {
                font-size: em(30);
            }
        }

        &--center {
            // This is for flexbox layout. This can/will be removed once we move away from the centered logo design.
        }

        &--brochures, &--newsletters {
            display: none;

            @include media-query(500px) {
                display: flex;
            }
        }
    }

    input:checked ~ .header__nav-container {
        // h-pos y-pos blur spread color, The spread covers the screen and the y-pos corrects the downward growth of the spread
        box-shadow: 0 -2048px 0 2048px rgba(0, 46, 85, .90), 0 0 28px 7px rgba(75, 75, 75, 1);
        left: 0;

        @include laptop {
            box-shadow: none;
        }
    }

    &__sub-menu-toggle:checked + &__nav-sub-container {
        left: 0;
    }

    &__nav {
        background-color: $navy;
        border-top: 4px solid $yellow-orange;
        display: flex;
        flex: 1;
        height: 58px;
        justify-content: space-evenly;
        position: relative;
        text-transform: uppercase;

        @include laptop {
            height: 56px;
        }

        @include desktop {
            height: 63px;
        }

        input[type=checkbox] {
            display: none;
        }

        &-container, &-sub-container {
            @include font-montserrat("SemiBold");
            background-color: $navy;
            border-right: 4px solid $yellow-orange;
            display: block;
            font-size: 1rem;
            // Size of next nav break point to reduce visible unintended animation
            left: -768px;
            margin: 0 0 0 -4px;
            padding: 0;
            position: absolute;
            top: -4px;
            transition-duration: .3s;
            transition-property: left;
            transition-timing-function: ease-in-out;
            width: 90%;
            z-index: 100;

            @include tablet {
                // Size of next nav break point to reduce visible unintended animation
                left: -1024px;
                width: 75%;
            }

            @include laptop {
                @include font-montserrat("SemiBold");
                background-color: transparent;
                border-right: none;
                display: block;
                margin: 0 auto;
                min-height: 0;
                padding: 0;
                position: static;
                transition-duration: 0s, 0s;
                width: $widthLaptop;
                z-index: auto;
            }

            @include desktop {
                width: $widthDesktop;
            }
        }

        &-container {
            min-height: calc(100% + 4px);

            @include laptop {
                display: flex;
                height: auto;
                min-height: auto;
            }
        }

        &-sub-container {
            bottom: 0;
            margin: 0;
            top: 52px;
            width: 100%;
            z-index: 110;

            @include tablet {
                top: 70px;
            }

            @include laptop {
                display: none;
            }
        }

        &--center {
            display: none;

            @include laptop {
                display: flex;
                flex: 2;
                height: 100%;
            }
        }

        &-close-button {
            align-items: center;
            background-color: #1e6aaa;
            border-bottom: 1px solid $white;
            display: flex;
            justify-content: space-between;
            margin: 0 -4px 0;
            padding: 8px 15px;
            position: relative;

            @include laptop {
                display: none;
            }

            > label {
                @include font-montserrat("Black");
                background-color: $blue;
                color: $white;
                cursor: pointer;
                display: block;
                font-size: em(14);
                letter-spacing: 1px;
                margin-right: -4px;
                position: relative;

                @include tablet {
                    padding: 10px 0 11px 0;
                }

                > i {
                    @include tablet {
                        right: 21px;
                        top: 19px;
                    }
                }

                > .button {
                    margin: 0;
                }
            }
        }

        &-button {
            border-bottom: 1px solid $white;
            display: block;
            margin-bottom: 0;
            text-align: right;

            @include laptop {
                align-items: center;
                border-bottom-width: 0;
                border-left: 1px solid $white;
                display: flex;
                flex: 1;
                justify-content: center;

                &--no-left-border {
                    border-left: 0;
                }

                &:nth-child(5n+3) {
                    flex: 1.5;
                }

                &:hover, &.hover {
                    border-bottom: 5px solid $blue;
                    box-sizing: border-box;

                    > a, label {
                        margin-bottom: -5px;
                    }
                }
            }

            &--search, &--shop {
                display: none;

                @include laptop {
                    display: flex;
                }

                > a, > label {
                    @include laptop {
                        align-items: center;
                        display: flex !important;
                        flex: 1;
                        font-size: em(16px) !important;
                        height: 100%;
                        justify-content: center;
                    }

                    @include desktop {
                        font-size: em(18px) !important;
                    }
                }
            }

            &--search {
                @include laptop {
                    border-right: 1px solid $white;

                    i {
                        font-size: em(32px);
                        line-height: 56px;
                    }
                }
            }

            &--shop {
                @include laptop {
                    a {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-evenly !important;
                        text-align: center;
                    }

                    i {
                        font-size: em(32px);
                    }
                }
            }

            &--marketing {
                a {
                    padding-left: 0 !important;
                }

                i {
                    font-size: em(32px);
                    margin-right: 10px;

                    @include tablet {
                        font-size: em(42px);
                    }
                }

                &-shop {
                    i {
                        font-size: em(30px, 18px);

                        @include tablet {
                            font-size: em(40px, 18px);
                        }
                    }
                }

                @include laptop {
                    display: none;
                }
            }

            > label + a {
                display: none;

                @include laptop {
                    align-items: center;
                    display: flex;
                    flex: 1;
                    font-size: em(16px);
                    height: 100%;
                    justify-content: center;
                    line-height: 18px;
                    text-align: center;
                    text-decoration: none;
                }

                @include desktop {
                    font-size: em(18px)
                }
            }

            > a, label {
                align-items: center;
                color: $white;
                cursor: pointer;
                display: flex;
                font-size: em(18px);
                height: 40px;
                justify-content: flex-end;
                line-height: em(18px, 18px);
                padding: 0 46px;
                position: relative;
                text-decoration: none;

                @include tablet {
                    height: 54px;
                    padding: 0 76px;
                }

                @include laptop {
                    display: none;
                    justify-content: center;
                    padding: 0 5px;
                }

                > img {
                    margin-right: 10px;

                    @include tablet {
                        margin-right: 15px;
                    }
                }

                &:hover {
                    background-color: $blue;

                    @include laptop {
                        background-color: transparent;
                    }
                }

                &:after {
                    background: $navy;
                    content: "\e967";
                    font-family: "travicons";
                    height: 100%;
                    line-height: 41px;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 0;
                    width: 36px;

                    @include tablet {
                        font-size: em(24);
                        line-height: 55px;
                        width: 56px;
                    }

                    @include laptop {
                        background: none;
                        content: "";
                    }
                }
            }

            &--otrd {
                padding: 10px 0;

                img {
                    width: 150px;
                }
            }
        }

        &-dropdown {
            display: none;

            h4 {
                @include laptop {
                    color: $yellow;
                }
            }

            &.display {
                @include laptop {
                    background-color: $navy;
                    border: 1px solid $white;
                    box-shadow: 0 20px 46px -15px $black;
                    color: $white;
                    display: flex;
                    left: 50%;
                    min-height: 225px;
                    padding-top: 15px;
                    position: absolute;
                    text-align: left;
                    top: 56px;
                    transform: translateX(-50%);
                    width: $widthLaptop - 2;
                    z-index: 10;
                }

                @include desktop {
                    top: 63px;
                    width: $widthDesktop - 2;
                }
            }
        }
    }

    &__spacer {
        height: 60px;
        width: 100%;

        @include laptop {
            // Top-bar + nav
            height: 88px + 56px;
        }

        @include desktop {
            // Top-bar + nav
            height: 107px + 63px;
        }
    }

    &__locale {
        &-flag {
            height: 23px;
            width: 30px;

            @include tablet {
                height: 30px;
                width: 40px;
            }
        }
    }

    &__mobile-badges {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;

        .badge {
            background-color: transparent;
            line-height: 0;

            &:first-child {
                padding-right: 10px;
            }

            &:after {
                display: none;
            }
        }
    }

    &__social-bar {
        border-bottom: 0;
        display: flex;
        justify-content: flex-end;
        margin-right: 46px;
        margin-top: 10px;
        z-index: 10;

        @include media-query(405px) {
            margin-top: 15px;
        }

        @include tablet {
            margin-right: 76px;
        }

        @include laptop {
            border-left: 0;
            box-shadow: 5px 7px 20px 0 rgba(0, 0, 0, .5);
            flex-direction: column;
            margin: 0;
            overflow: auto;
            padding: 0;
            position: fixed;
            right: 0;
            top: 230px;
            white-space: normal;
        }

        &-icon {
            align-items: center;
            background-color: $blue;
            border-right: 1px solid $white;
            box-sizing: border-box;
            color: $white !important;
            cursor: pointer;
            display: flex;
            font-size: em(32px);
            height: 45px;
            justify-content: center;
            margin: 0;
            padding: 0;
            position: relative;
            text-decoration: none;
            width: 45px;

            &:hover {
                background-color: $yellow;
            }

            &:nth-child(1) {
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
            }

            &:last-child {
                border-bottom-right-radius: 4px;
                border-right: 0;
                border-top-right-radius: 4px;
            }

            &:after {
                /* remove arrow that it used on the rest of the mobile nav */
                content: none;
            }

            @include tablet {
                height: 50px;
                width: 50px;
            }

            @include laptop {
                border-bottom: 1px solid $white;
                border-radius: 0;
                border-right: 0;

                &:nth-child(1) {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }

                &:last-child {
                    border: 0;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }
}

@include tablet-and-below {
    .menu-open {
        .header {
            bottom: 0;
            overflow: auto;

            &__warning {
                display: none;
            }

            &__menu-button {
                background-color: $navy;
                border-top: 4px solid #fac613;
                box-sizing: border-box;
                height: 58px;
                position: fixed;
                right: 0;
                width: 100%;
            }

            &__nav {
                background-color: transparent;
                border: none;
                height: 100%;
            }
        }
    }
}
