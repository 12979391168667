.search-filter {
    background-color: $navy;
    color: $white;
    padding: em(3) em(10);

    &__category {
        display: block;
    }

    &__category-label, &__search-label {
        display: none;
        vertical-align: middle;
    }

    &__category-list {
        max-width: 100%;
        vertical-align: middle;
    }

    &__search-field[type="search"], &__submit {
        margin-left: 0;
        margin-right: 0;
        vertical-align: middle;
    }

    &__submit {
        font-size: em(13);
    }
}

.sort-filter {

    > .select-field {
        width: 100%;

        @include laptop {
            width: 250px;
        }
    }

    &__sort-button {
        display: block;
    }

    &__sort-types {
        align-items: center;
        background-color: $navy;
        color: $white;
        padding: em(16px);

        &--stack {
            flex-wrap: wrap;
        }
    }

    &__heading {
        color: $white;
        display: none;
        @include font-sucrose("Bold");
        flex: 1 0 auto;
        font-size: 28px;
        margin: 0;
    }

    &__sort-type {
        display: block;
        flex: 1 0 auto;
        padding: 10 0;
        text-align: left;

        label {
            font-size: em(20px);
            line-height: em(24px, 20px);
            text-transform: none;
            white-space: nowrap;
        }

        input {
            display: inline-block;
            margin: 0 em(10px);
            vertical-align: baseline;
        }
    }

    &__sort-type-stack {
        @extend .sort-filter__sort-type;
        flex: 1 0 50%;
    }
}

@media screen and (min-width: 400px) {
    .sort-filter {
        &__sort-types {
            display: flex;
        }
    }
}

@media screen and (min-width: 600px) {
    .sort-filter {
        &__sort-button {
            display: block;
        }

        &__sort-types {
            flex-wrap: nowrap;
        }

        &__sort-type-stack,
        &__sort-type {
            box-sizing: border-box;
            flex: 1 0 auto;
            text-align: center;
        }
    }
}

@media screen and (min-width: 750px) {
    .sort-filter {
        &__sort-button {
            display: none;
        }

        &__heading {
            display: block;
            text-align: left;
        }

        &__sort-types {
            flex-wrap: nowrap;
        }

        &__sort-types.hidden {
            display: flex !important;
        }
    }
}

@media screen and (min-width: 500px) {
    .search-filter {
        &__category {
            display: inline-block;
            width: 50%;
        }

        &__search {
            display: inline-block;
            text-align: right;
            width: 50%;
        }

        &__search-field[type="search"] {
            display: inline-block;
            width: 55%;
        }
    }
}

@media screen and (min-width: 768px) {
    .search-filter {
        &__search-field[type="search"] {
            width: 65%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .search-filter {
        &__category-label {
            display: inline-block;
            text-align: right;
            width: 30%;
        }

        &__category-list {
            width: 60%;
        }

        &__search-label {
            display: inline-block;
            width: 30%;
        }

        &__search-field[type="search"] {
            vertical-align: middle;
            width: 50%;
        }
    }
}