.upcoming-events__fishing-trail {
    background-color: $fishing-trail-red;
    position: relative;
    z-index: 1;

    .upcoming-events {
        .no-webp &__header, .no-js &__header {
            background-image: url("/images/fishing-trail/fishing-trail-attractions.jpg");
        }

        .webp &__header {
            background-image: url("/images/fishing-trail/fishing-trail-attractions.webp");
        }

        &__header {
            background-position: 0px -26px;
            background-repeat: no-repeat;
            text-align: right;

            @include tablet-and-below {
                text-align: center;
            }

            @include laptop {
                background-position: -75px 50px;
            }

            @include media-query(1370px) {
                background-position: -40px 50px;
            }

            @include desktop {
                background-position: 0 80px;
            }

            h1 {
                color: $white;
                font-size: rem(48px);
                width: auto;

                @include tablet {
                    float: none;
                }
            }
        }

        &__body {
            background-color: $fishing-trail-blue;
        }

        &__buttons {
            margin-top: 20px;

            .button {
                background-color: $fishing-trail-blue;
                display: inline-block;
                padding-left: em(18px);
                padding-right: em(40px);
                width: auto;

                @include tablet {
                    margin-left: em(15px);
                    text-align: center;
                }

                @include laptop {
                    &:nth-child(2) {
                        margin-top: em(10px);
                    }
                }
            }
        }
    }
}