.store {
    &__top-close-button {
        display: flex;
        justify-content: flex-end;
    }

    &__spacer {
        background: $white;
        display: none;
        height: 40px;
        width: 100%;
        z-index: 11;

        @include tablet {
            display: block;
        }
    }

    &__check-out {
        position: relative;

        &-title {
            margin-top: 0;
        }

        &-confirm {
            background-color: $off-white;
            border: 1px solid $beige;
            font-size: em(14px, 16px);
            margin-bottom: 20px;
            padding: 20px;

            &-label {
                font-weight: bold;
                margin-bottom: 0;
            }

            .column > p:last-child {
                margin-bottom: 0;
            }
        }

        &-buttons {
            display: flex;

            > div:first-child, button:first-child {
                margin-right: 10px;
            }
        }

        &--mobile {
            padding: 20px;

            input, select {
                background-color: $white !important;
            }

            .store__check-out-confirm {
                background-color: $beige;
            }
        }
    }

    &__required {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    &-confirmation {
        &__order-details {
            font-size: em(16px, 18px);
            margin-bottom: 10px;
            padding: 5px 0 0;

            .notice {
                color: $orange;
            }
        }

        &__order-address {
            color: $charcoalgray;
        }

        &-order-review {
            display: flex;
            padding: 35px 0;

            &__item-image {
                flex: 1 1 auto;
                padding: 0 20px 15px;
                text-align: center;

                img {
                    max-width: 150px;
                    min-width: 75px;
                }
            }

            &__item-content {
                flex: 5 1 auto;
                font-size: em(16px);
            }

            &__item-title {
                border-bottom: 1px solid $gray;
                color: $charcoalgray;
                font-weight: bold;
                margin: 0 0 15px;
            }

            &__item-description {
                margin: 0;
            }
        }
    }

    &__zipcode-response span {
        display: inline-block;
        font-size: em(24, 16);
        font-weight: bold;
        padding: 15px 0;
    }

    &__bulk-order-header {
        overflow: hidden;

        strong {
            @include font-montserrat("SemiBold");
        }
    }

    &__bulk-order-header-enter-active, &__bulk-order-header-leave-active {
        transition: all .3s;
    }

    &__bulk-order-header-enter, &__bulk-order-header-leave-to {
        max-height: 0;
    }
}