.whats-new {
    &__header {
        &--international {
            @include media-query(700px) {
                width: auto;
            }
        }
    }

    &--international {
        background-color: transparent;
    }
}
