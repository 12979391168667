.deals-filter {
    padding: em(30px, 18px) 0;

    .select-field {
        width: 100%;
    }

    &__filter-title {
        @include font-sucrose("Bold");
        color: $gray;
        font-size: em(30);
    }
}

@media screen and (min-width: 545px) {
    .deals-filter {
        .select-field {
            width: 450px;
        }
    }
}

@media screen and (min-width: 768px) {
    .deals-filter {
        padding: em(50px, 18px) 0;
    }
}