.listing {
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 -10px;
        position: relative;

        @include laptop {
            flex-direction: row;
        }

        &-title, &-subtitle {
            display: none;
            @include laptop {
                display: block;
            }
        }

        &-event-notice {
            background-color: $off-white;
            border: em(2px, 24px) solid $beige;
            border-radius: em(10px, 24px);
            color: $orange;
            font-size: rem(24px);
            line-height: em(28px, 24px);
            margin-bottom: em(25px, 24px);
            padding: em(15px, 24px);
        }

        &-covid-notice {
            background-color: $off-white;
            border: em(2px, 18px) solid $beige;
            border-radius: em(10px, 18px);
            color: $orange;
            font-size: rem(18px);
            line-height: em(28px, 18px);
            margin-bottom: em(25px, 18px);
            padding: em(15px, 18px);
        }

        &-title {
            font-size: em(40px);
            margin-top: 0;
        }

        &-subtitle {
            @include font-montserrat("Bold");
            font-size: rem(30px);
            margin-bottom: rem(14px);
            margin-top: 0;

            @include laptop {
                font-size: rem(34px);
                margin-bottom: rem(18px);
            }
        }

        &-header-date {
            font-size: rem(32px);
        }

        &-columns {
            display: flex;
            flex-direction: column;
            margin: 0 -10px;

            @include laptop {
                flex-direction: row;
            }
        }

        &-column {
            display: block;
            margin: 0 10px 20px;

            &:last-child {
                margin-bottom: 0;
            }

            @include laptop {
                margin: 0 10px 10px;
            }
        }

        &-hours {
            display: inline-flex !important;

            @include laptop {
                display: none !important;
            }
        }

        &-item {
            display: inline-block;
            margin-right: rem(15px);
            text-decoration: none;

            @include laptop {
                display: block;
                margin-bottom: rem(10px);
            }

            &--icon {
                align-items: center;
                display: inline-flex;
                vertical-align: middle;

                @include laptop {
                    display: flex;
                }

                i, div {
                    margin-right: 5px;
                    max-height: 30px;
                }

                i {
                    font-size: 1.3em;
                }
            }
        }

        &-label {
            @include font-montserrat("Regular");
        }
    }

    &__contact {
        @include font-montserrat("Bold");
        color: $blue;
        font-size: rem(18px);

        @include laptop {
            @include clearfix();
        }

        &-name, &-address {
            display: none;
            @include laptop {
                display: block;
            }
        }
    }

    &--state-park {
        .listing {
            &__info {
                &-title, &-subtitle {
                    display: block;
                }
            }

            &__contact {
                &-name, &-address {
                    display: block;
                }
            }
        }
    }
}
