.music-trail__upcoming-events {
    background-color: $music-trail-red;
    position: relative;
    width: 100%;
    z-index: 1;

    .upcoming-events {
        @include tablet {
            .no-webp &__header, .no-js &__header {
                background-image: url("/images/music-trail/events-background.jpg");
            }

            .webp &__header {
                background-image: url("/images/music-trail/events-background.webp");
            }
        }

        &__header {
            background-position: 0px -26px;
            background-repeat: no-repeat;
            text-align: right;

            @include tablet-and-below {
                text-align: center;
            }

            @include laptop {
                background-position: -75px 50px;
            }

            @include media-query(1370px) {
                background-position: -40px 50px;
            }

            @include desktop {
                background-position: 0 80px;
            }

            h1 {
                color: $white;
                font-size: rem(48px);
                width: auto;

                @include tablet {
                    float: none;
                }
            }
        }

        &__body {
            background-color: $music-trail-charcoal-gray;
        }

        &__buttons {
            margin-top: 20px;

            .button {
                background-color: $music-trail-charcoal-gray;
                display: inline-block;
                padding-left: em(18px);
                padding-right: em(40px);
                width: auto;

                @include tablet {
                    margin-left: em(15px);
                    text-align: center;
                }

                @include laptop {
                    &:nth-child(2) {
                        margin-top: em(10px);
                    }
                }
            }
        }
    }

    .event-card__date-range {
        color: $music-trail-red;
    }
}