.tag-page {

    &__description-container {
        margin-bottom: 15px;
    }

    &__description {
        overflow: hidden;

        &::after {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
            bottom: 0;
            content: "";
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
        }

        &.expanded::after {
            display: none;
        }
    }

    &__description-read-more {
        position: relative;
        visibility: visible;

        &.hide {
            display: block !important;
            position: absolute;
            visibility: hidden;
        }
    }

    &__tag-icons {
        margin-bottom: em(70px);

        h2 {
            margin-bottom: em(30px, 36px);
        }
    }

    &__articles-header {
        border-bottom: 1px solid $beige;
        margin-bottom: 0;
        padding-bottom: em(10px, 36px);
    }

}

@media screen and (min-width: 768px) {
    .tag-page {
        &__description::after {
            background: linear-gradient(to bottom, rgba(255,255,255,0) -30%, white 100%);
            height: 50%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .tag-page {
        &__tag-icons {
            margin-bottom: em(100px);

            h2 {
                margin-bottom: em(50px, 36px);
            }
        }
    }
}