.long-road-to-liberty__banner {
    align-items: center;
    background: $long-road-to-liberty-red;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 40px 20px;
    position: relative;

    @include tablet {
        padding: 30px;
    }

    @include laptop {
        flex-direction: row;
    }

    &-text {
        align-items: center;
        @include font-sucrose("Bold");
        color: $white;
        display: flex;
        flex-direction: column;
        font-size: 25px;
        padding-top: 20px;
        text-align: center;

        @include tablet {
            font-size: 35px;
            padding-top: 15px;
        }

        @include laptop {
            align-items: flex-start;
            flex-basis: 50%;
            font-size: 40px;
            padding-left: 60px;
        }

        &-body {
            @include font-montserrat("Regular");
            font-size: 15px;
            width: 100%;

            @include tablet {
                font-size: 18px;
                max-width: 675px;
            }

            @include laptop {
                text-align: left;
                width: 550px;
            }

            @include desktop {
                width: 700px;
            }
        }
    }

    &-img {
        display: flex;

        @include laptop {
            flex-basis: 50%;
            justify-content: flex-end;
        }

        img {
            margin: 0 auto;
            max-width: 70%;

            @include tablet {
                max-width: 500px;
            }

            @include laptop {
                margin: 0;
                width: 100%;
            }
        }
    }

    &-button {
        @include font-montserrat("Bold");
        background-color: $long-road-to-liberty-dark-gray;
        border: 4px solid $long-road-to-liberty-dark-gray;
        font-size: 18px;
        padding: em(5px, 18px) em(20px, 18px);
        text-align: center;

        @include tablet {
            font-size: 20px;
            padding: em(5px, 20px) em(20px, 20px);
        }

        @include laptop {
            font-size: 25px;
            padding: em(5px, 25px) em(20px, 25px);
        }

        &:link, &:visited, &:active, &:hover {
            color: $white;
        }

        &:hover {
            background-color: $long-road-to-liberty-red;
        }
    }
}