.separator {
    $sep-class: ".photo-ops";

    &__icon {
        &#{$sep-class} {
            $width: 153px;
            $height: 99px;

            background: url("/images/landing_page_separators/photo-ops-icon.png") no-repeat top left;
            display: block;
            height: $height;
            overflow: hidden;
            width: $width;
        }
    }

    &__background {
        &#{$sep-class} {
            $width: 1920px;
            $height: 600px;

            background: no-repeat top left;
            display: block;
            height: $height;
            margin-left: -#{$width/2};
            margin-top: -#{$height/2};
            overflow: hidden;
            width: $width;
        }
    }

    &__quote-block {
        &#{$sep-class} {
            max-width: 425px;
        }
    }
}