.mobile-cart {
    align-content: center;
    align-items: center;
    background-color: $blue;
    box-shadow: -4px 4px 5px 0px rgba(0, 0, 0, 0.28);
    color: $white;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    font-size: em(18px);
    height: 50px;
    justify-content: space-evenly;
    padding: 7px 8px 8px;
    position: fixed;
    right: 0;
    text-align: center;
    width: 70px;
    z-index: 20;

    i {
        font-size: 1.3em;
    }

    &__label {
        font-size: em(16px, 20px);
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    &__content {
        background-color: $off-white;
        left: 100%;
        overflow: scroll;
        padding: 60px 0 0;
        position: fixed !important;
        scroll-padding-top: 300px;
        transition: left .3s;
        width: 100%;
        z-index: 21;

        &--open {
            left: 0;
        }
    }

    @include tablet {
        display: none;
    }
}
