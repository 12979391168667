.state-park-deals {
    background: url("/images/shell/state-parks/state-park-deals-bg.jpg") $beige no-repeat top left;
    background-size: cover;
    box-sizing: border-box;
    padding: 0 0 50px 0;

    &__description {
        padding-top: 50px;
    }

    &__view-coupons {
        color: $blue;
        @include font-montserrat("Bold");
        display: block;
        font-size: em(25);
        line-height: em(20);
        text-decoration: none;
        text-transform: uppercase;
    }

    &__description-text {
        color: $blue;
        @include font-montserrat("SemiBold");
        display: block;
        font-size: em(20);
    }

    &__subtext {
        margin: 50px 0 20px 20px;
        width: 370px;
    }

    &__view-all {
        &.button {
            margin-left: 20px;
        }
    }

    &__listings {
        display: none;
        padding-top: 50px;
    }

    .hide-mobile {
        display: none;
    }

    .featured-content__image-block .featured-content__topic {
        font-size: em(20px);
    }

    .featured-content__image-block:hover .featured-content__topic {
        font-size: em(25px);
    }

    @media (min-width: 500px) {
        background-position: top right;
    }

    @include tablet() {
        .hide-mobile {
            display: block;
        }
        &__subtext {
            width: 250px;
        }

        .featured-content {
            padding-bottom: 15px;
        }

        .featured-content__image {
            max-height: 400px;
            max-width: 400px;
        }
    }

    @include laptop() {
        &__listings {
            display: block;
        }
        &__subtext {
            text-shadow: 1px 0px #ffffff, 1px 1px #ffffff, -1px 0px #ffffff, -1px -1px #ffffff;
            width: 340px;
        }
    }
}