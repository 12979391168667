$column-gap: 0.50rem !default;

.column {
    box-sizing: border-box;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: $column-gap;

    @include mobile-only {
        &--mobile-hidden {
            display: none;
        }
    }

    @include tablet-only {
        &--tablet-hidden {
            display: none;
        }
    }

    @include laptop-only {
        &--laptop-hidden {
            display: none;
        }
    }

    @for $i from 1 through 12 {
        &--mobile-#{$i} {
            flex: none;
            width: percentage($i / 12);
        }
        &--mobile-offset-#{$i} {
            margin-left: percentage($i / 12);
        }
    }

    @include tablet() {
        @for $i from 1 through 12 {
            &--#{$i}, &--tablet-#{$i} {
                flex: none;
                width: percentage($i / 12);
            }
            &--offset-#{$i}, &--tablet-offset-#{$i} {
                margin-left: percentage($i / 12);
            }
        }
    }

    @include laptop() {
        @for $i from 1 through 12 {
            &--#{$i}, &--laptop-#{$i} {
                flex: none;
                width: percentage($i / 12);
            }
            &--offset-#{$i}, &--offset-laptop-#{$i} {
                margin-left: percentage($i / 12);
            }
        }
    }

    @include desktop() {
        @for $i from 1 through 12 {
            &--#{$i} {
                flex: none;
                width: percentage($i / 12);
            }
            &--offset-#{$i} {
                margin-left: percentage($i / 12);
            }
        }
    }
}

.columns {
    margin-left: (-$column-gap);
    margin-right: (-$column-gap);

    &:last-child {
        margin-bottom: (-$column-gap);
    }

    &:not(:last-child) {
        margin-bottom: calc(1.5rem - #{$column-gap});
    }

    // Modifiers
    &--mobile {
        display: flex;
    }

    &--multiline {
        flex-wrap: wrap;
        margin-top: (-$column-gap);

        > .column {
            padding: $column-gap;
        }
    }

    @include tablet() {
        display: flex;
    }
}

