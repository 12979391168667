.separator {
    $sep-class: ".quote";

    &__icon {
        &#{$sep-class} {
            $width: 175px;
            $height: 99px;

            background: url("/images/landing_page_separators/bison-icon.png") no-repeat top left;
            display: block;
            height: $height;
            overflow: hidden;
            width: $width;
        }
    }

    &__background {
        &#{$sep-class} {
            $width: 430px;
            $height: 600px;

            background: url("/images/landing_page_separators/quote-mobile-bg.png") no-repeat top left;
            display: block;
            height: $height;
            margin-left: -#{$width/2};
            margin-top: -#{$height/2};
            overflow: hidden;
            width: $width;
        }
    }

    &__quote-block {
        &#{$sep-class} {
            max-width: 700px;

            .separator__quote-text {
                font-size: rem(28px);
                @media (min-width: 768px) {
                    .quote-author {
                        text-align: right;
                    }
                }
            }
        }
    }

    @media (min-width: 430px) {
        &__background {
            &#{$sep-class} {
                $width: 1024px;
                background: url("/images/landing_page_separators/quote-tablet-bg.png") no-repeat top left;
                margin-left: -#{$width/2};
                width: $width;
            }
        }

        &__quote-block {
            &#{$sep-class} {
                .separator__quote-text {
                    font-size: rem(36px);
                }
            }
        }
    }

    @media (min-width: 1024px) {
        &__background {
            &#{$sep-class} {
                $width: 1920px;
                background: url("/images/landing_page_separators/quote-bg.png") no-repeat top left;
                margin-left: -#{$width/2};
                width: $width;

            }
        }

    }
}