.search-result {
    border-bottom: 1px solid $beige;
    font-size: rem(18px);
    margin: 0;
    padding: em(30px, 18px) 0;
    position: relative;

    &:last-child {
        border-bottom: 0;
    }

    &__image img {
        border: 5px solid $beige;
        box-shadow: 7px 10px 25px 0px rgba(75, 75, 75, 0.5);
        max-width: em(290px, 18px);
        // remove extra space due to being inline
        vertical-align: bottom;
    }

    &__notice {
        @include font-montserrat("SemiBold");
        font-size: em(20px, 18px);
        font-weight: bold;
        margin-top: em(15px, 20px);

        a {
            color: $orange;
        }

        i {
            margin-right: 10px;
        }
    }

    &__event-date, &__event-hours {
        color: $blue;
        @include font-montserrat("Bold");
        font-size: 20px;
        margin-bottom: em(10px, 20px);
    }

    &__event-hours {
        color: $blue;
        @include font-montserrat("Bold");
        font-size: 18px;
        margin-bottom: em(10px, 18px);
    }

    &__deal-name {
        font-size: rem(18px);
        margin: 0;
        padding-bottom: em(10px, 20px);

        a {
            text-decoration: none;
        }
    }

    &__phone {
        @include font-montserrat("Bold");
    }

    &__subhead {
        font-size: em(24px, 18px);
        line-height: em(28px, 24px);
        margin-top: em(10px, 24px);
        @include font-montserrat("Bold");
    }

    &__info {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;
    }

    h2 {
        font-size: em(26px, 18px);
        font-weight: bold;
        @include font-montserrat("Bold");
        letter-spacing: .025em;
        line-height: 1.2em;
        margin-bottom: em(0);
        margin-top: em(15px, 26px);

        a {
            text-decoration: none;
        }
    }

    address {
        @include font-montserrat("SemiBold");
        color: $blue;
        font-size: em(14px);
        line-height: 1.3;
        margin-top: em(10px);

        b {
            @include font-montserrat("Bold");
            font-weight: normal;
        }
    }

    p {
        color: $gray;
        font-size: em(18px, 18px);
        @include font-montserrat("Regular");
        margin-bottom: em(25px, 18px);
        margin-top: em(10px, 18px);
    }

    .button {
        font-size: em(14px);
        margin: 0;
        padding-left: em(15px, 18px);
        padding-right: em(45px, 18px);
    }

    &--staggered {
        .search-result {
            &__image {
                img {
                    box-sizing: border-box;
                    max-width: 100%;
                }
            }
        }
    }

    &--no-border {
        border: none;
    }
}

.sponsored-result {
    border: 2px solid $off-white;
    border-radius: 35px;
    box-sizing: border-box;
    padding: 15px;

    &__label {
        color: $blue;

        @include laptop {
            margin-right: 15px;
        }
    }
}

.sponsored-result + .sponsored-result {
    margin-top: 35px;
}

@media screen and (min-width: 768px) {
    .search-result {
        @include clearfix();

        &__image {
            float: left;
            text-align: center;
            width: em(340px, 18px);

            img {
                max-width: em(310px, 18px);
            }
        }

        &__notice {
            margin-bottom: 10px;
            margin-top: 0;
        }

        &__details {
            margin-left: em(360px, 18px);
        }

        h2 {
            margin-top: 0;
        }

        &--staggered {
            .search-result {
                &__image {
                    img {
                        max-width: em(310px, 18px);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .search-result {
        padding: em(38px, 18px) 0;

        &__image {
            width: em(430px, 18px);

            img {
                box-shadow: 10px 15px 45px 0px rgba(75, 75, 75, 0.5);
                max-width: em(390px, 18px);
            }
        }

        &__details {
            margin-left: em(450px, 18px);
        }

        &--staggered {
            border-width: 0;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            width: 50%;

            &:nth-child(3n) {
                border-top: 1px solid $off-white;
                border-width: 1px 0;
                display: block;
                width: auto;

                .search-result {
                    &__image {
                        float: left;
                        margin-bottom: 0;
                        width: em(450px, 18px);

                        img {
                            max-width: em(450px, 18px);
                        }
                    }

                    &__details {
                        margin-left: em(485px, 18px);
                    }
                }
            }

            &:nth-child(3n - 2) {
                border-right: 1px solid $off-white;
                padding: em(30px, 18px) em(30px, 18px) em(30px, 18px) 0;
            }

            &:nth-child(3n - 1) {
                padding: em(30px, 18px) 0 em(30px, 18px) em(30px, 18px);
            }

            .search-result {
                &__image {
                    float: none;
                    margin-bottom: em(20px, 18px);
                    width: auto;

                    img {
                        max-width: 100%;
                    }
                }

                &__details {
                    margin-left: 0;
                }
            }
        }
    }
}

.search-summary {
    border-bottom: 1px solid $beige;
    border-top: 1px solid $beige;
    @include font-montserrat("Regular");
    color: $gray;
    font-size: em(14px);
    line-height: em(18px, 14px);
    padding-bottom: em(16px, 14px);
    padding-top: em(22px, 14px);
    text-transform: uppercase;

    &__per-page {
        b, a, a:hover, a:focus, a:active, a:visited {
            @include font-montserrat("Bold");
            color: $gray;
            display: inline-block;
            font-weight: normal;
            margin: 0 em(4px, 14px);
        }
    }

    &__count {
        margin-bottom: em(10px, 14px);
    }

    &__refine-button button {
        margin: 0;
        padding-left: em(10px, 18px);
        padding-right: em(40px, 18px);
    }
}

@media screen and (min-width: 620px) {
    .search-summary {
        height: em(22px, 14px);
        padding-bottom: em(28px, 14px);
        padding-top: em(10px, 14px);

        position: relative;

        &__count {
            margin: 0;
        }

        &__refine-button {
            position: absolute;
            right: 0;
            top: em(10px, 14px);
        }
    }
}

@media screen and (min-width: 1024px) {
    .search-summary {
        height: em(22px, 14px);
        padding-bottom: em(16px, 14px);
        padding-top: em(22px, 14px);

        &__per-page {
            display: inline-block;
            margin-right: em(125px, 14px);
        }

        &__count {
            display: inline-block;
        }

        &__refine-button {
            right: em(50px, 14px); // Fix for social sidenav issue @ 1024px.
        }
    }
}

@media screen and (min-width: 1110px) {
    .search-summary {
        &__refine-button {
            right: 0; // Fix for social sidenav issue @ 1024px.
        }
    }
}