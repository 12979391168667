.long-road-to-liberty__spotlights {
    align-items: center;
    background-image: url("/images/long-road-to-liberty/backgrounds/slider.jpg");
    background-size: 100% 100%;
    display: flex;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#c0b47b", GradientType=0);
    flex-direction: column;
    margin-top: 15px;
    padding-top: 30px;
    position: relative;
    width: 100%;

    .owl-carousel {
        display: block;
    }

    &-title {
        color: black;
        font-size: rem(40px);
        text-align: center;
    }

    &-wrap {
        overflow: hidden;
        padding: 30px 0 40px;
        position: relative;
        width: 100%;

        @include media-query(536px) {
            overflow: visible;
        }
    }

    &-nav {
        &-button--next, &-button--prev {
            color: $long-road-to-liberty-red;
            cursor: pointer;
            font-size: rem(48px);
            position: absolute;
            top: 26%;

            @include media-query(536px) {
                bottom: 53%;
                top: auto;
            }
        }

        &-button--next {
            right: 0;
        }

        &-button--prev {
            left: 0;
        }
    }
}

.long-road-to-liberty__spotlight {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &--blue-green {
        .long-road-to-liberty__spotlight-hover {
            background-color: #{$long-road-to-liberty-blue-green}bf;
        }

        .long-road-to-liberty__spotlight-header {
            color: $long-road-to-liberty-blue-green;
        }
    }

    &--yellow {
        .long-road-to-liberty__spotlight-hover {
            background-color: #{$long-road-to-liberty-yellow}bf;
        }

        .long-road-to-liberty__spotlight-header {
            color: $long-road-to-liberty-yellow;
        }
    }

    &--gray {
        .long-road-to-liberty__spotlight-hover {
            background-color: #{$long-road-to-liberty-gray}bf;
        }

        .long-road-to-liberty__spotlight-header {
            color: $long-road-to-liberty-gray;
        }
    }

    &--green {
        .long-road-to-liberty__spotlight-hover {
            background-color: #{$long-road-to-liberty-green}bf;
        }

        .long-road-to-liberty__spotlight-header {
            color: $long-road-to-liberty-green;
        }
    }

    &--red {
        .long-road-to-liberty__spotlight-hover {
            background-color: #{$long-road-to-liberty-red}bf;
        }

        .long-road-to-liberty__spotlight-header {
            color: $long-road-to-liberty-red;
        }
    }

    &-image {
        border-radius: 50%;
        box-shadow: 0 8px 10px 0 rgba(75, 75, 75, 0.5);
        display: block;
        height: 275px;
        margin-bottom: 25px;
        width: 275px;

        &:hover, &--active {
            .long-road-to-liberty__spotlight-hover {
                display: flex;
            }
        }
    }

    &-name {
        @include font-montserrat("Bold");
        color: $white;
        font-size: rem(20px);

        @include laptop {
            font-size: rem(22px);
            margin-bottom: rem(5px);
        }
    }

    &-description {
        color: $white;
        font-size: rem(16px);
        line-height: rem(17px);

        @include laptop {
            font-size: rem(17px);
            line-height: rem(19px);
        }
    }

    &-header {
        @include font-sucrose("Bold");
        color: $music-trail-red;
        font-size: rem(28px);
        text-align: center;

        @include laptop {
            font-size: rem(30px);
        }
    }

    &-hover {
        align-items: center;
        border-radius: 50%;
        box-sizing: border-box;
        display: none;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 25px;
        text-align: center;
        width: 100%;
    }
}
