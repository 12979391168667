.ad-container {
    background: url("/images/ad_backgrounds/ad-bg-desktop.jpg") no-repeat $beige;
    background-position: left 30% bottom 30px;
    text-align: center;

    @include tablet {
        .ad-container {
            background-position: center center;
            background-size: cover;
        }
    }

    &__content {

        display: inline-block;
        padding: 40px 0;
        width: auto;

        > div {
            display: inline-block;
            margin: 10px;
            width: 300px;
        }

        .ad-heading {
            display: block;
            margin: 0 auto;
            text-align: center;
            width: auto;

            h6 {
                color: $blue;
                font-size: em(13px);
                letter-spacing: .05em;
                @include font-montserrat("Bold");
                margin: 0 0 10px 0;
                padding: 8px 20px;
                position: relative;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }

    &--inline {
        margin-bottom: 30px;
        margin-top: 30px;

        .ad-container {
            &__content {
                padding: 20px 0 0;

                > div {
                    margin: 0 0 25px;

                    @media (min-width: 500px) {
                        margin: 0 15px 25px;
                    }
                }
            }
        }
    }

    &--narrow {
        .ad-container {
            &__content > div {
                @include laptop {
                    margin: 0 0 15px;
                }
            }
        }
    }

    &--no-margin {
        margin: 0;
    }
}