/*
Normalizer, html, body, a ul li...
*/

html, body {
    margin: 0;
    padding: 0;
    @include font-montserrat("Regular");
}

@include tablet-and-below {
    html.menu-open, .menu-open body {
        overflow: hidden;
    }
}

img {
    border: 0;
}

a, a:visited, a:active {
    color: $blue;
}

a:hover {
    color: $navy;
}

h1, h2, h3, h4, h5, h6 {
    color: $blue;
    line-height: 1em;
    margin-bottom: em(18px, 46px);
    @include font-sucrose("Bold");
}

h1 {
    //font-size: 3em;
    font-size: em(40px);
}

h2 {
    //font-size: 2.25em;
    font-size: em(34px);
}

h3 {
    //font-size: 1.75em;
    font-size: em(28px);
}

h4 {
    //font-size: 1.125em;
    font-size: em(22px);
}

p {
    font-size: em(18px);
    line-height: em(28px, 18px);
    margin-top: 0;
}

ol, ul {
    font-size: em(18px);
    line-height: em(24px, 18px);

    li {
        margin-bottom: em(10px, 20px);
    }
}

strong, b {
    @include font-montserrat("Bold")
}

address {
    font-style: normal;
}

/**
 * Form resets
 *
 * Modified from: FormHack v1.2.0 (formhack.io)
 */

// TODO: Create resets for Label, Select, Check, Radio, Textarea, and Range if needed. Remove commented out defaults.

// Config moved to _config.scss

/* Global Reset Styles ------------------ */

/* textarea,
select,
option,
optgroup,
fieldset
legend, */
input,
button,
.button {
    box-sizing: border-box;
    color: $gray;
    display: block;
    font-size: rem(18px);
    margin: em(10px, $fh-psd-input-font-size);
    @include font-montserrat("Bold");
    outline: none;
    vertical-align: top;
}

button,
.button {
    margin-left: 0;
}

label {
    @include font-montserrat("SemiBold");
    font-size: rem($fh-psd-label-font-size);
    line-height: em(18px);
    text-transform: uppercase;
}

/*
datalist {
	@include font-montserrat("Regular");
	font-size: rem($fh-font-size);
}
*/

.table.is-striped {
    background-color: $white;
    color: #363636;
}

.table.is-striped td,
.table.is-striped th {
    padding: 0.5em 0.75em;
    vertical-align: top;
}

.table.is-striped thead {
    background-color: transparent;
}

.table.is-striped thead td,
.table.is-striped thead th {
    border: 1px solid #dbdbdb;
    border-width: 0 0 2px;
    color: #363636;
}

.table.is-striped tbody {
    background-color: transparent;
}

.table.is-striped tbody tr:last-child td,
.table.is-striped tbody tr:last-child th {
    border-bottom-width: 0;
}

.table.is-striped tbody tr:nth-child(even) {
    background-color: #eaeaea;
}

/* Input & Textarea ------------------ */

/* Fields with standard width */
/* input[type="color"],
input[list],
input[type="file"],
textarea,
select, */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"] {
    background-color: $white;
    border: 0;
    border-radius: 0;
    @include font-montserrat("Regular");
    box-shadow: inset 2px 3px 3px 0px rgba(164, 164, 158, 0.5);
    font-size: rem($fh-input-font-size);
    // IE ignores line-height
    height: 2.25em;
    line-height: 2.25em;
    padding: em(0px, $fh-psd-input-font-size) em(10px, $fh-psd-input-font-size) em(0px, $fh-psd-input-font-size) em(10px, $fh-psd-input-font-size);
    width: 100%;
    @include input-placeholder {
        color: $gray;
    }
}

/* Fields with standard height */
/* input[list],
input[type="color"], */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"] {
    -webkit-appearance: none;
}

/* Other */

input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

/*
textarea {
	-webkit-appearance: none;
	overflow: auto;
}
*/

/*
input[type="range"] {
	height: $fh-input-height;
	width: $fh-input-width;
	max-width: $fh-input-max-width;
}
*/

/*
input[type="file"] {
	min-height: $fh-input-height;
}
*/

/*
input[type="checkbox"],
input[type="radio"] {
	display: inline-block;
	vertical-align: middle;
}
*/
// For checkbox and radio to be centered, need to wrap the input and label in a span -
// .checkbox-container {
// 	display: block;
// 	text-align: center;
// }

$checkbox-large-scale: 2;
input[type="checkbox"] {
    display: inline-block;
    /* Large sized Checkboxes */
    -ms-transform: scale3d($checkbox-large-scale, $checkbox-large-scale, 1);
    -webkit-transform: scale3d($checkbox-large-scale, $checkbox-large-scale, 1);
    transform: scale3d($checkbox-large-scale, $checkbox-large-scale, 1);
}

@media screen and (min-width: 1024px) {
    $checkbox-normal-scale: 1;
    input[type="checkbox"] {
        display: inline-block;
        /* Large sized Checkboxes */
        -ms-transform: scale3d($checkbox-normal-scale, $checkbox-normal-scale, 1);
        -webkit-transform: scale3d($checkbox-normal-scale, $checkbox-normal-scale, 1);
        transform: scale3d($checkbox-normal-scale, $checkbox-normal-scale, 1);
    }
}

/* Select ------------------ */
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: $off-white url("/images/layout/select-field-arrow.png") 100% / #{em(28)} no-repeat;
    border: 0px;
    border-radius: 0;
    color: $gray;
    cursor: pointer;
    font-size: em(16);
    height: em(32);
    @include font-montserrat("SemiBold");
    line-height: em(20);
    min-width: 150px;
    padding: 0 em(32) 0 em(8);
    text-align: left;
    text-transform: uppercase;
}

select:focus {
    border: none;
}

/* Default Button */
button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="image"],
.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: $blue;
    border: 0;
    @include font-montserrat("Bold");
    border-radius: 0;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: rem(18px);
    letter-spacing: 1px;
    padding: em(4px, $fh-psd-button-font-size) em(20px, $fh-psd-button-font-size) em(5px, $fh-psd-button-font-size) em(20px, $fh-psd-button-font-size);
    text-decoration: none;
    text-transform: uppercase;
    width: auto;

    &:focus {
        background-color: $blue;
        color: $white;
        outline: 2px dotted $charcoalgray;
    }

    &:hover {
        background-color: $blue;
        color: $white;
    }
}

a.button:visited, a.button:hover, a.button:active {
    background-color: $blue;
    color: $white;
}

/* Yellow Button */
input.button-yellow[type="button"],
input.button-yellow[type="submit"],
input.button-yellow[type="reset"],
input.button-yellow[type="image"],
.button-yellow {
    background-color: $yellow;

    &:focus {
        background-color: $yellow;
    }

    &:hover {
        background-color: $yellow;
    }
}

a.button-yellow:visited, a.button-yellow:hover, a.button-yellow:active {
    background-color: $yellow;
    color: $white;
}

/* Disabled Button */
input.button-disabled[type="button"],
input.button-disabled[type="submit"],
input.button-disabled[type="reset"],
input.button-disabled[type="image"],
.button-disabled {
    background-color: lighten($charcoalgray, 35%);

    &:focus {
        background-color: lighten($charcoalgray, 35%);
    }

    &:hover {
        background-color: lighten($charcoalgray, 35%);
        cursor: default;
    }
}

a.button-disabled {
    &:visited, &:hover, &:active {
        background-color: lighten($charcoalgray, 35%);
        color: $white;
    }

    &:hover {
        cursor: default;
    }
}

button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* States ------------------ */

input[disabled],
textarea[disabled],
select[disabled],
option[disabled],
button[disabled] {
    cursor: not-allowed;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
    outline: lighten($charcoalgray, 40%) solid 2px;
}

/**
 * End form resets
 */

// Add arrow and line to primary/default buttons. Only works with <button /> elements since input elements are not a container.
.button-primary {
    overflow: hidden;
    padding-right: em(33px, $fh-psd-button-font-size);
    position: relative;
    text-align: left;

    &::after {
        border-left: 1px solid $white;
        box-sizing: border-box;
        content: ">";
        display: block;
        height: 10em;
        line-height: 10em;
        padding: 0 em(4px, $fh-psd-button-font-size) 0 em(5px, $fh-psd-button-font-size); // Adjust because the greater than character is aligned to the bottom with this font. Move closer to the center.
        position: absolute;
        right: 0;
        text-align: center;
        top: 50%;
        -moz-transform: translateY(-51%);
        -ms-transform: translateY(-51%);
        -webkit-transform: translateY(-51%);
        transform: translateY(-51%);
    }

}

.button-primary-reverse {
    overflow: hidden;
    padding-left: em(33px, $fh-psd-button-font-size);
    position: relative;
    text-align: left;

    &::after {
        border-right: 1px solid $white;
        box-sizing: border-box;
        content: "<";
        display: block;
        height: 10em;
        left: 0;
        line-height: 10em;
        padding: 0 em(4px, $fh-psd-button-font-size) 0 em(5px, $fh-psd-button-font-size); // Adjust because the greater than character is aligned to the bottom with this font. Move closer to the center.
        position: absolute;
        text-align: center;
        top: 50%;
        -moz-transform: translateY(-51%);
        -ms-transform: translateY(-51%);
        -webkit-transform: translateY(-51%);
        transform: translateY(-51%);
    }
}

.button-close {
    align-items: center;
    display: flex;
    padding-left: em(10px, 18px) !important;
    padding-right: em(10px, 18px) !important;
    position: relative;

    &:after {
        content: "\e95b";
        font-family: "travicons";
        margin-left: 10px;
    }
}

.button-text-shadow {
    text-shadow: em(2px, $fh-psd-button-font-size) em(3px, $fh-psd-button-font-size) em(7px, $fh-psd-button-font-size) rgba(33, 33, 33, .75);
}

.button-call-to-action {
    @extend .button-primary;
    padding: em(6px, $fh-psd-button-font-size) em(38px, $fh-psd-button-font-size) em(6px, $fh-psd-button-font-size) em(20px, $fh-psd-button-font-size);
    @extend .button-text-shadow;

    &::after {
        padding: 0 em(4px, $fh-psd-button-font-size) 0 em(5px, $fh-psd-button-font-size); // Adjust because the greater than character is aligned to the bottom with this font. Move closer to the center.
        text-shadow: none;
    }
}

.button-call-to-action-reverse {
    @extend .button-primary-reverse;
    padding: em(6px, $fh-psd-button-font-size) em(20px, $fh-psd-button-font-size) em(6px, $fh-psd-button-font-size) em(35px, $fh-psd-button-font-size);
    @extend .button-text-shadow;

    &::after {
        padding: 0 em(4px, $fh-psd-button-font-size) 0 em(5px, $fh-psd-button-font-size); // Adjust because the greater than character is aligned to the bottom with this font. Move closer to the center.
        text-shadow: none;
    }
}

.button-shadow {
    box-shadow: 5px 9px 20px 0px rgba(75, 75, 75, 0.5);
}

@keyframes spin {
    from {
        transform: rotate(0deg);

    }
    to {
        transform: rotate(359deg);
    }
}

@mixin loader($color) {
    animation: spin 500ms infinite linear;
    border: 0.2rem solid $color;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
}

%loader {
    @include loader($white);
}

@mixin center($width, $height: 0) {
    position: absolute;
    @if $height != 0 {
        left: calc(50% - (#{$width} / 2));
        top: calc(50% - (#{$height} / 2));
    } @else {
        left: calc(50% - (#{$width} / 2));
        top: calc(50% - (#{$width} / 2));
    }
}

@mixin button--block {
    display: block;
    margin-right: 0;
    text-align: center;
    width: 100%;
}

%button-block {
    @include button--block;
}

.button {
    &-group {
        .button:last-child {
            margin-right: 0;
        }
    }

    &--social {
        padding: 0.3em 0.6em;
    }

    &--block {
        @extend %button-block;

        @include mobile-only {
            &-mobile {
                @include button--block;
            }
        }
    }

    &--loading {
        align-items: center;
        color: transparent !important;
        display: flex;
        justify-content: center;
        pointer-events: none;
        position: relative;

        &::after {
            @extend %loader;
            position: absolute;
        }
    }

    &--danger {
        background-color: $red;

        &:focus {
            background-color: $red;
        }

        &:hover {
            background-color: darken($red, 5%);
        }
    }

    &--small {
        font-size: rem(12px);
        margin: 0;
    }
}

