.readmore {
    p {
        display: block;
    }

    &__toggle:before {
        content: "Read Less";
    }

    &__description-container {
        margin-bottom: 15px;
    }

    &__description {
        overflow: hidden;

        &::after {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
            bottom: 0;
            content: "";
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
        }

        @include tablet {
            &::after {
                background: linear-gradient(to bottom, rgba(255,255,255,0) -30%, white 100%);
                height: 50%;
            }
        }

        &.expanded::after {
            display: none;
        }
    }

    &__description-read-more {
        position: relative;
        visibility: visible;

        &.hide {
            display: block !important;
            position: absolute;
            visibility: hidden;
        }
    }
}

.readmore--collapsed {
    p:first-child {
        display: block;
    }

    p {
        display: none;
    }

    .readmore__toggle:before {
        content: "Read More";
    }
}