.fishing-trail__header {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 156px;
    width: 100%;

    @media screen and (min-width: 430px) {
        height: 250px;
    }

    @media screen and (min-width: 1024px) {
        height: 350px;
    }

    @at-root {
        h1.fishing-trail__header-title {
            display: none;
            @media screen and (min-width: 800px) {
                display: block;
            }
        }

        h2.fishing-trail__header-title {
            display: block;
            font-size: em(50px);
            @media screen and (min-width: 500px) {
                font-size: em(65px);
            }

            @media screen and (min-width: 800px) {
                display: none;
            }
        }
    }

    &-title {
        color: $blue;
        font-size: rem(65px); // Slightly smaller than PSD because the web font doesn't look as good when the letter spacing is reduced.
        line-height: em(60px, 70px);
        margin: 0;
        @include font-sucrose("Bold");
        padding: 25px 0;
        text-align: left;
        text-transform: uppercase;

        @media screen and (min-width: 800px) {
            color: $white;
            font-size: rem(80px);
            line-height: em(70px, 80px);
            padding: 125px 0 50px 0; // Centered at 1024px, left and right padding no longer needs to match .content__padded
            text-align: center;
            text-shadow: 0 0 15px $black, 0 0 2px $black;

            span {
                font-size: 3rem;
            }
        }

        @include laptop {
            padding: 200px 0 50px 0;
        }
    }

    .no-webp &--default, .no-js &--default {
        background-image: url("/images/page_headers/fishing-trail-header_mobile.png");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/fishing-trail-header_tablet.png");
        }

        @media screen and (min-width: 1024px) {
            background-image: url("/images/page_headers/fishing-trail-header.png");
        }
    }

    .webp &--default {
        background-image: url("/images/page_headers/fishing-trail-header_mobile.webp");

        @media screen and (min-width: 430px) {
            background-image: url("/images/page_headers/fishing-trail-header_tablet.webp");
        }

        @media screen and (min-width: 1024px) {
            background-image: url("/images/page_headers/fishing-trail-header.webp");
        }
    }

    &--default {
        background-position: center;
        background-size: auto;
    }
}