/*
 * Amplitude Classes
 */
.amplitude-repeat-on, .amplitude-shuffle-on {
    > i {
        color: $yellow !important;
    }
}

.amplitude-paused {
    i.icon-pause {
        display: none;
    }

    i.icon-play {
        display: block;
    }
}

.amplitude-playing {
    i.icon-pause {
        display: block;
    }

    i.icon-play {
        display: none;
    }
}

td.amplitude-playing {
    .player__tracks-playing {
        display: block;
    }

    .player__tracks-track {
        display: none;
    }
}

td.amplitude-paused {
    .player__tracks-playing {
        display: none;
    }

    .player__tracks-track {
        display: block;
    }
}

.player {
    background: $off-white;
    border: 2px solid $off-white;
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    &__download {
        padding: 0.4em 1em;
        position: relative;
        text-align: center;
        z-index: 50;

        span {
            display: none;
        }

        @include laptop {
            span {
                display: inline-block;
            }
        }
    }

    &__control-bar {
        background-color: $navy;
        display: flex;
        flex-direction: row;
        padding: 20px 0;

        &-left, &-right {
            width: 20%
        }

        &-center {
            flex: 1
        }
    }

    &__control {
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        height: 50px;
        justify-content: center;
        margin: 0 5px;
        width: 50px;

        @include tablet {
            margin: 0 15px;
        }

        i {
            color: $white;
            font-size: rem(16);
        }

        &--play-pause {
            border: 1px solid $white;
            border-radius: 100%;
        }
    }

    &__controls {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;

        &-left, &-right {
            width: 20%
        }

        &-center {
            display: flex;
            justify-content: space-around;
        }

        &-play-pause {
        }

        div#repeat-container {
            float: left;
            padding-top: 20px;
            width: 25%;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        padding: 0;
        width: 100%;

        @include tablet {
            flex-direction: row;
        }
    }

    &__album {
        padding: 15px;
    }

    &__tracks {
        padding: 15px 0 15px 0;
        width: 100%;

        @include tablet {
            padding-right: 15px;
        }

        table {
            border-collapse: collapse;
            margin: 0;
            width: 100%;
        }

        th, td {
            border-bottom: 1px solid $beige;
            width: auto;
        }

        tr {
            cursor: pointer;

            &:hover {
                background-color: $beige;

                .amplitude-playing {
                    .player__tracks-playing {
                        display: block !important;
                    }
                }

                .player__tracks-play {
                    display: block;
                }

                .player__tracks-playing {
                    display: none;
                }

                .player__tracks-track {
                    display: none;
                }
            }
        }

        td {
            padding: 5px;
        }

        td:first-child {
            width: 40px;
        }

        &-playing, &-play {
            font-size: rem(14);
        }

        &-play {
            display: none;
        }

        &-duration {
            padding-right: 15px !important;
            text-align: right;
            width: 10% !important;
        }

        &-download {
            text-align: right;
        }
    }

    &__current-time, &__duration {
        color: $white;
        font-size: rem(14);
        text-align: center;
        width: 15%;
    }

    &__time {
        display: flex;
    }

    &__progress {
        cursor: pointer;
        float: left;
        height: 20px;
        position: relative;
        width: 70%;

        &:hover input[type=range].amplitude-song-slider::-webkit-slider-thumb {
            display: block;
        }

        &:hover input[type=range].amplitude-song-slider::-moz-range-thumb {
            visibility: visible;
        }

        progress.player__progress-played {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            border: none;
            border-radius: 5px;
            height: 4px;
            left: 0;
            position: absolute;
            right: 0;
            top: 8px;
            width: 100%;
            z-index: 60;
            /* Needed for Firefox */
        }

        /* Small only */
        @media screen and (max-width: 39.9375em) {
            div#amplitude-player div#amplitude-left {
                width: 100%;
            }
            div#amplitude-player div#amplitude-left img[amplitude-song-info="cover_art_url"] {
                height: auto;
                width: auto;
            }
        }

        /* Small only */
        @media screen and (max-width: 39.9375em) {
            div#amplitude-player div#amplitude-right {
                width: 100%;
            }
        }

        @media all and (-ms-high-contrast: none) {
            *::-ms-backdrop, progress.player__progress-played {
                background-color: #cfd8dc;
                border: none;
                color: $yellow;
            }
        }

        @supports (-ms-ime-align: auto) {
            div#progress-container progress.player__progress-played {
                color: $yellow;
                border: none;
            }
        }

        progress.player__progress-played[value]::-webkit-progress-bar {
            background: none;
            border-radius: 5px;
        }

        progress.player__progress-played[value]::-webkit-progress-value {
            background-color: $yellow;
            border-radius: 5px;
        }

        progress.player__progress-played::-moz-progress-bar {
            background: none;
            background-color: $yellow;
            border-radius: 5px;
            height: 4px;
        }

        progress.player__progress-buffered {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            background-color: #d7dee3;
            border: none;
            border-radius: 5px;
            height: 4px;
            left: 0;
            position: absolute;
            right: 0;
            top: 8px;
            width: 100%;
            z-index: 10;
        }

        progress.player__progress-buffered[value]::-webkit-progress-bar {
            background-color: #cfd8dc;
            border-radius: 5px;
        }

        progress.player__progress-buffered[value]::-webkit-progress-value {
            background-color: $off-white;
            border-radius: 5px;
            transition: width .1s ease;
        }

        progress.player__progress-buffered::-moz-progress-bar {
            background: none;
            background-color: $off-white;
            border-radius: 5px;
            height: 4px;
        }

        progress::-ms-fill {
            border: none;
        }

        @-moz-document url-prefix() {
            progress.player__progress-buffered {
                border: none;
            }
        }

        @media all and (-ms-high-contrast: none) {
            *::-ms-backdrop, progress.player__progress-buffered {
                border: none;
                color: $off-white;
            }
        }

        @supports (-ms-ime-align: auto) {
            progress.player__progress-buffered {
                color: $off-white;
                border: none;
            }
        }

        input[type=range] {
            -webkit-appearance: none;
            background-color: inherit;
            cursor: pointer;
            height: 20px;
            margin: 7.5px 0;
            outline: none;
            position: absolute;
            top: -7px;
            width: 100%;
            z-index: 20;
        }

        input[type=range]:focus {
            outline: none;
        }

        input[type=range]::-webkit-slider-runnable-track {
            background: #0075a9;
            border: 0 solid #010101;
            border-radius: 0;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
            cursor: pointer;
            height: 0px;
            width: 100%;
        }

        input[type=range]::-webkit-slider-thumb {
            -webkit-appearance: none;
            background: $yellow;
            border: 1px solid $yellow;
            border-radius: 16px;
            box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
            cursor: pointer;
            height: 15px;
            margin-top: -7.5px;
            width: 15px;
        }

        input[type=range]:focus::-webkit-slider-runnable-track {
            background: #00adfb;
        }

        input[type=range]::-moz-range-track {
            background: #0075a9;
            border: 0px solid #010101;
            border-radius: 0px;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
            cursor: pointer;
            height: 0px;
            width: 100%;
        }

        input[type=range]::-moz-range-thumb {
            background: $yellow;
            border: 1px solid $yellow;
            border-radius: 16px;
            box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
            cursor: pointer;
            height: 15px;
            width: 15px;
        }

        input[type=range]::-ms-track {
            background: transparent;
            border-color: transparent;
            color: transparent;
            cursor: pointer;
            height: 0px;
            width: 100%;
        }

        input[type=range]::-ms-fill-lower {
            background: #003d57;
            border: 0px solid #010101;
            border-radius: 0px;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
        }

        input[type=range]::-ms-fill-upper {
            background: #0075a9;
            border: 0px solid #010101;
            border-radius: 0px;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
        }

        input[type=range]::-ms-thumb {
            background: $yellow;
            border: 1px solid $yellow;
            border-radius: 16px;
            box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
            cursor: pointer;
            display: block;
            height: 0px;
            height: 15px;
            width: 15px;
        }
    }

    &__cover {
        margin-bottom: 5px;
    }

    &__meta {
        text-align: center;

        &-song {
            font-size: rem(20);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-album {
            @include font-montserrat("Bold");
            color: $navy;
            font-size: rem(14);
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }

    &--small {
        flex-direction: column;

        @include tablet {
            flex-direction: row;
        }

        .player__info {
            @include tablet {
                width: 150px;
            }
        }

        .player__album {
            padding: 0;
        }

        .player__cover {
            display: flex;
            margin: 0;

            > img {
                height: 100%;
            }
        }

        .player__control-bar {
            flex: 1;
            flex-direction: column;
            padding: 15px 0;

            &-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0 15px 15px;
                @include tablet {
                    flex-direction: row;
                    padding: 0 15px 0 0;
                }
            }
        }

        .player__control-bar-center {
            align-items: center;
            display: flex;
            flex-direction: column;
            @include tablet {
                flex-direction: row;
            }
        }

        .player__controls {
            margin-left: -55px;

            @include tablet {
                margin-bottom: 0;
                margin-left: 0;
            }
        }

        .player__time {
            padding: 0 15px;
            width: 100%;
            @include tablet {
                padding-right: 15px;
                width: 100%;
            }
        }

        .player__meta-song {
            color: $white;
            margin-bottom: 15px;
            margin-left: 0;
            text-align: center;

            @include tablet {
                margin-bottom: 0;
                margin-left: 15px;
                text-align: left;
            }
        }
    }
}
