.cart {
    background-color: $off-white;

    &__header {
        padding: 20px;

        &-title {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-top: 0;
        }

        &-icon {
            color: $black;
            display: inline-block;
            font-size: em(24px);
        }

        .button {
            margin-bottom: 0;
        }
    }

    &__item {
        align-items: center;
        border-bottom: 1px dotted $gray;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        padding: 10px 0;
        text-align: left;

        &:first-of-type {
            border-top: 1px dotted $gray;
            margin-top: 8px;
        }

        &-remove {
            cursor: pointer;
            padding: 0 15px;
        }

        i.icon-close {
            color: $red;
        }
    }

    hr {
        border: 0;
        border-top: 1px dotted $gray;
        height: 0;
        margin: 0;
    }
}
