.tag-icon {
    align-items: center;
    background-color: $blue;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    margin: 5px 10px;
    min-height: 56px;
    padding: 0 5px;
    position: relative;
    text-align: center;
    @include font-montserrat("Semibold");
    width: calc(50% - 25px);

    @include media-query(678px) {
        margin: 5px;
        min-height: 66px;
        padding: 0 15px;
        width: 268px;
    }

    &:hover {
        // a weird line shows up behind the icons on hover...
        color: transparent;
    }

    &__content {
        align-items: center;
        display: flex;
        justify-content: center;
        line-height: 56px;
        padding: 0;

        @include media-query(678px) {
            line-height: 66px;
            padding: 15px 0;
        }

        i {
            color: $white;
            font-size: em(24);

            @include media-query(450px) {
                font-size: em(36);
            }

            @include media-query(678px) {
                font-size: em(48);
            }
        }

        span {
            color: $white;
            display: inline-block;
            font-size: em(14);
            line-height: 100%;
            margin-left: 5px;
            text-align: left;

            @include media-query(678px) {
                font-size: em(14);
            }
        }
    }

    &__results {
        border-left: 1px solid $white;
        color: $white;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 70px;

        span {
            font-size: 13px;
            font-weight: 300;
            line-height: 100%;
            text-transform: uppercase;
            vertical-align: top;
        }

        span.number {
            @include font-montserrat("Bold");
            display: block;
            font-size: 20px;
            font-weight: 700;
            line-height: 100%;
            margin-bottom: 3px;
            margin-top: 14px;
            padding-bottom: 0;
        }
    }

    &--with-results {
        box-sizing: border-box;
        height: 50px;
        margin: 1%;
        padding: 0 70px 0 0;
        width: 100%;

        @include media-query(600px) {
            flex-basis: 48%;
        }

        @include laptop {
            flex-basis: 31%;
        }

        .tag-icon__content {
            padding: 0;

            i {
                font-size: em(38);

                @include media-query(678px) {
                    font-size: em(45);
                }
            }
        }
    }
}

.tag-icons-grid {
    display: flex;
    flex-wrap: wrap;

    &--things-to-do {
        margin: auto auto em(40px);
        width: 100%;

        @include media-query(678px) {
            width: 616px;
        }

        @include laptop {
            width: 924px;
        }

        @include media-query(1440px) {
            width: 1232px;
        }
    }

    &--tag-page, &--search-page, &--industry-forms {
        margin: auto;
        width: 98%;

        @include laptop {
            width: 924px;
        }
    }

    &--industry-forms {
        .tag-icon {
            box-sizing: border-box;
            height: 50px;
            margin: 1% auto;
            text-transform: uppercase;
            width: 96%;

            @include media-query(550px) {
                flex-basis: 48%;
                margin: 1%;
            }

            @include laptop {
                flex-basis: 31%;
            }
        }
    }
}