#locale-select {
    display: none;
}

#locale-select:checked + .locale-select__button > .locale-select__list {
    display: block !important;
}

.locale-select {
    height: 35px;

    &__button {
        display: inline-flex;
        height: 100%;
        position: relative;
    }

    &__list {
        background: $blue;
        border: 1px solid $navy;
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 32px;
        z-index: 100;
    }

    &__country {
        align-items: center;
        color: $white !important;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        margin: 0 !important;
        padding: 4px 8px;
        text-decoration: none;
        text-transform: none;

        @include laptop {
            width: 50px;
        }

        &:hover {
            background-color: $navy;
        }

        div {
            display: flex;
            flex: 1;
        }

        span, i {
        }

        &--selected {
            border: 1px solid $navy;

            &:hover {
                background-color: transparent;
            }
        }
    }

    img {
        height: 15px;
        width: 20px;
    }

    i {
        color: $yellow;
    }
}