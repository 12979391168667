.tourism-industry {

    &__forms {
        margin-bottom: 100px;

        h1 {
            @include font-sucrose("Bold");
            text-align: center;
        }

        p {
            text-align: center;
        }
    }

}