.listing {
    &__header {
        background-image: url("/images/layout/listing/header.png");
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: contain;
        height: auto;
        position: relative;

        &-inside {
            padding-bottom: rem(20);
            padding-top: rem(20);

            @include tablet {
                padding-bottom: rem(40);
                padding-top: rem(40);
            }

            @include laptop {
                padding-bottom: 0;
            }
        }

        &-info {
            @include laptop {
                display: none;
            }
        }

        &-name {
            margin-top: 0;
        }

        &-address {
            @include font-montserrat("Bold");
            color: $blue;
            font-size: rem(18px);
        }

        &-notice {
            @include font-montserrat("SemiBold");
            color: $orange;
            font-size: em(22px, 16px);
            font-weight: bold;
            margin-top: em(15px, 22px);

            i {
                margin-right: 10px;
            }

            p {
                font-size: em(18px, 22px);
            }

            @include tablet {
                font-size: em(24px, 16px);
                margin-top: em(15px, 24px);

                p {
                    font-size: em(20px, 24px);
                }
            }
        }
    }

    &--state-park {
        .listing__header {
            height: 325px;
            overflow: hidden;
            position: relative;

            @media screen and (min-width: 500px) {
                height: 375px;
            }

            @include laptop {
                display: block;
                height: 404px;
            }

            &-image {
                display: block;
                height: 115%;
                left: 95%;
                position: relative;
                transform: translate(-50%, 0);

                @media screen and (min-width: 500px) {
                    height: 100%;
                    left: 55%;
                }

                @include laptop {
                    height: auto;
                    left: 50%;
                }

                @include desktop {
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    transform: translate(0, 0);
                    width: 100%;
                }
            }

            &-name {
                color: $white;
                font-size: em(50);
                left: 0;
                line-height: em(45px, 50px);
                padding: 25px;
                @include font-sucrose("Bold");
                position: absolute;
                right: 0;
                text-shadow: 0 0 15px $black, 0 0 2px $black;
                top: 25px;

                @media screen and (min-width: 500px) {
                    text-align: center;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                @include laptop {
                    font-size: em(65);
                    padding-right: 50px;
                }
            }
        }
    }
}