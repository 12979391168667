/*
Over Ride!!

File to include last so that the classes take priority.
This is mostly needed because twitter bootstrap doesn't adhere to BEM style and because _other.scss is loaded too soon
*/

/*
Add this class to any element that you want to be sticky within its parent container
Refer to https://github.com/wilddeer/stickyfill for the polyfill and http://wilddeer.github.io/stickyfill/test/ for examples of how it works
*/
.sticky, .sticky-offset {
    position: sticky;
    top: 0;
}

.sticky:before,
.sticky:after,
.sticky-offset:before,
.sticky-offset:after {
    content: "";
    display: table;
}