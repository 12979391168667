.hero {
    background: no-repeat top center;
    background-size: cover;
    height: 200px;
    width: 100%;

    @media screen and (min-width: 430px) {
        height: 300px;
    }

    @media screen and (min-width: 800px) {
        height: 400px;
    }

    @include laptop {
        background-position: top center;
        height: 550px;
    }

    @media screen and (min-width: 1920px) {
        // All backgrounds have this, it was extracted out
        background-position: bottom center;
    }

    @at-root {
        h1.hero__title {
            display: none;
            @media screen and (min-width: 800px) {
                display: block;
            }
        }

        h2.hero__title {
            display: block;
            font-size: em(50px);
            @media screen and (min-width: 500px) {
                font-size: em(65px);
            }

            @media screen and (min-width: 800px) {
                display: none;
            }
        }
    }

    &__title {
        color: $blue;
        font-size: rem(65px); // Slightly smaller than PSD because the web font doesn't look as good when the letter spacing is reduced.
        line-height: em(60px, 70px);
        margin: 0;
        @include font-sucrose("Bold");
        padding: 25px 0;
        text-align: left;
        text-transform: uppercase;

        @media screen and (min-width: 800px) {
            color: $white;
            font-size: rem(80px);
            line-height: em(70px, 80px);
            padding: 125px 0 50px 0; // Centered at 1024px, left and right padding no longer needs to match .content__padded
            text-align: center;
            text-shadow: 0 0 15px $black, 0 0 2px $black;

            span {
                font-size: 3rem;
            }
        }

        @include laptop {
            padding: 200px 0 50px 0;
        }
    }
}