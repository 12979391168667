.brochures {
    &__spacer {
        background: $white;
        height: 40px;
        width: 100%;
        z-index: 11;
    }

    &__help {
        color: #666666;
        font-size: 12px;
        padding: 20px 0 40px;
    }

    &__region-group {
        border-bottom: 2px solid $off-white;
        border-left: 2px solid $off-white;
        border-right: 2px solid $off-white;
        padding: 30px 0.5rem 0;
        z-index: -10;
    }

    &__header-button {
        display: block;
        padding: em(20px, 18px) 0 0;

        > .button {
            font-size: rem(14px);
            line-height: em(20px, 14px);
            margin: 0;
        }
    }

    &__cart-buttons {
        padding: 15px 20px;

        .button {
            display: block;
            font-size: em(13px);
            margin: 0 0 0.5rem;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }

            @include media-query(400px) {
                display: inline-block;
            }

            @include laptop {
                font-size: em(11px);
            }
        }
    }
}

.brochure {

    &__header-section {
        margin-bottom: em(5px, 16px);
    }

    &__header-title {
        background-color: $off-white;
        color: $gray;
        display: block;
        @include font-sucrose("Bold");
        font-size: em(28px);
        font-weight: normal;
        line-height: 1em;
        padding: em(15px, 30px) em(44px, 30px);
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
        word-wrap: break-word;
        z-index: 10;

        span {
            display: none;
            @include font-montserrat("SemiBold");
            font-size: em(22px, 30px);
            line-height: 1em;
            margin-left: 5px;
            text-transform: none;
            vertical-align: middle;

            @include laptop {
                display: inline-block;
            }
        }

        img {
            display: none;
            float: right;
            height: 30px;
            margin-left: 15px;
            vertical-align: middle;

            @include media-query(420px) {
                display: inline-block;
            }

        }
    }

    &__modal {
        &-content {
            padding: 15px;

            &-details {
                display: block;
                justify-items: stretch;
                @include media-query(600px) {
                    display: flex;
                }
            }

            &-image {
                flex: 1 1 auto;
                text-align: center;

                > img {
                    max-height: 200px;

                    @include media-query(600px) {
                        max-height: none;
                    }
                }
            }

            &-text {
                flex: 2 1 auto;
                margin-left: 15px;
            }

            &-name {
                font-size: 20px;
                font-weight: bold;
                margin-top: 10px;

                @include laptop {
                    margin-top: 0;
                }
            }

            &-description {
                @include font-montserrat("Regular");
                font-size: em(15px);
                line-height: 1.4;
                margin-bottom: 30px;
            }

            &-btn-group {
                bottom: 10px;
            }

            .button {
                display: inline-block;
                font-size: em(13px);
                padding: em(4px, $fh-psd-button-font-size) em(13px, $fh-psd-button-font-size) em(5px, $fh-psd-button-font-size) em(13px, $fh-psd-button-font-size);

                &-primary {
                    padding: em(4px, $fh-psd-button-font-size) em(26px, $fh-psd-button-font-size) em(5px, $fh-psd-button-font-size) em(13px, $fh-psd-button-font-size);
                }

                @include media-query(600px) {
                    margin-top: 20px;
                }
            }
        }

        .v-modal__content {
            @include font-montserrat("Regular");
            font-size: rem(18);
            line-height: rem(24);
            vertical-align: top;

            @include tablet {
                width: 725px;
            }

            @include media-query(990px) {
                width: 900px;
            }
        }
    }
}

// TODO: Clean Up

.submit-bttn {
    font-size: em(16);
}

/** Brochures **/
.brochure-item {
    text-align: center;

    &--added {
        img {
            opacity: 0.25;
        }

        .brochure-item__image {
            position: relative;
        }

        .brochure-item__image:after {
            color: $green;
            content: "\e95a";
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: "travicons" !important;
            font-size: 36px;
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            height: 36px;

            /* Better Font Rendering =========== */
            left: 50%;
            line-height: 1;

            margin-left: -18px;
            margin-top: -18px;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            speak: none;
            text-transform: none;
            top: 50%;
            width: 36px;
        }
    }

    &__info {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
    }

    &__image {
        position: relative;
    }

    &__name {
        color: #000000;
        display: block;
        height: 75px;
        line-height: em(22);
        max-width: 100%;
        overflow: hidden;
        padding-top: 5px;
        width: auto;

        @include media-query-between(768px, 860px) {
            width: 90px;
        }
    }

    &__buttons {
        padding-top: 4px;
    }

    .button {
        display: inline-block;
        font-size: em(13px);
        margin: 0 0 0.5rem 0;
        padding: em(4px, $fh-psd-button-font-size) em(13px, $fh-psd-button-font-size);
        width: 100%;
    }

    select {
        margin-bottom: em(15px);
        min-width: auto;
        width: 100%;
    }

    select.brochure-item__select {
        height: 27px;
        margin: 0 0 0.5rem 0;
    }
}

.brochure-cart-item {
    border-bottom: 1px dotted #4b4b4b;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 10px 0;
    text-align: left;

    &:first-child {
        border-top: 1px dotted #4b4b4b;
        margin-top: 8px;
    }

    &__name {
        line-height: 20px;
    }

    &__remove-link {
        align-self: center;
        color: #c12b30;
        @include font-montserrat("Bold");
        font-size: 1.3em;
        line-height: 1;
        padding: 0 8px;
        text-decoration: none;

        &:hover {
            color: darken(#c12b30, 5%);
            text-decoration: none;
        }
    }
}

.brochure-order-form {
    padding: 15px;

    h4 {
        margin-bottom: 20px;
        margin-top: 0;
    }

    .select-field select {
        background-color: #ffffff;
    }

    &__form-button {
        font-size: em(12);
    }

    &__confirmation-box {
        background-color: $beige;
        border: 1px solid $black;
        font-size: em(14px, 16px);
        margin-bottom: 10px;
        padding: 5px;

        .error {
            color: #dd0000;
        }
    }

    &__review-line {
        background-image: url("/images/shell/brochures/h_repeat.gif");
        background-repeat: repeat-x;
        border: 0;
        height: 1px;

        &--spaced {
            margin: 30px 0;
        }
    }
}

.brochure-list {
    button {
        max-width: 150px;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

/* Heading */
@include laptop {
    .page-heading p {
        font-size: 18px;
        line-height: em(26px, 18px);
    }
}

/* End Heading */

/* Form */
@media (max-width: 900px) {
    .formCountry {
        margin-left: 0
    }

    .brochure-form .formSection {
        margin: 0 auto 0;
        padding: 0;
        width: 100%;
    }

    .brochure-help {
        margin: 0 auto 21px;
    }

    .brochure-help span {
        color: #333333;
        font-size: 14px;
    }
}

@media (max-width: 700px) {
    .brochure-form .formSection {
        margin: 0 auto 0;
    }

    .brochure-help span {
        color: #333333;
        font-size: 14px;
    }
}
