.music-trail__milestones {
    align-items: center;
    background: rgba(255, 255, 255, 1);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(192, 180, 123, 1) 40%, rgba(192, 180, 123, 1) 100%);
    display: flex;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#c0b47b", GradientType=0);
    flex-direction: column;
    padding-top: 50px;
    position: relative;
    width: 100%;

    &-title {
        color: $music-trail-red;
        font-size: rem(40px);
        text-align: center;
    }

    &-wrap {
        overflow: hidden;
        padding: 30px 0 65px;
        position: relative;
        width: 100%;

        @include media-query(536px) {
            overflow: visible;
        }
    }

    &-border {
        background-image: url("/images/music-trail/milestone-border.jpg");
        background-repeat: repeat-x;
        bottom: 0;
        height: 55px;
        left: 0;
        position: absolute;
        right: 0;
    }

    &-nav {
        &-button--next, &-button--prev {
            color: $music-trail-red;
            cursor: pointer;
            font-size: rem(48px);
            position: absolute;
            top: 26%;

            @include media-query(536px) {
                bottom: 12%;
                top: auto;
            }
        }

        &-button--next {
            right: 0;
        }

        &-button--prev {
            left: 0;
        }
    }
}

$milestone-radius-small: 190px;
$milestone-radius-large: 230px;

.music-trail__milestone {
    align-items: center;
    display: flex;
    flex-direction: column;

    &-image {
        border: 5px solid $music-trail-gold;
        border-radius: 100%;
        box-shadow: 0 8px 10px 0 rgba(75, 75, 75, 0.5);
        display: block;
        height: $milestone-radius-small;
        margin-bottom: 25px;
        position: relative;
        width: $milestone-radius-small;

        @include laptop {
            height: $milestone-radius-large;
            margin-bottom: 25px;
            position: relative;
            width: $milestone-radius-large;
        }

        &:hover, &--active {
            .music-trail__milestone-hover {
                display: flex;
            }
        }
    }

    &-name {
        @include font-montserrat("Bold");
        color: $white;
        font-size: rem(20px);

        @include laptop {
            font-size: rem(22px);
            margin-bottom: rem(5px);
        }
    }

    &-description {
        color: $white;
        font-size: rem(16px);
        line-height: rem(17px);

        @include laptop {
            font-size: rem(17px);
            line-height: rem(19px);
        }
    }

    &-date {
        @include font-sucrose("Bold");
        color: $music-trail-red;
        font-size: rem(28px);

        @include laptop {
            font-size: rem(30px);
        }
    }

    &-hover {
        align-items: center;
        background-color: transparentize($music-trail-red, 0.25);
        border-radius: 100%;
        box-sizing: border-box;
        display: none;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 10px;
        text-align: center;
        width: 100%;

        @include laptop {
            margin-bottom: 25px;
            padding: 0 25px;
            position: relative;
        }
    }
}