.social-icon {
    background-color: $blue;
    display: block;
    height: 50px;
    text-align: center;

    width: 50px;

    i {
        color: $white;
        font-size: em(28);
        line-height: 50px;
        text-align: center;
    }

    &:hover {
        background-color: $yellow;
        text-decoration: none;
    }

}

.acrobat-info {
    font-size: rem(12);
    padding: em(20) 0;
}

.get-acrobat-button {
    @include hide-text();
    background: url("/images/shell/layout/get-adobe.png") no-repeat left top;
    display: inline-block;
    height: 39px;
    margin: 10px 0;
    width: 158px;
}

// Add this class to any element that should not have wrapping text
.no-wrap {
    white-space: nowrap;
}

.clickable {
    cursor: pointer;
}

// js-display is used to hide elements that should only work with javascript. The class is removed in base.js.
.hidden, .js-display {
    display: none;
}

.clearfix::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
    width: 0;
}

.required {
    color: $red;
    padding: 2px;
}

p.error-box {
    font-size: 0.75em;
}

.text--centered {
    text-align: center;
}

.text--right {
    text-align: right;
}

.text--left {
    text-align: left;
}

.img, .image {
    &--bordered {
        img {
            border: 5px solid $beige;
            box-shadow: 7px 10px 25px 0px rgba(75, 75, 75, 0.5);
            // remove extra space due to being inline
            box-sizing: border-box;
            vertical-align: bottom;
        }
    }

    &--responsive {
        display: block;
        height: auto;
        max-width: 100%;

        > img {
            width: 100%;
        }
    }
}

.clipped {
    overflow: hidden !important;
}

.notice {
    color: $orange;
}