@mixin site-search-result-section {
    border-bottom: 1px solid $beige;
    padding-bottom: 30px;
    margin-bottom: 50px;
}

#site-search-button {
    display: none;
}

#search {
    // To jump to the search section via a link
    height: 0;
    position: relative;
    top: -160px;
    width: 0;
}

#site-search-button:checked ~ .site-search {
    display: block;
}

.site-search {
    background-color: rgba($navy, 0.95);
    box-shadow: inset 0 40px 40px -40px;
    display: none;
    height: 100vh;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 25;

    form {
        font-size: rem(16px);
        padding: 100px 0 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    input[type="search"] {
        box-shadow: none;
        display: inline-block;
        font-size: 1em; // Use font-size from form.
        height: auto;
        line-height: normal;
        margin: 0px;
        padding: em(3px, 16px) em(10px, 16px) em(7px, 16px) em(10px, 16px);
        width: 225px;
        @include input-placeholder {
            color: $gray;
            text-transform: uppercase;
        }
    }

    button {
        display: inline-block;
        font-size: 1em; // Use font-size from form.
        margin: 0;
        padding: 0;
        width: em(34px, 16px);
    }

    &__button-close {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    &__input-wrap {
        display: flex;
        justify-content: center;
    }
}

.site-search-results {
    h1 {
        border-bottom: 1px solid $beige;
        margin-bottom: 30px;
        padding-bottom: 10px;
    }

    &__sections {
        @include site-search-result-section;

        .button {
            display: block;
            font-size: rem(16px);

            @media (min-width: 400px) {
                display: inline-block;
                font-size: rem(14px);
            }
        }
    }

    &__start {
        @include site-search-result-section;

        ul {
            list-style: none;
            padding-left: 0;

            li {
                font-size: em(24px);
                @include font-montserrat("Bold");
                margin: 10px 0;

                a {
                    color: $gray;
                }
            }
        }
    }

    &__listings {
        @include site-search-result-section;
    }

    &__events {
        @include site-search-result-section;
    }

    &__suggest {
        @include site-search-result-section;
    }

    &__photos {
        @include site-search-result-section;

        .photoThumb {
            display: inline-block;
            margin-right: 5px;
        }
    }

    &__videos {
        @include site-search-result-section;

        .searchVideoThumb {
            display: inline-table;
        }
    }

    &__deals {
        @include site-search-result-section;
    }

    &__articles {
        @include site-search-result-section;
    }

    &__road_trips {
        @include site-search-result-section;
    }

    &__rhythm_and_routes {
        @include site-search-result-section;
    }

    .button-call-to-action {
        margin-top: 15px;
    }
}

@media screen and (min-width: 768px) {

    #search {
        //To jump to the search section via a link
        top: -200px;
    }

    .site-search {
        form {
            font-size: rem(42px);
            padding-top: 130px;
        }

        input[type="search"] {
            padding-bottom: em(5px, 16px);
            padding-top: em(5px, 16px);
            width: 530px;
        }

        &__button-close {
            font-size: rem(16px);
        }
    }
}

@media screen and (min-width: 1024px) {
    #search {
        //To jump to the search section via a link
        top: -180px;
    }

    .site-search {
        input[type="search"] {
            width: 800px; // TODO: Should be 850px but social bar does not go behind search form.
        }
    }
}

@media screen and (min-width: 1920px) {
    #search {
        //To jump to the search section via a link
        top: -220px;
    }

    .site-search {
        input[type="search"] {
            width: 850px;
        }

        &__button-close {
            right: 25px;
            top: 25px;
        }
    }
}