ul.filter-grid {
    color: $gray;
    display: flex;
    flex-wrap: wrap;
    font-size: rem(14px);
    gap: 1%;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        background-color: $lightbeige;
        flex-basis: 100%;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        &:nth-child(even) {
            background-color: $white;
        }

        @include media-query(600px) {
            flex-basis: 49%;

            &:nth-child(4n), &:nth-child(4n-1) {
                background-color: $white;
            }

            &:nth-child(4n-2), &:nth-child(4n-3) {
                background-color: $lightbeige;
            }
        }

        @include media-query(930px) {
            flex-basis: 32%;

            &:nth-child(6n), &:nth-child(6n-1), &:nth-child(6n-2) {
                background-color: $white;
            }

            &:nth-child(6n-3), &:nth-child(6n-4), &:nth-child(6n-5) {
                background-color: $lightbeige;
            }
        }

        @include laptop {
            flex-basis: 24%;

            &:nth-child(8n), &:nth-child(8n-1), &:nth-child(8n-2), &:nth-child(8n-3) {
                background-color: $white;
            }

            &:nth-child(8n-4), &:nth-child(8n-5), &:nth-child(8n-6), &:nth-child(8n-7) {
                background-color: $lightbeige;
            }
        }

        input[type="checkbox"] {
            margin: 0 em(10px, 14px) em(3px, 14px) 0;
            -ms-transform: scale3d(1, 1, 1);
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            vertical-align: middle;
        }

        label {
            align-items: center;
            box-sizing: border-box;
            color: $gray;
            cursor: pointer;
            display: flex;
            height: 100%;
            line-height: 1.2em;
            padding: em(10px, 14px);
            text-transform: none;
        }
    }
}