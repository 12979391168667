.weather-widget {

    &__forecast {

        margin-bottom: 30px;

        &-icon {
            display: inline-block;
            margin-right: 5px;

            canvas {
                height: 100%;
                width: 100%;
            }
        }

        &-text {
            display: inline-block;
            font-size: em(24px);
            margin: 0;
            @include font-montserrat("SemiBold");
            vertical-align: top;
        }

        &-summary {
            margin: 0;
        }

    }

    &__daily {
        @include clearfix;
        margin-bottom: 15px;
        width: 100%;

        &-icon {
            float: left;
            margin-right: 2%;
            width: 10%;

            canvas {
                height: 100%;
                width: 100%;
            }
        }

        &-day {
            @include font-montserrat("SemiBold");
            float: left;
            width: 12%;
        }

        &-forecast {
            float: right;
            margin: 0 10% 0;
            position: relative;
            width: 56%;
        }

        &-min, &-max {
            position: absolute;
            width: 10%;
        }

        &-min {
            text-align: right;
        }

        &-max {
            text-align: left;
        }

        &-bar {
            background-color: #1e6aaa;
            border-radius: 10px;
            height: 20px;
            position: absolute;
            width: auto;
        }
    }

    &__updated-text {
        font-size: em(12px);
        font-style: italic;
    }
}